import * as R from "ramda";
import {
  dragableBehaviour,
  binBehaviour,
  defaultColor,
  launchableBehavior,
  launchableBinBehaviour,
  defaultCollectableBehavior,
  defaultDrawableBehavior,
} from "./../constants/defaultValues";

// function to return the hex color from rgb string, to display on screen
export function returnHex(color) {
  const rgba = color.replace(/^rgba?\(|\s+|\)$/g, "").split(",");
  const hex = `#${(
    (1 << 24) +
    (parseInt(rgba[0]) << 16) +
    (parseInt(rgba[1]) << 8) +
    parseInt(rgba[2])
  )
    .toString(16)
    .slice(1)}`;
  return hex;
}

export function fontSizeArray() {
  var sizeArray = [];
  for (var i = 1; i <= 40; i++) {
    sizeArray.push(i);
  }
  return sizeArray;
}

export function targetAreaPercentage() {
  var areaPercentage = [];
  for (var i = 1; i <= 100; i++) {
    areaPercentage.push(i);
  }
  return areaPercentage;
}

export function reloadArray() {
  var sizeArray = [];
  for (var i = 1; i <= 10; i++) {
    sizeArray.push(i);
  }
  return sizeArray;
}

export function setColorPickerCSS(color) {
  return {
    default: {
      color: {
        width: "36px",
        height: "14px",
        borderRadius: "2px",
        background: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
      },
      swatch: {
        padding: "5px",
        background: "#fff",
        borderRadius: "1px",
        boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
        display: "inline-block",
        cursor: "pointer",
      },
      popover: {
        position: "absolute",
        zIndex: "2",
      },
      cover: {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
      },
    },
  };
}

export function setColorPickerCSSStyle(color) {
  return {
    default: {
      color: {
        width: "36px",
        height: "14px",
        borderRadius: "2px",
        background: color,
      },
      swatch: {
        padding: "5px",
        background: "#fff",
        borderRadius: "1px",
        boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
        display: "inline-block",
        cursor: "pointer",
      },
      popover: {
        position: "absolute",
        zIndex: "2",
      },
      cover: {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
      },
    },
  };
}

export function updateGameObjectToSourceCode(
  sceneIndex,
  sourceCode,
  gameObject,
  objType,
  gameObjectKey
) {
  var selectedScene = R.path(["scenes"], sourceCode)[sceneIndex];
  delete gameObject["objectType"];
  var objects = {};
  if (R.path(["objects"], selectedScene)) {
    objects = R.path(["objects"], selectedScene);
    var editedObject = R.path([gameObjectKey], objects);
    if (editedObject) {
      objects[gameObjectKey] = gameObject;
      selectedScene["objects"] = objects;
    }
  }
  return sourceCode;
}

export function addGameObjectToSourceCode(sceneIndex, sourceCode, gameObject, objType) {
  var selectedScene = R.path(["scenes"], sourceCode)[sceneIndex];
  delete gameObject["objectType"];
  var objects = {};
  if (R.path(["objects"], selectedScene)) {
    objects = R.path(["objects"], selectedScene);
    setGameObjectProps(selectedScene, objects, gameObject, objType);
  } else {
    setGameObjectProps(selectedScene, objects, gameObject, objType);
  }
  return sourceCode;
}

export function setGameObjectProps(selectedScene, gameObjects, gameObject, objType) {
  delete gameObject["objectType"];
  var selectedLayers = R.path(["layers"], selectedScene);
  if (objType === "nonInteractiveObjects") {
    gameObjects[gameObject.name] = gameObject;
    selectedLayers.forEach(function (layer) {
      var objects = [];
      if (gameObject.name && gameObject.name === "background") {
        if (layer.id === "background" || layer.name === "background") {
          objects = layer.objects ? layer.objects : [];
          objects.push(gameObject.name);
          layer["objects"] = objects;
        }
      } else {
        if (layer.id === "static" || layer.name === "static") {
          objects = layer.objects ? layer.objects : [];
          objects.push(gameObject.name);
          layer["objects"] = objects;
        }
      }
    });
  } else if (objType === "dragableObjects") {
    selectedLayers.forEach(function (layer) {
      gameObject["behaviors"] = dragableBehaviour;
      gameObjects[gameObject.name] = gameObject;
      var objects = [];
      if (layer.id === "draggable" || layer.name === "draggable") {
        objects = layer.objects ? layer.objects : [];
        objects.push(gameObject.name);
        layer["objects"] = objects;
      }
    });
  } else if (objType === "binObjects") {
    selectedLayers.forEach(function (layer) {
      gameObject["behaviors"] = binBehaviour;
      gameObjects[gameObject.name] = gameObject;
      if (layer.id === "launchable" || layer.name === "launchable") {
        gameObject["behaviors"] = launchableBinBehaviour;
      }
      var objects = [];
      if (layer.id === "bin" || layer.name === "bin") {
        objects = layer.objects ? layer.objects : [];
        objects.push(gameObject.name);
        layer["objects"] = objects;
      }
    });
  } else if (objType === "launchableObjects") {
    selectedLayers.forEach(function (layer) {
      gameObject["behaviors"] = launchableBehavior;
      gameObjects[gameObject.name] = gameObject;
      var objects = [];
      if (layer.id === "launchable" || layer.name === "launchable") {
        objects = layer.objects ? layer.objects : [];
        objects.push(gameObject.name);
        layer["objects"] = objects;
      }
    });
  } else if (objType === "collectableObjects") {
    selectedLayers.forEach(function (layer) {
      gameObject["behaviors"] = defaultCollectableBehavior;
      gameObjects[gameObject.name] = gameObject;
      var objects = [];
      if (layer.id === "collectable" || layer.name === "collectable") {
        objects = layer.objects ? layer.objects : [];
        objects.push(gameObject.name);
        layer["objects"] = objects;
      }
    });
  } else if (objType === "drawableObjects") {
    selectedLayers.forEach(function (layer) {
      gameObject["behaviors"] = defaultDrawableBehavior;
      gameObjects[gameObject.name] = gameObject;
      var objects = [];
      if (layer.id === "drawable" || layer.name === "drawable") {
        objects = layer.objects ? layer.objects : [];
        objects.push(gameObject.name);
        layer["objects"] = objects;
      }
    });
  } else if (objType === "maskableObjects") {
    gameObjects[gameObject.name] = gameObject;
  }
  selectedScene["objects"] = gameObjects;
  return selectedScene;
}

export function deleteGameObject(
  sourceCode,
  sceneIndex,
  objectName,
  objectType,
  truengageCreativeType
) {
  var selectedScene = R.path(["scenes"], sourceCode)[sceneIndex];
  var objects = {};
  var layers = [];

  // to remove object from Game Objects
  if (R.path(["objects"], selectedScene)) {
    objects = R.path(["objects"], selectedScene);
    var editedObject = R.path([objectName], objects);
    if (editedObject) {
      delete objects[objectName];
      selectedScene["objects"] = objects;
    }

    // to delete targetObject from Drawing type Creative
    if (truengageCreativeType === "Drawing" && objectType === "nonInteractiveObjects" && objects) {
      Object.keys(objects).map((key, index) => {
        const behaviorsLens = R.lensPath([key, "behaviors", "drawable", "targetObjects"]);
        if (R.view(behaviorsLens, objects)) {
          var drawableTargetObject = R.view(behaviorsLens, objects);
          if (drawableTargetObject && drawableTargetObject.length > 0) {
            objects[key]["behaviors"]["drawable"]["targetObjects"] = [];
          }
        } 
      });
    }

    // to delete targetObject from Collectable type Creative
    if (
      truengageCreativeType === "Collectable" &&
      objectType === "nonInteractiveObjects" &&
      objects
    ) {
      Object.keys(objects).map((key, index) => {
        const behaviorsLens = R.lensPath([key, "behaviors", "collectable", "targetObjects"]);
        if (R.view(behaviorsLens, objects)) {
          var collectableTargetObjects = R.view(behaviorsLens, objects);
          if (collectableTargetObjects && collectableTargetObjects.length > 0) {
            var idx = R.findIndex(R.propEq("object", key))(collectableTargetObjects);
            collectableTargetObjects.splice(idx, 1);
          }
        }
      });
    }

    // reset holds and masks attributes on deleting dragable objects
    objectType === "dragableObjects" &&
      objects &&
      Object.keys(objects).map((key, index) => {
        const behaviorsLens = R.lensPath([key, "behaviors", "bin"]);
        if (R.view(behaviorsLens, objects)) {
          var binBehaviorObject = R.view(behaviorsLens, objects);
          if (binBehaviorObject && binBehaviorObject.holds) {
            binBehaviorObject.holds =
              binBehaviorObject.holds > 1 ? binBehaviorObject.holds - 1 : binBehaviorObject.holds;
          }
          if (binBehaviorObject && binBehaviorObject.masks && binBehaviorObject.masks[objectName]) {
            delete binBehaviorObject.masks[objectName];
          }
          objects[key]["behaviors"]["bin"] = binBehaviorObject;
        }
      });
  }

  // to remove objectName from Layer Array
  if (R.path(["layers"], selectedScene)) {
    layers = R.path(["layers"], selectedScene);
    for (let [key, value] of Object.entries(layers)) {
      if (value && value["objects"]) {
        var layerObjects = R.without([objectName], value["objects"]);
        value["objects"] = layerObjects;
      }
    }
  }

  return sourceCode;
}

export function addNewGameState(newGameStateCode, sourceCode) {
  var sceneArray = R.path(["scenes"], sourceCode);
  if (sceneArray && sceneArray.length > 0) {
    sceneArray.push(JSON.parse(newGameStateCode));
  }
  return sourceCode;
}

export function getDayStartTime() {
  var date = new Date();
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  return date.getTime();
}

export function getDayEndTime() {
  var date = new Date();
  date.setHours(23);
  date.setMinutes(59);
  date.setSeconds(59);
  return date.getTime();
}

export function setShapeObjectColor(shapeColor) {
  if (shapeColor.includes("rgb(")) {
    shapeColor = shapeColor.replaceAll("rgb(", "");
    shapeColor = shapeColor.replaceAll(")", "");
    shapeColor = shapeColor.replaceAll("'", "");
    var colorArr = shapeColor.split(",");
    var resColor = {};
    if (colorArr) {
      if (colorArr[0]) {
        resColor["r"] = colorArr[0].trim();
      }
      if (colorArr[1]) {
        resColor["g"] = colorArr[1].trim();
      }
      if (colorArr[2]) {
        resColor["b"] = colorArr[2].trim();
      }
      if (colorArr[3]) {
        resColor["a"] = colorArr[3].trim();
      }
    }
    return resColor;
  } else {
    return defaultColor;
  }
}

export function getholdingCapacityArray(size) {
  var capacityArray = [];
  for (var i = 1; i <= size; i++) {
    capacityArray.push(i);
  }
  return capacityArray;
}

export function getTweenType(tweenObject) {
  var tweenType = "invisible";
  var transitions = R.path(["behaviors", "tween", "transitions"], tweenObject);
  if (transitions && transitions.length === 1) {
    if (transitions[0].length === 3 && transitions[0][1].alpha === 0) {
      tweenType = "invisible";
    } else {
      tweenType = "move";
    }
  } else {
    tweenType = "resize";
  }
  return tweenType;
}

export function setInvisibleTween(tweenInvisibleBehavior) {
  var newBehavior = {};
  var behavior = {};
  var tween = {};
  var transitions = [];
  newBehavior["type"] = "addBehavior";
  newBehavior["target"] = tweenInvisibleBehavior.target;
  tween["loop"] = tweenInvisibleBehavior.loop;
  var transition = ["to", { alpha: 0 }, tweenInvisibleBehavior.delay];
  transitions.push(transition);
  tween["transitions"] = transitions;
  behavior["tween"] = tween;
  newBehavior["behaviors"] = behavior;
  return newBehavior;
}

export function setMoveTween(tweenMoveBehavior) {
  var newBehavior = {};
  var behavior = {};
  var tween = {};
  var transitions = [];
  newBehavior["type"] = "addBehavior";
  newBehavior["target"] = tweenMoveBehavior.target;
  tween["loop"] = tweenMoveBehavior.loop;
  var transitionArray = [];
  transitionArray.push("to");
  if (tweenMoveBehavior.moveDirection && tweenMoveBehavior.moveDirection === "x") {
    transitionArray.push({ x: tweenMoveBehavior.position });
  } else {
    transitionArray.push({ y: tweenMoveBehavior.position });
  }
  transitionArray.push(tweenMoveBehavior.delay);
  transitionArray.push("ease:-1");
  transitions.push(transitionArray);
  tween["transitions"] = transitions;
  behavior["tween"] = tween;
  newBehavior["behaviors"] = behavior;
  return newBehavior;
}

export function setResizeTween(sourceCode, sceneIndex, tweenResizeBehavior) {
  var newBehavior = {};
  var behavior = {};
  var tween = {};
  var transitions = [];
  var transitionArray = [];
  var selectedScene = R.path(["scenes"], sourceCode)[sceneIndex];
  var objects = {};
  if (R.path(["objects"], selectedScene)) {
    objects = R.path(["objects"], selectedScene);
  }
  if (objects && objects[tweenResizeBehavior.target]) {
    var currentObject = objects[tweenResizeBehavior.target];
    if (tweenResizeBehavior.resizeDirection && tweenResizeBehavior.resizeDirection === "x") {
      transitionArray.push("to");
      transitionArray.push({
        x: currentObject.position && currentObject.position.x ? currentObject.position.x : 0,
        scaleX: 1,
      });
      transitionArray.push(tweenResizeBehavior.delay);
    } else {
      transitionArray.push("to");
      transitionArray.push({
        y: currentObject.position && currentObject.position.y ? currentObject.position.y : 0,
        scaleY: 1,
      });
      transitionArray.push(tweenResizeBehavior.delay);
    }
  }
  transitions.push(transitionArray);
  newBehavior["type"] = "addBehavior";
  newBehavior["target"] = tweenResizeBehavior.target;
  tween["loop"] = tweenResizeBehavior.loop;
  transitionArray = [];
  transitionArray.push("to");
  var postionObj = {};
  if (tweenResizeBehavior.resizeDirection && tweenResizeBehavior.resizeDirection === "x") {
    postionObj["x"] = tweenResizeBehavior.position;
    postionObj["scaleX"] = tweenResizeBehavior.scale;
  } else {
    postionObj["y"] = tweenResizeBehavior.position;
    postionObj["scaleY"] = tweenResizeBehavior.scale;
  }
  transitionArray.push(postionObj);
  transitionArray.push(tweenResizeBehavior.delay);
  transitions.push(transitionArray);
  tween["transitions"] = transitions;
  behavior["tween"] = tween;
  newBehavior["behaviors"] = behavior;
  return newBehavior;
}

export function getCurrentObjectFromSourceCode(sourceCode, sceneIndex, gameObjectkey) {
  var currentObject = {};
  var selectedScene = R.path(["scenes"], sourceCode)[sceneIndex];
  var objects = {};
  if (R.path(["objects"], selectedScene)) {
    objects = R.path(["objects"], selectedScene);
  }
  if (objects && objects[gameObjectkey]) {
    currentObject = objects[gameObjectkey];
  }
  return currentObject;
}

export function setMoveTweenForGameObject(tweenMoveBehavior) {
  var behavior = {};
  var tween = {};
  var transitions = [];
  tween["loop"] = tweenMoveBehavior.loop;
  var transitionArray = [];
  transitionArray.push("to");
  if (tweenMoveBehavior.moveDirection && tweenMoveBehavior.moveDirection === "x") {
    transitionArray.push({ x: tweenMoveBehavior.position });
  } else {
    transitionArray.push({ y: tweenMoveBehavior.position });
  }
  transitionArray.push(tweenMoveBehavior.delay);
  transitionArray.push("ease:-1");
  transitions.push(transitionArray);
  tween["transitions"] = transitions;
  behavior["tween"] = tween;
  return behavior;
}

export function updateResizeTweenForGameObject(tweenResizeBehavior) {
  var behavior = {};
  var tween = {};
  var transitions = [];
  tween["loop"] = tweenResizeBehavior.loop;
  tweenResizeBehavior &&
    tweenResizeBehavior.transitions &&
    tweenResizeBehavior.transitions.length > 0 &&
    tweenResizeBehavior.transitions.map((transition) => {
      var transitionArray = [];
      transitionArray.push("to");
      var postionObj = {};
      if (tweenResizeBehavior.resizeDirection && tweenResizeBehavior.resizeDirection === "x") {
        postionObj["x"] = transition[1].x ? Number(transition[1].x) : "";
        postionObj["scaleX"] = transition[1].scaleX ? Number(transition[1].scaleX) : "";
      } else {
        postionObj["y"] = transition[1].y ? Number(transition[1].y) : "";
        postionObj["scaleY"] = transition[1].scaleY ? Number(transition[1].scaleY) : "";
      }
      transitionArray.push(postionObj);
      transitionArray.push(transition[2] ? Number(transition[2]) : 100);
      transitions.push(transitionArray);
    });
  tween["transitions"] = transitions;
  behavior["tween"] = tween;
  return behavior;
}

export function getTargetObjectSize(sourceCode, sceneIndex) {
  var selectedScene = R.path(["scenes"], sourceCode)[sceneIndex];
  var objects = {};
  if (R.path(["objects"], selectedScene)) {
    objects = R.path(["objects"], selectedScene);
  }
  return Object.keys(objects).length - 1;
}
