import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  closeEditGameObjectModal,
  cancelEditingOfObject,
  updateLoaderState,
} from "../../store/actions/gameObjects";
import { useDispatch } from "react-redux";
import { compose } from "redux";
import { Button, Modal } from "react-bootstrap";
import GameObjectForm from "../../utils/gameObject/gameObjectForm";
import validate from "../../utils/gameObject/validateGameObject";
import ImageGameObject from "../../ui/gameObjects/imageGameObject";
import TextGameObject from "../../ui/gameObjects/textGameObject";
import ShapeGameObject from "../../ui/gameObjects/shapeGameObject";
import SpritesheetGameObject from "../../ui/gameObjects/spritesheetGameObject";
import ContainerGameObject from "../../ui/gameObjects/containerGameObject";
import { SketchPicker } from "react-color";
import reactCSS from "reactcss";
import { setColorPickerCSSStyle } from "./../../utils/appUtils";
import {
  updateGameObjectToSourceCode,
  addGameObjectToSourceCode,
  getCurrentObjectFromSourceCode,
} from "../../utils/appUtils";
import { updateSelectedTrueCreativePropsFunc } from "../../store/actions/truengageCreatives";
import BinGameObjectBehavior from "./../../ui/gameObjectBehaviors/binGameObjectBehavior";
import GameObjectBehavior from "./../../ui/gameObjectBehaviors/gameObjectBehavior";
import LaunchableGameObjectBehavior from "./../../ui/gameObjectBehaviors/launchableGameObjectBehavior";
import DragableGameObjectBehavior from "../../ui/gameObjectBehaviors/dragableGameObjectBehavior";
import CollectableGameObjectBehavior from "./../../ui/gameObjectBehaviors/collectableGameObjectBehavior";
import DrawableGameObjectBehavior from "./../../ui/gameObjectBehaviors/drawableGameObjectBehavior";
import Loader from "./../../pages/loader";
const EditGameObjectModal = ({
  isShowEditGameObjectModal,
  gameObjectKey,
  gameObjectValue,
  type,
  dragableModel,
  sourceCode,
  objType,
  originalResponse,
  sceneNo,
  creativeUid,
  creativeAccountId,
  gameObjectArray,
  selectedLayers,
  truengageCreativeType,
  isLoaderEnabled,
}) => {
  const [gameObject, setGameObject] = useState({ name: "", position: "", objectType: "Image" });
  const [showContainerForm, setShowContainerForm] = useState(false);
  const [position, setPosition] = useState({
    x: "",
    y: "",
  });
  const [scale, setScale] = useState({
    x: "",
    y: "",
  });
  const [shadow, setShadow] = useState({
    color: "#D3D3D3",
    blur: 10,
  });
  const [frames, setFrames] = useState({
    width: "",
    height: "",
    regX: "",
    regY: "",
  });
  const [shapeDimension, setShapeDimension] = useState({
    offsetX: "",
    offsetY: "",
    width: "",
    height: "",
  });
  const [shapeColor, setShapeColor] = useState("#FFFFFF");
  const [framerate, setFramerate] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [emptyContainerCheck, setEmptyContainerCheck] = useState(null);
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [holds, setHolds] = useState(1);
  const [masks, setMasks] = useState({});
  const [actions, setActions] = useState([]);
  const styles = reactCSS(setColorPickerCSSStyle(shadow.color));

  const dispatch = useDispatch();

  // hook that initializes the form according to ADD/EDIT
  useEffect(() => {
    if (gameObjectValue) {
      let gameObjectLocal = JSON.parse(JSON.stringify(gameObjectValue)); //  deep clone
      setGameObject({ ...gameObjectLocal });
      setPosition(gameObjectValue.position ? gameObjectValue.position : { x: "", y: "" });
      setScale(gameObjectValue.scale ? gameObjectValue.scale : { x: "", y: "" });
      setShadow(gameObjectValue.shadow ? gameObjectValue.shadow : { color: "#D3D3D3", blur: 10 });
      setFrames(
        gameObjectValue.spritesheet && gameObjectValue.spritesheet.frames
          ? gameObjectValue.spritesheet.frames
          : { width: "", height: "", regX: "", regY: "" }
      );
      setFramerate(
        gameObjectValue.spritesheet && gameObjectValue.spritesheet.framerate
          ? gameObjectValue.spritesheet.framerate
          : ""
      );
      if (
        gameObjectValue.shape &&
        gameObjectValue.shape.graphics &&
        gameObjectValue.shape.graphics.length > 0
      ) {
        if (gameObjectValue.shape.graphics[0] && gameObjectValue.shape.graphics[0][1]) {
          setShapeColor(gameObjectValue.shape.graphics[0][1]);
        }
        if (gameObjectValue.shape.graphics[1]) {
          setShapeDimension({
            offsetX: gameObjectValue.shape.graphics[1][1]
              ? gameObjectValue.shape.graphics[1][1]
              : "",
            offsetY: gameObjectValue.shape.graphics[1][2]
              ? gameObjectValue.shape.graphics[1][2]
              : "",
            width: gameObjectValue.shape.graphics[1][3] ? gameObjectValue.shape.graphics[1][3] : "",
            height: gameObjectValue.shape.graphics[1][4]
              ? gameObjectValue.shape.graphics[1][4]
              : "",
          });
        }
      }

      setHolds(
        gameObjectValue.behaviors &&
          gameObjectValue.behaviors.bin &&
          gameObjectValue.behaviors.bin.holds
          ? gameObjectValue.behaviors.bin.holds
          : 1
      );
      setActions(
        gameObjectValue.behaviors &&
          gameObjectValue.behaviors.bin &&
          gameObjectValue.behaviors.bin.actions
          ? gameObjectValue.behaviors.bin.actions
          : []
      );
      setMasks(
        gameObjectValue.behaviors &&
          gameObjectValue.behaviors.bin &&
          gameObjectValue.behaviors.bin.masks
          ? gameObjectValue.behaviors.bin.masks
          : {}
      );

      setIsEdit(true);
    } else {
      setGameObject({ name: "", position: "", objectType: "Image" });
      setPosition({ x: "", y: "" });
      setScale({ x: "", y: "" });
      setFrames({ width: "", height: "", regX: "", regY: "" });
      setFramerate("");
      setShapeDimension({ offsetX: "", offsetY: "", width: "", height: "" });
      setShadow({
        color: "#D3D3D3",
        blur: 10,
      });

      setShapeColor("#FFFFFF");
      setEmptyContainerCheck(null);
      setDisplayColorPicker(false);
      setHolds(1);
      setMasks({});
      setActions([]);
      setIsEdit(false);
    }
    return () => {
      resetFormStates();
    };
  }, [gameObjectValue, isShowEditGameObjectModal]);
  //  ------- END OF HOOK

  useEffect(() => {
    setErrors({});
    setEmptyContainerCheck(null);
  }, [gameObject.objectType]);

  const [
    handleChange,
    handlePositionChange,
    handleSubmit,
    errors,
    setErrors,
    handleImageChange,
    handleScaleChange,
    handleFrameChange,
    handleSpriteSheetChange,
    handleShapeChange,
    handleShadowChange,
    handleBinBehaviorChange,
  ] = GameObjectForm(
    validate,
    gameObject,
    setGameObject,
    position,
    setPosition,
    scale,
    setScale,
    sceneNo,
    frames,
    setFrames,
    setFramerate,
    shapeDimension,
    setShapeDimension,
    setShapeColor,
    gameObjectArray,
    saveForm,
    shadow,
    setShadow,
    setHolds,
    setMasks
  );

  function closeModal(type) {
    if (type === "cancel") {
      setShowContainerForm(false);
      dispatch(cancelEditingOfObject());
      setErrors({});
    }
    dispatch(closeEditGameObjectModal());
  }

  function resetFormStates() {
    setGameObject({ name: "", position: "", objectType: "Image" });
    setPosition({ x: "", y: "" });
    setScale({ x: "", y: "" });
    setFrames({ width: "", height: "", regX: "", regY: "" });
    setFramerate("");
    setShapeDimension({ offsetX: "", offsetY: "", width: "", height: "" });
    setShadow({
      color: "#D3D3D3",
      blur: 10,
    });

    setShapeColor("#FFFFFF");
    setEmptyContainerCheck(null);
    setDisplayColorPicker(false);
    setHolds(1);
    setMasks({});
    setActions([]);
    setIsEdit(false);
  }

  // need this extra function, to close the modal and also hit API
  const onSubmit = (evt, type) => {
    dispatch(updateLoaderState(true));
    handleSubmit(evt, type);
  };

  let header = "Edit Game Object " + gameObjectKey;
  if (type !== undefined && type === "ADD") {
    header = "Add New Game Object";
  }
  function validateEmptyContainer() {
    if (
      !gameObject.container ||
      !gameObject.container?.objects ||
      Object.keys(gameObject.container?.objects).length === 0
    )
      return false;
    else return true;
  }

  function getMeta(url) {
    var img = new Image();
    return new Promise((resolve, reject) => {
      img.onload = function () {
        let dimensions = { width: 100, height: 100 };
        dimensions.width = this.width || 100;
        dimensions.height = this.height || 100;
        resolve(dimensions);
      };
      img.src = url;
    });
  }

  async function saveForm() {
    // *** set the image objects dimensions, if they are being used as collectible
    if (
      gameObject &&
      gameObject.behaviors &&
      gameObject.behaviors.collectable &&
      gameObject.behaviors.collectable.targetObjects
    ) {
      let sourceCopy = { ...sourceCode };

      // set collectable game object with Behavior
      for (let [index, item] of gameObject.behaviors.collectable.targetObjects.entries()) {
        for (var elem of Object.keys(sourceCopy.scenes[0].objects)) {
          if (item.object === elem) {
            if (
              sourceCopy &&
              sourceCopy.scenes[0] &&
              sourceCopy.scenes[0].objects[elem] &&
              sourceCopy.scenes[0].objects[elem].image &&
              sourceCopy.scenes[0].objects[elem].image.source
            ) {
              gameObject.behaviors.collectable.targetObjects[index].dimensions = await getMeta(
                sourceCopy.scenes[0].objects[elem].image.source
              );
            } else {
              gameObject.behaviors.collectable.targetObjects[index].dimensions = {
                width: 100,
                height: 100,
              };
            }
          }
        }
      }

      if (type !== "ADD") {
        gameObject.behaviors.collectable.dimensions = await getMeta(gameObject.image.source);
      }
    }

    // set the image objects dimensions, if they are being used as Drawable
    if (
      gameObject &&
      gameObject.behaviors &&
      gameObject.behaviors.drawable &&
      gameObject.behaviors.drawable.targetObjects
    ) {
      let sourceCopy = { ...sourceCode };
      for (let [index, item] of gameObject.behaviors.drawable.targetObjects.entries()) {
        for (var elem of Object.keys(sourceCopy.scenes[0].objects)) {
          if (item.object === elem) {
            if (
              sourceCopy &&
              sourceCopy.scenes[0] &&
              sourceCopy.scenes[0].objects[elem] &&
              sourceCopy.scenes[0].objects[elem].image &&
              sourceCopy.scenes[0].objects[elem].image.source
            ) {
              gameObject.behaviors.drawable.targetObjects[index].dimensions = await getMeta(
                sourceCopy.scenes[0].objects[elem].image.source
              );
            } else {
              gameObject.behaviors.drawable.targetObjects[index].dimensions = {
                width: 100,
                height: 100,
              };
            }
          }
        }
      }
      if (type !== "ADD") {
        gameObject.behaviors.drawable.dimensions = await getMeta(gameObject.image.source);
      }
    }

    // ***************** end
    if (gameObject.objectType === "Container" && !validateEmptyContainer()) {
      setEmptyContainerCheck("Need atleast one object");
      return;
    } else {
      setEmptyContainerCheck(null);
    }

    if (type === "ADD") {
      if (gameObject.objectType === "Text") {
        delete gameObject.container;
        delete gameObject.spritesheet;
        delete gameObject.image;
        delete gameObject.shape;
      } else if (gameObject.objectType === "Spritesheet") {
        delete gameObject.text;
        delete gameObject.container;
        delete gameObject.image;
        delete gameObject.shape;
      } else if (gameObject.objectType === "Image") {
        delete gameObject.text;
        delete gameObject.container;
        delete gameObject.spritesheet;
        delete gameObject.shape;
      } else if (gameObject.objectType === "Container") {
        delete gameObject.text;
        delete gameObject.image;
        delete gameObject.spritesheet;
        delete gameObject.shape;
      } else if (gameObject.objectType === "Shape") {
        delete gameObject.text;
        delete gameObject.image;
        delete gameObject.container;
        delete gameObject.spritesheet;
      }
      var resultedSource = addGameObjectToSourceCode(sceneNo, sourceCode, gameObject, objType);

      if (objType === "collectableObjects") {
        const dimensions =
          gameObject && gameObject.image && gameObject.image.source
            ? await getMeta(gameObject.image.source)
            : { width: 100, height: 100 };
        const currentGameObject = getCurrentObjectFromSourceCode(
          sourceCode,
          sceneNo,
          gameObject.name
        );
        if (
          currentGameObject &&
          currentGameObject.behaviors &&
          currentGameObject.behaviors.collectable &&
          currentGameObject.behaviors.collectable.dimensions
        ) {
          currentGameObject.behaviors.collectable.dimensions = dimensions;
        }
      }

      if (objType === "drawableObjects") {
        const dimensions =
          gameObject && gameObject.image && gameObject.image.source
            ? await getMeta(gameObject.image.source)
            : { width: 100, height: 100 };
        const currentGameObject = getCurrentObjectFromSourceCode(
          sourceCode,
          sceneNo,
          gameObject.name
        );
        if (
          currentGameObject &&
          currentGameObject.behaviors &&
          currentGameObject.behaviors.drawable &&
          currentGameObject.behaviors.drawable.dimensions
        ) {
          currentGameObject.behaviors.drawable.dimensions = dimensions;
        }
      }

      updateGameObject(creativeUid, resultedSource);
    } else {
      var resultedSource = updateGameObjectToSourceCode(
        sceneNo,
        sourceCode,
        gameObject,
        objType,
        gameObjectKey
      );
      updateGameObject(creativeUid, resultedSource);
    }
  }

  async function updateGameObject(creativeUid, resultedSource) {
    await dispatch(closeEditGameObjectModal());
    await dispatch(updateSelectedTrueCreativePropsFunc(creativeUid, resultedSource));
    await dispatch(updateLoaderState(false));
    resetFormStates();
  }

  return (
    <Modal
      show={isShowEditGameObjectModal}
      onHide={() => closeModal("cancel")}
      className="editGameObjectPopup"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {isLoaderEnabled ? <Loader /> : ""}
      <Modal.Header closeButton>
        <Modal.Title> {header} </Modal.Title>
      </Modal.Header>
      {gameObject ? (
        <Modal.Body>
          <form className="editGameObjectPopupForm" id="addGameObject">
            <div className="form-group">
              <label>Name</label>
              <div className="input-control">
                <input
                  type="text"
                  name="name"
                  required={true}
                  disabled={isEdit}
                  value={gameObject.name}
                  placeholder="Enter Name"
                  onChange={(evt) => handleChange(evt)}
                  className={errors.nameIsEmpty && "input-error"}
                />
                {errors.nameIsEmpty && <small>{errors.nameIsEmpty}</small>}
              </div>
            </div>
            <div className="form-group">
              <label>Position</label>
              <div className="row property-section">
                <div className="form-group col-sm-6">
                  <label>Horizontal</label>
                  <div className="input-control">
                    <input
                      type="number"
                      pattern="[0-9]*"
                      inputMode="numeric"
                      name="x"
                      value={position.x}
                      placeholder="Horizontal"
                      onChange={handlePositionChange}
                    />
                  </div>
                </div>
                <div className="form-group col-sm-6">
                  <label>Vertical</label>
                  <div className="input-control">
                    <input
                      type="number"
                      pattern="[0-9]*"
                      inputMode="numeric"
                      name="y"
                      value={position.y}
                      placeholder="Vertical"
                      onChange={handlePositionChange}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="form-group">
              <label>Scale</label>
              <div className="row property-section">
                <div className="form-group col-sm-6">
                  <label>Horizontal</label>
                  <div className="input-control">
                    <input
                      type="number"
                      pattern="[0-9]*"
                      inputMode="numeric"
                      name="x"
                      value={scale.x}
                      placeholder="Horizontal"
                      onChange={handleScaleChange}
                      className={errors.scaleXIsValid && "input-error"}
                    />
                    {errors.scaleXIsValid && <small>{errors.scaleXIsValid}</small>}
                    <small style={{ color: "green" }}> Value 1 is for exact image size</small>
                  </div>
                </div>
                <div className="form-group col-sm-6">
                  <label>Vertical</label>
                  <div className="input-control">
                    <input
                      type="number"
                      pattern="[0-9]*"
                      inputMode="numeric"
                      name="y"
                      value={scale.y}
                      placeholder="Vertical"
                      onChange={handleScaleChange}
                      className={errors.scaleYIsValid && "input-error"}
                    />
                    {errors.scaleYIsValid && <small>{errors.scaleYIsValid}</small>}
                    <small style={{ color: "green" }}> Value 1 is for exact image size</small>
                  </div>
                </div>
              </div>
            </div>

            <div className="form-group">
              <label>Shadow</label>
              <div className="row property-section">
                <div className="form-group col-sm-6">
                  <label>Color</label>
                  <div className="color-box">
                    <div
                      style={styles.swatch}
                      onClick={() => setDisplayColorPicker(!displayColorPicker)}
                    >
                      <div style={styles.color} />
                    </div>
                    {displayColorPicker ? (
                      <div style={styles.popover}>
                        <div style={styles.cover} onClick={() => setDisplayColorPicker(false)} />
                        <SketchPicker
                          color={shadow.color}
                          name="color"
                          onChange={handleShadowChange}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="form-group col-sm-6">
                  <label>Blur Rate</label>
                  <div className="input-control">
                    <input
                      type="number"
                      pattern="[0-9]*"
                      inputMode="numeric"
                      name="blur"
                      value={shadow.blur}
                      placeholder="Blur"
                      onChange={handleShadowChange}
                      className={errors.shadowBlurIsValid && "input-error"}
                    />
                    {errors.shadowBlurIsValid && <small>{errors.shadowBlurIsValid}</small>}
                  </div>
                </div>
              </div>
            </div>

            <div
              className="form-group"
              hidden={
                truengageCreativeType === "Collectable" ||
                objType === "collectableObjects" ||
                truengageCreativeType === "Drawing" ||
                objType === "drawableObjects"
              }
            >
              <label>Type</label>
              <div className="input-control">
                <span className="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    value={"Image"}
                    disabled={isEdit}
                    checked={gameObject.objectType === "Image" ? true : false}
                    name="objectType"
                    onChange={(evt) => handleChange(evt)}
                    className="custom-control-input"
                    id="type-img"
                  />
                  <label className="custom-control-label" htmlFor="type-img">
                    {"Image"}
                  </label>
                </span>

                <span className="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    value={"Text"}
                    disabled={isEdit}
                    checked={gameObject.objectType === "Text" ? true : false}
                    name="objectType"
                    onChange={(evt) => handleChange(evt)}
                    className="custom-control-input"
                    id="type-text"
                  />
                  <label className="custom-control-label" htmlFor="type-text">
                    {"Text"}
                  </label>
                </span>
                <span className="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    value={"Shape"}
                    disabled={isEdit}
                    checked={gameObject.objectType === "Shape" ? true : false}
                    name="objectType"
                    onChange={(evt) => handleChange(evt)}
                    className="custom-control-input"
                    id="type-shape"
                  />
                  <label className="custom-control-label" htmlFor="type-shape">
                    {"Shape"}
                  </label>
                </span>
                <span className="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    value={"Spritesheet"}
                    disabled={isEdit}
                    checked={gameObject.objectType === "Spritesheet" ? true : false}
                    name="objectType"
                    onChange={(evt) => handleChange(evt)}
                    className="custom-control-input"
                    id="type-sprite"
                  />
                  <label className="custom-control-label" htmlFor="type-sprite">
                    {"Spritesheet"}
                  </label>
                </span>
                <span className="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    value={"Container"}
                    disabled={isEdit}
                    checked={gameObject.objectType === "Container" ? true : false}
                    name="objectType"
                    onChange={(evt) => handleChange(evt)}
                    className="custom-control-input"
                    id="type-container"
                  />
                  <label className="custom-control-label" htmlFor="type-container">
                    {"Container"}
                  </label>
                </span>
              </div>
            </div>
            <div className="type-objects-container">
              {gameObject && gameObject.objectType === "Image" ? (
                <ImageGameObject
                  gameObject={gameObject}
                  handleImageChange={handleImageChange}
                  creativeAccountId={creativeAccountId}
                  errors={errors}
                />
              ) : (
                <>
                  {gameObject && gameObject.objectType === "Text" ? (
                    <TextGameObject
                      gameObject={gameObject}
                      setGameObject={setGameObject}
                      errors={errors}
                    />
                  ) : (
                    <>
                      {gameObject && gameObject.objectType === "Spritesheet" ? (
                        <SpritesheetGameObject
                          gameObject={gameObject}
                          handleFrameChange={handleFrameChange}
                          handleSpriteSheetChange={handleSpriteSheetChange}
                          setGameObject={setGameObject}
                          handleChange={handleChange}
                          frames={frames}
                          setFrames={setFrames}
                          creativeAccountId={creativeAccountId}
                          framerate={framerate}
                          setFramerate={setFramerate}
                          errors={errors}
                        />
                      ) : (
                        <>
                          {gameObject && gameObject.objectType === "Shape" ? (
                            <ShapeGameObject
                              shapeColor={shapeColor}
                              shapeDimension={shapeDimension}
                              handleShapeChange={handleShapeChange}
                              errors={errors}
                            />
                          ) : (
                            <ContainerGameObject
                              gameObject={gameObject}
                              handleImageChange={handleImageChange}
                              setGameObject={setGameObject}
                              position={position}
                              setPosition={setPosition}
                              scale={scale}
                              setScale={setScale}
                              frames={frames}
                              setFrames={setFrames}
                              sourceCode={sourceCode}
                              objType={objType}
                              isEdit={isEdit}
                              emptyContainerCheck={emptyContainerCheck}
                              shapeColor={shapeColor}
                              shapeDimension={shapeDimension}
                              showContainerForm={showContainerForm}
                              setShowContainerForm={setShowContainerForm}
                            />
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </div>

            {isEdit && objType === "binObjects" ? (
              <BinGameObjectBehavior
                holds={holds}
                masks={masks}
                actions={actions}
                gameObjects={dragableModel?.scenes[sceneNo].objects}
                handleBinBehaviorChange={handleBinBehaviorChange}
                selectedLayers={selectedLayers}
                sceneIndex={sceneNo}
                sourceCode={sourceCode}
                gameObjectKey={gameObjectKey}
              />
            ) : (
              <>
                {isEdit && objType === "launchableObjects" ? (
                  <LaunchableGameObjectBehavior
                    currentGameObject={gameObjectValue}
                    sourceCode={sourceCode}
                    sceneIndex={sceneNo}
                    selectedLayers={selectedLayers}
                    gameObjectKey={gameObjectKey}
                  />
                ) : (
                  <>
                    {isEdit &&
                    (objType === "nonInteractiveObjects" || objType === "maskableObjects") ? (
                      <GameObjectBehavior
                        gameObject={gameObject}
                        sourceCode={sourceCode}
                        sceneIndex={sceneNo}
                        gameObjectKey={gameObjectKey}
                        selectedLayers={selectedLayers}
                      />
                    ) : (
                      <>
                        {isEdit && objType === "dragableObjects" ? (
                          <DragableGameObjectBehavior
                            gameObject={gameObject}
                            selectedLayers={selectedLayers}
                            sourceCode={sourceCode}
                            sceneIndex={sceneNo}
                            gameObjectKey={gameObjectKey}
                            creativeUid={creativeUid}
                          />
                        ) : (
                          <>
                            {isEdit && objType === "collectableObjects" ? (
                              <CollectableGameObjectBehavior
                                currentGameObject={gameObjectValue}
                                gameObject={gameObject}
                                sourceCode={sourceCode}
                                sceneIndex={sceneNo}
                                selectedLayers={selectedLayers}
                                gameObjectKey={gameObjectKey}
                              />
                            ) : (
                              <>
                                {isEdit && objType === "drawableObjects" ? (
                                  <DrawableGameObjectBehavior
                                    currentGameObject={gameObjectValue}
                                    gameObject={gameObject}
                                    sourceCode={sourceCode}
                                    sceneIndex={sceneNo}
                                    selectedLayers={selectedLayers}
                                    gameObjectKey={gameObjectKey}
                                  />
                                ) : (
                                  ""
                                )}
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </form>
        </Modal.Body>
      ) : (
        ""
      )}
      <Modal.Footer>
        <div className="btn-group">
          <Button
            className="create-btn"
            disabled={showContainerForm}
            onClick={(evt) => onSubmit(evt, type)}
          >
            {"Save"}
          </Button>
          <Button className="cancel-btn" onClick={() => closeModal("cancel")}>
            {"Cancel"}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

function mapStateToProps(state) {
  return {
    isShowEditGameObjectModal: state.gameObject.isShowEditGameObjectModal,
    gameObjectKey: state.gameObject.gameObjectKey,
    gameObjectValue: state.gameObject.gameObjectValue,
    type: state.gameObject.type,
    dragableModel: state.truengageCreative.dragableModel,
    sourceCode: state.truengageCreative.sourceCode,
    originalResponse: state.truengageCreative.originalResponse,
    sceneNo: state.gameObject.sceneNo,
    creativeUid: state.gameObject.creativeUid,
    objType: state.gameObject.objType,
    selectedLayers: state.gameObject.selectedLayers,
    operationResponse: state.truengageCreative.operationResponse,
    truengageCreativeType: state.truengageCreative.truengageCreativeType,
    isLoaderEnabled: state.gameObject.isLoaderEnabled,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeEditGameObjectModal: () => dispatch(closeEditGameObjectModal()),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(EditGameObjectModal);