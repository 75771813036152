// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import firebase from "firebase/app";

// Add the Firebase services that you want to use
// We only want to use Firebase Auth here
import "firebase/auth";

var firebaseConfig = {
  apiKey: "AIzaSyCQrpWdkdXHSOYggxqnHK1V0au-OJhWTVg",
  authDomain: "xps-production.firebaseapp.com",
  databaseURL: "https://xps-production.firebaseio.com",
  projectId: "firebase-xps-production",
  storageBucket: "firebase-xps-production.appspot.com",
  messagingSenderId: "16471215761",
  appId: "1:16471215761:web:6593fa835dfbf92b8bb930",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// Finally, export it to use it throughout your app
export default firebase;
