import React, { useEffect } from "react";
import { connect } from "react-redux";
import { isUserLoggedIn } from "./../../store/actions/auth";
export default (ChildComponent) => {
  const ComposedComponent = (props) => {
    useEffect(() => {
      if (props.auth.isLoaded && props.auth.isEmpty) {
        props.history.push("/");
      }
    }, [props.auth, props.history]);

    return <ChildComponent {...props} />;
  };

  function mapStateToProps(state) {
    return {
      auth: state.firebase.auth,
    };
  }

  return connect(mapStateToProps)(ComposedComponent);
};