import { Types } from "../constants/videoline-types";

const INITIAL_STATE = {
  videoLineMsg: "",
  videoLineMap: undefined,
  selectedVideoline: undefined,
  selectedVideoLineVersion: undefined,
};

export default function videoline(state = INITIAL_STATE, action) {
  if (action.type === Types.GET_ALL_XPS_VIDEOLINES_CAMPAIGN_ID) {
    return { ...state, xpsVideoLines: action.payload };
  } else if (action.type === Types.ERROR_IN_LOADING_VIDEOLINES) {
    return { ...state, videoLineMsg: action.payload };
  } else if (action.type === Types.VIDEO_LINE_MAP) {
    return { ...state, videoLineMap: action.payload };
  } else if (action.type === Types.GET_CURRENT_VIDEOLINE) {
    return {
      ...state,
      selectedVideoline: action.payload.videoline,
      selectedVideoLineVersion: action.payload.videolineVersion,
    };
  } else if (action.type === Types.CLEAR_XPSVIDEO_LINES) {
    return { ...state, xpsVideoLines: action.payload };
  } else {
    return state;
  }
}
