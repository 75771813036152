import { Types } from "./../constants/auth-types";
import { beginApiCall, apiCallError } from "./apiStatus";
import firebase from "../../config/firebase";
import authService from "../../services/authService";

const requestLogin = () => {
  return {
    type: Types.LOGIN_REQUEST,
  };
};

const receiveLogin = (user) => {
  return {
    type: Types.LOGIN_SUCCESS,
    payload: user,
  };
};

const loginError = (authMessage) => {
  return {
    type: Types.LOGIN_FAILURE,
    payload: authMessage,
  };
};

const requestLogout = () => {
  return {
    type: Types.LOGOUT_REQUEST,
  };
};

const receiveLogout = () => {
  return {
    type: Types.LOGOUT_SUCCESS,
  };
};

const logoutError = () => {
  return {
    type: Types.LOGOUT_FAILURE,
  };
};

const verifyRequest = () => {
  return {
    type: Types.VERIFY_REQUEST,
  };
};

/*const verifySuccess = () => {
  return {
    type: Types.VERIFY_SUCCESS,
  };
};*/

// Signing in with Firebase
export const signin = (email, password, callback) => async (dispatch, getState) => {
  try {
    dispatch(beginApiCall());
    dispatch(requestLogin());
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((user) => {
        if (!authService.setLocalStorageProps(user)) {
          signout();
        } else {
          dispatch(receiveLogin(user));
        }
        callback();
      })
      .catch(() => {
        dispatch(apiCallError());
        dispatch(loginError("Invalid login credentials"));
      });
  } catch (err) {
    dispatch(apiCallError());
    dispatch(loginError("Invalid login credentials"));
  }
};

// Signing out with Firebase
export const signout = () => async (dispatch) => {
  try {
    dispatch(beginApiCall());
    dispatch(requestLogout());
    firebase
      .auth()
      .signOut()
      .then(() => {
        authService.removeLocalStorageProps();
        dispatch(receiveLogout());
      })
      .catch(() => {
        dispatch(apiCallError());
        dispatch(logoutError());
      });
  } catch (err) {
    dispatch(apiCallError());
    dispatch({
      type: Types.LOGOUT_FAILURE,
      payload: "Error, we were not able to log you out. Please try again.",
    });
  }
};

// Verifying Auth with Firebase
export const verifyAuth = () => (dispatch) => {
  dispatch(verifyRequest());
  firebase.auth().onAuthStateChanged((user) => {
    if (user !== null) {
      dispatch(receiveLogin(user));
    } else {
      authService.removeLocalStorageProps();
    }
  });
};

export const isUserLoggedIn = () => async (dispatch) => {
  const status = await authService.isAdminUser(localStorage.uid);
  return new Promise((resolve) => {
    if (localStorage.access_token && localStorage.uid && status) {
      resolve(true);
    } else {
      // manually signout
      firebase
        .auth()
        .signOut()
        .then(() => {
          authService.removeLocalStorageProps();
          dispatch(loginError("You don't have sufficient permission to access your account"));
        })
        .catch(() => {});
      resolve(false);
    }
  });
};

