import React, { useEffect } from "react";
import { connect } from "react-redux";
import { signin } from "../store/actions/auth";
import LoginForm from "../utils/login/loginForm";
import validate from "../utils/login/validateLoginForm";
import Spinner from "./spinner";
import { apiCallReset } from "../store/actions/apiStatus";
import { useDispatch } from "react-redux";
import LoginImg from "../images/login-img.png";

const Login = ({ signin, authMsg, history, loading }) => {
  const [credentials, handleChange, handleSubmit, resetForm, errors] = LoginForm(login, validate);

  function login() {
    signin(credentials.email, credentials.password, () => history.push("/"));
  }
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(apiCallReset());
  }, []);

  return (
    <div className="login-page">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-5 login">
            <h2>{"Sign In"}</h2>
            {authMsg && <p className="auth-message">{authMsg}</p>}
            <form onSubmit={handleSubmit} noValidate>
              <div className="input-group email-field">
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={credentials.email}
                  placeholder="Email"
                  onChange={handleChange}
                  className={(errors.emailIsEmpty || errors.emailFormatInvalid) && "input-error"}
                />
                {errors.emailIsEmpty && <small>{errors.emailIsEmpty}</small>}
                {errors.emailFormatInvalid && <small>{errors.emailFormatInvalid}</small>}
              </div>

              {/* PASSWORD */}
              <div className="input-group password-field">
                <input
                  type="password"
                  id="password"
                  name="password"
                  value={credentials.password}
                  placeholder="Password"
                  onChange={handleChange}
                  className={(errors.passIsStrong || errors.passIsEmpty) && "input-error"}
                />
                {errors.passIsStrong && <small>{errors.passIsStrong}</small>}
                {errors.passIsEmpty && <small>{errors.passIsEmpty}</small>}
              </div>
              {/* BUTTONS */}
              <div className="form-btns">
                <button type="submit" className="btn btn-primary btn-login">
                  {loading ? <Spinner /> : "Login"}
                </button>
                <button className="btn btn-default" onClick={resetForm}>
                  Reset
                </button>
              </div>
            </form>
          </div>
          <div className="col-md-7 col-sm-12 side-img">
            <img src={LoginImg} alt="Login" />
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    authMsg: state.auth.authMsg,
    loading: state.apiStatus.apiCallsInProgress > 0,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    signin: (email, password, callback) => dispatch(signin(email, password, callback)),
    apiCallReset: () => dispatch(apiCallReset()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
