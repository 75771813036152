const GameBehaviorForm = (
  clickableBehavior,
  tweenMoveBehavior,
  tweenResizeBehavior,
  tweenResizeTransition,
  pathBehavior,
  pathTransitions,
  pathStart,
  setClickableBehavior,
  setTweenMoveBehavior,
  setTweenResizeBehavior,
  setTweenResizeTransition,
  setIsAddingResizeTransition,
  setIsTweenResizeTransitionEdit,
  setPathTransitions,
  setIsPathTransitionEdit,
  setIsAddingTransition,
  setPathBehavior,
  setPathStart,
  setErrors
) => {
  const handleClickableChange = (e, type) => {
    var tempClickableBehavior = clickableBehavior;
    if (e.target.name === "type") {
      setErrors({});
      tempClickableBehavior["type"] = "advanceGame";
      tempClickableBehavior["delay"] = "";
      tempClickableBehavior["url"] = "";
    }
    tempClickableBehavior[e.target.name] = e.target.value;
    setClickableBehavior({ ...tempClickableBehavior });
  };

  const handleTweenMoveChange = (e, type) => {
    var tempTweenMoveBehavior = tweenMoveBehavior;
    tempTweenMoveBehavior[e.target.name] = e.target.value;
    setTweenMoveBehavior({ ...tempTweenMoveBehavior });
  };

  const handleTweenResizeChange = (e, type) => {
    var tempTweenResizeBehavior = tweenResizeBehavior;
    tempTweenResizeBehavior[e.target.name] = e.target.value;
    setTweenResizeBehavior({ ...tempTweenResizeBehavior });
  };

  const handleTweenResizeTransitionChange = (e, type) => {
    var tempTweenResizeTransition = tweenResizeTransition;
    tempTweenResizeTransition[e.target.name] = e.target.value;
    setTweenResizeTransition({ ...tempTweenResizeTransition });
  };

  const editTweenResizeTransitions = (e, idx) => {
    var tempTweenResizeBehavior = tweenResizeBehavior;
    if (tempTweenResizeBehavior.resizeDirection === "x") {
      setTweenResizeTransition({
        position: tempTweenResizeBehavior.transitions[idx][1].x,
        scale: tempTweenResizeBehavior.transitions[idx][1].scaleX,
        delay: tempTweenResizeBehavior.transitions[idx][2],
      });
    } else {
      setTweenResizeTransition({
        position: tempTweenResizeBehavior.transitions[idx][1].y,
        scale: tempTweenResizeBehavior.transitions[idx][1].scaleY,
        delay: tempTweenResizeBehavior.transitions[idx][2],
      });
    }
    setIsTweenResizeTransitionEdit({ status: true, index: idx });
    setIsAddingResizeTransition(true);
  };

  const editPathTransitions = (e, idx) => {
    var tempPathBehavior = pathBehavior;
    setPathTransitions({ ...tempPathBehavior.transitions[idx] });
    setIsPathTransitionEdit({ status: true, index: idx });
    setIsAddingTransition(true);
  };

  const handleCancel = (e, idx) => {
    setPathTransitions({ x: "", y: "", duration: "" });
    setIsPathTransitionEdit({ status: false, index: -1 });
    setIsAddingTransition(false);
    setIsTweenResizeTransitionEdit({ status: false, index: -1 });
    setIsAddingResizeTransition(false);
    setTweenResizeTransition({
      scale: "",
      position: "",
      delay: "",
    });
  };

  const handlePathBehaviorChange = (e, type) => {
    var tempPathBehavior = pathBehavior;
    if (type === "startPosition") {
      var tempPathStart = pathStart;
      tempPathStart[e.target.name] =
        e.target.value && e.target.value !== "" ? Number(e.target.value) : "";
      setPathStart({ ...tempPathStart });
      tempPathBehavior["start"] = tempPathStart;
      setPathBehavior({ ...tempPathBehavior });
    } else if (type === "transitions") {
      var tempPathTransitions = pathTransitions;
      tempPathTransitions[e.target.name] =
        e.target.value && e.target.value !== "" ? Number(e.target.value) : "";
      setPathTransitions({ ...tempPathTransitions });
    }
  };

  return [
    handleClickableChange,
    handleTweenMoveChange,
    handleTweenResizeChange,
    handleTweenResizeTransitionChange,
    editTweenResizeTransitions,
    editPathTransitions,
    handleCancel,
    handlePathBehaviorChange,
  ];
};

export default GameBehaviorForm;
