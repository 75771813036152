import firebase from "./../config/firebase";
import AWS from "aws-sdk";

AWS.config.update({
  accessKeyId: process.env.REACT_APP_ACCESS_ID,
  secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
});

const myBucket = new AWS.S3({
  params: { Bucket: process.env.REACT_APP_BUCKET_NAME },
  region: process.env.REACT_APP_REGION,
});

// Initialize Firebase
//const db = firebase.database();

const firestoreDB = firebase.firestore();

const getImageResouceByAccountId = (accountId) => {
  return new Promise((resolve) => {
    firestoreDB
      .collection("truengage_image_resources")
      .where("account", "==", accountId)
      .get()
      .then((querySnapshot) => {
        const response = [];
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          response.push(doc.data());
        });
        resolve(response);
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
        resolve(error);
      });
  });
};

const addNewImageResourceAWS = (fileObj) => {
  let fileName = fileObj.name;
  return new Promise((resolve) => {
    const params = {
      ACL: "public-read",
      Key: process.env.REACT_APP_DIR_NAME + fileName, // folder-name and file name
      ContentType: fileObj.type,
      Body: fileObj,
    };
    myBucket
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        // that's how you can keep track of your upload progress
        //  progress: Math.round((evt.loaded / evt.total) * 100),
      })
      .send(async (err, data) => {
        if (err) {
          // handle the error here
          resolve({ message: "Image not added", error: err, success: false });
        } else {
          // call firebase api here
          resolve({ message: "Image added ", response: data, success: true });
        }
      });
  });
};

const addNewImageResourceFirestore = (formData) => {
  return new Promise((resolve) => {
    firestoreDB
      .collection("truengage_image_resources")
      .add(formData)
      .then((docRef) => {
        resolve({ message: "Image added", response: docRef.id, success: true });
      })
      .catch((error) => {
        resolve({ message: "Image saving failed", response: error, success: false });
      });
  });
};

export default {
  getImageResouceByAccountId,
  addNewImageResourceFirestore,
  addNewImageResourceAWS,
};
