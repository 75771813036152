import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { ReactComponent as ArrowIcon } from "../../images/down-arrow.svg";
import "react-dropdown/style.css";
import reactCSS from "reactcss";
import { SketchPicker } from "react-color";
import {
  fontSizeArray,
  targetAreaPercentage,
  setColorPickerCSSStyle,
} from "./../../utils/appUtils";
import infoIcon from "../../images/info-logo.svg";
import ReactTooltip from "react-tooltip";

const DrawableGameObjectBehavior = ({ currentGameObject, gameObject, selectedLayers }) => {
  const [isShowDetail, setIsShowDetail] = useState(false);
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [targetObject, setTargetObject] = useState({ object: "", layer: "" });
  const [delay, setDelay] = useState();
  const [color, setColor] = useState("#000000");
  const [targetedArea, setTargetedArea] = useState(50);
  const [lineWidth, setLineWidth] = useState(10);

  useEffect(() => {
    if (currentGameObject) {
      setLineWidth(
        currentGameObject.behaviors.drawable.lineWidth
          ? currentGameObject.behaviors.drawable.lineWidth
          : 10
      );

      setColor(
        currentGameObject.behaviors.drawable.color
          ? currentGameObject.behaviors.drawable.color
          : "#000000"
      );

      setDelay(
        currentGameObject.behaviors.drawable.actions.empty[0].delay
          ? currentGameObject.behaviors.drawable.actions.empty[0].delay
          : ""
      );

      setTargetObject(
        currentGameObject.behaviors.drawable.targetObjects &&
          currentGameObject.behaviors.drawable.targetObjects[0]
          ? currentGameObject.behaviors.drawable.targetObjects[0]
          : {}
      );

      setTargetedArea(
        currentGameObject.behaviors.drawable.targetedArea
          ? currentGameObject.behaviors.drawable.targetedArea
          : 50
      );
    }
  }, [currentGameObject]);

  const styles = reactCSS(setColorPickerCSSStyle(color));
  var sizeArray = fontSizeArray();
  const options = sizeArray.map((elem) => (
    <option key={elem} value={elem}>
      {elem}
    </option>
  ));

  var areaPercentage = targetAreaPercentage();
  const areaOptions = areaPercentage.map((elem) => (
    <option key={elem} value={elem}>
      {elem}
    </option>
  ));

  const gameObjectOptions = selectedLayers.map(
    (layer) =>
      layer.objects &&
      layer.objects.map((object) => (
        <option key={layer.name + "=>" + object} value={layer.name + "=>" + object}>
          {object}
        </option>
      ))
  );

  const handleChange = (e) => {
    if (e.target) {
      if (e.target.name === "delay") {
        gameObject.behaviors.drawable.actions.empty[0].delay = e.target.value
          ? Number(e.target.value)
          : "";
        setDelay(e.target.value ? Number(e.target.value) : "");
      } else if (e.target.name === "lineWidth") {
        gameObject.behaviors.drawable.lineWidth = e.target.value ? Number(e.target.value) : "";
        setLineWidth(e.target.value ? Number(e.target.value) : "");
      } else if (e.target.name === "targetObject") {
        var values = e.target.value;
        if (values.includes("=>")) {
          var tempTargetObject = targetObject;
          var layer = values.split("=>")[0];
          var targetedObject = values.split("=>")[1];
          tempTargetObject.object = targetedObject;
          tempTargetObject.layer = layer;
          setTargetObject({ ...tempTargetObject });
          gameObject.behaviors.drawable.targetObjects[0] = tempTargetObject;
        }
      } else if (e.target.name === "targetedArea") {
        var tempTargetedArea = targetedArea;
        tempTargetedArea = e.target.value ? Number(e.target.value) : "";
        setTargetedArea(tempTargetedArea);
        gameObject.behaviors.drawable.targetedArea = tempTargetedArea;
      }
    } else {
      gameObject.behaviors.drawable.color = e.hex;
      setColor(e.hex);
    }
  };

  return (
    <div className={isShowDetail ? "bin-object-container show" : "bin-object-container"}>
      <h3 onClick={() => setIsShowDetail(!isShowDetail)} className="toggle-content-title">
        Drawable Game Object Behaviors
        <span className="arrow">
          <ArrowIcon />
        </span>
      </h3>
      {isShowDetail ? (
        <>
          <div className="form-group">
            <label>
              Marking Object Properties
              <span className="form-group info-icon" data-tip data-for="markingObjectTip">
                <img src={infoIcon} width="15px" height="15px" alt="" />
              </span>
            </label>
            <ReactTooltip id="markingObjectTip" place="top" effect="solid">
              Can change Marking object properties like Color and Line Width
            </ReactTooltip>
            <div className="form-group">
              <div className="row property-section">
                <div className="form-group col-md-6 col-sm-6">
                  <label>{"Line Width"}</label>
                  <select
                    name="lineWidth"
                    className="custom-select"
                    value={lineWidth}
                    onChange={handleChange}
                  >
                    {options}
                  </select>
                </div>
                <div className="form-group col-md-6 col-sm-6">
                  <label>Marking Color</label>
                  <div className="color-box">
                    <div
                      style={styles.swatch}
                      onClick={() => setDisplayColorPicker(!displayColorPicker)}
                    >
                      <div style={styles.color} />
                    </div>
                    {displayColorPicker ? (
                      <div style={styles.popover}>
                        <div style={styles.cover} onClick={() => setDisplayColorPicker(false)} />
                        <SketchPicker color={color} name="color" onChange={handleChange} />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="form-group">
            <label>
              Target Objects
              <span className="form-group info-icon" data-tip data-for="targetObjectTip">
                <img src={infoIcon} width="15px" height="15px" alt="" />
              </span>
            </label>
            <ReactTooltip id="targetObjectTip" place="top" effect="solid">
              Select target objects on which you want to Draw
            </ReactTooltip>
            <div className="form-group">
              <div className="input-control">
                <select
                  name="targetObject"
                  className="custom-select"
                  value={targetObject.layer + "=>" + targetObject.object}
                  onChange={handleChange}
                >
                  <option key={""} value={""}>
                    {"Select an option"}
                  </option>
                  {gameObjectOptions}
                </select>
              </div>
            </div>
          </div>

          <div className="form-group">
            <label>
              Targeted Area(%age)
              <span className="form-group info-icon" data-tip data-for="targetedAreaTip">
                <img src={infoIcon} width="15px" height="15px" alt="" />
              </span>
            </label>
            <ReactTooltip id="targetedAreaTip" place="top" effect="solid">
              Painted Area
            </ReactTooltip>
            <div className="form-group">
              <div className="input-control">
                <select
                  name="targetedArea"
                  className="custom-select"
                  value={targetedArea}
                  onChange={handleChange}
                >
                  <option key={""} value={""}>
                    {"Select an option"}
                  </option>
                  {areaOptions}
                </select>
              </div>
            </div>
          </div>

          <div className="form-group">
            <label>Behavior Actions</label>
            <div className="form-group">
              <div className="row property-section">
                <div className="form-group col-md-6 col-sm-6">
                  <label>{"Action Type"}</label>
                  <input
                    type="text"
                    disabled={true}
                    value={currentGameObject.behaviors.drawable.actions.empty[0].type}
                  ></input>
                </div>
                <div className="form-group col-md-6 col-sm-6">
                  <label>{"Delay(in milliseconds)"}</label>
                  <input
                    type="number"
                    pattern="[0-9]*"
                    inputMode="numeric"
                    name="delay"
                    value={delay}
                    onChange={handleChange}
                  ></input>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(DrawableGameObjectBehavior);
