import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  loadXPSVideoLinesByCampaignId,
  loadXPSVideoLinesByCampaignId2,
  loadVideoLineMap,
  clearXPSVideos,
} from "../store/actions/videolines";
import { getSelectedCampaignDetail } from "../store/actions/campaigns";
import { useDispatch } from "react-redux";
import Loader from "./loader";
import requireAuth from "./hoc/requireAuth";
import { compose } from "redux";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import "moment-timezone";

import { ReactComponent as DownArrow } from "../images/down-arrow.svg";

const VideoLine = ({ xpsVideoLines, videoLineMap, selectedCampaign }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [viewOldVideoline, setViewOldVideoline] = useState(false);
  const oldVideoLines = {};
  const recentVideoLines = {};
  Moment.globalFormat = "D MMM, HH:mm";
  useEffect(() => {
    dispatch(getSelectedCampaignDetail(id));
    dispatch(loadVideoLineMap());
    dispatch(loadXPSVideoLinesByCampaignId2(id));
    return () => {
      dispatch(clearXPSVideos());
    };
  }, []);

  // to list out xps videolines
  function _bindXPSVideolines(videolines) {
    return Object.keys(videolines).map((item, index) => {
      return (
        <Link key={item + `-` + index} to={`/truengage-creative-list/${item}`}>
          {" "}
          <li key={item + `-` + index} className="check-box compaign-status">
            <span className="status-dot"></span>
            <span className="status-details">
              <span>{videoLineMap[videolines[item].video_line_version].name} </span>
              <span>
                <Moment unix>
                  {videoLineMap[videolines[item].video_line_version].start_at / 1000}
                </Moment>{" "}
                {"-"}
                <Moment unix>
                  {videoLineMap[videolines[item].video_line_version].end_at / 1000}
                </Moment>
              </span>
              <span>
                {" $"}
                {videoLineMap[videolines[item].video_line_version].rate}
                {" at "} {videoLineMap[videolines[item].video_line_version].rate_type}
              </span>
            </span>
          </li>
        </Link>
      );
    });
  }

  if (xpsVideoLines && Object.keys(xpsVideoLines).length > 0) {
    Object.keys(xpsVideoLines).map((item, index) => {
      if (xpsVideoLines[item].age === "recent") {
        recentVideoLines[item] = xpsVideoLines[item];
      } else {
        oldVideoLines[item] = xpsVideoLines[item];
      }
    });
  }

  return (
    <div>
      {xpsVideoLines && Object.keys(xpsVideoLines).length > 0 ? (
        <div className="page">
          <nav aria-label="breadcrumb" className="page-breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to={"/"}>
                  <span>Accounts</span>
                </Link>
              </li>
              <li className="breadcrumb-item">
                <Link to={`/campaigns/${selectedCampaign.account}`}>
                  {" "}
                  <span>Campaign</span>
                </Link>
              </li>
            </ol>
          </nav>

          <h3>{selectedCampaign.name}</h3>
          <div className="listing-container">
            <h5 className="list-title">
              {Object.keys(xpsVideoLines).length}
              {Object.keys(xpsVideoLines).length > 1 ? " Video Lines" : " Video Line"}
            </h5>
            <ul id="recentVideoLines">
              {recentVideoLines ? _bindXPSVideolines(recentVideoLines) : ""}
            </ul>
            <div className="list-footer">
              <h5 className="footer-title" onClick={() => setViewOldVideoline(!viewOldVideoline)}>
                {Object.keys(oldVideoLines).length}{" "}
                {Object.keys(oldVideoLines).length > 1 ? " Old Video Lines" : " Old Video Line"}
                <span className="arrow">
                  <DownArrow />
                </span>
              </h5>
              {viewOldVideoline ? (
                <ul id="oldVideoLines">{oldVideoLines ? _bindXPSVideolines(oldVideoLines) : ""}</ul>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      ) : xpsVideoLines && Object.keys(xpsVideoLines).length === 0 ? (
        <div className="page">No Video Lines for selected campaign : {selectedCampaign.name}</div>
      ) : (
        <Loader />
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    xpsVideoLines: state.videoline.xpsVideoLines,
    videoLineMap: state.videoline.videoLineMap,
    selectedCampaign: state.campaign.selectedCampaign,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadXPSVideoLinesByCampaignId: (id) => dispatch(loadXPSVideoLinesByCampaignId(id)),
    loadXPSVideoLinesByCampaignId2: (id) => dispatch(loadXPSVideoLinesByCampaignId2(id)),
    loadVideoLineMap: () => dispatch(loadVideoLineMap()),
    getSelectedCampaignDetail: (id) => dispatch(getSelectedCampaignDetail(id)),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps), requireAuth)(VideoLine);
