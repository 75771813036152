import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateLoaderState } from "../../store/actions/gameObjects";

const CPCBannerForm = (saveCpcBanner, validate) => {
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [cpcBanner, setCpcBanner] = useState({
    name: "",
    type: "div",
    source: "",
    width: "",
    height: "",
    top: 0,
    left: 0,
    right: 0,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      saveCpcBanner();

      // Cleaning inputs after submit action
      setCpcBanner({
        name: "",
        type: "div",
        width: "",
        height: "",
        top: 0,
        left: 0,
        right: 0,
      });

      setErrors({});

      setIsSubmitting(false);
    }
  }, [errors, isSubmitting, saveCpcBanner]);

  function handleChange(e) {
    e.persist();
    setCpcBanner((cpcBanner) => ({
      ...cpcBanner,
      [e.target.name]: e.target.value,
    }));
  }

  const handleSubmit = (e) => {
    dispatch(updateLoaderState(true));
    if (e) e.preventDefault();
    setIsSubmitting(true);
    setErrors(validate(cpcBanner));
    dispatch(updateLoaderState(false));
  };

  function resetForm() {}

  return [cpcBanner, setCpcBanner, handleChange, handleSubmit, resetForm, errors];
};

export default CPCBannerForm;
