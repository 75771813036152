import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { compose } from "redux";
import * as R from "ramda";
import { openEditGameObjectModal } from "../store/actions/gameObjects";
import {
  deleteGameObjectFunc,
  addGameStateFunc,
  resetOperationResponse,
  updateSelectedTrueCreativeDimensionFunc,
  updateSelectedTrueCreativeStateDurationFunc,
} from "../store/actions/truengageCreatives";
import { openEditCPCBannerModal } from "../store/actions/CPCBanner";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EditCPCBannerObjectModal from "./modals/editCPCBannerModal";

import { ReactComponent as SaveIcon } from "../images/tick.svg";
import { ReactComponent as CloseIcon } from "../images/cancel.svg";
import { ReactComponent as DeleteIcon } from "../images/trash.svg";
import { ReactComponent as ArrowIcon } from "../images/down-arrow.svg";
import { ReactComponent as ImageIcon } from "../images/image.svg";
import { ReactComponent as TextIcon } from "../images/text.svg";
import { ReactComponent as ShapeIcon } from "../images/shapes.svg";
import { ReactComponent as ContainerIcon } from "../images/container.svg";
import { ReactComponent as SpriteIcon } from "../images/spritesheet-icon.svg";

const EditTruengageCreativeSidebar = ({
  dragableModel,
  creativeName,
  creativeId,
  sourceCode,
  operationResponse,
  isCpcBannerEnabled,
  truengageCreativeType,
  originalGameObjectValue,
  cancelEdit,
}) => {
  const dispatch = useDispatch();
  const [editAdDimension, setEditAdDimension] = useState({
    width: false,
    height: false,
  });
  const [editGameState, setGameState] = useState(false);
  const [editStateDuration, setEditStateDuration] = useState({
    status: false,
    index: -1,
  });
  const [editGameLayer, setEditGameLayer] = useState({
    status: false,
    index: -1,
  });
  const [editGameTransition, setEditGameTransition] = useState({
    status: false,
    index: -1,
  });
  const [editGameTransitionFadeIn, setEditGameTransitionFadeIn] = useState({
    status: false,
    index: -1,
  });
  const [editGameTransitionFadeOut, setEditGameTransitionFadeOut] = useState({
    status: false,
    index: -1,
  });

  const [editNonInteractiveGameObject, setEditNonInteractiveGameObject] = useState({
    status: false,
    index: -1,
  });
  const [editDragableGameObject, setEditDragableGameObject] = useState({
    status: false,
    index: -1,
  });
  const [editDrawableGameObject, setEditDrawableGameObject] = useState({
    status: false,
    index: -1,
  });
  const [editLaunchableGameObject, setEditLaunchableGameObject] = useState({
    status: false,
    index: -1,
  });
  const [editCollectableGameObject, setEditCollectableGameObject] = useState({
    status: false,
    index: -1,
  });
  const [editBinGameObject, setEditBinGameObject] = useState({
    status: false,
    index: -1,
  });

  const [adDimension, setAdDimension] = useState({
    width: "",
    height: "",
  });

  const [stateDurations, setStateDurations] = useState({ duration: [] });

  const [stateTransitions, setStateTransitions] = useState({
    fadeIn: [],
    fadeOut: [],
  });

  const [editMaskableGameObject, setEditMaskableGameObject] = useState({
    status: false,
    index: -1,
  });

  const [currentObjName, setCurrentObjName] = useState(null);

  useEffect(() => {
    if (operationResponse != null) {
      if (operationResponse) {
        toast.success("Truengage Props updated successfully!");
      } else {
        toast.error("Failed to save!");
      }

      if (operationResponse === "behaviour") {
        let element = document.getElementById(currentObjName);
        element.click();
      }
      dispatch(resetOperationResponse());
    }

    let dragObj = JSON.parse(JSON.stringify(dragableModel));
    setSceneAttributes({ ...dragObj });

    return () => {
      // console.log("Unmounting here");
    };
  }, [operationResponse, dragableModel]);

  const setSceneAttributes = (dragableModel) => {
    var durationArray = [];
    var transitionFadeInArray = [];
    var transitionFadeOutArray = [];

    setAdDimension({
      ...adDimension,
      width: dragableModel.width,
      height: dragableModel.height,
    });

    dragableModel["scenes"].forEach((scene) => {
      durationArray.push(scene["duration"] ? scene["duration"] / 1000 : "");
      transitionFadeInArray.push({
        type: "fadeIn",
        duration:
          scene["transitions"] && scene["transitions"]["into"]
            ? scene["transitions"]["into"]["duration"] / 1000
            : "",
      });
      transitionFadeOutArray.push({
        type: "fadeOut",
        duration:
          scene["transitions"] && scene["transitions"]["out"]
            ? scene["transitions"]["out"]["duration"] / 1000
            : "",
      });
    });
    setStateDurations({ ...stateDurations, ["duration"]: durationArray });
    setStateTransitions({
      ...stateTransitions,
      fadeIn: transitionFadeInArray,
      fadeOut: transitionFadeOutArray,
    });
  };

  function _bindGameStates(scenes) {
    return scenes.map((scene, index) => {
      return (
        <div key={index} className="sidebar-list-states">
          <h3>
            <span>State #{index + 1}</span>
            {index > 0 ? (
              <span
                className="close-state btn btn-default btn-delete"
                onClick={() => deleteGameStateConfirmation(index)}
              >
                <DeleteIcon />
              </span>
            ) : (
              ""
            )}
          </h3>
          <ToastContainer />
          <li key={"stateDuration" + index}>
            {!editStateDuration.status && editStateDuration.index === -1 ? (
              <label
                id="creativeWidth"
                onClick={() => setEditStateDuration({ status: true, index: index })}
              >
                State Duration {scene.duration ? scene.duration / 1000 : ""}
                <span className="arrow">
                  <ArrowIcon />
                </span>
              </label>
            ) : (
              [
                editStateDuration.status && editStateDuration.index === index ? (
                  <div>
                    <label>
                      State Duration{" "}
                      <span
                        className="arrow"
                        onClick={() => setEditStateDuration({ status: false, index: -1 })}
                      >
                        <ArrowIcon />
                      </span>
                    </label>
                    <div className="sidebar-input">
                      <input
                        type="number"
                        pattern="[0-9]*"
                        inputMode="numeric"
                        name="stateDuration"
                        onChange={(e) => handleStateDurationChange(index, e)}
                        value={stateDurations.duration[index]}
                      />
                      <button
                        disabled={
                          !stateDurations.duration[index] || stateDurations.duration[index] === 0
                        }
                        onClick={() => saveStateDurationHandle(index)}
                        className="btn btn-default obj-action-btn"
                      >
                        <SaveIcon />
                      </button>
                      <button
                        onClick={() => resetStateDurationHandle()}
                        className="btn btn-default obj-action-btn"
                      >
                        <CloseIcon />
                      </button>
                    </div>
                  </div>
                ) : (
                  <label
                    id="creativeWidth"
                    onClick={() => setEditStateDuration({ status: true, index: index })}
                  >
                    State Duration {scene.duration ? scene.duration : ""}
                    <span className="arrow">
                      <ArrowIcon />
                    </span>
                  </label>
                ),
                "",
              ]
            )}
          </li>
          <li key={"gamelayer" + index}>
            <label
              onClick={() =>
                setEditGameLayer({
                  status: !editGameLayer.status,
                  index: editGameLayer.index === -1 ? index : -1,
                })
              }
            >
              {"State layer"}
              <span className="arrow">
                <ArrowIcon />
              </span>
            </label>
            {editGameLayer.status && editGameLayer.index === index ? (
              <ul id="stateLayersOfGame" className="values-list">
                {_bindGameLayers(scene.layers)}
              </ul>
            ) : (
              ""
            )}
          </li>

          <li key={"transition" + index}>
            <label
              onClick={() =>
                setEditGameTransition({
                  status: !editGameTransition.status,
                  index: editGameTransition.index === -1 ? index : -1,
                })
              }
            >
              {"State Transitions"}
              <span className="arrow">
                <ArrowIcon />
              </span>
            </label>
            {editGameTransition.status && editGameTransition.index === index ? (
              <ul id="stateTransitionProps">
                <li>
                  <>
                    {!editGameTransitionFadeIn.status && editGameTransitionFadeIn.index === -1 ? (
                      <label
                        id="transitionFadeIn"
                        onClick={() =>
                          setEditGameTransitionFadeIn({
                            status: true,
                            index: index,
                          })
                        }
                      >
                        {"Fade In "}
                        {scene.transitions &&
                        scene.transitions.into &&
                        scene.transitions.into.duration
                          ? scene.transitions.into.duration / 1000
                          : ""}
                        <span className="arrow">
                          <ArrowIcon />
                        </span>
                      </label>
                    ) : (
                      <div>
                        <label>
                          {"Fade In "}
                          <span className="arrow">
                            <ArrowIcon />
                          </span>
                        </label>
                        <div className="sidebar-input">
                          <input
                            type="number"
                            pattern="[0-9]*"
                            inputMode="numeric"
                            name="transitionFadeIn"
                            value={stateTransitions["fadeIn"][index].duration}
                            onChange={(e) => handleTransitionChange(index, e)}
                          />
                          <button
                            disabled={!stateTransitions["fadeIn"][index].duration}
                            onClick={() => saveTransition(index)}
                            className="btn btn-default obj-action-btn"
                          >
                            <SaveIcon />
                          </button>
                          <button
                            onClick={() => resetSceneTransitions()}
                            className="btn btn-default obj-action-btn"
                          >
                            <CloseIcon />
                          </button>
                        </div>
                      </div>
                    )}
                  </>
                </li>

                <li>
                  <>
                    {!editGameTransitionFadeOut.status && editGameTransitionFadeOut.index === -1 ? (
                      <label
                        id="transitionFadeOut"
                        onClick={() =>
                          setEditGameTransitionFadeOut({
                            status: true,
                            index: index,
                          })
                        }
                      >
                        {"Fade Out "}
                        {scene.transitions &&
                        scene.transitions.out &&
                        scene.transitions.out.duration
                          ? scene.transitions.out.duration / 1000
                          : ""}
                        <span className="arrow">
                          <ArrowIcon />
                        </span>
                      </label>
                    ) : (
                      <div>
                        <label>
                          Fade Out{" "}
                          <span className="arrow">
                            <ArrowIcon />
                          </span>
                        </label>
                        <div className="sidebar-input">
                          <input
                            type="number"
                            pattern="[0-9]*"
                            inputMode="numeric"
                            name="transitionFadeOut"
                            value={stateTransitions["fadeOut"][index].duration}
                            onChange={(e) => handleTransitionChange(index, e)}
                          />
                          <button
                            disabled={!stateTransitions["fadeOut"][index].duration}
                            onClick={() => saveTransition(index)}
                            className="btn btn-default obj-action-btn"
                          >
                            <SaveIcon />
                          </button>
                          <button
                            onClick={() => resetSceneTransitions()}
                            className="btn btn-default obj-action-btn"
                          >
                            <CloseIcon />
                          </button>
                        </div>
                      </div>
                    )}
                  </>
                </li>
              </ul>
            ) : (
              ""
            )}
          </li>

          <li key={"nonInteractiveGameObject" + index}>
            <label
              onClick={() =>
                setEditNonInteractiveGameObject({
                  status: !editNonInteractiveGameObject.status,
                  index: editNonInteractiveGameObject.index === -1 ? index : -1,
                })
              }
            >
              {"Non Interactive Game Objects"}
              <span className="arrow">
                <ArrowIcon />
              </span>
            </label>
            {editNonInteractiveGameObject.status && editNonInteractiveGameObject.index === index ? (
              <div>
                <ul id="nonInteractiveGameObjects" className="values-list">
                  {_bindGameObjects(
                    scene.objects.nonInteractiveObjects,
                    "nonInteractiveObjects",
                    index,
                    scene.layers
                  )}
                </ul>
                <button
                  className="btn btn-primary btn-switch"
                  onClick={() =>
                    dispatch(
                      openEditGameObjectModal(
                        undefined,
                        undefined,
                        index,
                        creativeId,
                        "nonInteractiveObjects",
                        scene.layers,
                        cancelEdit,
                        originalGameObjectValue
                      )
                    )
                  }
                >
                  Add New Game Object
                </button>
              </div>
            ) : (
              ""
            )}
          </li>
          {truengageCreativeType && truengageCreativeType === "Draggable" ? (
            <li key={"dragableGameObject" + index}>
              <label
                onClick={() =>
                  setEditDragableGameObject({
                    status: !editDragableGameObject.status,
                    index: editDragableGameObject.index === -1 ? index : -1,
                  })
                }
              >
                {"Draggable Game Objects"}
                <span className="arrow">
                  <ArrowIcon />
                </span>
              </label>
              {editDragableGameObject.status && editDragableGameObject.index === index ? (
                <div>
                  <ul id="draggableGameObjects" className="values-list">
                    {_bindGameObjects(
                      scene.objects.dragableObjects,
                      "dragableObjects",
                      index,
                      scene.layers
                    )}
                  </ul>
                  <button
                    className="btn btn-primary btn-switch"
                    onClick={() =>
                      dispatch(
                        openEditGameObjectModal(
                          undefined,
                          undefined,
                          index,
                          creativeId,
                          "dragableObjects",
                          scene.layers,
                          cancelEdit,
                          originalGameObjectValue
                        )
                      )
                    }
                  >
                    Add New Game Object
                  </button>
                </div>
              ) : (
                ""
              )}
            </li>
          ) : (
            ""
          )}

          {truengageCreativeType && truengageCreativeType === "Launchable" ? (
            <li key={"launchableGameObject" + index}>
              <label
                onClick={() =>
                  setEditLaunchableGameObject({
                    status: !editLaunchableGameObject.status,
                    index: editLaunchableGameObject.index === -1 ? index : -1,
                  })
                }
              >
                {"Launchable Game Objects"}
                <span className="arrow">
                  <ArrowIcon />
                </span>
              </label>
              {editLaunchableGameObject.status && editLaunchableGameObject.index === index ? (
                <div>
                  <ul id="launchableGameObjects" className="values-list">
                    {_bindGameObjects(
                      scene.objects.launchableObjects,
                      "launchableObjects",
                      index,
                      scene.layers
                    )}
                  </ul>
                  <button
                    className="btn btn-primary btn-switch"
                    onClick={() =>
                      dispatch(
                        openEditGameObjectModal(
                          undefined,
                          undefined,
                          index,
                          creativeId,
                          "launchableObjects",
                          scene.layers,
                          cancelEdit,
                          originalGameObjectValue
                        )
                      )
                    }
                  >
                    Add New Game Object
                  </button>
                </div>
              ) : (
                ""
              )}
            </li>
          ) : (
            ""
          )}

          {truengageCreativeType && truengageCreativeType === "Collectable" ? (
            <li key={"collectableGameObject" + index}>
              <label
                onClick={() =>
                  setEditCollectableGameObject({
                    status: !editCollectableGameObject.status,
                    index: editCollectableGameObject.index === -1 ? index : -1,
                  })
                }
              >
                {"Collectable Game Objects"}
                <span className="arrow">
                  <ArrowIcon />
                </span>
              </label>
              {editCollectableGameObject.status && editCollectableGameObject.index === index ? (
                <div>
                  <ul id="collectableGameObjects" className="values-list">
                    {_bindGameObjects(
                      scene.objects.collectableObjects,
                      "collectableObjects",
                      index,
                      scene.layers
                    )}
                  </ul>

                  {Object.keys(scene.objects.collectableObjects).length <= 0 ? (
                    <button
                      className="btn btn-primary btn-switch"
                      onClick={() =>
                        dispatch(
                          openEditGameObjectModal(
                            undefined,
                            undefined,
                            index,
                            creativeId,
                            "collectableObjects",
                            scene.layers,
                            cancelEdit,
                            originalGameObjectValue
                          )
                        )
                      }
                    >
                      Add New Game Object
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
            </li>
          ) : (
            ""
          )}

          {truengageCreativeType && truengageCreativeType === "Drawing" ? (
            <li key={"drawableGameObjects" + index}>
              <label
                onClick={() =>
                  setEditDrawableGameObject({
                    status: !editDrawableGameObject.status,
                    index: editDrawableGameObject.index === -1 ? index : -1,
                  })
                }
              >
                {"Drawable Game Objects"}
                <span className="arrow">
                  <ArrowIcon />
                </span>
              </label>
              {editDrawableGameObject.status && editDrawableGameObject.index === index ? (
                <div>
                  <ul id="drawableGameObjects" className="values-list">
                    {_bindGameObjects(
                      scene.objects.drawableObjects,
                      "drawableObjects",
                      index,
                      scene.layers
                    )}
                  </ul>

                  {Object.keys(scene.objects.drawableObjects).length <= 0 ? (
                    <button
                      className="btn btn-primary btn-switch"
                      onClick={() =>
                        dispatch(
                          openEditGameObjectModal(
                            undefined,
                            undefined,
                            index,
                            creativeId,
                            "drawableObjects",
                            scene.layers,
                            cancelEdit,
                            originalGameObjectValue
                          )
                        )
                      }
                    >
                      Add New Game Object
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
            </li>
          ) : (
            ""
          )}

          {scene.objects.binObjects ? (
            <li key={"binGameObject" + index}>
              <label
                onClick={() =>
                  setEditBinGameObject({
                    status: !editBinGameObject.status,
                    index: editBinGameObject.index === -1 ? index : -1,
                  })
                }
              >
                {"Bin Game Objects"}
                <span className="arrow">
                  <ArrowIcon />
                </span>
              </label>
              {editBinGameObject.status && editBinGameObject.index === index ? (
                <div>
                  <ul id="binGameObjects" className="values-list">
                    {_bindGameObjects(scene.objects.binObjects, "binObjects", index, scene.layers)}
                  </ul>
                  <button
                    className="btn btn-primary btn-switch"
                    onClick={() =>
                      dispatch(
                        openEditGameObjectModal(
                          undefined,
                          undefined,
                          index,
                          creativeId,
                          "binObjects",
                          scene.layers,
                          cancelEdit,
                          originalGameObjectValue
                        )
                      )
                    }
                  >
                    Add New Game Object
                  </button>
                </div>
              ) : (
                ""
              )}
            </li>
          ) : (
            ""
          )}

          {truengageCreativeType &&
          truengageCreativeType === "Draggable" &&
          scene.objects.binObjects &&
          Object.keys(scene.objects.binObjects).length > 0 ? (
            <li key={"maskableGameObject" + index}>
              <label
                onClick={() =>
                  setEditMaskableGameObject({
                    status: !editMaskableGameObject.status,
                    index: editMaskableGameObject.index === -1 ? index : -1,
                  })
                }
              >
                {"Maskable Game Objects"}
                <span className="arrow">
                  <ArrowIcon />
                </span>
              </label>
              {editMaskableGameObject.status && editMaskableGameObject.index === index ? (
                <div>
                  <ul id="maskableGameObjects" className="values-list">
                    {_bindGameObjects(
                      scene.objects.maskableObjects,
                      "maskableObjects",
                      index,
                      scene.layers
                    )}
                  </ul>
                  <button
                    className="btn btn-primary btn-switch"
                    onClick={() =>
                      dispatch(
                        openEditGameObjectModal(
                          undefined,
                          undefined,
                          index,
                          creativeId,
                          "maskableObjects",
                          scene.layers,
                          cancelEdit,
                          originalGameObjectValue
                        )
                      )
                    }
                  >
                    Add New Game Object
                  </button>
                </div>
              ) : (
                ""
              )}
            </li>
          ) : (
            ""
          )}
        </div>
      );
    });
  }

  function _bindGameLayers(layers) {
    return layers.map((layer, index) => {
      return (
        <li key={`layer` + index}>
          <span> {layer.name ? layer.name : layer.id} </span>
        </li>
      );
    });
  }

  function modalTrigger(
    item,
    objects,
    sceneNo,
    creativeId,
    type,
    layers,
    cancelEdit,
    originalGameObjectValue
  ) {
    setCurrentObjName(item + sceneNo);
    dispatch(
      openEditGameObjectModal(
        item,
        objects[item],
        sceneNo,
        creativeId,
        type,
        layers,
        cancelEdit,
        originalGameObjectValue
      )
    );
  }

  function _bindGameObjects(objects, type, sceneNo, layers) {
    return (
      objects &&
      Object.keys(objects).map((item, index) => {
        return (
          <li key={`object` + index} className="check-box cursor-pointer">
            {objects[item].image ? (
              <span className="object-icon">
                <ImageIcon />
              </span>
            ) : objects[item].text ? (
              <span className="object-icon text-type">
                <TextIcon />
              </span>
            ) : objects[item].container ? (
              <span className="object-icon">
                <ContainerIcon />
              </span>
            ) : objects[item].shape ? (
              <span className="object-icon">
                <ShapeIcon />
              </span>
            ) : objects[item].spritesheet ? (
              <span className="object-icon">
                <SpriteIcon />
              </span>
            ) : null}

            <span
              id={item + sceneNo}
              onClick={() => {
                modalTrigger(
                  item,
                  objects,
                  sceneNo,
                  creativeId,
                  type,
                  layers,
                  cancelEdit,
                  originalGameObjectValue
                );
              }}
            >
              {item}{" "}
            </span>
            {item === "background" ? (
              ""
            ) : (
              <span
                className="delete-game-object"
                onClick={() => deleteGameObject(item, type, index, sceneNo, creativeId)}
                className="btn btn-default btn-delete"
              >
                <DeleteIcon />
              </span>
            )}
          </li>
        );
      })
    );
  }

  const deleteGameObject = (item, type, index, sceneNo) => {
    // hit the api here
    confirmAlert({
      title: "Confirm to delete",
      message: `Are you sure to delete ${item}.`,
      buttons: [
        {
          label: "Yes",
          onClick: () =>
            dispatch(
              deleteGameObjectFunc(
                creativeId,
                item,
                type,
                sceneNo,
                sourceCode,
                truengageCreativeType
              )
            ),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const addGameState = () => {
    confirmAlert({
      title: "Are you sure to add new Game State?",
      buttons: [
        {
          label: "Yes",
          onClick: () => dispatch(addGameStateFunc(creativeId, sourceCode, truengageCreativeType)),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  function handleChange(e) {
    e.persist();
    setAdDimension((adDimension) => ({
      ...adDimension,
      [e.target.name]: e.target.value,
    }));
  }

  function handleStateDurationChange(index, e) {
    e.persist();
    const tempStateDurations = stateDurations.duration;
    tempStateDurations[index] = e.target.value ? Number(e.target.value) : "";
    setStateDurations({ duration: tempStateDurations });
  }

  function saveStateDurationHandle(index) {
    var stateDuration = stateDurations.duration[index];
    var selectedScene = R.path(["scenes"], sourceCode)[index];
    selectedScene["duration"] = stateDuration * 1000;
    setEditStateDuration({ status: false, index: -1 });
    dispatch(updateSelectedTrueCreativeStateDurationFunc(creativeId, sourceCode));
  }

  function resetStateDurationHandle(index) {
    setEditStateDuration({ status: false, index: -1 });
    setSceneAttributes(dragableModel);
  }

  function resetGameDimension() {
    setEditAdDimension({ width: false, height: false });
    setSceneAttributes(dragableModel);
  }

  function resetSceneTransitions() {
    setEditGameTransitionFadeIn({
      status: false,
      index: -1,
    });
    setEditGameTransitionFadeOut({
      status: false,
      index: -1,
    });
    setSceneAttributes(dragableModel);
  }
  function saveAdDimension(type) {
    dispatch(
      updateSelectedTrueCreativeDimensionFunc(creativeId, type, adDimension[type], sourceCode)
    );
    setEditAdDimension({ width: false, height: false });
  }

  function handleTransitionChange(index, e) {
    e.persist();
    const tempStateFadeIns = stateTransitions.fadeIn;
    const tempStateFadeOuts = stateTransitions.fadeOut;
    if (e.target.name === "transitionFadeIn") {
      tempStateFadeIns[index].duration = e.target.value ? Number(e.target.value) : "";
    } else {
      tempStateFadeOuts[index].duration = e.target.value ? Number(e.target.value) : "";
    }

    setStateTransitions({
      ...stateTransitions,
      fadeIn: tempStateFadeIns,
      fadeOut: tempStateFadeOuts,
    });
  }

  function saveTransition(index) {
    var selectedScene = R.path(["scenes"], sourceCode)[index];
    var fadeIn = stateTransitions.fadeIn[index] * 1000;
    var fadeOut = stateTransitions.fadeOut[index] * 1000;
    var transition = { into: fadeIn, out: fadeOut };
    selectedScene["transitions"] = transition;
    setEditGameTransitionFadeIn({
      status: false,
      index: -1,
    });
    setEditGameTransitionFadeOut({
      status: false,
      index: -1,
    });
    dispatch(updateSelectedTrueCreativeStateDurationFunc(creativeId, sourceCode));
  }

  const deleteGameStateConfirmation = (stateIndex) => {
    // hit the api here
    confirmAlert({
      title: "Confirm to delete",
      message: `Are you sure to delete Game State?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteGameState(stateIndex),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  /**
   * to delete Game State by index number
   * @param {*} stateIndex
   */
  function deleteGameState(stateIndex) {
    var selectedScene = R.path(["scenes"], sourceCode)[stateIndex];
    if (selectedScene) {
      sourceCode["scenes"].splice(stateIndex, 1);
      dispatch(updateSelectedTrueCreativeStateDurationFunc(creativeId, sourceCode));
    }
  }

  return (
    <div className="right-sidebar">
      <h3 id="creativeName" className="creator-name">
        {creativeName}
      </h3>
      <div className={!editAdDimension.width ? "accordian" : "accordian show"}>
        {!editAdDimension.width ? (
          <label
            id="creativeWidth"
            onClick={() => setEditAdDimension({ width: !editAdDimension.width })}
          >
            Width {dragableModel.width}
            <span className="arrow">
              <ArrowIcon />
            </span>
          </label>
        ) : (
          <div>
            <label
              onClick={() => setEditAdDimension({ width: !editAdDimension.width, height: false })}
            >
              Width{" "}
              <span className="arrow">
                <ArrowIcon />
              </span>
            </label>
            <div className="sidebar-input">
              <input
                type="number"
                pattern="[0-9]*"
                inputMode="numeric"
                name="width"
                value={adDimension.width}
                onChange={handleChange}
              />
              <button
                disabled={!adDimension.width || adDimension.width === 0}
                onClick={() => saveAdDimension("width")}
                className="btn btn-default obj-action-btn"
              >
                <SaveIcon />
              </button>
              <button
                onClick={() => resetGameDimension()}
                className="btn btn-default obj-action-btn"
              >
                <CloseIcon />
              </button>
            </div>
          </div>
        )}
      </div>

      <div className={!editAdDimension.height ? "accordian" : "accordian show"}>
        {!editAdDimension.height ? (
          <label
            id="creativeHeight"
            onClick={() => setEditAdDimension({ height: !editAdDimension.height })}
          >
            Height {dragableModel.height}
            <span className="arrow">
              <ArrowIcon />
            </span>
          </label>
        ) : (
          <div>
            <label
              onClick={() => setEditAdDimension({ width: false, height: !editAdDimension.height })}
            >
              Height{" "}
              <span className="arrow">
                <ArrowIcon />
              </span>
            </label>
            <div className="sidebar-input">
              <input
                type="number"
                pattern="[0-9]*"
                inputMode="numeric"
                name="height"
                value={adDimension.height}
                onChange={handleChange}
              />
              <button
                disabled={!adDimension.height || adDimension.height === 0}
                onClick={() => saveAdDimension("height")}
                className="btn btn-default obj-action-btn"
              >
                <SaveIcon />
              </button>
              <button
                onClick={() => resetGameDimension()}
                className="btn btn-default obj-action-btn"
              >
                <CloseIcon />
              </button>
            </div>
          </div>
        )}
      </div>
      {isCpcBannerEnabled ? (
        <div className="accordian">
          <label
            onClick={() =>
              dispatch(
                openEditCPCBannerModal(
                  dragableModel &&
                    dragableModel["cpcBanner"] &&
                    dragableModel["cpcBanner"] &&
                    dragableModel["cpcBanner"]["domElement"]
                    ? dragableModel["cpcBanner"]["domElement"]
                    : undefined
                )
              )
            }
            className="modal-link"
          >
            CPC Banner
          </label>
        </div>
      ) : (
        ""
      )}
      <div className={!editGameState ? "accordian" : "accordian show"}>
        <label onClick={() => setGameState(!editGameState)}>
          Game States{" "}
          <span className="arrow">
            <ArrowIcon />
          </span>
        </label>
        {!editGameState ? (
          ""
        ) : (
          <div>
            <ul className="sidebar-list" id="gameState">
              {_bindGameStates(dragableModel.scenes)}
            </ul>
            {truengageCreativeType === "Draggable" || truengageCreativeType === "Launchable" ? (
              <button className="btn btn-primary btn-switch" onClick={() => addGameState()}>
                Add New Game State
              </button>
            ) : (
              ""
            )}
          </div>
        )}
      </div>
      <EditCPCBannerObjectModal creativeuid={creativeId} />
    </div>
  );
};

function mapStateToProps(state) {
  return {
    dragableModel: state.truengageCreative.dragableModel,
    creativeName: state.truengageCreative.creativeName,
    sourceCode: state.truengageCreative.sourceCode,
    isCpcBannerEnabled: state.truengageCreative.isCpcBannerEnabled,
    operationResponse: state.truengageCreative.operationResponse,
    truengageCreativeType: state.truengageCreative.truengageCreativeType,
    originalGameObjectValue: state.gameObject.originalGameObjectValue,
    cancelEdit: state.gameObject.cancelEdit,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    openEditGameObjectModal: (key, value, stateIndex, creativeId, objType, layers) =>
      dispatch(openEditGameObjectModal(key, value, stateIndex, creativeId, objType, layers)),
    deleteGameObjectFunc: (
      creativeUid,
      objectName,
      sceneIndex,
      sourceCode,
      truengageCreativeType
    ) =>
      dispatch(
        deleteGameObjectFunc(creativeUid, objectName, sceneIndex, sourceCode, truengageCreativeType)
      ),
    resetOperationResponse: () => dispatch(resetOperationResponse()),
    updateSelectedTrueCreativeDimensionFunc: (creativeId, inputType, value, sourceCode) =>
      dispatch(updateSelectedTrueCreativeDimensionFunc(creativeId, inputType, value, sourceCode)),
    updateSelectedTrueCreativeStateDurationFunc: (creativeId, sourceCode) =>
      dispatch(updateSelectedTrueCreativeStateDurationFunc(creativeId, sourceCode)),
    openEditCPCBannerModal: (cpcBanner) => dispatch(openEditCPCBannerModal(cpcBanner)),
    addGameStateFunc: (creativeUid, sourceCode) =>
      dispatch(addGameStateFunc(creativeUid, sourceCode)),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(EditTruengageCreativeSidebar);
