import { Types } from "../constants/account-types";

const INITIAL_STATE = {
  xpsAccounts: {},
  selectedAccount: undefined,
};

export default function account(state = INITIAL_STATE, action) {
  if (action.type === Types.ERROR_IN_LOADING_ACCOUNTS) {
    return { ...state, accountMsg: action.payload };
  } else if (action.type === Types.GET_ALL_XPS_ACCOUNTS) {
    return { ...state, xpsAccounts: action.payload };
  } else if (action.type === Types.GET_ALL_XPS_CAMPAIGNS_ACCOUNT_ID) {
    return { ...state, xpsCampaigns: action.payload };
  } else if (action.type === Types.GET_CURRENT_ACCOUNT) {
    return { ...state, selectedAccount: action.payload };
  } else {
    return state;
  }
}
