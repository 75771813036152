import React, { useEffect } from "react";
import { connect } from "react-redux";
import { loadXPSAccounts, clearAccountState } from "../store/actions/accounts";
import { useDispatch } from "react-redux";
import Loader from "./loader";
import { Link } from "react-router-dom";
import requireAuth from "./hoc/requireAuth";
import { compose } from "redux";
const Home = ({ xpsAccounts }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadXPSAccounts());
    return () => {
      dispatch(clearAccountState());
    };
  }, []);

  function _bindXPSAccounts(accounts) {
    return Object.keys(accounts).map((item, index) => {
      return (
        <li key={index} className="check-box">
          <Link to={`/campaigns/${item}`}>{accounts[item].name}</Link>
        </li>
      );
    });
  }

  return (
    <div>
      {xpsAccounts && Object.keys(xpsAccounts).length > 0 ? (
        <div className="page">
          <h3>Accounts</h3>
          <div className="listing-container">
            <ul id="xpsAccounts">{xpsAccounts ? _bindXPSAccounts(xpsAccounts) : ""}</ul>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    xpsAccounts: state.account.xpsAccounts,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadXPSAccounts: () => dispatch(loadXPSAccounts()),
    clearAccountState: () => dispatch(clearAccountState()),

  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps), requireAuth)(Home);
