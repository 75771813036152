import { useState, useEffect } from "react";
import { getDayStartTime, getDayEndTime } from "./../appUtils";
import { useDispatch } from "react-redux";
import { updateLoaderState } from "../../store/actions/gameObjects";
const CreativeObjectForm = (saveCreative, validate) => {
  const dispatch = useDispatch();

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isNew, setIsNew] = useState(true);
  const [truengageCreative, setTruengageCreative] = useState({
    name: "",
    account: "",
    video_line: "",
    type: "Draggable",
    is_cpc_banner: false,
    weight: "",
    duration: "",
    start_at: getDayStartTime(),
    end_at: getDayEndTime(),
    engine_version: "2.2.1",
    source: "",
    paused: false,
    updated_at: new Date().getTime(),
    updated_by: localStorage.getItem("uid"),
  });
  const [devices, setDevices] = useState({
    desktop: false,
    tablet: false,
    phone: false,
    ctv: false,
  });

  const [flightDateType, setFlightDateType] = useState({
    start_type: "Custom",
    end_type: "Custom",
  });

  function handleChange(e) {
    e.persist();
    if (e.target.name === "start_type" || e.target.name === "end_type") {
      setFlightDateType((flightDateType) => ({
        ...flightDateType,
        [e.target.name]: e.target.value,
      }));
    } else if (e.target.name === "is_cpc_banner") {
      setTruengageCreative((truengageCreative) => ({
        ...truengageCreative,
        [e.target.name]: !truengageCreative.is_cpc_banner,
      }));
    } else if (e.target.name === "paused") {
      setTruengageCreative((truengageCreative) => ({
        ...truengageCreative,
        [e.target.name]: !truengageCreative.paused,
      }));
    } else if (e.target.name === "duration") {
      setTruengageCreative((truengageCreative) => ({
        ...truengageCreative,
        [e.target.name]: e.target.value ? Number(e.target.value) : "",
      }));
    } else {
      setTruengageCreative((truengageCreative) => ({
        ...truengageCreative,
        [e.target.name]: e.target.value,
      }));
    }
  }
  function handleChangeDate(option, e) {
    var start_at = truengageCreative.start_at;
    var end_at = truengageCreative.end_at;
    if (option === "start_at") {
      start_at = e.getTime();
    } else if (option === "end_at") {
      end_at = e.getTime();
    }
    setTruengageCreative((truengageCreative) => ({
      ...truengageCreative,
      start_at: start_at,
      end_at: end_at,
    }));
  }

  function onChangeDevice(option, e) {
    if (option === "desktop") {
      devices.desktop = !devices.desktop;
    } else if (option === "phone") {
      devices.phone = !devices.phone;
    } else if (option === "tablet") {
      devices.tablet = !devices.tablet;
    } else if (option === "ctv") {
      devices.ctv = !devices.ctv;
    }

    setDevices((devices) => ({
      ...devices,
      desktop: devices.desktop,
      phone: devices.phone,
      tablet: devices.tablet,
      ctv: devices.ctv,
    }));

    setTruengageCreative({ ...truengageCreative, "devices": devices });
  }

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      saveCreative();
      setErrors({});
      setIsSubmitting(false);
    }
    if (Object.keys(errors).length > 0) {
      setIsSubmitting(false);
      dispatch(updateLoaderState(false));
    }
  }, [errors, isSubmitting, saveCreative]);

  const handleSubmit = (e) => {
    if (e) e.preventDefault();
    setIsSubmitting(true);
    const tempErrors = validate(truengageCreative);
    setErrors({ ...tempErrors });
    dispatch(updateLoaderState(false));
  };


  return [
    truengageCreative,
    setTruengageCreative,
    setDevices,
    setFlightDateType,
    setIsNew,
    handleChange,
    handleChangeDate,
    onChangeDevice,
    handleSubmit,
    errors,
    setErrors,
    flightDateType,
    devices,
    isNew,
  ];
};

export default CreativeObjectForm;
