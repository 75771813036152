export default function validate(cpcBanner) {
  let errors = {};
  // Checking if name is not empty
  if (!cpcBanner.name || cpcBanner.name === "") {
    errors.nameIsEmpty = "Need to add banner name";
  }

  // Checking if source is not empty
  if (!cpcBanner.source || cpcBanner.source === "") {
    errors.sourceIsEmpty = "Need to add banner source";
  }

  // Checking if width is not empty
  if (!cpcBanner.width || cpcBanner.width === "") {
    errors.widthIsEmpty = "Need to add banner width";
  }

  // Checking if height is not empty
  if (!cpcBanner.height || cpcBanner.height === "") {
    errors.heightIsEmpty = "Need to add banner height";
  }

  return errors;
}
