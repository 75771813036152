import { Types } from "../constants/api-types";

export function beginApiCall() {
  return { type: Types.BEGIN_API_CALL };
}

export function apiCallError() {
  return { type: Types.API_CALL_ERROR };
}

export function apiCallReset() {
  return { type: Types.API_CALL_RESET };
}
