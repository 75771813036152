import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import "./css/index.css";
import "./scss/index.scss";
import App from "./pages/app";

//STYLESHEET
import "./app.scss";

// SETTING UP REDUX STORE
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import reduxThunk from "redux-thunk";
import reducers from "./store/reducers";
import "react-accessible-accordion/dist/fancy-example.css";
// ENHANCING STORE WITH FIREBASE
import { reactReduxFirebase } from "react-redux-firebase";
import firebase from "./config/firebase";
import { verifyAuth } from "./store/actions/auth";
const createStoreWithFirebase = compose(reactReduxFirebase(firebase))(createStore);
const store = createStoreWithFirebase(reducers, {}, applyMiddleware(reduxThunk));
store.dispatch(verifyAuth());
ReactDOM.render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>,
  document.getElementById("root")
);
