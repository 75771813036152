import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { compose } from "redux";
import { updateLoaderState } from "./../../store/actions/gameObjects";
import {
  addNewImage,
  loadImageResourcesByAccountId,
  resetImageAddedResponse,
  addNewImageToAWS,
  resetImageAddedAWSResponse,
} from "../../store/actions/image-resouces";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// ********** reading AWS credentials from .env file **************

const ImageGameObject = ({
  gameObject,
  imageResources,
  handleImageChange,
  auth,
  addNewImageFunc,
  imageOperationResponse,
  resetImageAddedResponse,
  loadImageResourcesByAccount,
  addImageToAWS,
  awsResponse,
  creativeAccountId,
  errors,
}) => {
  const dispatch = useDispatch();
  const imageResourceBaseUrl =
    "https://s3-" +
    process.env.REACT_APP_REGION +
    ".amazonaws.com/" +
    process.env.REACT_APP_BUCKET_NAME +
    "/" +
    process.env.REACT_APP_DIR_NAME +
    "";
  const [file, setFile] = useState(null);
  const fileRef = useRef();

  // hook to detect image operation response
  useEffect(() => {
    if (imageOperationResponse) {
      if (!imageOperationResponse.success) toast.error(imageOperationResponse.message);
      else toast.success(imageOperationResponse.message);
      loadImageResourcesByAccount(localStorage.getItem("accountId"));
      resetImageAddedResponse(); // state reset
    }
  }, [imageOperationResponse]);

  // hook to detect AWS image added response
  useEffect(() => {
    if (awsResponse) {
      if (awsResponse.success) {
        // call the firebase save image function
        saveImageToFirebase();
      } else {
        toast.info(awsResponse.message);
      }
    }
    dispatch(resetImageAddedAWSResponse()); // state reset
  }, [awsResponse]);

  const handleFileChange = (e) => {
    let fileObj = e.target.files[0];
    if (fileObj.type.indexOf("image/") === -1) {
      alert("Only Images are allowed");
      return;
    }
    dispatch(updateLoaderState(true));
    let fileDimensions = { width: 0, height: 0 };
    // ************* to get the image dimensions via plain javascript
    var url = URL.createObjectURL(fileObj);
    var img = new Image();

    img.onload = () => {
      fileDimensions.width = img.width;
      fileDimensions.height = img.height;
      fileObj.fileDimensions = fileDimensions;
      setFile(fileObj);
      // ****** aws function to put file into bucket
      addImageToAWS(fileObj);
    };
    img.src = url;
  };

  const saveImageToFirebase = () => {
    let formData = {
      account: creativeAccountId,
      bucket: process.env.REACT_APP_BUCKET_NAME,
      filename: file.name,
      height: file.fileDimensions.height,
      size: file.size,
      mime_type: file.type,
      status: "Active",
      updated_at: +new Date(), // current timestamp
      updated_by: auth.uid, // current user id
      width: file.fileDimensions.width,
    };
    addNewImageFunc(formData);
    dispatch(updateLoaderState(false));
  };

  var imageUrlArray = [];
  var isImageSelected = false;
  if (gameObject && gameObject.image && gameObject.image.source) {
    isImageSelected = true;
    imageUrlArray.push(gameObject.image.source);
  }
  imageResources.map((item, idx) => {
    if (imageUrlArray.indexOf(imageResourceBaseUrl + item.filename) === -1) {
      imageUrlArray.push(imageResourceBaseUrl + item.filename);
    }
  });

  return (
    <div className="objects-container-section">
      <ToastContainer />
      <div className="add-image-div">
        <input
          type="file"
          id="gameObjectImageInput"
          ref={fileRef}
          accept="image/*"
          onChange={handleFileChange}
        />
        <label htmlFor="gameObjectImageInput">
          <span className="plus-icon">+</span>Add Image
        </label>
      </div>
      <div className="image-container">
        {imageUrlArray.map((imageUrl, idx) => (
          <img
            key={idx}
            // style={ idx == 0 && isImageSelected ? {marginRight: "10px", border:"5px solid #42801c" } : { marginRight: "10px" }}
            src={imageUrl}
            onClick={() => handleImageChange(imageUrl)}
            width="100px"
            height="100px"
            alt=""
            className={idx === 0 && isImageSelected ? "selected" : " "}
          />
        ))}
      </div>
      {errors && errors.image && <small>{errors.image}</small>}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    imageResources: state.imageReducer.imageResources,
    auth: state.firebase.auth,
    imageOperationResponse: state.imageReducer.imageOperationResponse,
    awsResponse: state.imageReducer.awsResponse,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addNewImageFunc: (formData) => dispatch(addNewImage(formData)),
    resetImageAddedResponse: () => dispatch(resetImageAddedResponse()),
    loadImageResourcesByAccount: (id) => dispatch(loadImageResourcesByAccountId(id)),
    addImageToAWS: (fileObj) => dispatch(addNewImageToAWS(fileObj)),
    updateLoaderState: (status) => dispatch(updateLoaderState(status)),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(ImageGameObject);
