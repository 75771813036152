import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { compose } from "redux";
import { Button, Modal } from "react-bootstrap";
import { ReactComponent as EditIcon } from "../../images/edit.svg";
import { ReactComponent as DeleteIcon } from "../../images/trash.svg";
import {
  getCurrentObjectFromSourceCode,
  setMoveTweenForGameObject,
  updateResizeTweenForGameObject,
} from "./../../utils/appUtils";
import { closeEditGameObjectBehaviorModal } from "../../store/actions/gameObjects";
import { defaultClickableBehavior } from "./../../constants/defaultValues";
import { updateSelectedTrueCreativePropsFunc } from "../../store/actions/truengageCreatives";
import validate from "../../utils/gameObjectBehavior/validateGameObject";
import { confirmAlert } from "react-confirm-alert"; // Import
import ReactTooltip from "react-tooltip";
import infoIcon from "../../images/info-logo.svg";
import horizontalX from "../../images/gif/pathX.gif";
import verticalY from "../../images/gif/pathY.gif";
import tweenX from "../../images/gif/tweenResizeX.gif";
import tweenY from "../../images/gif/tweenResizeY.gif";
import GameBehaviorForm from "../../utils/gameObjectBehavior/gameBehaviorForm";
import { updateLoaderState } from "../../store/actions/gameObjects";
import Loader from "./../../pages/loader";

const EditGameObjectBehaviorModal = ({
  isShowEditGameObjectBehaviorModal,
  currentGameObject,
  sourceCode,
  sceneIndex,
  gameObjectKey,
  creativeUid,
  editBehaviorType,
  editBehavior,
  behaviorIndex,
  isUpdateClickable,
  isLoaderEnabled,
}) => {
  const dispatch = useDispatch();
  const [isAddingTransition, setIsAddingTransition] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isPathTransitionEdit, setIsPathTransitionEdit] = useState({ status: false, index: -1 });
  const [behaviorType, setBehaviorType] = useState("click");
  const [tweenType, setTweenType] = useState("resize");
  const [errors, setErrors] = useState({});
  const [clickableBehavior, setClickableBehavior] = useState({
    type: "advanceGame",
    delay: "",
    url: "",
  });
  const [tweenMoveBehavior, setTweenMoveBehavior] = useState({
    tweenType: "move",
    delay: "",
    position: "",
    loop: true,
    moveDirection: "x",
  });

  const [tweenResizeBehavior, setTweenResizeBehavior] = useState({
    tweenType: "resize",
    loop: true,
    resizeDirection: "x",
    transitions: [],
  });

  const [tweenResizeTransition, setTweenResizeTransition] = useState({
    scale: "",
    position: "",
    delay: "",
  });

  const [pathBehavior, setPathBehavior] = useState({
    loop: true,
    start: "",
    transitions: [],
  });

  const [pathStart, setPathStart] = useState({
    x: "",
    y: "",
  });

  const [pathTransitions, setPathTransitions] = useState({
    x: "",
    y: "",
    duration: "",
  });
  const [isAddingResizeTransition, setIsAddingResizeTransition] = useState(false);
  const [isTweenResizeTransitionEdit, setIsTweenResizeTransitionEdit] = useState({
    status: false,
    index: -1,
  });

  useEffect(() => {
    if (editBehavior && editBehaviorType) {
      if (editBehaviorType === "click") {
        setBehaviorType("click");
        setClickableBehavior({
          type: editBehavior.clickable.actions.click[behaviorIndex].type,
          delay: editBehavior.clickable.actions.click[behaviorIndex].delay,
          url: editBehavior.clickable.actions.click[behaviorIndex].url,
        });
      } else if (editBehaviorType === "tween") {
        setBehaviorType("tween");
        var tween = {};
        if (
          editBehavior &&
          editBehavior.tween &&
          editBehavior.tween.transitions &&
          editBehavior.tween.transitions.length === 1
        ) {
          setTweenType("move");
          tween = editBehavior.tween;
          setTweenMoveBehavior({
            loop: tween.loop,
            delay:
              tween.transitions && tween.transitions[0] && tween.transitions[0][2]
                ? tween.transitions[0][2]
                : "",
            moveDirection:
              tween.transitions &&
              tween.transitions[0] &&
              tween.transitions[0][1] &&
              tween.transitions[0][1].x
                ? "x"
                : "y",
            position:
              tween.transitions &&
              tween.transitions[0] &&
              tween.transitions[0][1] &&
              (tween.transitions[0][1].x || tween.transitions[0][1].y),
          });
        } else {
          setTweenType("resize");
          var tween = editBehavior.tween;
          setTweenResizeBehavior({
            loop: tween.loop,
            resizeDirection:
              tween.transitions &&
              tween.transitions[1] &&
              tween.transitions[1][1] &&
              tween.transitions[1][1].x
                ? "x"
                : "y",
            transitions: tween.transitions,
          });
        }
      } else {
        setBehaviorType("path");
        var path = editBehavior.path;
        setPathBehavior({ ...path });
        setPathStart({ ...path.start });
      }
      setIsEdit(true);
    } else {
      setDefaultStateValue();
    }
    return () => {
      console.log("Unmount Edit Game Behavior Modal");
    };
  }, [editBehavior, editBehaviorType, behaviorIndex, isShowEditGameObjectBehaviorModal]);

  const setDefaultStateValue = () => {
    setClickableBehavior({ type: "advanceGame", delay: "", url: "" });
    setTweenMoveBehavior({
      tweenType: "move",
      delay: "",
      position: "",
      loop: true,
      moveDirection: "x",
    });
    setTweenResizeBehavior({
      tweenType: "resize",
      loop: true,
      resizeDirection: "x",
      transitions: [],
    });

    setTweenResizeTransition({
      scale: "",
      position: "",
      delay: "",
    });

    setPathBehavior({
      loop: true,
      start: "",
      transitions: [],
    });

    setPathStart({
      x: "",
      y: "",
    });

    setPathTransitions({
      x: "",
      y: "",
      duration: "",
    });
    setIsAddingResizeTransition(false);
    setIsTweenResizeTransitionEdit({
      status: false,
      index: -1,
    });

    setIsAddingTransition(false);

    setIsPathTransitionEdit({ status: false, index: -1 });

    setTweenType("resize");
    setErrors({});

    setIsEdit(false);
  };

  const savePathTransitions = (e) => {
    var tempPathBehavior = pathBehavior;
    if (isPathTransitionEdit.status && isPathTransitionEdit.index > -1) {
      tempPathBehavior["transitions"][isPathTransitionEdit.index] = pathTransitions;
    } else {
      tempPathBehavior["transitions"].push(pathTransitions);
    }

    setPathBehavior({ ...tempPathBehavior });
    setPathTransitions({ x: "", y: "", duration: "" });
    setIsPathTransitionEdit({ status: false, index: -1 });
    setIsAddingTransition(false);
  };

  const saveTweenTransitions = (e) => {
    dispatch(updateLoaderState(true));
    var tempTweenResizeBehavior = tweenResizeBehavior;

    if (isTweenResizeTransitionEdit.status && isTweenResizeTransitionEdit.index > -1) {
      tempTweenResizeBehavior["transitions"][
        isTweenResizeTransitionEdit.index
      ] = updateTempTweenResizeBehavior(tweenResizeTransition, tempTweenResizeBehavior);
    } else {
      tempTweenResizeBehavior["transitions"].push(
        updateTempTweenResizeBehavior(tweenResizeTransition, tempTweenResizeBehavior)
      );
    }

    setTweenResizeBehavior({ ...tempTweenResizeBehavior });
    //setTweenResizeTransition(tweenResizeBehavior.transitions);
    setTweenResizeTransition({ scale: "", position: "", delay: "" });
    setIsTweenResizeTransitionEdit({ status: false, index: -1 });
    setIsAddingResizeTransition(false);
    dispatch(updateLoaderState(false));
  };

  const updateTempTweenResizeBehavior = (tweenResizeTransition, tempTweenResizeBehavior) => {
    var transitionArray = ["to"];
    if (tempTweenResizeBehavior.resizeDirection === "x") {
      transitionArray.push({
        x: tweenResizeTransition.position,
        scaleX: tweenResizeTransition.scale,
      });
      transitionArray.push(tweenResizeTransition.delay);
    } else {
      transitionArray.push({
        y: tweenResizeTransition.position,
        scaleY: tweenResizeTransition.scale,
      });
      transitionArray.push(tweenResizeTransition.delay);
    }
    return transitionArray;
  };

  // add new behavior
  const saveBehavior = (e) => {
    dispatch(updateLoaderState(true));

    // var response = validateForm();
    setErrors({});
    var errors = validate(
      behaviorType,
      clickableBehavior,
      pathStart,
      tweenType,
      tweenMoveBehavior,
      tweenResizeBehavior
    );
    setErrors(errors);
    var response = true;
    if (errors && Object.keys(errors).length > 0) {
      response = false;
      dispatch(updateLoaderState(false));
    }
    if (response) {
      var currentObject = getCurrentObjectFromSourceCode(sourceCode, sceneIndex, gameObjectKey);
      if (behaviorType === "click") {
        var copyClickableBehavior = {};
        var newClickBehavior = {};
        if (currentObject && currentObject.behaviors) {
          copyClickableBehavior = currentObject.behaviors;
          if (clickableBehavior.type === "advanceGame") {
            newClickBehavior.type = clickableBehavior.type;
            newClickBehavior.delay = clickableBehavior.delay;
          } else {
            newClickBehavior.type = clickableBehavior.type;
            newClickBehavior.url = clickableBehavior.url;
          }
          isUpdateClickable
            ? copyClickableBehavior.clickable.actions.click.push(newClickBehavior)
            : (copyClickableBehavior.clickable.actions.click[behaviorIndex] = newClickBehavior);
        } else {
          copyClickableBehavior = defaultClickableBehavior;
          if (clickableBehavior.type === "advanceGame") {
            newClickBehavior.type = clickableBehavior.type;
            newClickBehavior.delay = clickableBehavior.delay;
          } else {
            newClickBehavior.type = clickableBehavior.type;
            newClickBehavior.url = clickableBehavior.url;
          }
          copyClickableBehavior.clickable.actions.click.push(newClickBehavior);
        }
        currentObject["behaviors"] = copyClickableBehavior;
      } else if (behaviorType === "tween") {
        var newTweenBehavior = {};
        newTweenBehavior =
          tweenType === "move"
            ? setMoveTweenForGameObject(tweenMoveBehavior)
            : updateResizeTweenForGameObject(tweenResizeBehavior);
        currentObject["behaviors"] = newTweenBehavior;
      } else {
        var newTweenBehavior = {};
        newTweenBehavior["path"] = pathBehavior;
        currentObject["behaviors"] = newTweenBehavior;
      }
      dispatch(updateSelectedTrueCreativePropsFunc(creativeUid, sourceCode, true));
      //dispatch(closeEditGameObjectBehaviorModal());
      handleClose();
    }
  };

  const deletePathTransition = (e, idx) => {
    var tempPathBehavior = pathBehavior;
    tempPathBehavior.transitions.splice(idx, 1);
    setPathBehavior({ ...tempPathBehavior });
    setPathTransitions({ scale: "", position: "", delay: "" });
    setIsPathTransitionEdit({ status: false, index: -1 });
    setIsAddingTransition(false);
  };

  const deletePathTransitions = (e, idx) => {
    confirmAlert({
      title: "Confirm to delete",
      message: `Are you sure to delete`,
      buttons: [
        {
          label: "Yes",
          onClick: () => deletePathTransition(e, idx),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const deleteTweenResizeTransition = (e, idx) => {
    var tempTweenResizeBehavior = tweenResizeBehavior;
    tempTweenResizeBehavior.transitions.splice(idx, 1);
    setTweenResizeBehavior({ ...tempTweenResizeBehavior });
    setTweenResizeTransition({ scale: "", position: "", delay: "" });
    setIsTweenResizeTransitionEdit({ status: false, index: -1 });
    setIsAddingResizeTransition(false);
  };

  const deleteTweenResizeTransitions = (e, idx) => {
    confirmAlert({
      title: "Confirm to delete",
      message: `Are you sure to delete`,
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteTweenResizeTransition(e, idx),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const handleClose = (e) => {
    dispatch(closeEditGameObjectBehaviorModal());
    setIsPathTransitionEdit({ status: false, index: -1 });
    setIsTweenResizeTransitionEdit({ status: false, index: -1 });
    setIsAddingTransition(false);
    setIsAddingResizeTransition(false);
    setErrors({});
    setDefaultStateValue();
    setBehaviorType("click");
    dispatch(updateLoaderState(false));
  };

  const [
    handleClickableChange,
    handleTweenMoveChange,
    handleTweenResizeChange,
    handleTweenResizeTransitionChange,
    editTweenResizeTransitions,
    editPathTransitions,
    handleCancel,
    handlePathBehaviorChange,
  ] = GameBehaviorForm(
    clickableBehavior,
    tweenMoveBehavior,
    tweenResizeBehavior,
    tweenResizeTransition,
    pathBehavior,
    pathTransitions,
    pathStart,
    setClickableBehavior,
    setTweenMoveBehavior,
    setTweenResizeBehavior,
    setTweenResizeTransition,
    setIsAddingResizeTransition,
    setIsTweenResizeTransitionEdit,
    setPathTransitions,
    setIsPathTransitionEdit,
    setIsAddingTransition,
    setPathBehavior,
    setPathStart,
    setErrors
  );

  return (
    <Modal show={isShowEditGameObjectBehaviorModal} onHide={handleClose}>
      {isLoaderEnabled ? <Loader /> : ""}
      <Modal.Header closeButton>
        <Modal.Title>
          {isEdit ? "Edit " + behaviorType + " Behavior" : "Add " + behaviorType + " Behavior"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group">
          <label>Behavior Type</label>
          <div className="input-control">
            <ReactTooltip id="pathBehaviour" place="right" effect="solid">
              <img src={horizontalX} width="426px" height="240px" alt="" />
              <img src={verticalY} width="426px" height="240px"  alt="" />
            </ReactTooltip>
            <ReactTooltip id="tweenBoth" place="right" effect="solid">
              <img src={tweenX} width="426px" height="240px" alt=""  />
              <img src={tweenY} width="426px" height="240px"  alt="" />
            </ReactTooltip>
            <ReactTooltip id="clickBehaviourTip" place="right" effect="solid">
              <span class="tooltip-text">Click Behaviour</span>
            </ReactTooltip>

            <span className="custom-control custom-radio custom-control-inline">
              <input
                type="radio"
                disabled={isEdit || isUpdateClickable}
                value={"click"}
                checked={behaviorType === "click" ? true : false}
                name="containerObjectType"
                onChange={() => setBehaviorType("click")}
                className="custom-control-input"
                id="cont-type-click"
              />
              <label className="custom-control-label" htmlFor="cont-type-click">
                {"Click"}
              </label>
              <span className="form-group info-icon" data-tip data-for="clickBehaviourTip">
                <img src={infoIcon} width="15px" height="15px"  alt="" />
              </span>
            </span>
            <span className="custom-control custom-radio custom-control-inline">
              <input
                type="radio"
                disabled={isEdit || isUpdateClickable}
                value={"path"}
                checked={behaviorType === "path" ? true : false}
                name="containerObjectType"
                onChange={(evt) => {
                  setDefaultStateValue();
                  setBehaviorType("path");
                }}
                className="custom-control-input"
                id="cont-type-path"
              />
              <label className="custom-control-label" htmlFor="cont-type-path">
                {"Path"}
              </label>
              <span className="form-group info-icon" data-tip data-for="pathBehaviour">
                <img src={infoIcon} width="15px" height="15px"  alt="" />
              </span>
            </span>
            <span className="custom-control custom-radio custom-control-inline">
              <input
                type="radio"
                disabled={isEdit || isUpdateClickable}
                value={"tween"}
                checked={behaviorType === "tween" ? true : false}
                name="containerObjectType"
                onChange={(evt) => {
                  setDefaultStateValue();
                  setBehaviorType("tween");
                }}
                className="custom-control-input"
                id="cont-type-tween"
              />
              <label className="custom-control-label" htmlFor="cont-type-tween">
                {"Tween"}
              </label>
              <span className="form-group info-icon" data-tip data-for="tweenBoth">
                <img src={infoIcon} width="15px" height="15px"  alt="" />
              </span>
            </span>
          </div>
        </div>

        <div className="form-group" hidden={behaviorType !== "click"}>
          <div className="form-group">
            <label>Clickable behavior type</label>
            <div className="input-control">
              <span className="custom-control custom-radio custom-control-inline">
                <input
                  type="radio"
                  disabled={isEdit}
                  className="custom-control-input"
                  name="type"
                  checked={clickableBehavior.type === "advanceGame" ? true : false}
                  value={"advanceGame"}
                  onChange={(evt) => handleClickableChange(evt, "clickBehaviorType")}
                  id="advanceGame-type"
                />
                <label className="custom-control-label" htmlFor="advanceGame-type">
                  {"Advance Game"}
                </label>
              </span>
              <span className="custom-control custom-radio custom-control-inline">
                <input
                  type="radio"
                  disabled={isEdit}
                  className="custom-control-input"
                  name="type"
                  checked={clickableBehavior.type === "navigation" ? true : false}
                  value={"navigation"}
                  onChange={(evt) => handleClickableChange(evt, "clickBehaviorType")}
                  id="navigation-type"
                />
                <label className="custom-control-label" htmlFor="navigation-type">
                  {"Navigation"}
                </label>
              </span>
            </div>
          </div>

          <div className="form-group" hidden={clickableBehavior.type !== "navigation"}>
            <label>Navigation Url </label>
            <input
              type="text"
              placeholder="Navigation Url"
              name="url"
              value={clickableBehavior.url}
              onChange={(evt) => handleClickableChange(evt, "url")}
            />
            {errors && errors.navigationIsEmpty && <small>{errors.navigationIsEmpty}</small>}
          </div>

          <div className="form-group" hidden={clickableBehavior.type !== "advanceGame"}>
            <label>Delay(in milliseconds)</label>
            <input
              type="number"
              pattern="[0-9]*"
              inputMode="numeric"
              name="delay"
              placeholder="Delay Duration(in milliseconds)"
              value={clickableBehavior.delay}
              onChange={(evt) => handleClickableChange(evt, "delay")}
            />
          </div>
          {errors && errors.delayIsEmpty && <small>{errors.delayIsEmpty}</small>}
        </div>
        <div className="form-group" hidden={behaviorType !== "tween"}>
          <ReactTooltip id="tweenResizeX" place="right" effect="solid">
            <img src={tweenX} width="426px" height="240px" alt=""  />
          </ReactTooltip>

          <ReactTooltip id="tweenResizeY" place="right" effect="solid">
            <img src={tweenY} width="426px" height="240px" alt=""  />
          </ReactTooltip>
          <div className="form-group">
            <label>Tween behavior type</label>
            <div className="input-control">
              <span className="custom-control custom-radio custom-control-inline">
                <input
                  type="radio"
                  disabled={isEdit || isUpdateClickable}
                  className="custom-control-input"
                  name="tweenType"
                  checked={tweenType === "resize" ? true : false}
                  value={"resize"}
                  onChange={(evt) => {
                    setTweenType("resize");
                    setErrors({});
                    setTweenMoveBehavior({
                      tweenType: "move",
                      delay: "",
                      position: "",
                      loop: true,
                      moveDirection: "x",
                    });
                  }}
                  id="resize-type"
                />
                <label className="custom-control-label" htmlFor="resize-type">
                  {"Resize"}
                </label>
              </span>

              <span className="custom-control custom-radio custom-control-inline">
                <input
                  type="radio"
                  disabled={isEdit || isUpdateClickable}
                  className="custom-control-input"
                  name="tweenType"
                  checked={tweenType === "move" ? true : false}
                  value={"move"}
                  onChange={(evt) => {
                    setTweenType("move");
                    setErrors({});
                    setTweenResizeBehavior({
                      tweenType: "resize",
                      loop: true,
                      resizeDirection: "x",
                      transitions: [],
                    });
                    setTweenResizeTransition({
                      scale: "",
                      position: "",
                      delay: "",
                    });
                    setIsTweenResizeTransitionEdit({ status: false, index: -1 });
                    setIsAddingResizeTransition(false);
                  }}
                  id="move-type"
                />
                <label className="custom-control-label" htmlFor="move-type">
                  {"Move"}
                </label>
              </span>
            </div>
          </div>
          <div className="form-group" hidden={tweenType !== "resize"}>
            <div className="form-group">
              <label>Resize Direction</label>
              <div className="input-control">
                <span className="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    disabled={isEdit || isUpdateClickable}
                    value={"x"}
                    name="resizeDirection"
                    disabled={tweenResizeBehavior.transitions.length > 0}
                    checked={tweenResizeBehavior.resizeDirection === "x" ? true : false}
                    onChange={(e) => handleTweenResizeChange(e, "resize")}
                    className="custom-control-input"
                    id="resize-horizontal-type"
                  />
                  <label className="custom-control-label" htmlFor="resize-horizontal-type">
                    {"Horizontal"}
                  </label>
                  <span className="form-group info-icon" data-tip data-for="tweenResizeX">
                    <img src={infoIcon} width="15px" height="15px"  alt="" />
                  </span>
                </span>

                <span className="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    disabled={isEdit || isUpdateClickable}
                    value={"y"}
                    disabled={tweenResizeBehavior.transitions.length > 0}
                    checked={tweenResizeBehavior.resizeDirection === "y" ? true : false}
                    onChange={(e) => handleTweenResizeChange(e, "resize")}
                    name="resizeDirection"
                    className="custom-control-input"
                    id="resize-veritcal-type"
                  />
                  <label className="custom-control-label" htmlFor="resize-veritcal-type">
                    {"Vertical"}
                  </label>
                  <span className="form-group info-icon" data-tip data-for="tweenResizeY">
                    <img src={infoIcon} width="15px" height="15px"  alt="" />
                  </span>
                </span>
              </div>
            </div>
            {tweenResizeBehavior &&
            tweenResizeBehavior.transitions &&
            tweenResizeBehavior.transitions.length > 0 ? (
              <div className="form-group">
                {tweenResizeBehavior.transitions.map((transition, idx) => (
                  <div className="row property-section">
                    <div className="form-group col-md-3 col-sm-6">
                      <label>{"Scale: " + tweenResizeBehavior.resizeDirection}</label>
                      <input
                        disabled={true}
                        type="text"
                        value={transition[1] ? transition[1].scaleX || transition[1].scaleY : ""}
                      ></input>
                    </div>
                    <div className="form-group col-md-3 col-sm-6">
                      <label>{"Position: " + tweenResizeBehavior.resizeDirection}</label>
                      <input
                        disabled={true}
                        type="text"
                        value={transition[1] ? transition[1].x || transition[1].y : ""}
                      ></input>
                    </div>
                    <div className="form-group col-md-3 col-sm-6">
                      <label>{"Delay(in milliseconds)"}</label>
                      <input
                        disabled={true}
                        type="text"
                        value={transition[2] ? transition[2] : ""}
                      ></input>
                    </div>
                    <div className="form-group col-md-2 col-sm-12">
                      <span
                        className="btn btn-default obj-action-btn text-edit"
                        onClick={(evt) => editTweenResizeTransitions(evt, idx)}
                      >
                        <EditIcon />
                      </span>
                      <span
                        className="btn btn-default obj-action-btn text-delete"
                        onClick={(evt) => deleteTweenResizeTransitions(evt, idx)}
                      >
                        <DeleteIcon />
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              ""
            )}
            <div className="form-group">
              <div
                className="input-control row"
                hidden={
                  isAddingResizeTransition ||
                  (tweenResizeBehavior.transitions && tweenResizeBehavior.transitions.length === 2)
                }
              >
                <button
                  className="btn btn-primary btn-switch"
                  onClick={(evt) => setIsAddingResizeTransition(true)}
                >
                  Add New Transition
                </button>
              </div>

              {isAddingResizeTransition ? (
                <div className="form-group">
                  <label>Scaling-{tweenResizeBehavior.resizeDirection}</label>
                  <div className="sidebar-input">
                    <input
                      type="number"
                      pattern="[0-9]*"
                      inputMode="numeric"
                      placeholder={"Scale value"}
                      name="scale"
                      value={tweenResizeTransition.scale}
                      onChange={(e) => handleTweenResizeTransitionChange(e, "resize")}
                    />
                  </div>
                  <div className="form-group">
                    <label>Position</label>
                    <div className="sidebar-input">
                      <input
                        type="number"
                        pattern="[0-9]*"
                        inputMode="numeric"
                        name="position"
                        placeholder={"Position"}
                        value={tweenResizeTransition.position}
                        onChange={(e) => handleTweenResizeTransitionChange(e, "resize")}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Delay(in milliseconds)</label>
                    <div className="sidebar-input">
                      <input
                        type="number"
                        pattern="[0-9]*"
                        inputMode="numeric"
                        name="delay"
                        placeholder={"Duration(in milliseconds)"}
                        value={tweenResizeTransition.delay}
                        onChange={(e) => handleTweenResizeTransitionChange(e, "resize")}
                      />
                    </div>
                  </div>
                  <div className="input-control row">
                    <button
                      disabled={
                        !tweenResizeTransition.scale ||
                        !tweenResizeTransition.position ||
                        !tweenResizeTransition.delay
                      }
                      className="btn btn-primary btn-switch"
                      onClick={(evt) => saveTweenTransitions(evt)}
                    >
                      {isTweenResizeTransitionEdit.status && isTweenResizeTransitionEdit.index > -1
                        ? "Update Transition"
                        : "Save Transition"}
                    </button>
                    <button
                      className="btn btn-primary btn-switch"
                      onClick={(evt) => handleCancel(evt)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="form-group" hidden={tweenType !== "move"}>
            <div className="form-group">
              <label>Delay(in milliseconds)</label>
              <div className="sidebar-input">
                <input
                  type="number"
                  pattern="[0-9]*"
                  inputMode="numeric"
                  name="delay"
                  placeholder={"Duration(in milliseconds)"}
                  value={tweenMoveBehavior.delay}
                  onChange={(e) => handleTweenMoveChange(e, "move")}
                />
              </div>
              {errors && errors.tweenMoveDelayIsEmpty && (
                <small>{errors.tweenMoveDelayIsEmpty}</small>
              )}
            </div>
            <div className="form-group">
              <label>Move Direction</label>
              <div className="input-control">
                <span className="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    disabled={isEdit || isUpdateClickable}
                    value={"x"}
                    name="moveDirection"
                    checked={tweenMoveBehavior.moveDirection === "x" ? true : false}
                    onChange={(e) => handleTweenMoveChange(e, "move")}
                    className="custom-control-input"
                    id="position-horizontal-type"
                  />
                  <label className="custom-control-label" htmlFor="position-horizontal-type">
                    {"Horizontal"}
                  </label>
                </span>

                <span className="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    disabled={isEdit || isUpdateClickable}
                    value={"y"}
                    checked={tweenMoveBehavior.moveDirection === "y" ? true : false}
                    onChange={(e) => handleTweenMoveChange(e, "move")}
                    name="moveDirection"
                    className="custom-control-input"
                    id="position-veritcal-type"
                  />
                  <label className="custom-control-label" htmlFor="position-veritcal-type">
                    {"Vertical"}
                  </label>
                </span>
              </div>
            </div>
            <div className="form-group">
              <label>Destination</label>
              <div className="sidebar-input">
                <input
                  type="number"
                  pattern="[0-9]*"
                  inputMode="numeric"
                  name="position"
                  placeholder={"Destination where you want move target object"}
                  value={tweenMoveBehavior.position}
                  onChange={(e) => handleTweenMoveChange(e, "move")}
                />
              </div>
              {errors && errors.tweenMovePositionIsEmpty && (
                <small>{errors.tweenMovePositionIsEmpty}</small>
              )}
            </div>
          </div>
        </div>
        <div className="form-group" hidden={behaviorType !== "path"}>
          <div className="form-group">
            <label>Start</label>
            <div className="row property-section">
              <div className="col-sm-6">
                <label>Horizontal</label>
                <input
                  type="number"
                  pattern="[0-9]*"
                  inputMode="numeric"
                  name="x"
                  value={pathStart.x}
                  placeholder="Horizontal Position where transition will start"
                  onChange={(e) => handlePathBehaviorChange(e, "startPosition")}
                />
                {errors && errors.startXIsEmpty && <small>{errors.startXIsEmpty}</small>}
              </div>
              <div className="col-sm-6">
                <label>Vertical</label>
                <input
                  type="number"
                  pattern="[0-9]*"
                  inputMode="numeric"
                  name="y"
                  value={pathStart.y}
                  placeholder="Vertical Position where transition will start"
                  onChange={(e) => handlePathBehaviorChange(e, "startPosition")}
                />
                {errors && errors.startYIsEmpty && <small>{errors.startYIsEmpty}</small>}
              </div>
            </div>
          </div>
          {pathBehavior && pathBehavior.transitions && pathBehavior.transitions.length > 0 ? (
            <div className="form-group">
              {pathBehavior.transitions.map((transition, idx) => (
                <div className="row property-section">
                  <div className="form-group col-md-3 col-sm-6">
                    <label>{"Position: X"}</label>
                    <input disabled={true} type="text" value={transition.x}></input>
                  </div>
                  <div className="form-group col-md-3 col-sm-6">
                    <label>{"Position: Y"}</label>
                    <input disabled={true} type="text" value={transition.y}></input>
                  </div>
                  <div className="form-group col-md-3 col-sm-6">
                    <label>{"Delay(in milliseconds)"}</label>
                    <input disabled={true} type="text" value={transition.duration}></input>
                  </div>
                  <div className="form-group col-md-2 col-sm-12">
                    <span
                      className="btn btn-default obj-action-btn text-edit"
                      onClick={(evt) => editPathTransitions(evt, idx)}
                    >
                      <EditIcon />
                    </span>
                    <span
                      className="btn btn-default btn-delete obj-action-btn text-delete"
                      onClick={(evt) => deletePathTransitions(evt, idx)}
                    >
                      <DeleteIcon />
                    </span>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            ""
          )}
          <div className="form-group">
            <small style={{ color: "green" }}> Needs to add 2 Transtions</small>

            <div
              className="form-group"
              hidden={isAddingTransition || pathBehavior.transitions.length === 2}
            >
              <div className="input-control row">
                <button
                  className="btn btn-primary btn-switch"
                  onClick={(evt) => setIsAddingTransition(true)}
                >
                  Add New Transition
                </button>
              </div>
            </div>
            {isAddingTransition ? (
              <div className="form-group">
                <label>Transition Properties</label>
                <div className="form-group">
                  <label>Position</label>
                  <div className="row property-section">
                    <div className="col-sm-6">
                      <label>Horizontal</label>
                      <input
                        type="number"
                        pattern="[0-9]*"
                        inputMode="numeric"
                        name="x"
                        value={pathTransitions.x}
                        placeholder="Horizontal"
                        onChange={(e) => handlePathBehaviorChange(e, "transitions")}
                      />
                      {errors && errors.pathTransitionsXIsEmpty && (
                        <small>{errors.pathTransitionsXIsEmpty}</small>
                      )}
                    </div>
                    <div className="col-sm-6">
                      <label>Vertical</label>
                      <input
                        type="number"
                        pattern="[0-9]*"
                        inputMode="numeric"
                        name="y"
                        value={pathTransitions.y}
                        placeholder="Vertical"
                        onChange={(e) => handlePathBehaviorChange(e, "transitions")}
                      />
                      {errors && errors.pathTransitionsYIsEmpty && (
                        <small>{errors.pathTransitionsYIsEmpty}</small>
                      )}
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label>Duration(in milliseconds)</label>
                  <input
                    type="number"
                    pattern="[0-9]*"
                    inputMode="numeric"
                    name="duration"
                    value={pathTransitions.duration}
                    placeholder="Duration(in milliseconds)"
                    onChange={(e) => handlePathBehaviorChange(e, "transitions")}
                  />
                  {errors && errors.pathTransitionsDurationIsEmpty && (
                    <small>{errors.pathTransitionsDurationIsEmpty}</small>
                  )}
                </div>
                <div className="form-group">
                  <div className="input-control row">
                    <button
                      disabled={
                        !pathTransitions.x || !pathTransitions.y || !pathTransitions.duration
                      }
                      className="btn btn-primary btn-switch"
                      onClick={(evt) => savePathTransitions(evt)}
                    >
                      {isPathTransitionEdit.status && isPathTransitionEdit.index > -1
                        ? "Update Transition"
                        : "Save Transition"}
                    </button>
                    <button
                      className="btn btn-primary btn-switch"
                      onClick={(evt) => handleCancel(evt)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {behaviorType === "click" ? (
          <>
            <Button variant="primary" onClick={(evt) => saveBehavior(evt)}>
              Save Changes
            </Button>
          </>
        ) : (
          ""
        )}

        {behaviorType === "tween" ? (
          behaviorType === "tween" && tweenType === "resize" ? (
            <>
              <Button
                variant="primary"
                disabled={
                  tweenResizeBehavior.transitions && tweenResizeBehavior.transitions.length < 2
                }
                onClick={(evt) => saveBehavior(evt)}
              >
                Save Changes
              </Button>
            </>
          ) : (
            <Button variant="primary" onClick={(evt) => saveBehavior(evt)}>
              Save Changes
            </Button>
          )
        ) : (
          ""
        )}

        {behaviorType === "path" ? (
          <>
            <Button
              variant="primary"
              disabled={pathBehavior.transitions && pathBehavior.transitions.length < 2}
              onClick={(evt) => saveBehavior(evt)}
            >
              Save Changes
            </Button>
          </>
        ) : (
          ""
        )}
        <Button variant="secondary" onClick={(evt) => handleClose()}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
function mapStateToProps(state) {
  return {
    isShowEditGameObjectBehaviorModal: state.gameObject.isShowEditGameObjectBehaviorModal,
    creativeUid: state.gameObject.creativeUid,
    editBehaviorType: state.gameObject.editBehaviorType,
    editBehavior: state.gameObject.editBehavior,
    behaviorIndex: state.gameObject.behaviorIndex,
    isUpdateClickable: state.gameObject.isUpdateClickable,
    isLoaderEnabled: state.gameObject.isLoaderEnabled,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(EditGameObjectBehaviorModal);
