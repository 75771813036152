const BinBehaviorForm = (
  setActionType,
  setPathTransitions,
  setIsPathTransitionEdit,
  setIsAddingTransition,
  setTweenType,
  setBehaviorType,
  setAdvanceGameAction,
  setRemoveObjectAction,
  setReplaceObjectAction,
  setPathStart,
  setAddPathBehavior,
  setPathBehavior,
  setTweenInvisibleBehavior,
  setTweenMoveBehavior,
  advanceGameAction,
  removeObjectAction,
  replaceObjectAction,
  pathBehavior,
  addPathBehavior,
  pathStart,
  pathTransitions,
  sourceCode,
  sceneIndex,
  tweenInvisibleBehavior,
  tweenMoveBehavior
) => {
  const handleChangeActionType = (e) => {
    setActionType(e.target.value);
  };

  const handleCancel = (e, idx) => {
    setPathTransitions({ x: "", y: "", duration: "" });
    setIsPathTransitionEdit({ status: false, index: -1 });
    setIsAddingTransition(false);
  };

  const handleChangeBehaviorType = (e) => {
    if (e.target.name === "tweenType") {
      setTweenType(e.target.value);
    } else {
      setBehaviorType(e.target.value);
    }
  };

  const handleChangeAction = (actionType, e) => {
    if (actionType === "advanceGame") {
      const tempAdvanceGameAction = advanceGameAction;
      tempAdvanceGameAction.type = actionType;
      tempAdvanceGameAction.delay = e.target.value ? Number(e.target.value) : "";
      setAdvanceGameAction({ ...tempAdvanceGameAction });
    } else if (actionType === "removeObject") {
      var values = e.target.value;
      const tempRemoveObjectAction = removeObjectAction;
      tempRemoveObjectAction.type = actionType;
      if (values.includes("=>")) {
        var layer = values.split("=>")[0];
        var targetedObject = values.split("=>")[1];
        tempRemoveObjectAction.layer = layer;
        tempRemoveObjectAction.target = targetedObject;
      }
      setRemoveObjectAction({ ...removeObjectAction });
    } else {
      values = e.target.value;
      const tempReplaceObjectAction = replaceObjectAction;
      tempReplaceObjectAction.type = actionType;
      if (e.target.name === "replacementObject") {
        tempReplaceObjectAction.replacement = e.target.value;
      } else {
        if (values.includes("=>")) {
          layer = values.split("=>")[0];
          targetedObject = values.split("=>")[1];
          tempReplaceObjectAction.layer = layer;
          tempReplaceObjectAction.target = targetedObject;
        }
      }
      setReplaceObjectAction({ ...tempReplaceObjectAction });
    }
  };

  const handlePathBehaviorChange = (e, type) => {
    var tempPathBehavior = pathBehavior;
    var tempAddPathBehavior = addPathBehavior;
    if (type === "startPosition") {
      var tempPathStart = pathStart;
      tempPathStart[e.target.name] =
        e.target.value && e.target.value !== "" ? Number(e.target.value) : "";
      setPathStart({ ...tempPathStart });
      tempPathBehavior["start"] = tempPathStart;
      setPathBehavior({ ...tempPathBehavior });
    } else if (type === "transitions") {
      var tempPathTransitions = pathTransitions;
      tempPathTransitions[e.target.name] =
        e.target.value && e.target.value !== "" ? Number(e.target.value) : "";
      setPathTransitions({ ...tempPathTransitions });
    } else if (type === "target") {
      tempAddPathBehavior[e.target.name] = e.target.value;
      setAddPathBehavior({ ...tempAddPathBehavior });
    }
  };

  /**
   * call on Invisible Tween props change
   * @param {actionType} actionType
   * @param {state change Event} e
   */
  const handleTweenInvisibleChange = (actionType, e) => {
    var tempTweenInvisibleBehavior = tweenInvisibleBehavior;
    if (actionType === "loop") {
      tempTweenInvisibleBehavior.loop = !tempTweenInvisibleBehavior.loop;
    } else if (actionType === "delay") {
      tempTweenInvisibleBehavior[e.target.name] = e.target.value ? Number(e.target.value) : "";
    } else {
      tempTweenInvisibleBehavior[e.target.name] = e.target.value;
    }
    setTweenInvisibleBehavior({ ...tempTweenInvisibleBehavior });
  };

  /**
   * call on Move Tween props change
   * @param {action Type} actionType
   * @param {state change Event} e
   */
  const handleTweenMoveChange = (actionType, e) => {
    var tempTweenMoveBehavior = tweenMoveBehavior;
    if (actionType === "loop") {
      tempTweenMoveBehavior.loop = !tempTweenMoveBehavior.loop;
    } else if (actionType === "position") {
      tempTweenMoveBehavior[e.target.name] = e.target.value ? Number(e.target.value) : "";
    } else {
      tempTweenMoveBehavior[e.target.name] = e.target.value;
    }
    setTweenMoveBehavior({ ...tempTweenMoveBehavior });
  };

  return [
    handleChangeActionType,
    handleCancel,
    handleChangeBehaviorType,
    handleChangeAction,
    handlePathBehaviorChange,
    handleTweenInvisibleChange,
    handleTweenMoveChange,
  ];
};

export default BinBehaviorForm;
