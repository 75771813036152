import React, { useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { SketchPicker } from "react-color";
import reactCSS from "reactcss";
import { fontSizeArray, setColorPickerCSS } from "./../../utils/appUtils";
import { defaultColor } from "./../../constants/defaultValues";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import { ReactComponent as EditIcon } from "../../images/edit.svg";
import { ReactComponent as DeleteIcon } from "../../images/trash.svg";
import { ReactComponent as ArrowIcon } from "../../images/down-arrow.svg";

const TextGameObject = ({ gameObject, setGameObject, errors }) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [color, setColor] = useState(defaultColor);
  const [showTextForm, setShowTextForm] = useState(false);
  const [textError, setTextError] = useState({});
  const [currentTextObj, setCurrentTextObj] = useState({
    color: { r: "241", g: "112", b: "19", a: "1" },
    font: 24,
  });
  const [objectIdx, setObjectIdx] = useState(-1);
  var sizeArray = fontSizeArray();
  const options = sizeArray.map((elem) => (
    <option key={elem} value={elem}>
      {elem}
    </option>
  ));

  const styles = reactCSS(setColorPickerCSS(color));

  // setting the index of object we need to edit, and mutating the color object
  const startEditObject = (idx, item) => {
    let tempObj = {};
    let colors = { r: "241", g: "112", b: "19", a: "1" };
    if (item[2]) {
      let arr = item[2].match(/\d+/g);
      colors = {
        r: arr[0],
        g: arr[1],
        b: arr[2],
        a: arr.length > 4 ? arr[3] + "." + arr[4] : arr[3],
      };
    }
    tempObj.description = item[0] ? item[0] : "";
    tempObj.font = item[1] ? item[1].replace(/[^0-9]/g, "") : 1;
    tempObj.bold = item[1].includes("bold") ? true : false;
    tempObj.color = colors;
    //tempObj.hex = returnHex(item[2]);
    //setColor(returnHex(item[2]));
    setColor(colors);
    setObjectIdx(idx);
    setCurrentTextObj({ ...tempObj });
    setShowTextForm(true);
  };

  const handleEditTextObject = (e) => {
    let tempObj = { ...currentTextObj };
    // if there is no e.target, it means the color is being edited
    if (e.target) {
      if (e.target.name === "bold") tempObj["bold"] = !tempObj["bold"];
      else tempObj[e.target.name] = e.target.value;
    } else {
      // set the hex color from rgb string, to display on screen
      tempObj["color"] = e.rgb;
      setColor(e.rgb);
    }
    setCurrentTextObj({ ...tempObj });
  };

  const saveTextObject = () => {
    let tempObj = "";
    let tempGameObj = { ...gameObject };
    tempObj = [];
    if (!currentTextObj || !currentTextObj.description || currentTextObj.description === "") {
      setTextError({ description: "Please enter some text" });
      return;
    }
    tempObj.push(currentTextObj.description);

    if (currentTextObj.bold) {
      tempObj.push("bold " + currentTextObj.font + "px Helvetica");
    } else {
      tempObj.push(currentTextObj.font + "px Helvetica");
    }

    tempObj.push(
      "rgba(" +
        currentTextObj.color.r +
        "," +
        currentTextObj.color.g +
        "," +
        currentTextObj.color.b +
        "," +
        currentTextObj.color.a +
        ")"
    );
    if (objectIdx >= 0) {
      tempGameObj.text[objectIdx] = tempObj;
    } else {
      if (!tempGameObj.text) {
        tempGameObj.text = [];
      }
      tempGameObj.text.push(tempObj);
    }

    // set the parent object here
    setGameObject(tempGameObj);
    reset();
  };

  const reset = () => {
    let tempObj = {
      description: "",
      font: 24,
      bold: false,
      color: { r: "241", g: "112", b: "19", a: "1" },
    };
    setObjectIdx(-1);
    setCurrentTextObj({ ...tempObj });
    setShowTextForm(false);
    setTextError({ description: "" });
    setColor({ r: "241", g: "112", b: "19", a: "1" });
  };

  const deleteTextObject = (item, idx) => {
    confirmAlert({
      title: "Confirm to delete",
      message: `Are you sure to delete`,
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteTextGameObject(item, idx),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const deleteTextGameObject = (item, idx) => {
    let gameObj = { ...gameObject };
    gameObj.text.splice(idx, 1);
    setGameObject(gameObj);
  };

  // --------end ------------

  return (
    <div className="objects-container-section">
      {gameObject && gameObject.text && gameObject.text.length > 0 ? (
        <div id="existingTexts">
          <h3>Existing Text Details</h3>
          <ul className="existing-texts">
            {gameObject.text.map((text, index) => (
              <li key={index}>
                <span
                  className="text-span"
                  style={{ marginRight: "50px", color: text[2] }}
                >
                  {" "}
                  {text[0]}{" "}
                </span>

                <span
                  className="btn btn-default obj-action-btn text-edit"
                  onClick={() => startEditObject(index, text)}
                >
                  <EditIcon />
                </span>
                <span
                  className="btn btn-default btn-delete obj-action-btn text-delete"
                  onClick={() => deleteTextObject(text[0], index)}
                >
                  <DeleteIcon />
                </span>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        ""
      )}

      <h3 onClick={() => setShowTextForm(true)} className="toggle-content-title">
        {objectIdx === -1 ? (
          <span className="btn btn-primary btn-sm"> Add New</span>
        ) : (
          `Editing ${currentTextObj.description}`
        )}
        <span className="arrow">
          <ArrowIcon />
        </span>
      </h3>
      <div className="form-group" hidden={!showTextForm}>
        <div className="row property-section">
          <div className="col-sm-12 mr-b-15">
            <label>Description</label>
            <div className="input-control">
              <input
                type="text"
                onChange={handleEditTextObject}
                name="description"
                value={currentTextObj.description}
              />
            </div>
            <span style={{ color: "red" }}>{textError.description}</span>
          </div>
          <div className="col-sm-4">
            <label>Font Size</label>
            <select
              name="font"
              className="custom-select"
              value={currentTextObj.font}
              onChange={handleEditTextObject}
            >
              {options}
            </select>
          </div>
          <div className="col-sm-4">
            <label>Font Color</label>
            <div className="color-box">
              <div style={styles.swatch} onClick={() => setDisplayColorPicker(!displayColorPicker)}>
                <div style={styles.color} />
              </div>
              {displayColorPicker ? (
                <div style={styles.popover}>
                  <div style={styles.cover} onClick={() => setDisplayColorPicker(false)} />
                  <SketchPicker
                    color={currentTextObj.color}
                    name="color"
                    onChange={handleEditTextObject}
                  />
                </div>
              ) : null}
            </div>
          </div>
          <div className="col-sm-4">
            <label>Font Weight</label>
            <div className="input-control">
              <span className="custom-control custom-radio custom-control-inline">
                <input
                  type="checkbox"
                  defaultChecked={currentTextObj.bold}
                  //id="bold"
                  name="bold"
                  checked={currentTextObj.bold}
                  onClick={handleEditTextObject}
                  className="custom-control-input"
                  id="type-bold"
                />
                <label className="custom-control-label" htmlFor="type-bold">
                  {"Bold"}
                </label>
              </span>
            </div>
          </div>
          <div className="col-sm-12">
            <button
              disabled={!currentTextObj.description}
              onClick={() => saveTextObject()}
              className="add-btn btn btn-primary btn-sm"
            >
              {objectIdx === -1 ? "Add" : `Update`}{" "}
            </button>
            <span onClick={() => reset()} className="cancel-btn btn btn-primary btn-sm">
              {"Cancel"}
            </span>
          </div>
        </div>
      </div>
      {errors && errors.text && <small>{errors.text}</small>}
    </div>
  );
};

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(TextGameObject);
