import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  closeEditTruengageCreativeModal,
  saveTruengageCreativeProps,
  updateTruengageCreativeProps,
} from "../../store/actions/truengageCreatives";
import {
  defaultSource,
  launchableDefaultSource,
  collectableDefaultSource,
  drawableDefaultSource,
} from "../../constants/defaultValues";
import { getSelectedVideolineDetail } from "../../store/actions/videolines";
import { useDispatch } from "react-redux";
import { compose } from "redux";
import { Button, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getDayStartTime, getDayEndTime } from "./../../utils/appUtils";
import creativeObjectForm from "../../utils/creativeObject/creativeObjectForm";
import validate from "../../utils/creativeObject/validateCreativeObjectForm";
import { updateLoaderState } from "../../store/actions/gameObjects";
import Loader from "./../../pages/loader";

const EditTruengageCreativeModal = ({
  isShowEditTruegageCreativeModal,
  selectedVideoLineVersion,
  editTruengageCreative,
  videoLineId,
  editTruengageCreativeId,
  jynxVersions,
  isLoaderEnabled,
}) => {
  const dispatch = useDispatch();
  const [
    truengageCreative,
    setTruengageCreative,
    setDevices,
    setFlightDateType,
    setIsNew,
    handleChange,
    handleChangeDate,
    onChangeDevice,
    handleSubmit,
    errors,
    setErrors,
    flightDateType,
    devices,
    isNew,
  ] = creativeObjectForm(saveCreative, validate);

  const onSubmit = (evt) => {
    dispatch(updateLoaderState(true));
    handleSubmit();
  };

  useEffect(() => {
    if (editTruengageCreative) {
      setTruengageCreative({ ...editTruengageCreative });
      setDevices(editTruengageCreative.devices);
      var start = "Custom";
      var end = "Custom";
      if (selectedVideoLineVersion && truengageCreative) {
        if (editTruengageCreative.start_at === selectedVideoLineVersion.start_at) {
          start = "VideoLineDate";
        }
        if (editTruengageCreative.end_at === selectedVideoLineVersion.end_at) {
          end = "VideoLineDate";
        }
        setFlightDateType({ start_type: start, end_type: end });
        setIsNew(false);
        setErrors({});
      }
    } else {
      setTruengageCreative({
        name: "",
        account: "",
        video_line: "",
        type: "Draggable",
        is_cpc_banner: false,
        weight: "",
        duration: "",
        start_at: getDayStartTime(),
        end_at: getDayEndTime(),
        engine_version: "2.2.1",
        source: "",
        paused: false,
        updated_at: new Date().getTime(),
        updated_by: localStorage.getItem("uid"),
      });
      setDevices({
        desktop: false,
        tablet: false,
        phone: false,
        ctv: false,
      });

      setFlightDateType({
        start_type: "Custom",
        end_type: "Custom",
      });
      setIsNew(true);
      setErrors({});
    }
  }, [editTruengageCreative, isShowEditTruegageCreativeModal]);

  // to close Modal
  function closeModal() {
    dispatch(closeEditTruengageCreativeModal());
    dispatch(updateLoaderState(false));
  }

  /**
   * to save Truengage Creative Props
   */
  function saveCreative() {
    dispatch(updateLoaderState(true));

    if (flightDateType.start_type === "VideoLineDate") {
      truengageCreative.start_at = selectedVideoLineVersion.start_at;
      truengageCreative["start_at"] = truengageCreative["start_at"]
        ? truengageCreative["start_at"]
        : getDayStartTime();
    }
    if (flightDateType.end_type === "VideoLineDate") {
      truengageCreative.end_at = selectedVideoLineVersion.end_at;
      truengageCreative["end_at"] = truengageCreative["end_at"]
        ? truengageCreative["end_at"]
        : getDayEndTime();
    }

    if (isNew) {
      if (truengageCreative.type === "Draggable") {
        defaultSource.scenes[0].duration = truengageCreative.duration
          ? Number(truengageCreative.duration) * 1000
          : defaultSource.scenes[0].duration;
        truengageCreative["source"] = JSON.stringify(defaultSource);
      } else if (truengageCreative.type === "Launchable") {
        launchableDefaultSource.scenes[0].duration = truengageCreative.duration
          ? Number(truengageCreative.duration) * 1000
          : launchableDefaultSource.scenes[0].duration;
        truengageCreative["source"] = JSON.stringify(launchableDefaultSource);
      } else if (truengageCreative.type === "Drawing") {
        drawableDefaultSource.scenes[0].duration = truengageCreative.duration
          ? Number(truengageCreative.duration) * 1000
          : drawableDefaultSource.scenes[0].duration;
        truengageCreative["source"] = JSON.stringify(drawableDefaultSource);
      } else {
        collectableDefaultSource.scenes[0].duration = truengageCreative.duration
          ? Number(truengageCreative.duration) * 1000
          : collectableDefaultSource.scenes[0].duration;
        truengageCreative["source"] = JSON.stringify(collectableDefaultSource);
      }

      truengageCreative["account"] = selectedVideoLineVersion.account;
      truengageCreative["video_line"] = selectedVideoLineVersion.video_line;
      dispatch(saveTruengageCreativeProps(videoLineId, truengageCreative));
    } else {
      dispatch(
        updateTruengageCreativeProps(videoLineId, editTruengageCreativeId, truengageCreative)
      );
    }
    closeModal();
  }
  return (
    <Modal
      show={isShowEditTruegageCreativeModal}
      onHide={closeModal}
      className="editTruengageCreativePopup"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {isLoaderEnabled ? <Loader /> : ""}

      <Modal.Header closeButton>
        <Modal.Title>
          {isNew
            ? "Add New Truengage Creative"
            : truengageCreative.name.length > 20
            ? "Edit Truengage Creative : " + truengageCreative.name.substring(0, 20) + "..."
            : "Edit Truengage Creative : " + truengageCreative.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form
          className="editTruengageCreativePopupForm container-fluid"
          id="addTruengageCreativeObject"
        >
          <div className="form-group">
            <label>Name</label>
            <div className="input-control">
              <input
                type="text"
                name="name"
                id="name"
                value={truengageCreative.name}
                placeholder="Name"
                disabled={!isNew}
                onChange={handleChange}
                className={errors.nameIsEmpty && "input-error"}
              />
              <div>{errors.nameIsEmpty && <small>{errors.nameIsEmpty}</small>}</div>
            </div>
          </div>

          <div className="form-group">
            <label>{"Type"}</label>
            <div className="form-group">
              <div className="inline-controls">
                <span className="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    value={"Draggable"}
                    name="type"
                    disabled={!isNew}
                    checked={truengageCreative.type === "Draggable"}
                    onChange={handleChange}
                    className="custom-control-input"
                    id="start-line"
                  />
                  <label className="custom-control-label" htmlFor="start-line">
                    {"Draggable"}
                  </label>
                </span>
                <span className="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    value={"Launchable"}
                    name="type"
                    disabled={!isNew}
                    checked={truengageCreative.type === "Launchable"}
                    onChange={handleChange}
                    className="custom-control-input"
                    id="launchable"
                  />
                  <label className="custom-control-label" htmlFor="launchable">
                    {"Launchable"}
                  </label>
                </span>
                <span className="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    value={"Collectable"}
                    name="type"
                    disabled={!isNew}
                    checked={truengageCreative.type === "Collectable"}
                    onChange={handleChange}
                    className="custom-control-input"
                    id="Collectable"
                  />
                  <label className="custom-control-label" htmlFor="Collectable">
                    {"Collectable"}
                  </label>
                </span>
                <span className="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    value={"Drawing"}
                    name="type"
                    disabled={!isNew}
                    checked={truengageCreative.type === "Drawing"}
                    onChange={handleChange}
                    className="custom-control-input"
                    id="Drawing"
                  />
                  <label className="custom-control-label" htmlFor="Drawing">
                    {"Drawing"}
                  </label>
                </span>
              </div>
            </div>
          </div>
          <div className="form-group">
            <label>Is Cpc Banner</label>
            <div className="inline-controls">
              <div className="custom-control custom-checkbox custom-control-inline tru-toggle-button">
                <input
                  type="checkbox"
                  name={"is_cpc_banner"}
                  checked={truengageCreative.is_cpc_banner}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  className="custom-control-input"
                  id="isCpcBanner"
                />
                <label className="custom-control-label" htmlFor="isCpcBanner">
                  {" Is Cpc Banner"}
                </label>
              </div>
            </div>
          </div>
          <div className="form-group">
            <label>Game Duration(seconds)</label>
            <div className="input-control">
              <input
                type="number"
                pattern="[0-9]*"
                inputMode="numeric"
                name="duration"
                value={truengageCreative.duration}
                placeholder="Game Duration(seconds)"
                onChange={handleChange}
                className={errors.durationLengthIsZero && "input-error"}
              />
              <div>
                {errors.durationLengthIsZero && <small>{errors.durationLengthIsZero}</small>}
              </div>
            </div>
          </div>

          <div className="form-group">
            <label>Game Engine Version</label>
            <div className="input-control">
              <select
                disabled={!isNew}
                name="engine_version"
                placeholder="Select Engine Version"
                id="engine_version"
                className="custom-select"
                value={truengageCreative.engine_version}
                onChange={handleChange}
              >
                {jynxVersions &&
                  Object.keys(jynxVersions).map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div className="form-group">
            <label>{"Flight Dates"}</label>
            <div className="form-group fight-dates-value">
              <div className="inline-controls">
                <span className="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    value={"VideoLineDate"}
                    name="start_type"
                    checked={flightDateType.start_type === "VideoLineDate"}
                    onChange={handleChange}
                    className="custom-control-input"
                    id="start-with-line"
                  />
                  <label className="custom-control-label" htmlFor="start-with-line">
                    {"Start when line starts"}
                  </label>
                </span>
                <span className="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    value={"Custom"}
                    name="start_type"
                    checked={flightDateType.start_type === "Custom"}
                    onChange={handleChange}
                    className="custom-control-input"
                    id="custom-start"
                  />
                  <label className="custom-control-label" htmlFor="custom-start">
                    {"Custom start"}
                  </label>
                </span>
                {flightDateType.start_type === "Custom" ? (
                  <div className="custom-fight-date">
                    <DatePicker
                      selected={truengageCreative.start_at}
                      showTimeSelect
                      timeFormat="p"
                      timeIntervals={10}
                      dateFormat="Pp"
                      onChange={(date) => handleChangeDate("start_at", date)}
                      className="form-control"
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="inline-controls">
                <span className="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    value={"VideoLineDate"}
                    name="end_type"
                    checked={flightDateType.end_type === "VideoLineDate"}
                    onChange={handleChange}
                    className="custom-control-input"
                    id="end-with-line"
                  />
                  <label className="custom-control-label" htmlFor="end-with-line">
                    {"End when line end"}
                  </label>
                </span>
                <span className="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    value={"Custom"}
                    name="end_type"
                    checked={flightDateType.end_type === "Custom"}
                    onChange={handleChange}
                    className="custom-control-input"
                    id="custom-end"
                  />
                  <label className="custom-control-label" htmlFor="custom-end">
                    {"Custom end"}
                  </label>
                </span>
                {flightDateType.end_type === "Custom" ? (
                  <div className="custom-fight-date">
                    <DatePicker
                      selected={truengageCreative.end_at}
                      showTimeSelect
                      timeFormat="p"
                      timeIntervals={10}
                      dateFormat="Pp"
                      onChange={(date) => handleChangeDate("end_at", date)}
                      className="form-control"
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>

          <div className="form-group">
            <label>Weight</label>
            <div className="input-control">
              <input
                type="number"
                pattern="[0-9]*"
                inputMode="numeric"
                name="weight"
                value={truengageCreative.weight}
                placeholder="Weight"
                onChange={handleChange}
                className={errors.weightIsZero && "input-error"}
              />
            </div>
            {errors.weightIsZero && <small>{errors.weightIsZero}</small>}
          </div>

          <div className="form-group">
            <label>Device Targeting</label>
            <div className="input-control">
              <div
                className="inline-controls"
                className={errors.deviceNotSelected && "input-error"}
              >
                <div className="custom-control custom-checkbox custom-control-inline">
                  <input
                    type="checkbox"
                    checked={devices.desktop}
                    onChange={(e) => onChangeDevice("desktop", e)}
                    className="custom-control-input"
                    id="desktop"
                  />
                  <label className="custom-control-label" htmlFor="desktop">
                    {" Desktop"}
                  </label>
                </div>

                <div className="custom-control custom-checkbox custom-control-inline">
                  <input
                    type="checkbox"
                    checked={devices.phone}
                    onChange={(e) => onChangeDevice("phone", e)}
                    className="custom-control-input"
                    id="phone"
                  />
                  <label className="custom-control-label" htmlFor="phone">
                    {" Phone"}
                  </label>
                </div>

                <div className="custom-control custom-checkbox custom-control-inline">
                  <input
                    type="checkbox"
                    checked={devices.tablet}
                    onChange={(e) => onChangeDevice("tablet", e)}
                    className="custom-control-input"
                    id="tablet"
                  />
                  <label className="custom-control-label" htmlFor="tablet">
                    {" Tablet"}
                  </label>
                </div>

                <div className="custom-control custom-checkbox custom-control-inline">
                  <input
                    type="checkbox"
                    checked={devices.ctv}
                    onChange={(e) => onChangeDevice("ctv", e)}
                    className="custom-control-input"
                    id="ctv"
                  />
                  <label className="custom-control-label" htmlFor="ctv">
                    {" CTV"}
                  </label>
                </div>
              </div>
              {errors.deviceNotSelected && <small>{errors.deviceNotSelected}</small>}
            </div>
          </div>
          <div className="form-group">
            <label>Paused</label>
            <div className="inline-controls">
              <div className="custom-control custom-checkbox custom-control-inline tru-toggle-button">
                <input
                  type="checkbox"
                  name={"paused"}
                  checked={truengageCreative.paused}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  className="custom-control-input"
                  id="paused"
                />
                <label className="custom-control-label" htmlFor="paused">
                  {" Paused"}
                </label>
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button className="create-btn" onClick={(evt) => onSubmit(evt)}>
          {"Save"}
        </Button>
        <Button className="cancel-btn" onClick={closeModal}>
          {"Cancel"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

function mapStateToProps(state) {
  return {
    isShowEditTruegageCreativeModal: state.truengageCreative.isShowEditTruegageCreativeModal,
    selectedVideoLineVersion: state.videoline.selectedVideoLineVersion,
    editTruengageCreative: state.truengageCreative.editTruengageCreative,
    editTruengageCreativeId: state.truengageCreative.editTruengageCreativeId,
    jynxVersions: state.truengageCreative.jynxVersions,
    isLoaderEnabled: state.gameObject.isLoaderEnabled,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeEditTruengageCreativeModal: () => dispatch(closeEditTruengageCreativeModal()),
    saveTruengageCreativeProps: (source) => dispatch(saveTruengageCreativeProps(source)),
    getSelectedVideolineDetail: (id) => dispatch(getSelectedVideolineDetail(id)),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(EditTruengageCreativeModal);
