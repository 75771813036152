import { Types } from "./../constants/account-types";
import { beginApiCall, apiCallError } from "./apiStatus";
import API from "../../services/accountService";

const setXPSAccounts = (accounts) => {
  return {
    type: Types.GET_ALL_XPS_ACCOUNTS,
    payload: accounts,
  };
};

const setErrorInLoadingXPSAccounts = () => {
  return {
    type: Types.ERROR_IN_LOADING_ACCOUNTS,
    payload: "Error in loading XPS Accounts",
  };
};

const setSelectedAccount = (account) => {
  return {
    type: Types.GET_CURRENT_ACCOUNT,
    payload: account,
  };
};

// Load all xps accounts
export const loadXPSAccounts = () => async (dispatch) => {
  try {
    dispatch(beginApiCall());
    await API.loadXPSAccounts().then(
      (data) => {
        dispatch(setXPSAccounts(data));
      },
      (error) => {
        console.log(error);
      }
    );
  } catch (err) {
    dispatch(apiCallError());
    dispatch(setErrorInLoadingXPSAccounts());
  }
};

// Load xps account Details by ID
export const getSelectedAccountDetail = (id) => async (dispatch) => {
  try {
    dispatch(beginApiCall());
    await API.getSelectedAccountDetail(id).then(
      (data) => {
        dispatch(setSelectedAccount(data));
      },
      (error) => {
        console.log(error);
      }
    );
  } catch (err) {
    dispatch(apiCallError());
  }
};


// To clear Account state attributes from Redux
export const clearAccountState = () => async (dispatch) => {
  dispatch(setXPSAccounts(undefined));
  dispatch(setErrorInLoadingXPSAccounts(""));
};