export default function validate(gameObject, gameObjectArray, type, shapeDimension) {
  let errors = {};

  // Checking if name is not empty
  if (type === "ADD") {
    if (!gameObject.name || gameObject.name === "") {
      errors.nameIsEmpty = "Need to add Game Object name";
    } else {
      var result = gameObjectArray.findIndex(
        (item) => gameObject.name.toLowerCase() === item.toLowerCase()
      );
      if (result > -1) {
        errors.nameIsEmpty =
          "You have already taken " +
          gameObject.name +
          " in this Game State. Please use different name";
      }
    }
  }

  if (gameObject.scale && Number(gameObject.scale.x) < 0) {
    errors.scaleXIsValid = "Please use value greater than 0";
  }

  if (gameObject.scale && Number(gameObject.scale.y) < 0) {
    errors.scaleYIsValid = "Please use value greater than 0";
  }

  if (gameObject.shadow && Number(gameObject.shadow.blur) < 1) {
    errors.shadowBlurIsValid = "Please use value greater than 0";
  }

  /***
   * Validation for Spritesheet Type Game Object
   */
  if (gameObject.objectType === "Spritesheet") {
    if (!gameObject.spritesheet) {
      errors.frameIsEmpty = "Need to enter Frame Details";
      errors.frameaterIsEmpty = "Need to enter Frame Rate";
    } else if (!gameObject.spritesheet.frames) {
      errors.frameIsEmpty = "Need to enter Frame Details";
    } else {
      if (Number(gameObject.spritesheet.frames.height) < 1) {
        errors.heightIsEmpty = "Need to enter Frame Height";
      }
      if (Number(gameObject.spritesheet.frames.width) < 1) {
        errors.widthIsEmpty = "Need to enter Frame Width";
      }
      if (Number(gameObject.spritesheet.frames.regX) < 1) {
        errors.regXIsEmpty = "Need to enter Frame regX";
      }
      if (Number(gameObject.spritesheet.frames.regY) < 1) {
        errors.regYIsEmpty = "Need to enter Frame regY";
      }
    }
    if (gameObject.spritesheet && !gameObject.spritesheet.framerate) {
      errors.framerateIsEmpty = "Need to enter Frame rate";
    } else if (gameObject.spritesheet && gameObject.spritesheet.framerate) {
      if (Number(gameObject.spritesheet.framerate) < 1) {
        errors.framerateIsEmpty = "Need to enter Frame Rate";
      }
    }

    if (gameObject.spritesheet) {
      if (!gameObject.spritesheet?.source) {
        errors.image = "Need to add an image for spritesheet";
      }
    } else {
      errors.image = "Need to add an image for spritesheet";
    }
  }

  /***
   * Validation for Shape Type Game Object
   */
  if (gameObject.objectType === "Shape") {
    if (shapeDimension.offsetX === "") {
      errors.offsetXIsEmpty = "Need to enter offsetX";
    }
    if (shapeDimension.offsetY === "") {
      errors.offsetYIsEmpty = "Need to enter offsetY";
    }

    if (Number(shapeDimension.width) < 1) {
      errors.shapeWidthIsEmpty = "Need to enter width";
    }

    if (Number(shapeDimension.height) < 1) {
      errors.shapeHeightIsEmpty = "Need to enter height";
    }
  }

  /***
   * Validation for image Type Game Object
   */
  if (gameObject.objectType === "Image") {
    if (!gameObject.image) {
      errors.image = "Need to add an image";
    }
    if (!gameObject.image?.source) {
      errors.image = "Need to add an image";
    }
  }

  /***
   * Validation for text Type Game Object
   */
  if (gameObject.objectType === "Text") {
    if (!gameObject.text) {
      errors.text = "Need to add texts";
    }
    if (gameObject.text?.length === 0) {
      errors.text = "Need to add texts";
    }
  }

  return errors;
}
