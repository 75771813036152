import { useState, useEffect } from "react";
import { updateLoaderState } from "./../../store/actions/gameObjects";
import { useDispatch } from "react-redux";
const GameObjectForm = (
  validate,
  gameObject,
  setGameObject,
  position,
  setPosition,
  scale,
  setScale,
  sceneNo,
  frames,
  setFrames,
  setFramerate,
  shapeDimension,
  setShapeDimension,
  setShapeColor,
  gameObjectArray,
  saveForm,
  shadow,
  setShadow,
  setHolds,
  setMasks,
  setSelectedGameObject,
  setDelay,
  handleClose,
  sceneIndex,
  actionType,
  setActionType
) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();

  const [errors, setErrors] = useState({});
  useEffect(() => {
    // Checking if any error occurred during validation
    // and if a form is still submitting
    if (Object.keys(errors).length === 0 && isSubmitting) {
      saveForm();
      setErrors({});
      setIsSubmitting(false);
    }
    if (Object.keys(errors).length > 0) {
      setIsSubmitting(false);
      dispatch(updateLoaderState(false));
    }
  }, [errors, isSubmitting, saveForm]);

  const handleSubmit = (e, type) => {
    if (e) e.preventDefault();
    setIsSubmitting(true);
    setErrors(validate(gameObject, gameObjectArray[sceneNo], type, shapeDimension));
  };

  const handlePositionChange = (e) => {
    e.persist();
    let tempPosition = { ...position };
    tempPosition[e.target.name] =
      e.target.value && e.target.value !== "" ? Number(e.target.value) : "";
    setPosition(tempPosition);
    setGameObject({ ...gameObject, "position": tempPosition });
  };

  const handleScaleChange = (e) => {
    e.persist();
    let tempScale = { ...scale };
    tempScale[e.target.name] = e.target.value && e.target.value !== "" ? Number(e.target.value) : "";
    setScale(tempScale);
    setGameObject({ ...gameObject, "scale": tempScale });
  };

  const handleChange = (e) => {
    e.persist();
    if (e.target.name === "objectType") {
      if (e.target.value === "Text") {
        delete gameObject.container;
        delete gameObject.spritesheet;
        delete gameObject.image;
        delete gameObject.shape;
        setFrames({ width: "", height: "", regX: "", regY: "" });
        setShapeDimension({
          offsetX: "",
          offsetY: "",
          width: "",
          height: "",
        });
        setFramerate("");
        setShapeColor("#FFFFFF");
      } else if (e.target.value === "Spritesheet") {
        delete gameObject.text;
        delete gameObject.container;
        delete gameObject.image;
        delete gameObject.shape;
        setShapeDimension({
          offsetX: "",
          offsetY: "",
          width: "",
          height: "",
        });
        setShapeColor("#FFFFFF");
      } else if (e.target.value === "Image") {
        delete gameObject.text;
        delete gameObject.container;
        delete gameObject.spritesheet;
        delete gameObject.shape;
        setFrames({ width: "", height: "", regX: "", regY: "" });
        setShapeDimension({
          offsetX: "",
          offsetY: "",
          width: "",
          height: "",
        });
        setShapeColor("#FFFFFF");
        setFramerate("");
      } else if (e.target.value === "Container") {
        delete gameObject.text;
        delete gameObject.image;
        delete gameObject.spritesheet;
        delete gameObject.shape;
      } else if (e.target.value === "Shape") {
        delete gameObject.text;
        delete gameObject.image;
        delete gameObject.container;
        delete gameObject.spritesheet;
        setFramerate("");
        setFrames({ width: "", height: "", regX: "", regY: "" });
      }
    }
    setGameObject((gameObject) => ({
      ...gameObject,
      [e.target.name]: e.target.value,
    }));
  };

  const handleImageChange = (newURL) => {
    setGameObject((gameObject) => ({
      ...gameObject,
      image: { source: newURL },
    }));
  };

  const handleSpriteSheetChange = (newURL) => {
    let spriteObj = { ...gameObject };
    if (!spriteObj.spritesheet) spriteObj.spritesheet = {};

    spriteObj.spritesheet["source"] = newURL;
    setGameObject(spriteObj);
  };

  const handleFrameChange = (e) => {
    let frameObj = { ...frames };
    let copyObj = { ...gameObject };
    if (e.target.name === "framerate") {
      setFramerate(e.target.value && e.target.value !== "" ? Number(e.target.value) : "");
      if (!copyObj.spritesheet) {
        copyObj.spritesheet = {};
      }
      copyObj.spritesheet.framerate =
        e.target.value && e.target.value !== "" ? Number(e.target.value) : "";
      setGameObject({ ...copyObj });
    } else {
      frameObj[e.target.name] =
        e.target.value && e.target.value !== "" ? Number(e.target.value) : "";
      setFrames(frameObj);
      if (!copyObj.spritesheet) {
        copyObj.spritesheet = {};
      }
      copyObj.spritesheet.frames = frameObj;

      setGameObject({ ...copyObj });
    }
  };

  const handleShapeChange = (e) => {
    let shapeDimensionObj = { ...shapeDimension };
    let tempGameObj = { ...gameObject };
    if (!tempGameObj.shape) {
      tempGameObj.shape = {};
      tempGameObj.shape.graphics = [];
      tempGameObj.shape.graphics.push(["f", "#FFFFFF"]);
      tempGameObj.shape.graphics.push(["dr", "", "", "", ""]);
    }
    if (e.target) {
      shapeDimensionObj[e.target.name] =
        e.target.value && e.target.value !== "" ? Number(e.target.value) : "";
      setShapeDimension(shapeDimensionObj);
      if (tempGameObj.shape.graphics[1] && tempGameObj.shape.graphics[1].length > 0) {
        tempGameObj.shape.graphics[1][1] = shapeDimensionObj.offsetX;
        tempGameObj.shape.graphics[1][2] = shapeDimensionObj.offsetY;
        tempGameObj.shape.graphics[1][3] = shapeDimensionObj.width;
        tempGameObj.shape.graphics[1][4] = shapeDimensionObj.height;
      }
    } else {
      setShapeColor(e.hex);
      tempGameObj.shape.graphics[0][1] = e.hex;
    }
    setGameObject({ ...tempGameObj });
  };

  const handleShadowChange = (e) => {
    let tempShadow = { ...shadow };
    if (e.target) {
      tempShadow[e.target.name] =
        e.target.value && e.target.value !== "" ? Number(e.target.value) : "";
    } else {
      tempShadow["color"] = e.hex;
    }
    setShadow(tempShadow);
    setGameObject({ ...gameObject, "shadow": tempShadow });
  };

  const handleBinBehaviorChange = (e) => {
    e.persist();
    let copyObj = { ...gameObject };
    if (e.target.name === "holds") {
      setHolds(e.target.value && e.target.value !== "" ? Number(e.target.value) : "");
      copyObj.behaviors.bin.holds =
        e.target.value && e.target.value !== "" ? Number(e.target.value) : "";
    } else {
      const tempMasks =
        copyObj.behaviors && copyObj.behaviors.bin && copyObj.behaviors.bin.masks
          ? copyObj.behaviors.bin.masks
          : {};
      if (e.target.value !== "") {
        tempMasks[e.target.name] = e.target.value;
      } else {
        delete tempMasks[e.target.name];
      }
      setMasks({ ...tempMasks });
      copyObj.behaviors.bin.masks = tempMasks;
    }
    setGameObject({ ...copyObj });
  };

  const saveNewAction = (e) => {
    handleClose();
  };

  const handleActionBehaviorChange = (e) => {
    if (e.target.name === "selectedGameObject") {
      setSelectedGameObject(e.target.value);
    } else if (e.target.name === "delay") {
      setDelay(e.target.value);
    } else if (e.target.name === "actionType") {
      setActionType(e.target.value);
    }
  };

  return [
    handleChange,
    handlePositionChange,
    handleSubmit,
    errors,
    setErrors,
    handleImageChange,
    handleScaleChange,
    handleFrameChange,
    handleSpriteSheetChange,
    handleShapeChange,
    handleShadowChange,
    handleBinBehaviorChange,
    saveNewAction,
    handleActionBehaviorChange,
  ];
};

export default GameObjectForm;
