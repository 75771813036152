import React, { useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { getholdingCapacityArray } from "./../../utils/appUtils";
import { useDispatch } from "react-redux";
import { openEditBinGameObjectBehavoirModal } from "../../store/actions/gameObjects";
import { ReactComponent as EditIcon } from "../../images/edit.svg";
import { ReactComponent as DeleteIcon } from "../../images/trash.svg";
import { ReactComponent as ArrowIcon } from "../../images/down-arrow.svg";

import EditBinBehaviorActionModal from "../../components/modals/editBinBehaviorActionModal";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import * as R from "ramda";
import { updateSelectedTrueCreativePropsFunc } from "../../store/actions/truengageCreatives";
import infoIcon from "../../images/info-logo.svg";
import ReactTooltip from "react-tooltip";
import {
  maskingToolTipDesc,
  holdingCapacityToolTipDesc,
  binActionTooltipDesc,
  launchableBinActionTooltipDesc,
} from "./../../constants/defaultValues";

const BinGameObjectBehavior = ({
  holds,
  masks,
  actions,
  gameObjects,
  handleBinBehaviorChange,
  sceneIndex,
  selectedLayers,
  sourceCode,
  gameObjectKey,
  creativeUid,
  truengageCreativeType,
}) => {
  const [isShowDetail, setIsShowDetail] = useState(false);

  var holdingCapacityArray = getholdingCapacityArray(
    gameObjects && gameObjects.dragableObjects ? Object.keys(gameObjects.dragableObjects).length : 0
  );
  const dispatch = useDispatch();

  const customEngagementEvents = {};
  Object.keys(actions).map((key, index) => {
    if (key !== "full") {
      customEngagementEvents[key] = actions[key];
    }
  });

  const handleShow = (e) => {
    e.preventDefault();
    dispatch(openEditBinGameObjectBehavoirModal(undefined, undefined));
  };

  const handleCustomEngagementEventChange = (e) => {
    var selectedScene = R.path(["scenes"], sourceCode)[sceneIndex];
    var objects = {};
    if (R.path(["objects"], selectedScene)) {
      objects = R.path(["objects"], selectedScene);
    }
    if (objects && objects[gameObjectKey]) {
      var currentObject = objects[gameObjectKey];
      if (
        currentObject &&
        currentObject.behaviors &&
        currentObject.behaviors.bin &&
        currentObject.behaviors.bin.actions
      ) {
        if (currentObject.behaviors.bin.actions[e.target.name]) {
          delete currentObject.behaviors.bin.actions[e.target.name];
        } else {
          const actionArray = [{ type: "event", name: e.target.name }];
          currentObject.behaviors.bin.actions[e.target.name] = actionArray;
        }
        dispatch(updateSelectedTrueCreativePropsFunc(creativeUid, sourceCode, true));
      }
    }
  };

  const holdinCapacityOptions = holdingCapacityArray.map((elem) => (
    <option key={elem} value={elem}>
      {elem}
    </option>
  ));

  const options =
    gameObjects &&
    gameObjects.maskableObjects &&
    Object.keys(gameObjects.maskableObjects).map((item, index) => (
      <option key={item} value={item}>
        {item}
      </option>
    ));

  const deleteBinBehaviorActionConfirmation = (index) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteBinBehaviorAction(index),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const deleteBinBehaviorAction = (index) => {
    var selectedScene = R.path(["scenes"], sourceCode)[sceneIndex];
    var objects = {};
    if (R.path(["objects"], selectedScene)) {
      objects = R.path(["objects"], selectedScene);
    }
    if (objects && objects[gameObjectKey]) {
      var currentObject = objects[gameObjectKey];
      if (
        currentObject &&
        currentObject.behaviors &&
        currentObject.behaviors.bin &&
        currentObject.behaviors.bin.actions &&
        currentObject.behaviors.bin.actions.full &&
        currentObject.behaviors.bin.actions.full[index]
      ) {
        currentObject.behaviors.bin.actions.full.splice(index, 1);
        dispatch(updateSelectedTrueCreativePropsFunc(creativeUid, sourceCode, true));
      }
    }
  };

  function _bindMaskingObjects(objects) {
    return Object.keys(objects).map((item, index) => {
      return (
        <div className="col-md-6 col-sm-12">
          <label>{item} </label>
          <select
            className="custom-select"
            name={item}
            value={masks[item] ? masks[item] : ""}
            onChange={handleBinBehaviorChange}
          >
            <option key={""} value={""}>
              Select Option
            </option>
            {options}
          </select>
        </div>
      );
    });
  }

  function _bindEngagementEvents(objects) {
    return (
      objects &&
      Object.keys(objects).map((item, index) => {
        return (
          <div className="form-group">
            <div className="col-md-3 col-sm-6">
              <div className="custom-control custom-checkbox custom-control-inline">
                <input
                  type="checkbox"
                  checked={customEngagementEvents[item] ? true : false}
                  onChange={handleCustomEngagementEventChange}
                  name={item}
                  className="custom-control-input"
                  id={"customEngagement-" + item}
                />
                <label className="custom-control-label" for={"customEngagement-" + item}>
                  {item}
                </label>
              </div>
            </div>
          </div>
        );
      })
    );
  }

  function _binBehaviorActions(actions) {
    return (
      actions &&
      actions.full &&
      actions.full.map((action, index) => {
        return (
          <div className="form-group">
            {action.type === "advanceGame" ? (
              <div className="row property-section">
                <div className="form-group col-md-5 col-sm-6">
                  <label>{"Action Type"}</label>
                  <input type="text" disabled={true} value={action.type}></input>
                </div>
                <div className="form-group col-md-4 col-sm-6">
                  <label>{"Delay(in milliseconds)"}</label>
                  <input
                    disabled={true}
                    type="text"
                    value={action.delay ? action.delay : ""}
                  ></input>
                </div>
                <div className="form-group col-md-3 col-sm-12">
                  <span
                    className="btn btn-default obj-action-btn text-edit"
                    onClick={() =>
                      dispatch(openEditBinGameObjectBehavoirModal(actions.full, index))
                    }
                  >
                    <EditIcon />
                  </span>
                  <span
                    className="btn btn-default btn-delete obj-action-btn text-delete"
                    onClick={() => deleteBinBehaviorActionConfirmation(index)}
                  >
                    <DeleteIcon />
                  </span>
                </div>
              </div>
            ) : (
              <>
                {action.type === "replaceObject" ? (
                  <div className="row property-section">
                    <div className="form-group col-md-3 col-sm-6">
                      <label>{"Action Type"}</label>
                      <input type="text" disabled={true} value={action.type}></input>
                    </div>
                    <div className="form-group col-md-3 col-sm-6">
                      <label>{"Target Object"}</label>
                      <input
                        disabled={true}
                        type="text"
                        value={action.target ? action.target : ""}
                      ></input>
                    </div>
                    <div className="form-group col-md-3 col-sm-6">
                      <label>{"Layer"}</label>
                      <input
                        disabled={true}
                        type="text"
                        value={action.layer ? action.layer : ""}
                      ></input>
                    </div>

                    <div className="form-group col-md-3 col-sm-12">
                      <span
                        className="btn btn-default obj-action-btn text-edit"
                        onClick={() =>
                          dispatch(openEditBinGameObjectBehavoirModal(actions.full, index))
                        }
                      >
                        <EditIcon />
                      </span>
                      <span
                        className="btn btn-default btn-delete obj-action-btn text-delete"
                        onClick={() => deleteBinBehaviorActionConfirmation(index)}
                      >
                        <DeleteIcon />
                      </span>
                    </div>
                  </div>
                ) : (
                  <>
                    {action.type === "removeObject" ? (
                      <div className="row property-section">
                        <div className="form-group col-md-3 col-sm-6">
                          <label>{"Action Type"}</label>
                          <input type="text" disabled={true} value={action.type}></input>
                        </div>
                        <div className="form-group col-md-3 col-sm-6">
                          <label>{"Target Object"}</label>
                          <input
                            disabled={true}
                            type="text"
                            value={action.target ? action.target : ""}
                          ></input>
                        </div>
                        <div className="form-group col-md-3 col-sm-6">
                          <label>{"Layer"}</label>
                          <input
                            disabled={true}
                            type="text"
                            value={action.layer ? action.layer : ""}
                          ></input>
                        </div>

                        <div className="form-group col-md-3 col-sm-12">
                          <span
                            className="btn btn-default obj-action-btn text-edit"
                            onClick={() =>
                              dispatch(openEditBinGameObjectBehavoirModal(actions.full, index))
                            }
                          >
                            <EditIcon />
                          </span>
                          <span
                            className="btn btn-default btn-delete obj-action-btn text-delete"
                            onClick={() => deleteBinBehaviorActionConfirmation(index)}
                          >
                            <DeleteIcon />
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div className="row property-section">
                        <div className="form-group col-md-3 col-sm-6">
                          <label>{"Action Type"}</label>
                          <input type="text" disabled={true} value={action.type}></input>
                        </div>
                        <div className="form-group col-md-3 col-sm-6">
                          <label>{"Target Object"}</label>
                          <input
                            disabled={true}
                            type="text"
                            value={action.target ? action.target : ""}
                          ></input>
                        </div>
                        <div className="form-group col-md-3 col-sm-6">
                          <label>{"Type"}</label>
                          <input
                            disabled={true}
                            type="text"
                            value={
                              action && action.behaviors && action.behaviors.tween
                                ? "Tween"
                                : "Path"
                            }
                          ></input>
                        </div>

                        <div className="form-group col-md-3 col-sm-12">
                          <span
                            className="btn btn-default obj-action-btn text-edit"
                            onClick={() =>
                              dispatch(openEditBinGameObjectBehavoirModal(actions.full, index))
                            }
                          >
                            <EditIcon />
                          </span>
                          <span
                            className="btn btn-default btn-delete obj-action-btn text-delete"
                            onClick={() => deleteBinBehaviorActionConfirmation(index)}
                          >
                            <DeleteIcon />
                          </span>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        );
      })
    );
  }

  return (
    <div className={isShowDetail ? "bin-object-container show" : "bin-object-container"}>
      <h3 onClick={() => setIsShowDetail(!isShowDetail)} className="toggle-content-title">
        Bin Game Object Behaviors
        <span className="arrow">
          <ArrowIcon />
        </span>
      </h3>
      {isShowDetail ? (
        <>
          <div
            className="form-group"
            hidden={truengageCreativeType === "Launchable" || truengageCreativeType === "Collectable"}
          >
            <div className="form-group">
              <div className="input-control row property-section">
                <div className="col-md-6 col-sm-6">
                  <label>Holding Capacity</label>
                  <span className="form-group info-icon" data-tip data-for="holdingDetailTip">
                    <img src={infoIcon} width="15px" height="15px" alt="" />
                  </span>
                  <ReactTooltip id="holdingDetailTip" place="right" effect="solid">
                    <span class="tooltip-text">{holdingCapacityToolTipDesc}</span>
                  </ReactTooltip>
                  <select
                    name="holds"
                    className="custom-select"
                    value={holds}
                    onChange={handleBinBehaviorChange}
                  >
                    {holdinCapacityOptions}
                  </select>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label>Masking Details </label>
              <span className="form-group info-icon" data-tip data-for="maskingDetailTip">
                <img src={infoIcon} width="15px" height="15px" alt="" />
              </span>
              <ReactTooltip id="maskingDetailTip" place="right" effect="solid">
                <span class="tooltip-text">{maskingToolTipDesc}</span>
              </ReactTooltip>
              <div className="form-group">
                <div className="input-control row property-section">
                  {gameObjects &&
                    gameObjects.dragableObjects &&
                    _bindMaskingObjects(gameObjects.dragableObjects)}
                </div>
              </div>
            </div>

            {gameObjects &&
            gameObjects.dragableObjects &&
            Object.keys(gameObjects.dragableObjects).length > holds ? (
              <div className="form-group" hidden={truengageCreativeType === "Launchable"}>
                <label>Custom Engagement Events</label>
                <div className="input-control row property-section">
                  {gameObjects &&
                    gameObjects.dragableObjects &&
                    _bindEngagementEvents(gameObjects.dragableObjects, actions)}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="form-group behavior-action">
            <label>Behavior Actions</label>
            <span className="form-group info-icon" data-tip data-for="binActionDetailTip">
              <img src={infoIcon} width="15px" height="15px" alt="" />
            </span>
            <ReactTooltip id="binActionDetailTip" place="right" effect="solid">
              <span class="tooltip-text">
                {truengageCreativeType === "Launchable"
                  ? launchableBinActionTooltipDesc
                  : binActionTooltipDesc}
              </span>
            </ReactTooltip>
            {_binBehaviorActions(actions)}
            <div className="form-group">
              <div className="row property-section">
                <button className="btn btn-primary btn-switch" onClick={handleShow}>
                  Add New Action
                </button>
              </div>
            </div>
          </div>

          <EditBinBehaviorActionModal
            sceneIndex={sceneIndex}
            selectedLayers={selectedLayers}
            sourceCode={sourceCode}
            gameObjectKey={gameObjectKey}
          />
        </>
      ) : (
        ""
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    creativeUid: state.gameObject.creativeUid,
    truengageCreativeType: state.truengageCreative.truengageCreativeType,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    openEditBinGameObjectBehavoirModal: (actions, actionIndex) =>
      dispatch(openEditBinGameObjectBehavoirModal(actions, actionIndex)),
    updateSelectedTrueCreativePropsFunc: (creativeUid, sourceCode, behaviorBool) =>
      dispatch(updateSelectedTrueCreativePropsFunc(creativeUid, sourceCode, behaviorBool)),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(BinGameObjectBehavior);
