export default function validate(
  actionType,
  advanceGameAction,
  removeObjectAction,
  behaviorType,
  tweenType,
  tweenInvisibleBehavior,
  tweenMoveBehavior,
  replaceObjectAction,
  pathBehavior,
  pathStart
) {
  let errors = {};
  if (actionType === "advanceGame") {
    if (!advanceGameAction.delay || advanceGameAction.delay <= 0) {
      errors.delayIsEmpty = "Need to add delay";
    }
  } else if (actionType === "removeObject") {
    if (
      (!removeObjectAction.layer || removeObjectAction.layer === "") &&
      (!removeObjectAction.target || removeObjectAction.target === "")
    ) {
      errors.objectIsEmpty = "Need to add Object";
    }
  } else if (actionType === "replaceObject") {
    if (
      (!replaceObjectAction.layer || removeObjectAction.layer === "") &&
      (!replaceObjectAction.target || replaceObjectAction.target === "")
    ) {
      errors.targetIsEmpty = "Need to add Target";
    }
    if (!replaceObjectAction.replacement || replaceObjectAction.replacement === "") {
      errors.replacementIsEmpty = "Need to add Replacement";
    }
  } else if (actionType === "addBehavior") {
    if (behaviorType === "tween") {
      if (tweenType === "invisible") {
        if (!tweenInvisibleBehavior.target || tweenInvisibleBehavior.target === "") {
          errors.tweenTargetIsEmpty = "Need to add Target";
        }
        if (!tweenInvisibleBehavior.delay || tweenInvisibleBehavior.delay <= 0) {
          errors.tweenDelayIsEmpty = "Need to add delay";
        }
      } else if (tweenType === "move") {
        if (!tweenMoveBehavior.target || tweenMoveBehavior.target === "") {
          errors.tweenMoveTargetIsEmpty = "Need to add move behavior Target";
        }
        if (!tweenMoveBehavior.delay || tweenMoveBehavior.delay === "") {
          errors.tweenMoveDelayIsEmpty = "Need to add move behavior delay";
        }
        if (!tweenMoveBehavior.position || tweenMoveBehavior.position === "")
          errors.tweenMovePositionIsEmpty = "Need to add move behavior position";
      }
    } else if (behaviorType === "path") {
      if (!pathBehavior.target || pathBehavior.target === "") {
        errors.pathBehaviorIsEmpty = "Need to add target object";
      }
      if (!pathStart.x || pathStart.x === "") {
        errors.pathStartXIsEmpty = "Need to add start horizontal postion";
      }
      if (!pathStart.y || pathStart.y === "") {
        errors.pathStartYIsEmpty = "Need to add start vertical postion";
      }
    }
  }
  return errors;
}
