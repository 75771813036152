const ValidateContainer = (
  gameObject,
  currentObject,
  currentObjectType,
  isEdit,
  isObjectEdit,
  gameObjectArray,
  shapeDimension
) => {
  let containerObjectArray = [];
  let obj = gameObject && gameObject.container && gameObject.container.objects;
  for (var key in obj) {
    containerObjectArray.push(key);
  }

  function validateInnerObject() {
    // isObjectEdit is check for whether we are editing or adding new object inside container
    let errors = {};
    if (!isObjectEdit) {
      if (containerObjectArray.includes(currentObject.name)) {
        errors.name = "Name already used";
      }
    }

    if (currentObjectType === "Image") {
      if (
        !currentObject.image ||
        !currentObject.image?.source ||
        currentObject.image?.source === ""
      ) {
        errors.image = "Please choose an image";
      }
      if (!currentObject.name || currentObject.name === "") {
        errors.name = "Please enter a name";
      }
    } else if (currentObjectType === "Text") {
      if (!currentObject.text || currentObject.text.length === 0) {
        errors.texts = "Please enter some texts";
      }
      if (!currentObject.name || currentObject.name === "") {
        errors.name = "Please enter a name";
      }
    } else if (currentObjectType === "Spritesheet") {
      if (!currentObject.name || currentObject.name === "") {
        errors.name = "Please enter a name";
      }
      // if (!currentObject.spritesheet) {
      //   errors.spritesheet = "Please enter spritesheet properties";
      // }

      if (!currentObject.spritesheet) {
        currentObject.spritesheet = {};
      }
      if (currentObject.spritesheet) {
        if (!errors.frames) errors.frames = {};
        if (!currentObject.spritesheet.frames || !currentObject.spritesheet.frames?.width) {
          errors.frames.width = "Please enter a width";
        } else if (currentObject.spritesheet.frames.width <= 0) {
          errors.frames.width = "Width should be greater than 0";
        }
        if (!currentObject.spritesheet.frames || !currentObject.spritesheet.frames?.height) {
          errors.frames.height = "Please enter a height";
        } else if (currentObject.spritesheet.frames.height <= 0) {
          errors.frames.height = "Height should be greater than 0";
        }
        if (!currentObject.spritesheet.frames || !currentObject.spritesheet.frames?.regX) {
          errors.frames.regX = "Please enter a regX";
        } else if (currentObject.spritesheet.frames.regX <= 0) {
          errors.frames.regX = "RegX should be greater than 0";
        }
        if (!currentObject.spritesheet.frames || !currentObject.spritesheet.frames?.regY) {
          errors.frames.regY = "Please enter a regY";
        } else if (currentObject.spritesheet.frames.regY <= 0) {
          errors.frames.regY = "RegY should be greater than 0";
        }

        if (!currentObject.spritesheet.framerate) {
          errors.framerate = "Please enter a Framerate";
        } else if (currentObject.spritesheet.framerate <= 0) {
          errors.framerate = "Framerate should be greater than 0";
        }

        if (currentObject.spritesheet) {
          if (!currentObject.spritesheet?.source) {
            errors.image = "Need to add an image for spritesheet";
          }
        }
        if (errors.frames) {
          if (Object.keys(errors.frames).length === 0) {
            delete errors.frames;
          }
        }
      }
    } else if (currentObjectType === "Shape") {
      /***
       * Validation for Shape Type Game Object
       */

      if (!currentObject.name || currentObject.name === "") {
        errors.name = "Please enter a name";
      }
      if (!currentObject.shape) {
        errors.offsetXIsEmpty = "Please enter shape properties";
      }

      if (shapeDimension.offsetX === "" || !shapeDimension.offsetX) {
        errors.offsetXIsEmpty = "Need to enter offsetX";
      }
      if (shapeDimension.offsetY === "" || !shapeDimension.offsetY) {
        errors.offsetYIsEmpty = "Need to enter offsetY";
      }

      if (Number(shapeDimension.width) < 1 || !shapeDimension.width) {
        errors.shapeWidthIsEmpty = "Need to enter width";
      }

      if (Number(shapeDimension.height) < 1 || !shapeDimension.height) {
        errors.shapeHeightIsEmpty = "Need to enter height";
      }
    }

    return errors;
  }

  return [validateInnerObject];
};
export default ValidateContainer;
