import { Types } from "../constants/image-resource-types";

const INITIAL_STATE = {
  imageResources: undefined,
  imageOperationResponse:null,
  awsResponse:null
};

export default function imageResource(state = INITIAL_STATE, action) {
  if (action.type === Types.GET_IMAGE_RESOURCES_BY_ACCOUNT_ID) {
    return { ...state, imageResources: action.payload };
  }
  else  if (action.type === Types.ADD_NEW_IMAGE) {

    return { ...state, imageOperationResponse: action.payload, };
  } 
  else  if (action.type === Types.RESET_IMAGE_OPERATION_RESPONSE) {

    return { ...state, imageOperationResponse:null };
  } 
  else  if (action.type === Types.ADD_NEW_IMAGE_AWS) {

    return { ...state, awsResponse: action.payload };
  } 
  else  if (action.type === Types.RESET_ADD_NEW_IMAGE_AWS_RESPONSE) {

    return { ...state, awsResponse:null };
  } 
  
  
  else {
    return state;
  }
}
