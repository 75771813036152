import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { compose } from "redux";
import { Button, Modal } from "react-bootstrap";
import { ReactComponent as EditIcon } from "../../images/edit.svg";
import { ReactComponent as DeleteIcon } from "../../images/trash.svg";
import { closeEditPathBehaviorModal } from "../../store/actions/gameObjects";
import { confirmAlert } from "react-confirm-alert"; // Import
import { getCurrentObjectFromSourceCode } from "./../../utils/appUtils";
import { updateSelectedTrueCreativePropsFunc } from "../../store/actions/truengageCreatives";

const EditPathBehaviorActionModal = ({
  isShowPathBehaviorModal,
  sourceCode,
  sceneIndex,
  gameObjectKey,
  creativeUid,
  editPathBehavior,
}) => {
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const [isAddingTransition, setIsAddingTransition] = useState(false);
  const [isPathTransitionEdit, setIsPathTransitionEdit] = useState({ status: false, index: -1 });

  const [pathBehavior, setPathBehavior] = useState({
    loop: true,
    start: "",
    transitions: [],
  });

  const [pathStart, setPathStart] = useState({
    x: "",
    y: "",
  });

  const [pathTransitions, setPathTransitions] = useState({
    x: "",
    y: "",
    duration: "",
  });
  const [errors, setErrors] = useState({});


  useEffect(() => {
    if (editPathBehavior) {
      setPathBehavior(
        editPathBehavior && editPathBehavior.path
          ? editPathBehavior.path
          : {
              loop: true,
              start: "",
              transitions: [],
            }
      );
      setPathStart(
        editPathBehavior && editPathBehavior.path && editPathBehavior.path.start
          ? editPathBehavior.path.start
          : {
              x: "",
              y: "",
            }
      );
      setIsEdit(true);
    } else {
      setPathBehavior({
        loop: true,
        start: "",
        transitions: [],
      });

      setPathStart({
        x: "",
        y: "",
      });

      setPathTransitions({
        x: "",
        y: "",
        duration: "",
      });
      setIsEdit(false);
      setIsAddingTransition(false);
      setIsPathTransitionEdit({ status: false, index: -1 });
      setErrors({});
    }
  }, [editPathBehavior]);

  const handleClose = () => {
    dispatch(closeEditPathBehaviorModal());
    setPathBehavior({
      loop: true,
      start: "",
      transitions: [],
    });

    setPathStart({
      x: "",
      y: "",
    });

    setPathTransitions({
      x: "",
      y: "",
      duration: "",
    });
    setIsEdit(false);
    setIsAddingTransition(false);
    setIsPathTransitionEdit({ status: false, index: -1 });
    setErrors({});
  };
  const editPathTransitions = (e, idx) => {
    var tempPathBehavior = pathBehavior;
    setPathTransitions({ ...tempPathBehavior.transitions[idx] });
    setIsPathTransitionEdit({ status: true, index: idx });
    setIsAddingTransition(true);
  };

  const handleCancel = (e, idx) => {
    setPathTransitions({ x: "", y: "", duration: "" });
    setIsPathTransitionEdit({ status: false, index: -1 });
    setIsAddingTransition(false);
  };

  const handlePathBehaviorChange = (e, type) => {
    var tempPathBehavior = pathBehavior;
    if (type === "startPosition") {
      var tempPathStart = pathStart;
      tempPathStart[e.target.name] =
        e.target.value && e.target.value !== "" ? Number(e.target.value) : "";
      setPathStart({ ...tempPathStart });
      tempPathBehavior["start"] = tempPathStart;
      setPathBehavior({ ...tempPathBehavior });
    } else if (type === "transitions") {
      var tempPathTransitions = pathTransitions;
      tempPathTransitions[e.target.name] =
        e.target.value && e.target.value !== "" ? Number(e.target.value) : "";
      setPathTransitions({ ...tempPathTransitions });
    }
  };

  const savePathTransitions = (e) => {
    var tempPathBehavior = pathBehavior;
    if (isPathTransitionEdit.status && isPathTransitionEdit.index > -1) {
      tempPathBehavior["transitions"][isPathTransitionEdit.index] = pathTransitions;
    } else {
      tempPathBehavior["transitions"].push(pathTransitions);
    }

    setPathBehavior({ ...tempPathBehavior });
    setPathTransitions({ x: "", y: "", duration: "" });
    setIsPathTransitionEdit({ status: false, index: -1 });
    setIsAddingTransition(false);
  };

  const deletePathTransition = (e, idx) => {
    var tempPathBehavior = pathBehavior;
    tempPathBehavior.transitions.splice(idx, 1);
    setPathBehavior({ ...tempPathBehavior });
    setPathTransitions({ scale: "", position: "", delay: "" });
    setIsPathTransitionEdit({ status: false, index: -1 });
    setIsAddingTransition(false);
  };

  const deletePathTransitions = (e, idx) => {
    confirmAlert({
      title: "Confirm to delete",
      message: `Are you sure to delete`,
      buttons: [
        {
          label: "Yes",
          onClick: () => deletePathTransition(e, idx),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const handleSavePathBehavior = () => {
    if(!validateForm(pathBehavior)){
      return false;
    }
    var currentObject = getCurrentObjectFromSourceCode(sourceCode, sceneIndex, gameObjectKey);
    var newBehavior = currentObject && currentObject.behaviors ? currentObject.behaviors : {};
    newBehavior["path"] = pathBehavior;
    currentObject["behaviors"] = newBehavior;
    dispatch(updateSelectedTrueCreativePropsFunc(creativeUid, sourceCode, true));
    handleClose();
  };

  const validateForm = () => {
    var status = true;
    var tempPathStart = pathStart ;
    var tempErrors = errors;
    if(!tempPathStart.x || tempPathStart.x === ""){
      tempErrors.startXIsEmpty = "Please provide value in start position";
      status = false;
    }
    if(!tempPathStart.y || tempPathStart.y === ""){
      tempErrors.startYIsEmpty = "Please provide value in start position";
      status = false;
    }
    setErrors({...tempErrors});
    return status;
  }


  return (
    <Modal show={isShowPathBehaviorModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {!isEdit ? "Add Launchable Behavior Action" : "Edit Launchable Behavior action "}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
          <div className="form-group">
            <div className="form-group">
              <label>Start Position</label>
              <div className="row property-section">
                <div className="col-sm-6">
                  <label>Horizontal</label>
                  <input
                    type="number"
                    pattern="[0-9]*"
                    inputMode="numeric"
                    name="x"
                    value={pathStart.x}
                    placeholder="Horizontal Position where transition will start"
                    onChange={(e) => handlePathBehaviorChange(e, "startPosition")}
                  />
                  {errors && errors.startXIsEmpty && <small>{errors.startXIsEmpty}</small>}
                </div>
                <div className="col-sm-6">
                  <label>Vertical</label>
                  <input
                    type="number"
                    pattern="[0-9]*"
                    inputMode="numeric"
                    name="y"
                    value={pathStart.y}
                    placeholder="Vertical Position where transition will start"
                    onChange={(e) => handlePathBehaviorChange(e, "startPosition")}
                  />
                  {errors && errors.startYIsEmpty && <small>{errors.startYIsEmpty}</small>}
                </div>
              </div>
            </div>
            {pathBehavior && pathBehavior.transitions && pathBehavior.transitions.length > 0 ? (
              <div className="form-group">
                <label>{"Existing Transitions"}</label>
                {pathBehavior.transitions.map((transition, idx) => (
                  <div className="row property-section">
                    <div className="form-group col-md-3 col-sm-6">
                      <label>{"Position: X"}</label>
                      <input disabled={true} type="text" value={transition.x}></input>
                    </div>
                    <div className="form-group col-md-3 col-sm-6">
                      <label>{"Position: Y"}</label>
                      <input disabled={true} type="text" value={transition.y}></input>
                    </div>
                    <div className="form-group col-md-3 col-sm-6">
                      <label>{"Delay(in milliseconds)"}</label>
                      <input disabled={true} type="text" value={transition.duration}></input>
                    </div>
                    <div className="form-group col-md-2 col-sm-12">
                      <span
                        className="btn btn-default obj-action-btn text-edit"
                        onClick={(evt) => editPathTransitions(evt, idx)}
                      >
                        <EditIcon />
                      </span>
                      <span
                        className="btn btn-default btn-delete obj-action-btn text-delete"
                        onClick={(evt) => deletePathTransitions(evt, idx)}
                      >
                        <DeleteIcon />
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              ""
            )}
            <div className="form-group">
              <small style={{ color: "green" }}> Needs to add 2 Transtions</small>

              <div
                className="form-group"
                hidden={isAddingTransition || pathBehavior.transitions.length === 2}
              >
                <div className="input-control row">
                  <button
                    className="btn btn-primary btn-switch"
                    onClick={(evt) => setIsAddingTransition(true)}
                  >
                    Add New Transition
                  </button>
                </div>
              </div>
              {isAddingTransition ? (
                <div className="form-group">
                  <label>Transition Properties</label>
                  <div className="form-group">
                    <label>Position</label>
                    <div className="row property-section">
                      <div className="col-sm-6">
                        <label>Horizontal</label>
                        <input
                          type="number"
                          pattern="[0-9]*"
                          inputMode="numeric"
                          name="x"
                          value={pathTransitions.x}
                          placeholder="Horizontal"
                          onChange={(e) => handlePathBehaviorChange(e, "transitions")}
                        />
                        {errors && errors.pathTransitionsXIsEmpty && (
                          <small>{errors.pathTransitionsXIsEmpty}</small>
                        )}
                      </div>
                      <div className="col-sm-6">
                        <label>Vertical</label>
                        <input
                          type="number"
                          pattern="[0-9]*"
                          inputMode="numeric"
                          name="y"
                          value={pathTransitions.y}
                          placeholder="Vertical"
                          onChange={(e) => handlePathBehaviorChange(e, "transitions")}
                        />
                        {errors && errors.pathTransitionsYIsEmpty && (
                          <small>{errors.pathTransitionsYIsEmpty}</small>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Duration(in milliseconds)</label>
                    <input
                      type="number"
                      pattern="[0-9]*"
                      inputMode="numeric"
                      name="duration"
                      value={pathTransitions.duration}
                      placeholder="Duration(in milliseconds)"
                      onChange={(e) => handlePathBehaviorChange(e, "transitions")}
                    />
                    {errors && errors.pathTransitionsDurationIsEmpty && (
                      <small>{errors.pathTransitionsDurationIsEmpty}</small>
                    )}
                  </div>
                  <div className="form-group">
                    <div className="input-control row">
                      <button
                        disabled={
                          !pathTransitions.x || !pathTransitions.y || !pathTransitions.duration
                        }
                        className="btn btn-primary btn-switch"
                        onClick={(evt) => savePathTransitions(evt)}
                      >
                        {isPathTransitionEdit.status && isPathTransitionEdit.index > -1
                          ? "Update Transition"
                          : "Save Transition"}
                      </button>
                      <button
                        className="btn btn-primary btn-switch"
                        onClick={(evt) => handleCancel(evt)}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </>
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={pathBehavior.transitions && pathBehavior.transitions.length < 2}
          variant="primary"
          onClick={handleSavePathBehavior}
        >
          Save Changes
        </Button>

        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

function mapStateToProps(state) {
  return {
    isShowPathBehaviorModal: state.gameObject.isShowPathBehaviorModal,
    creativeUid: state.gameObject.creativeUid,
    editPathBehavior: state.gameObject.editPathBehavior,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeEditPathBehaviorModal: () => dispatch(closeEditPathBehaviorModal()),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(EditPathBehaviorActionModal);
