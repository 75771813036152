export default function validate(
  behaviorType,
  clickableBehavior,
  pathStart,
  tweenType,
  tweenMoveBehavior,
  tweenResizeBehavior
) {
  let errors = {};
  if (behaviorType === "click") {
    if (clickableBehavior.type === "advanceGame") {
      if (!clickableBehavior.delay || clickableBehavior.delay <= 0) {
        errors.delayIsEmpty = "Need to add delay";
      }
    } else if (clickableBehavior.type === "navigation") {
      if (!clickableBehavior.url || clickableBehavior.url === "") {
        errors.navigationIsEmpty = "Need to add navigation URL";
      }
    }
  } else if (behaviorType === "path") {
    if (!pathStart.x) {
      errors.startXIsEmpty = "Need to add start Horizontal";
    }
    if (!pathStart.y) {
      errors.startYIsEmpty = "Need to add start Vertical";
    }
  }
  if (behaviorType === "tween") {
    if (tweenType === "resize") {
    } else if (tweenType === "move") {
      if (!tweenMoveBehavior.delay || tweenResizeBehavior.delay === "") {
        errors.tweenMoveDelayIsEmpty = "Need to add move behavior delay";
      }
      if (!tweenMoveBehavior.position || tweenResizeBehavior.position === "") {
        errors.tweenMovePositionIsEmpty = "Need to add move behavior position";
      }
    }
  }
  return errors;
}
