import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { ReactComponent as DeleteIcon } from "../../images/trash.svg";
import { ReactComponent as ArrowIcon } from "../../images/down-arrow.svg";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import * as R from "ramda";
import { getTargetObjectSize } from "./../../utils/appUtils";

const CollectableGameObjectBehavior = ({
  currentGameObject,
  gameObject,
  sourceCode,
  sceneIndex,
  selectedLayers,
  gameObjectKey,
}) => {
  var targetObjectSize = 0;
  const [isShowDetail, setIsShowDetail] = useState(false);
  const [selectedTargetObject, setSelectedTargetObject] = useState("0");
  const [targetObjects, setTargetObjects] = useState([]);
  const [delay, setDelay] = useState();
  useEffect(() => {
    if (currentGameObject) {
      targetObjectSize = getTargetObjectSize(sourceCode, sceneIndex);

      setTargetObjects(
        currentGameObject.behaviors.collectable.targetObjects
          ? currentGameObject.behaviors.collectable.targetObjects
          : []
      );

      setDelay(
        currentGameObject.behaviors.collectable.actions.empty[0].delay
          ? currentGameObject.behaviors.collectable.actions.empty[0].delay
          : ""
      );

      setSelectedTargetObject(
        currentGameObject.behaviors.collectable.targetObjects &&
          currentGameObject.behaviors.collectable.targetObjects.length > 0
          ? currentGameObject.behaviors.collectable.targetObjects.length === targetObjectSize
            ? "Select all objects"
            : currentGameObject.behaviors.collectable.targetObjects.length + " Object selected"
          : "No object selected"
      );
    }
  }, [currentGameObject]);

  const gameObjectOptions = [
    {
      value: "Select all objects",
      label: (
        <div className="custom-control custom-checkbox custom-control-inline">
          <input
            type="checkbox"
            name={"targetObjects"}
            checked={selectedTargetObject === "Select all objects" ? true : false}
            className="custom-control-input"
            id={"targetObjects"}
          />
          <label className="custom-control-label" htmlFor="targetObjects">
            {"Select all objects"}
          </label>
        </div>
      ),
    },
  ];

  selectedLayers.map((layer, index) => {
    layer.objects.map((object) => {
      if (object !== gameObjectKey) {
        let targetObj = { value: layer.name + " => " + object };
        targetObj.label = (
          <div className="custom-control custom-checkbox custom-control-inline">
            <input
              type="checkbox"
              checked={
                R.filter(R.propEq("object", object))(targetObjects).length > 0 ? true : false
              }
              name={"targetObjects"}
              value={targetObjects.includes(object)}
              className="custom-control-input"
              id={"targetObjects" + index}
            />
            <label className="custom-control-label" for={"targetObjects" + index}>
              {object}
            </label>
          </div>
        );
        gameObjectOptions.push(targetObj);
      }
    });
  });

  const _onSelectTargetObject = (e) => {
    var tempTargetObjects = targetObjects ? targetObjects : [];
    if (e && e.value === "Select all objects") {
      if (
        gameObjectOptions &&
        Object.keys(gameObjectOptions).length - 1 === tempTargetObjects.length
      ) {
        tempTargetObjects = [];
        setTargetObjects(tempTargetObjects);
        setSelectedTargetObject("No object selected");
      } else {
        tempTargetObjects = [];
        selectedLayers.map((layer, index) => {
          layer.objects.map((object) => {
            if (object !== gameObjectKey) {
              tempTargetObjects.push({ object: object, layer: layer.name });
            }
          });
        });
        setTargetObjects(tempTargetObjects);
        setSelectedTargetObject("Select all objects");
      }
    } else if (e && e.value !== "Select all objects") {
      var layerObjectArray = e.value.split(" => ");
      if (R.filter(R.propEq("object", layerObjectArray[1]))(targetObjects).length > 0) {
        tempTargetObjects = R.reject(R.propEq("object", layerObjectArray[1]))(targetObjects);
      } else {
        tempTargetObjects.push({ object: layerObjectArray[1], layer: layerObjectArray[0] });
      }
      setTargetObjects(tempTargetObjects);
      if (
        gameObjectOptions &&
        Object.keys(gameObjectOptions).length - 1 === tempTargetObjects.length
      ) {
        setSelectedTargetObject("Select all objects");
      } else {
        setSelectedTargetObject(
          tempTargetObjects.length === 0
            ? "No object selected"
            : tempTargetObjects.length + " Object selected"
        );
      }
    }
    gameObject.behaviors.collectable.targetObjects = tempTargetObjects;
  };

  const deleteTargetObjects = (idx) => {
    const tempTargetObjects = targetObjects;
    tempTargetObjects.splice(idx, 1);
    if (
      gameObjectOptions &&
      Object.keys(gameObjectOptions).length - 1 === tempTargetObjects.length
    ) {
      setSelectedTargetObject("Select all objects");
    } else {
      setSelectedTargetObject(
        tempTargetObjects.length === 0
          ? "No object selected"
          : tempTargetObjects.length + " Object selected"
      );
    }
    setTargetObjects(tempTargetObjects);
    gameObject.behaviors.collectable.targetObjects = tempTargetObjects;
  };

  const handleChange = (e) => {
    if (e.target.name === "delay") {
      gameObject.behaviors.collectable.actions.empty[0].delay = e.target.value
        ? Number(e.target.value)
        : "";
      setDelay(e.target.value ? Number(e.target.value) : "");
    }
  };

  function _bindSelectedTargetObjects(objects) {
    return Object.keys(objects).map((item, index) => {
      return (
        <li key={index} className="check-box">
          {objects[item].object}
          <span
            className="delete-game-object btn btn-default btn-delete"
            //className="btn btn-default btn-delete"
            onClick={() => deleteTargetObjects(index)}
          >
            <DeleteIcon />
          </span>
        </li>
      );
    });
  }

  return (
    <div className={isShowDetail ? "bin-object-container show" : "bin-object-container"}>
      <h3 onClick={() => setIsShowDetail(!isShowDetail)} className="toggle-content-title">
        Collectable Game Object Behaviors
        <span className="arrow">
          <ArrowIcon />
        </span>
      </h3>
      {isShowDetail ? (
        <>
          {targetObjects && targetObjects.length > 0 ? (
            <div className="form-group">
              <label>Selected Target Objects</label>
              <ul id="selectedTargetObjects" className="selected-objects">
                {targetObjects ? _bindSelectedTargetObjects(targetObjects) : ""}
              </ul>
            </div>
          ) : (
            ""
          )}
          <div className="form-group">
            <label>Target Objects</label>
            <div className="form-group">
              <div className="input-control">
                <Dropdown
                  value={selectedTargetObject}
                  options={gameObjectOptions}
                  onChange={_onSelectTargetObject}
                  placeholder="Select an option"
                />
              </div>
            </div>
          </div>
          <div className="form-group">
            <label>Behavior Actions</label>
            <div className="form-group">
              <div className="row property-section">
                <div className="form-group col-md-6 col-sm-6">
                  <label>{"Action Type"}</label>
                  <input
                    type="text"
                    disabled={true}
                    value={currentGameObject.behaviors.collectable.actions.empty[0].type}
                  ></input>
                </div>
                <div className="form-group col-md-6 col-sm-6">
                  <label>{"Delay(in milliseconds)"}</label>
                  <input
                    type="number"
                    pattern="[0-9]*"
                    inputMode="numeric"
                    name="delay"
                    value={delay}
                    onChange={handleChange}
                  ></input>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(CollectableGameObjectBehavior);
