import { Types } from "./../constants/game-object-types";

// to open Edit Game Object Modal
export const openEditGameObjectModal = (
  key,
  value,
  stateIndex,
  creativeId,
  objType,
  layers
) => async (dispatch) => {
  var type = "EDIT";
  if (key === undefined && value === undefined) {
    type = "ADD";
  }
  dispatch({
    type: Types.SHOW_EDIT_GAME_OBJECT_MODAL,
    payload: {
      status: true,
      gameObjectKey: key,
      gameObjectValue: value,
      type: type,
      sceneNo: stateIndex,
      creativeId: creativeId,
      objType: objType,
      selectedLayers: layers,
    },
  });
};

// to close Edit Game Object Modal
export const closeEditGameObjectModal = () => async (dispatch) => {
  dispatch({
    type: Types.HIDE_EDIT_GAME_OBJECT_MODAL,
    payload: false,
  });
};

// to close Edit Game Object Modal
export const cancelEditingOfObject = () => async (dispatch) => {
  dispatch({
    type: Types.EDIT_CANCEL,
  });
};

// to open Edit Bin Game Object Behavior Modal
export const openEditBinGameObjectBehavoirModal = (actions, actionIndex) => async (dispatch) => {
  dispatch({
    type: Types.SHOW_EDIT_BIN_BEHAVIOR_ACTION_MODAL,
    payload: {
      status: true,
      actionArray: actions,
      actionIndex: actionIndex,
    },
  });
};

// to close Edit Bin Game Object Behavior Modal
export const closeEditBinGameObjectBehavoirModal = () => async (dispatch) => {
  dispatch({
    type: Types.HIDE_EDIT_BIN_BEHAVIOR_ACTION_MODAL,
    payload: {
      status: false,
      actionArray: undefined,
      actionIndex: undefined,
    },
  });
};

// to open Edit Launchable Game Object Behavior Modal
export const openEditLaunchableGameObjectBehaviorModal = (actions, actionIndex) => async (
  dispatch
) => {
  dispatch({
    type: Types.SHOW_EDIT_LAUNCHABLE_BEHAVIOR_ACTION_MODAL,
    payload: {
      status: true,
      actionArray: actions,
      actionIndex: actionIndex,
    },
  });
};

// to close Edit Launchable Game Object Behavior Modal
export const closeEditLaunchableGameObjectBehaviorModal = () => async (dispatch) => {
  dispatch({
    type: Types.HIDE_EDIT_LAUNCHABLE_BEHAVIOR_ACTION_MODAL,
    payload: {
      status: false,
      actionArray: undefined,
      actionIndex: undefined,
    },
  });
};

// to open Edit Game Object Behavior Modal
export const openEditGameObjectBehaviorModal = (type, behavior, index, isUpdateClickable) => async (
  dispatch
) => {
  dispatch({
    type: Types.SHOW_EDIT_GAME_OBJECT_BEHAVIOR_MODAL,
    payload: {
      status: true,
      editBehaviorType: type,
      editBehavior: behavior,
      behaviorIndex: index,
      isUpdateClickable: isUpdateClickable,
    },
  });
};

// to close Edit Game Object Behavior Modal
export const closeEditGameObjectBehaviorModal = () => async (dispatch) => {
  dispatch({
    type: Types.HIDE_EDIT_GAME_OBJECT_BEHAVIOR_MODAL,
    payload: {
      status: false,
    },
  });
};

// to close Edit Mouse over Object Modal
export const openEditMouseOverBehaviorModal = (type, index, mouseEvent) => async (dispatch) => {
  dispatch({
    type: Types.SHOW_EDIT_MOUSE_OVER_MODAL,
    payload: {
      status: true,
      editMouseEventType: type,
      mouseEventIndex: index,
      mouseEventActionObject: mouseEvent,
    },
  });
};

// to close Edit Mouse over Object Modal
export const closeEditMouseOverBehaviorModal = () => async (dispatch) => {
  dispatch({
    type: Types.HIDE_EDIT_MOUSE_OVER_MODAL,
    payload: {
      status: false,
    },
  });
};

export const updateLoaderState = (status) => async (dispatch) => {
  dispatch({
    type: Types.UPDATE_LOADER_STATE,
    payload: {
      status: status,
    },
  });
};

// to open Edit Path Behavior for Dragable Game Objects
export const openEditPathBehaviorModal = (pathBehavior) => async (dispatch) => {
  dispatch({
    type: Types.SHOW_EDIT_PATH_BEHAVIOR_MODAL,
    payload: {
      status: true,
      editPathBehavior: pathBehavior,
    },
  });
};

// to close Edit Path Behavior for Dragable Game Objects
export const closeEditPathBehaviorModal = () => async (dispatch) => {
  dispatch({
    type: Types.HIDE_EDIT_PATH_BEHAVIOR_MODAL,
    payload: {
      status: false,
      editPathBehavior: undefined,
    },
  });
};
