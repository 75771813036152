import { Types } from "./../constants/truengage-creative-types";
import API from "../../services/truengageCreativeService";
import * as R from "ramda";

// to open CPC Banner Object Modal
export const openEditCPCBannerModal = (cpcBanner) => async (dispatch) => {
  dispatch({
    type: Types.SHOW_EDIT_CPC_BANNER_MODAL,
    payload: { status: true, editCPCBanner: cpcBanner },
  });
};
// to close CPC Banner Object Modal
export const closeEditCPCBannerModal = () => async (dispatch) => {
  dispatch({
    type: Types.HIDE_EDIT_CPC_BANNER_MODAL,
    payload: { status: false, editCPCBanner: undefined },
  });
};

const setTruengageCreative = (
  truengageCreative,
  sourceCode,
  creativeName,
  originalResponse,
  creativeAccountId,
  gameObjectArray,
  isCpcBannerEnabled,
  truengageCreativeType
) => {
  return {
    type: Types.CURRENT_SELECTED_TRUENGAGE_CREATIVE,
    payload: {
      truengageCreative: truengageCreative,
      sourceCode: sourceCode,
      creativeName: creativeName,
      originalResponse: originalResponse,
      creativeAccountId: creativeAccountId,
      gameObjectArray: gameObjectArray,
      isCpcBannerEnabled: isCpcBannerEnabled,
      truengageCreativeType: truengageCreativeType,
    },
  };
};

/**
 * to cpc Banner detail in Truengage_creative_props table
 * @param {creativeuid} creativeuid
 * @param {cpcBannerSource} cpcBannerSource
 */
export const saveCPCBannerInCreativeProps = (creativeuid, cpcBannerSource) => async (
  dispatch,
  getState
) => {
  var sourceCode = getState().truengageCreative.sourceCode;
  var scenes = R.path(["scenes"], sourceCode);
  if (scenes && scenes[0]) {
    scenes[0]["cpcBanner"] = cpcBannerSource;
  }
  let updated = await API.updateSelectedTrueCreativePropsFunc(creativeuid, sourceCode);
  if (updated) {
    await API.getTruengageCreativePropsByID(creativeuid).then(
      (data) => {
        if (data != null) {
          var sourceCode = undefined;
          var creativeName = undefined;
          var originalResponse = undefined;
          var creativeAccountId = undefined;
          var isCpcBannerEnabled = false;
          var truengageCreativeType = "Draggable";
          var gameObjectArray = [];
          if (data["creativeAccountId"]) {
            creativeAccountId = data["creativeAccountId"];
            delete data["creativeAccountId"];
          }
          if (data["gameObjectArray"]) {
            gameObjectArray = data["gameObjectArray"];
            delete data["gameObjectArray"];
          }
          if (data["source"]) {
            sourceCode = data["source"];
            delete data["source"];
          }
          if (data["isCpcBannerEnabled"]) {
            isCpcBannerEnabled = data["isCpcBannerEnabled"];
            delete data["isCpcBannerEnabled"];
          }
          if (data["name"]) {
            creativeName = data["name"];
            delete data["name"];
          }
          if (data["originalResponse"]) {
            originalResponse = data["originalResponse"];
            delete data["originalResponse"];
          }
          if (data["truengageCreativeType"]) {
            truengageCreativeType = data["truengageCreativeType"];
            delete data["truengageCreativeType"];
          }
          dispatch(
            setTruengageCreative(
              data,
              sourceCode,
              creativeName,
              originalResponse,
              creativeAccountId,
              gameObjectArray,
              isCpcBannerEnabled,
              truengageCreativeType
            )
          );
        } else {
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
};
