import React from "react";
import { connect } from "react-redux";
import { closeViewTruengageCreativeModal } from "../../store/actions/truengageCreatives";
import { useDispatch } from "react-redux";
import { compose } from "redux";
import { Button, Modal } from "react-bootstrap";
import JSONPretty from "react-json-pretty";
const ViewTruengageCreativeModal = ({ isShowViewTruegageCreativeModal, selectedSource }) => {
  const dispatch = useDispatch();

  function closeModal() {
    dispatch(closeViewTruengageCreativeModal());
  }

  return (
    <Modal
      show={isShowViewTruegageCreativeModal}
      onHide={closeModal}
      className="viewGameObjectPopup"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title> View Truengage Creative Source</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="viewGameObjectPopupForm" id="addGameObject">
          <div>
            <JSONPretty id="json-pretty" data={selectedSource}></JSONPretty>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <div className="btn-group">
          <Button className="create-btn" onClick={closeModal}>
            {"Close"}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

function mapStateToProps(state) {
  return {
    isShowViewTruegageCreativeModal: state.truengageCreative.isShowViewTruegageCreativeModal,
    selectedSource: state.truengageCreative.selectedSource,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeViewTruengageCreativeModal: () => dispatch(closeViewTruengageCreativeModal()),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(ViewTruengageCreativeModal);
