import { Types } from "../constants/game-object-types";

const INITIAL_STATE = {
  isShowEditGameObjectModal: false,
  gameObjectKey: undefined,
  gameObjectValue: undefined,
  type: undefined,
  sceneNo: 0,
  creativeUid: undefined,
  objType: undefined,
  isShowEditBinBehaviorActionModal: false,
  isShowEditLaunchableBehaviorActionModal: false,
  isShowEditGameObjectBehaviorModal: false,
  isShowEditMouseOverModal: false,
  editMouseEventType: undefined,
  mouseEventIndex: undefined,
  mouseEventActionObject: undefined,
  selectedLayers: [],
  actionIndex: undefined,
  actionArray: undefined,
  editBehaviorType: "click",
  editBehavior: undefined,
  behaviorIndex: undefined,
  cancelEdit: false,
  isLoaderEnabled: false,
  isShowPathBehaviorModal: false,
  editPathBehavior: undefined
};

export default function gameObject(state = INITIAL_STATE, action) {
  if (action.type === Types.SHOW_EDIT_GAME_OBJECT_MODAL) {
    return {
      ...state,
      isShowEditGameObjectModal: action.payload.status,
      gameObjectKey: action.payload.gameObjectKey,
      gameObjectValue: action.payload.gameObjectValue,
      type: action.payload.type,
      sceneNo: action.payload.sceneNo,
      creativeUid: action.payload.creativeId,
      objType: action.payload.objType,
      selectedLayers: action.payload.selectedLayers,
    };
  } else if (action.type === Types.HIDE_EDIT_GAME_OBJECT_MODAL) {
    return { ...state, isShowEditGameObjectModal: action.payload };
  } else if (action.type === Types.SHOW_EDIT_BIN_BEHAVIOR_ACTION_MODAL) {
    return {
      ...state,
      isShowEditBinBehaviorActionModal: action.payload.status,
      actionIndex: action.payload.actionIndex,
      actionArray: action.payload.actionArray,
    };
  } else if (action.type === Types.HIDE_EDIT_BIN_BEHAVIOR_ACTION_MODAL) {
    return {
      ...state,
      isShowEditBinBehaviorActionModal: action.payload.status,
      actionIndex: action.payload.actionIndex,
      actionArray: action.payload.actionArray,
    };
  } else if (action.type === Types.SHOW_EDIT_LAUNCHABLE_BEHAVIOR_ACTION_MODAL) {
    return {
      ...state,
      isShowEditLaunchableBehaviorActionModal: action.payload.status,
      actionIndex: action.payload.actionIndex,
      actionArray: action.payload.actionArray,
    };
  } else if (action.type === Types.HIDE_EDIT_LAUNCHABLE_BEHAVIOR_ACTION_MODAL) {
    return {
      ...state,
      isShowEditLaunchableBehaviorActionModal: action.payload.status,
      actionIndex: action.payload.actionIndex,
      actionArray: action.payload.actionArray,
    };
  } else if (action.type === Types.SHOW_EDIT_GAME_OBJECT_BEHAVIOR_MODAL) {
    return {
      ...state,
      isShowEditGameObjectBehaviorModal: action.payload.status,
      editBehaviorType: action.payload.editBehaviorType,
      editBehavior: action.payload.editBehavior,
      behaviorIndex: action.payload.behaviorIndex,
      isUpdateClickable: action.payload.isUpdateClickable,
    };
  } else if (action.type === Types.HIDE_EDIT_GAME_OBJECT_BEHAVIOR_MODAL) {
    return {
      ...state,
      isShowEditGameObjectBehaviorModal: action.payload.status,
    };
  } else if (action.type === Types.EDIT_CANCEL) {
    return {
      ...state,
      cancelEdit: !state.cancelEdit,
    };
  } else if (action.type === Types.SHOW_EDIT_MOUSE_OVER_MODAL) {
    return {
      ...state,
      isShowEditMouseOverModal: action.payload.status,
      editMouseEventType: action.payload.editMouseEventType,
      mouseEventIndex: action.payload.mouseEventIndex,
      mouseEventActionObject: action.payload.mouseEventActionObject,
    };
  } else if (action.type === Types.HIDE_EDIT_MOUSE_OVER_MODAL) {
    return {
      ...state,
      isShowEditMouseOverModal: action.payload.status,
    };
  } else if (action.type === Types.SHOW_EDIT_PATH_BEHAVIOR_MODAL) {
    return {
      ...state,
      isShowPathBehaviorModal: action.payload.status,
      editPathBehavior: action.payload.editPathBehavior,
    };
  } else if (action.type === Types.HIDE_EDIT_PATH_BEHAVIOR_MODAL) {
    return {
      ...state,
      isShowPathBehaviorModal: action.payload.status,
      editPathBehavior: action.payload.editPathBehavior,
    };
  } else if (action.type === Types.UPDATE_LOADER_STATE) {
    return {
      ...state,
      isLoaderEnabled: action.payload.status,
    };
  } else {
    return state;
  }
}
