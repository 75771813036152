import React, { useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { SketchPicker } from "react-color";
import reactCSS from "reactcss";
import { setColorPickerCSSStyle } from "./../../utils/appUtils";

const ShapeGameObject = ({ shapeColor, shapeDimension, handleShapeChange, errors }) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const styles = reactCSS(setColorPickerCSSStyle(shapeColor));

  return (
    <div className="objects-container-section">
      <div className="form-group">
        <div className="row property-section">
          <div className="form-group col-sm-4">
            <label>Color</label>
            <div className="color-box">
              <div style={styles.swatch} onClick={() => setDisplayColorPicker(!displayColorPicker)}>
                <div style={styles.color} />
              </div>
              {displayColorPicker ? (
                <div style={styles.popover}>
                  <div style={styles.cover} onClick={() => setDisplayColorPicker(false)} />
                  <SketchPicker color={shapeColor} name="color" onChange={handleShapeChange} />
                </div>
              ) : null}
            </div>
          </div>
          <div className="form-group col-sm-4">
            <label>Offset X</label>
            <input
              type="number"
              pattern="[0-9]*"
              inputMode="numeric"
              name="offsetX"
              value={shapeDimension.offsetX}
              onChange={handleShapeChange}
            />
            {errors && errors.offsetXIsEmpty && <small>{errors.offsetXIsEmpty}</small>}
          </div>
          <div className="form-group col-sm-4">
            <label>Offset Y</label>
            <input
              type="number"
              pattern="[0-9]*"
              inputMode="numeric"
              name="offsetY"
              value={shapeDimension.offsetY}
              onChange={handleShapeChange}
            />
            {errors && errors.offsetYIsEmpty && <small>{errors.offsetYIsEmpty}</small>}
          </div>
          <div className="form-group col-sm-6">
            <label>Width</label>
            <input
              type="number"
              pattern="[0-9]*"
              inputMode="numeric"
              name="width"
              value={shapeDimension.width}
              onChange={handleShapeChange}
            />
            {errors && errors.shapeWidthIsEmpty && <small>{errors.shapeWidthIsEmpty}</small>}
          </div>
          <div className="form-group col-sm-6">
            <label>Height</label>
            <input
              type="number"
              pattern="[0-9]*"
              inputMode="numeric"
              name="height"
              value={shapeDimension.height}
              onChange={handleShapeChange}
            />
            {errors && errors.shapeHeightIsEmpty && <small>{errors.shapeHeightIsEmpty}</small>}
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(ShapeGameObject);
