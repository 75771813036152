import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { loadXPSCampaignsByAccountId, clearCampaignState } from "../store/actions/campaigns";
import { getSelectedAccountDetail } from "../store/actions/accounts";
import { useDispatch } from "react-redux";
import Loader from "./loader";
import { Link } from "react-router-dom";
import requireAuth from "./hoc/requireAuth";
import { compose } from "redux";
import { useParams } from "react-router-dom";

import { ReactComponent as DownArrow } from "../images/down-arrow.svg";

const Campaigns = ({ xpsCampaigns, selectedAccount }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [viewOldCampaign, setViewOldCampaign] = useState(false);
  const oldCampaigns = {};
  const recentCampaigns = {};
  useEffect(() => {
    dispatch(getSelectedAccountDetail(id));
    dispatch(loadXPSCampaignsByAccountId(id));
    return () => {
      dispatch(clearCampaignState());
    };
  }, []);

  // to list out xps Campaigns
  function _bindXPSCampaigns(campaigns) {
    return Object.keys(campaigns).map((item, index) => {
      return (
        <li key={item} className="check-box">
          <Link to={`/video-line/${item}`}>
            {" "}
            <span>{campaigns[item].name} </span>
          </Link>
        </li>
      );
    });
  }

  if (xpsCampaigns && Object.keys(xpsCampaigns).length > 0) {
    Object.keys(xpsCampaigns).map((item, index) => {
      if (xpsCampaigns[item].age === "recent") {
        recentCampaigns[item] = xpsCampaigns[item];
      } else {
        oldCampaigns[item] = xpsCampaigns[item];
      }
    });
  }

  return (
    <div>
      {xpsCampaigns && Object.keys(xpsCampaigns).length > 0 ? (
        <div className="page">
          <Link to={"/"}>
            <h3>Accounts</h3>
          </Link>
          <h3>{selectedAccount.name}</h3>
          <div className="listing-container">
            <h5 className="list-title">
              {Object.keys(xpsCampaigns).length}
              {Object.keys(xpsCampaigns).length > 1 ? " Campaigns" : " Campaign"}
            </h5>
            <ul id="recentCampaigns">
              {recentCampaigns ? _bindXPSCampaigns(recentCampaigns) : ""}
            </ul>
            <div className="list-footer">
              <h5 className="footer-title" onClick={() => setViewOldCampaign(!viewOldCampaign)}>
                {Object.keys(oldCampaigns).length}{" "}
                {Object.keys(oldCampaigns).length > 1 ? " Old Campaigns" : " Old Campaign"}
                <span className="arrow">
                  <DownArrow />
                </span>
              </h5>
              {viewOldCampaign ? (
                <ul id="oldCampaigns">{oldCampaigns ? _bindXPSCampaigns(oldCampaigns) : ""}</ul>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    xpsCampaigns: state.campaign.xpsCampaigns,
    selectedAccount: state.account.selectedAccount,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadXPSCampaignsByAccountId: (id) => dispatch(loadXPSCampaignsByAccountId(id)),
    getSelectedAccountDetail: (id) => dispatch(getSelectedAccountDetail(id)),
    clearCampaignState: () => dispatch(clearCampaignState()),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps), requireAuth)(Campaigns);
