export default function validate(mouseEvent) {
  let errors = {};
  if (!mouseEvent.target || mouseEvent.target === "") {
    errors.targetIsEmpty = "Need to add Target";
  }
  if (!mouseEvent || mouseEvent.delay <= 0) {
    errors.delayIsEmpty = "Need to add Delay";
  }
  return errors;
}
