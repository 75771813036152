export const Types = {
  SHOW_EDIT_TRUENGAGE_CREATIVE_MODAL: "SHOW_EDIT_TRUENGAGE_CREATIVE_MODAL",
  HIDE_EDIT_TRUENGAGE_CREATIVE_MODAL: "HIDE_EDIT_TRUENGAGE_CREATIVE_MODAL",
  SHOW_VIEW_TRUENGAGE_CREATIVE_MODAL: "SHOW_VIEW_TRUENGAGE_CREATIVE_MODAL",
  HIDE_VIEW_TRUENGAGE_CREATIVE_MODAL: "HIDE_VIEW_TRUENGAGE_CREATIVE_MODAL",
  GET_TRUENGAGE_CREATIVES_BY_LINE_ID: "GET_TRUENGAGE_CREATIVES_BY_LINE_ID",
  GET_TRUENGAGE_CREATIVE_VERSION_MAP: "GET_TRUENGAGE_CREATIVE_VERSION_MAP",
  GET_TRUENGAGE_CREATIVE_ERROR_MESSAGE: "GET_TRUENGAGE_CREATIVE_ERROR_MESSAGE",
  CURRENT_SELECTED_TRUENGAGE_CREATIVE: "CURRENT_SELECTED_TRUENGAGE_CREATIVE",
  SAVE_TRUENGAGE_CREATIVE: "SAVE_TRUENGAGE_CREATIVE",
  UPDATE_SELECTED_TRUENGAGE_CREATIVE: "UPDATE_SELECTED_TRUENGAGE_CREATIVE",
  DELETE_GAME_STATE: "DELETE_GAME_STATE",
  RESET_OPERATION_RESPONSE: "RESET_OPERATION_RESPONSE",
  SHOW_EDIT_CPC_BANNER_MODAL: "SHOW_EDIT_CPC_BANNER_MODAL",
  HIDE_EDIT_CPC_BANNER_MODAL: "HIDE_EDIT_CPC_BANNER_MODAL",
  SHOW_VIEW_CPC_BANNER_MODAL: "SHOW_VIEW_CPC_BANNER_MODAL",
  HIDE_VIEW_CPC_BANNER_MODAL: "HIDE_VIEW_CPC_BANNER_MODAL",
  GET_CPC_BANNER_ERROR_MESSAGE: "GET_CPC_BANNER_ERROR_MESSAGE",
  SAVE_CPC_BANNER: "SAVE_CPC_BANNER",
  CLEAR_CURRENT_CREATIVE: "CLEAR_CURRENT_CREATIVE",
  SET_JYNX_VERSIONS: "SET_JYNX_VERSIONS",
  CLEAR_CREATIVES_LIST: "CLEAR_CREATIVES_LIST",
  PUBLISH_CREATIVE_RESPONSE: "PUBLISH_CREATIVE_RESPONSE",
};
