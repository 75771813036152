import { useState } from "react";

const ContainerObjectForm = (
  frames,
  setFrames,
  currentObject,
  setCurrentObject,
  shapeDimension,
  setShapeColor,
  setShapeDimension
) => {
  const [errors, setErrors] = useState({});

  const handleFrameChange = (e, imageURL) => {
    let frameObj = { ...frames };
    let currentObj = { ...currentObject };

    if (!currentObj.spritesheet) currentObj.spritesheet = {};

    // set the image field of spritesheet
    if (imageURL) {
      currentObj.spritesheet["source"] = imageURL;
    }
    // set the other fields of spritesheet
    else {
      if (e.target.name === "framerate") {
        currentObj.spritesheet.framerate = e.target.value;
        // setCurrentObject({ ...currentObj });
      } else {
        frameObj[e.target.name] = e.target.value;
        setFrames(frameObj);
        currentObj.spritesheet.frames = frameObj;
        // setCurrentObject({ ...currentObj });
      }
    }
    setCurrentObject({ ...currentObj });
  };

  // setting the outer properties of inner object
  const handleContainerChange = (e, type, newURL) => {
    e.preventDefault();
    let currentObj = { ...currentObject };
    if (type) {
      if (type === "position") {
        if (!currentObj.position) currentObj.position = { x: 0, y: 0 };
        currentObj.position[e.target.name] = e.target.value ? Number(e.target.value) : "";
      } else if (type === "scale") {
        if (!currentObj.scale) currentObj.scale = {};
        currentObj.scale[e.target.name] = e.target.value ? Number(e.target.value) : "";
      } else if (type === "image") {
        // ************** this image setter gets called only for image type object, not for spritesheet *******************
        if (!currentObj.image) currentObj.image = {};
        currentObj.image.source = newURL;
      }
    } else {
      currentObj[e.target.name] = e.target.value;
    }
    setCurrentObject(currentObj);
  };
  const handleShapeChange = (e) => {
    let shapeDimensionObj = { ...shapeDimension };
    let tempGameObj = { ...currentObject };
    if (!tempGameObj.shape) {
      tempGameObj.shape = {};
      tempGameObj.shape.graphics = [];
      tempGameObj.shape.graphics.push(["f", "#FFFFFF"]);
      tempGameObj.shape.graphics.push(["dr", "", "", "", ""]);
    }
    if (e.target) {
      shapeDimensionObj[e.target.name] = e.target.value;
      setShapeDimension(shapeDimensionObj);
      if (tempGameObj.shape.graphics[1] && tempGameObj.shape.graphics[1].length > 0) {
        tempGameObj.shape.graphics[1][1] = shapeDimensionObj.offsetX;
        tempGameObj.shape.graphics[1][2] = shapeDimensionObj.offsetY;
        tempGameObj.shape.graphics[1][3] = shapeDimensionObj.width;
        tempGameObj.shape.graphics[1][4] = shapeDimensionObj.height;
      }
    } else {
      setShapeColor(e.hex);
      tempGameObj.shape.graphics[0][1] = e.hex;
    }
    setCurrentObject({ ...tempGameObj });
  };

  return [errors, handleFrameChange, handleContainerChange, handleShapeChange];
};

export default ContainerObjectForm;
