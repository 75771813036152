import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { compose } from "redux";
import { Button, Modal } from "react-bootstrap";
import { closeEditLaunchableGameObjectBehaviorModal } from "../../store/actions/gameObjects";
import { updateSelectedTrueCreativePropsFunc } from "../../store/actions/truengageCreatives";
import * as R from "ramda";
import { updateLoaderState } from "../../store/actions/gameObjects";
import Loader from "./../../pages/loader";

const EditLaunchableBehaviorActionModal = ({
  isShowEditLaunchableBehaviorActionModal,
  sceneIndex,
  selectedLayers,
  sourceCode,
  gameObjectkey,
  creativeUid,
  actionArray,
  actionIndex,
  isLoaderEnabled,
}) => {
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const [actionType, setActionType] = useState("advanceGame");
  const [advanceGameAction, setAdvanceGameAction] = useState({ type: "advanceGame", delay: "" });
  const [removeObjectAction, setRemoveObjectAction] = useState({
    type: "removeObject",
    layer: "",
    target: "",
  });

  useEffect(() => {
    if (actionArray && actionIndex >= 0) {
      if (actionArray[actionIndex] && actionArray[actionIndex].type) {
        setActionType(actionArray[actionIndex].type);
        if (actionArray[actionIndex].type === "advanceGame") {
          const tempAdvanceGameAction = actionArray[actionIndex];
          setAdvanceGameAction({ ...tempAdvanceGameAction });
        } else if (actionArray[actionIndex].type === "removeObject") {
          const tempRemoveObjectAction = actionArray[actionIndex];
          setRemoveObjectAction({ ...tempRemoveObjectAction });
        } else {
          // to do for add Behaviors case
        }
      }
      setIsEdit(true);
    } else {
      setIsEdit(false);
    }
    return () => {
      console.log("Unmount Edit Behavior Action Modal");
    };
  }, [actionArray]);

  const setDefaultStateValue = () => {
    setActionType("advanceGame");
    setAdvanceGameAction({ type: "advanceGame", delay: "" });
    setRemoveObjectAction({
      type: "removeObject",
      layer: "",
      target: "",
    });
    setIsEdit(false);
    dispatch(updateLoaderState(false));
  };

  /**
   * to close current modal
   * @param {close event} e
   */
  const handleClose = (e) => {
    dispatch(closeEditLaunchableGameObjectBehaviorModal());
    setDefaultStateValue();
  };

  /**
   * to change action Type Radio button
   * @param {Event} e
   */
  const handleChangeActionType = (e) => {
    setActionType(e.target.value);
  };

  const handleChangeAction = (actionType, e) => {
    if (actionType === "advanceGame") {
      const tempAdvanceGameAction = advanceGameAction;
      tempAdvanceGameAction.type = actionType;
      tempAdvanceGameAction.delay = e.target.value;
      setAdvanceGameAction({ ...tempAdvanceGameAction });
    } else if (actionType === "removeObject") {
      var values = e.target.value;
      const tempRemoveObjectAction = removeObjectAction;
      tempRemoveObjectAction.type = actionType;
      if (values.includes("=>")) {
        var layer = values.split("=>")[0];
        var targetedObject = values.split("=>")[1];
        tempRemoveObjectAction.layer = layer;
        tempRemoveObjectAction.target = targetedObject;
      }
      setRemoveObjectAction({ ...removeObjectAction });
    } else {
    }
  };

  const handleSaveAction = (e) => {
    dispatch(updateLoaderState(true));
    e.persist();
    var selectedScene = R.path(["scenes"], sourceCode)[sceneIndex];
    var objects = {};
    if (R.path(["objects"], selectedScene)) {
      objects = R.path(["objects"], selectedScene);
    }
    if (objects && objects[gameObjectkey]) {
      var currentObject = objects[gameObjectkey];
      if (
        currentObject &&
        currentObject.behaviors &&
        currentObject.behaviors.launchable &&
        currentObject.behaviors.launchable.actions &&
        currentObject.behaviors.launchable.actions.empty
      ) {
        if (isEdit) {
          // to update existing action in full array
          currentObject.behaviors.launchable.actions.empty[actionIndex] =
            actionType === "removeObject" ? removeObjectAction : advanceGameAction;
        } else {
          // to add new action in full Array
          currentObject.behaviors.launchable.actions.empty.push(
            actionType === "removeObject" ? removeObjectAction : advanceGameAction
          );
        }
      }
    }
    dispatch(updateSelectedTrueCreativePropsFunc(creativeUid, sourceCode, true));
    dispatch(closeEditLaunchableGameObjectBehaviorModal());
    setDefaultStateValue();
  };

  const gameObjectOptions = selectedLayers.map(
    (layer) =>
      layer.objects &&
      layer.objects.map((object) => (
        <option key={layer.name + "=>" + object} value={layer.name + "=>" + object}>
          {layer.name + "=>" + object}
        </option>
      ))
  );

  const targetObjectOptions = selectedLayers.map(
    (layer) =>
      layer.objects &&
      layer.objects.map((object) => (
        <option key={object} value={object}>
          {object}
        </option>
      ))
  );

  return (
    <Modal show={isShowEditLaunchableBehaviorActionModal} onHide={handleClose}>
      {isLoaderEnabled ? <Loader /> : ""}

      <Modal.Header closeButton>
        <Modal.Title>
          {!isEdit ? "Add Launchable Behavior Action" : "Edit Launchable Behavior action "}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
          <div className="form-group">
            <label>Action Type</label>
            <div className="input-control">
              <span className="custom-control custom-radio custom-control-inline">
                <input
                  type="radio"
                  value={"advanceGame"}
                  checked={actionType === "advanceGame" ? true : false}
                  onChange={(evt) => {
                    handleChangeActionType(evt);
                    setRemoveObjectAction({
                      type: "removeObject",
                      layer: "",
                      target: "",
                    });
                  }}
                  disabled={isEdit}
                  name="actionType"
                  className="custom-control-input"
                  id="advance-game"
                />
                <label className="custom-control-label" htmlFor="advance-game">
                  {"Advance Game"}
                </label>
              </span>

              <span className="custom-control custom-radio custom-control-inline">
                <input
                  type="radio"
                  value={"removeObject"}
                  name="actionType"
                  checked={actionType === "removeObject" ? true : false}
                  onChange={(evt) => {
                    handleChangeActionType(evt);
                    setAdvanceGameAction({ type: "advanceGame", delay: "" });
                  }}
                  disabled={isEdit}
                  className="custom-control-input"
                  id="remove-object"
                />
                <label className="custom-control-label" htmlFor="remove-object">
                  {"Remove Object"}
                </label>
              </span>
            </div>
          </div>

          {actionType === "removeObject" ? (
            <div className="form-group">
              <label>Target Object</label>
              <select
                class="custom-select"
                name="targetObject"
                value={removeObjectAction.layer + "=>" + removeObjectAction.target}
                onChange={(e) => handleChangeAction("removeObject", e)}
              >
                <option key={""} value={""}>
                  {"Select Target Object"}
                </option>
                {gameObjectOptions}
              </select>
            </div>
          ) : (
            <>
              {actionType === "advanceGame" ? (
                <div className="form-group">
                  <div>
                    <label>Delay(in milliseconds)</label>
                    <div className="sidebar-input">
                      <input
                        type="number"
                        pattern="[0-9]*"
                        inputMode="numeric"
                        name="delay"
                        placeholder={"Delay(in milliseconds)"}
                        value={advanceGameAction.delay}
                        onChange={(e) => handleChangeAction("advanceGame", e)}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </>
          )}
        </>
      </Modal.Body>
      <Modal.Footer>
        {actionType === "removeObject" ? (
          <Button
            disabled={
              actionType === "removeObject"
                ? !removeObjectAction.target || removeObjectAction.target === ""
                  ? true
                  : false
                : false
            }
            variant="primary"
            onClick={handleSaveAction}
          >
            Save Changes
          </Button>
        ) : (
          ""
        )}

        {actionType === "advanceGame" ? (
          <Button
            disabled={
              actionType === "advanceGame" ? (!advanceGameAction.delay ? true : false) : false
            }
            variant="primary"
            onClick={handleSaveAction}
          >
            Save Changes
          </Button>
        ) : (
          ""
        )}
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

function mapStateToProps(state) {
  return {
    isShowEditLaunchableBehaviorActionModal:
      state.gameObject.isShowEditLaunchableBehaviorActionModal,
    gameObjectkey: state.gameObject.gameObjectKey,
    creativeUid: state.gameObject.creativeUid,
    actionArray: state.gameObject.actionArray,
    actionIndex: state.gameObject.actionIndex,
    isLoaderEnabled: state.gameObject.isLoaderEnabled,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeEditLaunchableGameObjectBehaviorModal: () =>
      dispatch(closeEditLaunchableGameObjectBehaviorModal()),
    updateSelectedTrueCreativePropsFunc: (creativeUid, sourceCode, behaviorBool) =>
      dispatch(updateSelectedTrueCreativePropsFunc(creativeUid, sourceCode, behaviorBool)),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  EditLaunchableBehaviorActionModal
);
