import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { useDispatch } from "react-redux";
import { signout } from "../store/actions/auth";
import Loader from "./loader";

const Header = ({ auth }) => {
  const dispatch = useDispatch();
  return (
    <header
      className={
        !auth.isLoaded
          ? "site-header loginout-header"
          : !auth.isEmpty
          ? "site-header loginout-header"
          : "site-header"
      }
    >
      <h1>
        TruEngageUI<sup>&reg;</sup>
      </h1>
      {!auth.isLoaded ? (
        <Loader />
      ) : !auth.isEmpty ? (
        <button className="btn btn-switch btn-logout" onClick={() => dispatch(signout())}>
          Logout
        </button>
      ) : (
        ""
      )}
    </header>
  );
};

function mapStateToProps(state) {
  return {
    auth: state.firebase.auth,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    signout: () => dispatch(signout()),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Header);
