export const Types = {
  SHOW_EDIT_GAME_OBJECT_MODAL: "SHOW_EDIT_GAME_OBJECT_MODAL",
  HIDE_EDIT_GAME_OBJECT_MODAL: "HIDE_EDIT_GAME_OBJECT_MODAL",
  ADD_NEW_GAME_OBJECT: "ADD_NEW_GAME_OBJECT",
  SHOW_EDIT_BIN_BEHAVIOR_ACTION_MODAL: "SHOW_EDIT_BIN_BEHAVIOR_ACTION_MODAL",
  HIDE_EDIT_BIN_BEHAVIOR_ACTION_MODAL: "HIDE_EDIT_BIN_BEHAVIOR_ACTION_MODAL",
  SHOW_EDIT_LAUNCHABLE_BEHAVIOR_ACTION_MODAL: "SHOW_EDIT_LAUNCHABLE_BEHAVIOR_ACTION_MODAL",
  HIDE_EDIT_LAUNCHABLE_BEHAVIOR_ACTION_MODAL: "HIDE_EDIT_LAUNCHABLE_BEHAVIOR_ACTION_MODAL",
  SHOW_EDIT_GAME_OBJECT_BEHAVIOR_MODAL: "SHOW_EDIT_GAME_OBJECT_BEHAVIOR_MODAL",
  HIDE_EDIT_GAME_OBJECT_BEHAVIOR_MODAL: "HIDE_EDIT_GAME_OBJECT_BEHAVIOR_MODAL",
  EDIT_CANCEL: "EDIT_CANCEL",
  SHOW_EDIT_MOUSE_OVER_MODAL: "SHOW_EDIT_MOUSE_OVER_MODAL",
  HIDE_EDIT_MOUSE_OVER_MODAL: "HIDE_EDIT_MOUSE_OVER_MODAL",
  SHOW_EDIT_PATH_BEHAVIOR_MODAL: "SHOW_EDIT_PATH_BEHAVIOR_MODAL",
  HIDE_EDIT_PATH_BEHAVIOR_MODAL: "HIDE_EDIT_PATH_BEHAVIOR_MODAL",
  UPDATE_LOADER_STATE: "UPDATE_LOADER_STATE",
};
