export default function validate(truengageCreative) {
  let errors = {};
  // Checking if name is not empty
  if (!truengageCreative.name || truengageCreative.name === "") {
    errors.nameIsEmpty = "Need to enter creative name";
  } else {
    if (truengageCreative.name.length > 100) {
      errors.nameIsEmpty = "Maximum characters limit for name field is exceeded";
    }
  }

  // Checking if duration is not 0
  if (Number(truengageCreative.duration) < 1) {
    errors.durationLengthIsZero = "Duration must be greater than 0";
  }

  // Checking if Weight is not 0
  if (Number(truengageCreative.weight) < 1) {
    errors.weightIsZero = "Weight must be greater than 0";
  }

  //Checking if any device is not selected
  if (!truengageCreative.devices) {
    errors.deviceNotSelected = "Atleast select one device";
  }

  return errors;
}
