import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Link } from "react-router-dom";
const Sidenav = () => {
  return (
    <div className="sidenav-menu">
      <span className="sidemenu-link">
        <Link to={`/`}>Account</Link>
      </span>
    </div>
  );
};

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Sidenav);
