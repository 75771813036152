import { Types } from "../constants/truengage-creative-types";

const INITIAL_STATE = {
  isShowEditTruegageCreativeModal: false,
  isShowViewTruegageCreativeModal: false,
  truengageCreatives: undefined,
  truengageCreativeMap: undefined,
  truengageCreativeErrorMessage: undefined,
  selectedSource: undefined,
  dragableModel: undefined,
  sourceCode: undefined,
  creativeName: undefined,
  editTruengageCreative: undefined,
  originalResponse: undefined,
  creativeAccountId: undefined,
  operationResponse: null,
  isShowEditCPCBannerModal: false,
  isShowViewCPCBannerModal: false,
  CPCBanner: undefined,
  CPCBannerErrorMessage: undefined,
  editTruengageCreativeId: undefined,
  gameObjectArray: [],
  isCpcBannerEnabled: false,
  truengageCreativeType: undefined,
  jynxVersions: undefined,
  publishCreativeResponse: undefined,
};

export default function truengageCreative(state = INITIAL_STATE, action) {
  if (action.type === Types.SHOW_EDIT_TRUENGAGE_CREATIVE_MODAL) {
    return {
      ...state,
      isShowEditTruegageCreativeModal: action.payload.status,
      editTruengageCreative: action.payload.editTruengageCreative,
      editTruengageCreativeId: action.payload.editTruengageCreativeId,
    };
  } else if (action.type === Types.HIDE_EDIT_TRUENGAGE_CREATIVE_MODAL) {
    return { ...state, isShowEditTruegageCreativeModal: action.payload };
  } else if (action.type === Types.SHOW_VIEW_TRUENGAGE_CREATIVE_MODAL) {
    return {
      ...state,
      isShowViewTruegageCreativeModal: action.payload.status,
      selectedSource: action.payload.selectedSource,
    };
  } else if (action.type === Types.HIDE_VIEW_TRUENGAGE_CREATIVE_MODAL) {
    return {
      ...state,
      isShowViewTruegageCreativeModal: action.payload.status,
      selectedSource: action.payload.selectedSource,
    };
  } else if (action.type === Types.GET_TRUENGAGE_CREATIVES_BY_LINE_ID) {
    return { ...state, truengageCreatives: action.payload };
  } else if (action.type === Types.GET_TRUENGAGE_CREATIVE_VERSION_MAP) {
    return { ...state, truengageCreativeMap: action.payload };
  } else if (action.type === Types.GET_TRUENGAGE_CREATIVE_ERROR_MESSAGE) {
    return { ...state, truengageCreativeErrorMessage: action.payload };
  } else if (action.type === Types.CURRENT_SELECTED_TRUENGAGE_CREATIVE) {
    return {
      ...state,
      dragableModel: action.payload.truengageCreative,
      sourceCode: action.payload.sourceCode,
      creativeName: action.payload.creativeName,
      originalResponse: action.payload.originalResponse,
      creativeAccountId: action.payload.creativeAccountId,
      gameObjectArray: action.payload.gameObjectArray,
      isCpcBannerEnabled: action.payload.isCpcBannerEnabled,
      truengageCreativeType: action.payload.truengageCreativeType,
    };
  } else if (action.type === Types.UPDATE_SELECTED_TRUENGAGE_CREATIVE) {
    return {
      ...state,
      dragableModel: action.payload.dragableModel,
      sourceCode: action.payload.sourceCode,
      operationResponse: action.payload.operationResponse,
    };
  } else if (action.type === Types.DELETE_GAME_STATE) {
    return {
      ...state,
      dragableModel: action.payload.dragableModel,
      sourceCode: action.payload.sourceCode,
      operationResponse: action.payload.operationResponse,
    };
  } else if (action.type === Types.RESET_OPERATION_RESPONSE) {
    return {
      ...state,
      operationResponse: null,
    };
  } else if (action.type === Types.SHOW_EDIT_CPC_BANNER_MODAL) {
    return {
      ...state,
      isShowEditCPCBannerModal: action.payload.status,
      editCPCBanner: action.payload.editCPCBanner,
    };
  } else if (action.type === Types.HIDE_EDIT_CPC_BANNER_MODAL) {
    return {
      ...state,
      isShowEditCPCBannerModal: action.payload.status,
      editCPCBanner: action.payload.editCPCBanner,
    };
  } else if (action.type === Types.SHOW_VIEW_CPC_BANNER_MODAL) {
    return {
      ...state,
      isShowViewCPCBannerModal: action.payload.status,
    };
  } else if (action.type === Types.HIDE_VIEW_CPC_BANNER_MODAL) {
    return {
      ...state,
      isShowViewCPCBannerModal: action.payload.status,
    };
  } else if (action.type === Types.GET_CPC_BANNER_ERROR_MESSAGE) {
    return { ...state, CPCBannerErrorMessage: action.payload };
  } else if (action.type === Types.CLEAR_CURRENT_CREATIVE) {
    return {
      ...state,
      dragableModel: undefined,
      sourceCode: undefined,
      creativeName: undefined,
      originalResponse: undefined,
      gameObjectArray: [],
      isCpcBannerEnabled: false,
    };
  } else if (action.type === Types.SAVE_TRUENGAGE_CREATIVE) {
    return { ...state, operationResponse: action.payload };
  } else if (action.type === Types.SET_JYNX_VERSIONS) {
    return { ...state, jynxVersions: action.payload };
  } else if (action.type === Types.CLEAR_CREATIVES_LIST) {
    return {
      ...state,
      truengageCreatives: action.payload,
      truengageCreativeErrorMessage: action.payload,
    };
  } else if (action.type === Types.PUBLISH_CREATIVE_RESPONSE) {
    return { ...state, publishCreativeResponse: action.payload.status };
  } else {
    return state;
  }
}
