import React, { useEffect } from "react";
import { connect } from "react-redux";
import Home from "./home";
import Login from "./login";
import Loader from "./loader";
import { isUserLoggedIn } from "./../store/actions/auth";
import { useDispatch } from "react-redux";

const Main = ({ auth }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    (async () => {
      if (!auth.isEmpty) {
        await dispatch(isUserLoggedIn());
      }
    })();
  }, []);
  return <div>{!auth.isLoaded ? <Loader /> : !auth.isEmpty ? <Home /> : <Login />}</div>;
};

function mapStateToProps(state) {
  return {
    auth: state.firebase.auth,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    isUserLoggedIn: () => dispatch(isUserLoggedIn()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);
