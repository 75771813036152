import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  closeEditCPCBannerModal,
  saveCPCBannerInCreativeProps,
} from "../../store/actions/CPCBanner";
import { useDispatch } from "react-redux";
import { compose } from "redux";
import { Button, Modal } from "react-bootstrap";
import cpcBannerForm from "../../utils/cpcBanner/cpcBannerForm";
import validate from "../../utils/cpcBanner/validateCpcBannerForm";
import { updateLoaderState } from "../../store/actions/gameObjects";
import Loader from "./../../pages/loader";

const EditCPCBannerModal = ({
  isShowEditCPCBannerModal,
  editCPCBanner,
  creativeuid,
  isLoaderEnabled,
}) => {
  const dispatch = useDispatch();

  const [cpcBanner, setCpcBanner, handleChange, handleSubmit, resetForm, errors] = cpcBannerForm(
    saveCpcBanner,
    validate
  );

  useEffect(() => {
    if (editCPCBanner) {
      setCpcBanner(editCPCBanner);
    } else {
      setCpcBanner({
        name: "",
        type: "div",
        width: "",
        height: "",
        top: 0,
        left: 0,
        right: 0,
      });
    }
  }, [editCPCBanner]);

  // to close Modal
  function closeModal() {
    dispatch(closeEditCPCBannerModal());
    setCpcBanner({
      name: "",
      type: "div",
      width: "",
      height: "",
      top: 0,
      left: 0,
      right: 0,
    });
    dispatch(updateLoaderState(false));
  }

  /**
   * to save CPC Banner Details in DB
   */
  function saveCpcBanner() {
    dispatch(updateLoaderState(true));
    var cpcBannerSource = {};
    cpcBannerSource["domElement"] = cpcBanner;
    dispatch(saveCPCBannerInCreativeProps(creativeuid, cpcBannerSource));
    closeModal();
  }

  return (
    <Modal
      show={isShowEditCPCBannerModal}
      onHide={closeModal}
      className="editCPCBannerPopup"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {isLoaderEnabled ? <Loader /> : ""}
      <Modal.Header closeButton>
        <Modal.Title> Add New CPC Banner </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="editCPCBannerPopupForm" id="addCPCBannerObject">
          <div className="form-group">
            <label>Name</label>
            <div className="input-control">
              <input
                type="text"
                name="name"
                value={cpcBanner.name}
                placeholder="Enter Name"
                onChange={handleChange}
              />
              {errors && errors.nameIsEmpty && <small>{errors.nameIsEmpty}</small>}
            </div>
          </div>

          <div className="form-group">
            <label>Source Code</label>
            <div className="input-control">
              <input
                type="text"
                name="source"
                value={cpcBanner.source}
                placeholder="Enter Banner Source"
                onChange={handleChange}
              />
              {errors && errors.sourceIsEmpty && <small>{errors.sourceIsEmpty}</small>}
            </div>
          </div>

          <div className="row">
            <div className="form-group col-sm-6">
              <label>Width</label>
              <div className="input-control">
                <input
                  type="number"
                  pattern="[0-9]*"
                  inputMode="numeric"
                  name="width"
                  value={cpcBanner.width}
                  placeholder="Enter Width"
                  onChange={handleChange}
                />
                {errors && errors.widthIsEmpty && <small>{errors.widthIsEmpty}</small>}
              </div>
            </div>

            <div className="form-group col-sm-6">
              <label>Height</label>
              <div className="input-control">
                <input
                  type="number"
                  pattern="[0-9]*"
                  inputMode="numeric"
                  name="height"
                  value={cpcBanner.height}
                  placeholder="Enter Height"
                  onChange={handleChange}
                />
                {errors && errors.heightIsEmpty && <small>{errors.heightIsEmpty}</small>}
              </div>
            </div>
          </div>

          <div className="form-group">
            <label>{"Banner Alignments"}</label>
            <div className="input-control row property-section">
              <div className="col-sm-4">
                <label>Top</label>
                <input
                  type="number"
                  pattern="[0-9]*"
                  inputMode="numeric"
                  name="top"
                  value={cpcBanner.top}
                  onChange={handleChange}
                />
              </div>
              <div className="col-sm-4">
                <label>Left</label>
                <input
                  type="number"
                  pattern="[0-9]*"
                  inputMode="numeric"
                  name="left"
                  value={cpcBanner.left}
                  onChange={handleChange}
                />
              </div>
              <div className="col-sm-4">
                <label>Right</label>
                <input
                  type="number"
                  pattern="[0-9]*"
                  inputMode="numeric"
                  name="right"
                  value={cpcBanner.right}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button className="create-btn" onClick={handleSubmit}>
          {"Save"}
        </Button>
        <Button className="cancel-btn" onClick={closeModal}>
          {"Cancel"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

function mapStateToProps(state) {
  return {
    isShowEditCPCBannerModal: state.truengageCreative.isShowEditCPCBannerModal,
    editCPCBanner: state.truengageCreative.editCPCBanner,
    isLoaderEnabled: state.gameObject.isLoaderEnabled,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeEditCPCBannerModal: () => dispatch(closeEditCPCBannerModal()),
    saveCPCBannerInCreativeProps: (creativeuid, cpcBannerSource) =>
      dispatch(saveCPCBannerInCreativeProps(creativeuid, cpcBannerSource)),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(EditCPCBannerModal);
