import firebase from "./../config/firebase";
const db = firebase.database();

const loadJynxVersions = () => {
  return new Promise((resolve) => {
    db.ref("/jynx_versions").once("value", (querySnapShot) => {
      const response = {};
      if (!querySnapShot.err) {
        for (let [key, value] of Object.entries(querySnapShot.val())) {
          response[value.version] = value.url;
        }
        response["2.2.1"] = "https://assets.xpsads.com/jynx/v2.2.1.js";
        response["2.2.3"] = "https://assets.xpsads.com/jynx/v2.2.3.js";
        resolve(response);
      } else {
        resolve(response);
      }
    });
  });
};

export default {
  loadJynxVersions,
};
