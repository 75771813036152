import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { compose } from "redux";
import { Button, Modal } from "react-bootstrap";
import { closeEditBinGameObjectBehavoirModal } from "../../store/actions/gameObjects";
import { updateSelectedTrueCreativePropsFunc } from "../../store/actions/truengageCreatives";
import * as R from "ramda";
import { ReactComponent as EditIcon } from "../../images/edit.svg";
import { ReactComponent as DeleteIcon } from "../../images/trash.svg";
import {
  getTweenType,
  setInvisibleTween,
  setMoveTween,
  setResizeTween,
} from "./../../utils/appUtils";
import validate from "../../utils/binBehavior/validateBinBehavior";
import { confirmAlert } from "react-confirm-alert"; // Import
import BinBehaviorForm from "../../utils/binBehavior/binBehaviorForm";
import ReactTooltip from "react-tooltip";
import horizontalX from "../../images/gif/pathX.gif";
import verticalY from "../../images/gif/pathY.gif";

import tweenX from "../../images/gif/tweenResizeX.gif";
import tweenY from "../../images/gif/tweenResizeY.gif";
import infoIcon from "../../images/info-logo.svg";
import Loader from "./../../pages/loader";
import { updateLoaderState } from "../../store/actions/gameObjects";

const EditBinBehaviorActionModal = ({
  isShowEditBinBehaviorActionModal,
  sceneIndex,
  selectedLayers,
  sourceCode,
  gameObjectkey,
  creativeUid,
  actionArray,
  actionIndex,
  isLoaderEnabled,
}) => {
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const [isAddingTransition, setIsAddingTransition] = useState(false);
  const [actionType, setActionType] = useState("advanceGame");
  const [behaviorType, setBehaviorType] = useState("tween");
  const [tweenType, setTweenType] = useState("invisible");
  const [isPathTransitionEdit, setIsPathTransitionEdit] = useState({
    status: false,
    index: -1,
  });
  const [advanceGameAction, setAdvanceGameAction] = useState({
    type: "advanceGame",
    delay: "",
  });
  const [errors, setErrors] = useState({});
  const [removeObjectAction, setRemoveObjectAction] = useState({
    type: "removeObject",
    layer: "",
    target: "",
  });

  const [replaceObjectAction, setReplaceObjectAction] = useState({
    type: "replaceObject",
    layer: "",
    target: "",
    replacement: "",
  });

  const [tweenInvisibleBehavior, setTweenInvisibleBehavior] = useState({
    type: "addBehavior",
    target: "",
    loop: false,
    delay: "",
  });

  const [tweenMoveBehavior, setTweenMoveBehavior] = useState({
    type: "addBehavior",
    target: "",
    loop: false,
    moveDirection: "x",
    position: "",
    delay: "",
  });

  const [addPathBehavior, setAddPathBehavior] = useState({
    type: "addBehavior",
    target: "",
    behaviors: {},
  });

  const [pathBehavior, setPathBehavior] = useState({
    loop: true,
    start: "",
    transitions: [],
  });

  const [pathStart, setPathStart] = useState({
    x: "",
    y: "",
  });

  const [pathTransitions, setPathTransitions] = useState({
    x: "",
    y: "",
    duration: "",
  });

  useEffect(() => {
    if (actionArray && actionIndex >= 0) {
      if (actionArray[actionIndex] && actionArray[actionIndex].type) {
        setActionType(actionArray[actionIndex].type);
        if (actionArray[actionIndex].type === "advanceGame") {
          const tempAdvanceGameAction = actionArray[actionIndex];
          setAdvanceGameAction({ ...tempAdvanceGameAction });
        } else if (actionArray[actionIndex].type === "removeObject") {
          const tempRemoveObjectAction = actionArray[actionIndex];
          setRemoveObjectAction({ ...tempRemoveObjectAction });
        } else if (actionArray[actionIndex].type === "replaceObject") {
          const tempReplaceObjectAction = actionArray[actionIndex];
          setReplaceObjectAction({ ...tempReplaceObjectAction });
        } else {
          if (
            actionArray &&
            actionArray[actionIndex] &&
            actionArray[actionIndex].behaviors &&
            actionArray[actionIndex].behaviors.tween
          ) {
            var type = getTweenType(actionArray[actionIndex]);
            setTweenType(type);
            var tween = actionArray[actionIndex].behaviors.tween;
            if (tween) {
              if (type === "invisible") {
                setTweenInvisibleBehavior({
                  target: actionArray[actionIndex].target,
                  loop: tween.loop,
                  delay:
                    tween.transitions && tween.transitions[0] && tween.transitions[0][2]
                      ? tween.transitions[0][2]
                      : "",
                });
              } else if (type === "move") {
                setTweenMoveBehavior({
                  target: actionArray[actionIndex].target,
                  loop: tween.loop,
                  delay:
                    tween.transitions && tween.transitions[0] && tween.transitions[0][2]
                      ? tween.transitions[0][2]
                      : "",
                  moveDirection:
                    tween.transitions &&
                    tween.transitions[0] &&
                    tween.transitions[0][1] &&
                    tween.transitions[0][1].x
                      ? "x"
                      : "y",
                  position:
                    tween.transitions &&
                    tween.transitions[0] &&
                    tween.transitions[0][1] &&
                    (tween.transitions[0][1].x || tween.transitions[0][1].y),
                });
              } else {
                console.log("somthing went wrong");
              }
            }
          } else if (
            actionArray &&
            actionArray[actionIndex] &&
            actionArray[actionIndex].behaviors &&
            actionArray[actionIndex].behaviors.path
          ) {
            setBehaviorType("path");
            var path = actionArray[actionIndex].behaviors.path;
            setAddPathBehavior({
              ...addPathBehavior,
              target: actionArray[actionIndex].target,
              type: "addBehavior",
            });
            if (path.start) {
              setPathStart(path.start);
            }
            if (path.transitions) {
              setPathBehavior({
                ...pathBehavior,
                transitions: path.transitions,
              });
            }
          }
        }
      }
      setIsEdit(true);
    } else {
      setDefaultStateValue();
    }
    return () => {
      console.log("Unmount Edit Behavior Action Modal");
    };
  }, [actionArray]);

  const setDefaultStateValue = () => {
    setIsAddingTransition(false);
    setActionType("advanceGame");
    setBehaviorType("tween");
    setTweenType("invisible");
    setIsPathTransitionEdit({ status: false, index: -1 });
    setAdvanceGameAction({ type: "advanceGame", delay: "" });
    setErrors({});
    setRemoveObjectAction({
      type: "removeObject",
      layer: "",
      target: "",
    });

    setReplaceObjectAction({
      type: "replaceObject",
      layer: "",
      target: "",
      replacement: "",
    });

    setTweenInvisibleBehavior({
      type: "addBehavior",
      target: "",
      loop: false,
      delay: "",
    });

    setTweenMoveBehavior({
      type: "addBehavior",
      target: "",
      loop: false,
      moveDirection: "x",
      position: "",
      delay: "",
    });

    setAddPathBehavior({
      type: "addBehavior",
      target: "",
      behaviors: {},
    });

    setPathBehavior({
      loop: true,
      start: "",
      transitions: [],
    });

    setPathStart({
      x: "",
      y: "",
    });

    setPathTransitions({
      x: "",
      y: "",
      duration: "",
    });
    setIsEdit(false);
    dispatch(updateLoaderState(false));
  };

  const gameObjectOptions = selectedLayers.map(
    (layer) =>
      layer.objects &&
      layer.objects.map((object) => (
        <option key={layer.name + "=>" + object} value={layer.name + "=>" + object}>
          {layer.name + "=>" + object}
        </option>
      ))
  );

  const targetObjectOptions = selectedLayers.map(
    (layer) =>
      layer.objects &&
      layer.objects.map((object) => (
        <option key={object} value={object}>
          {object}
        </option>
      ))
  );

  const savePathTransitions = (e) => {
    dispatch(updateLoaderState(true));
    var tempPathBehavior = pathBehavior;
    if (isPathTransitionEdit.status && isPathTransitionEdit.index > -1) {
      tempPathBehavior["transitions"][isPathTransitionEdit.index] = pathTransitions;
    } else {
      tempPathBehavior["transitions"].push(pathTransitions);
    }

    setPathBehavior({ ...tempPathBehavior });
    setPathTransitions({ x: "", y: "", duration: "" });
    setIsAddingTransition(false);
    setIsPathTransitionEdit({ status: false, index: -1 });
    dispatch(updateLoaderState(false));
  };

  const deletePathTransitions = (e, idx) => {
    confirmAlert({
      title: "Confirm to delete",
      message: `Are you sure to delete`,
      buttons: [
        {
          label: "Yes",
          onClick: () => deletePathTransition(e, idx),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const deletePathTransition = (e, idx) => {
    var tempPathBehavior = pathBehavior;
    tempPathBehavior.transitions.splice(idx, 1);
    setPathBehavior({ ...tempPathBehavior });
    setPathTransitions({ scale: "", position: "", delay: "" });
    setIsPathTransitionEdit({ status: false, index: -1 });
    setIsAddingTransition(false);
  };

  /**
   * Event transition event
   * @param {Event} e
   * @param {Transition Index} idx
   */
  const editPathTransitions = (e, idx) => {
    var tempPathBehavior = pathBehavior;
    setPathTransitions({ ...tempPathBehavior.transitions[idx] });
    setIsPathTransitionEdit({ status: true, index: idx });
    setIsAddingTransition(true);
  };

  /**
   * to save behavior in DB and update Redux states
   * @param {Submit Button click Event} e
   */
  const handleSaveAction = (e) => {
    dispatch(updateLoaderState(true));
    e.persist();
    setErrors({});
    // to validate form submit before saving it to DB
    var errors = validate(
      actionType,
      advanceGameAction,
      removeObjectAction,
      behaviorType,
      tweenType,
      tweenInvisibleBehavior,
      tweenMoveBehavior,
      replaceObjectAction,
      pathBehavior,
      pathStart
    );
    setErrors(errors);
    var response = true;
    if (errors && Object.keys(errors).length > 0) {
      response = false;
    }
    if (response) {
      var selectedScene = R.path(["scenes"], sourceCode)[sceneIndex];
      var objects = {};
      if (R.path(["objects"], selectedScene)) {
        objects = R.path(["objects"], selectedScene);
      }
      if (objects && objects[gameObjectkey]) {
        var currentObject = objects[gameObjectkey];

        if (
          currentObject &&
          currentObject.behaviors &&
          currentObject.behaviors.bin &&
          currentObject.behaviors.bin.actions &&
          currentObject.behaviors.bin.actions.full
        ) {
          if (isEdit) {
            // to update existing action in full array for removeObject Or advanceGame
            if (
              actionType === "removeObject" ||
              actionType === "advanceGame" ||
              actionType === "replaceObject"
            ) {
              currentObject.behaviors.bin.actions.full[actionIndex] =
                actionType === "removeObject"
                  ? removeObjectAction
                  : actionType === "replaceObject"
                  ? replaceObjectAction
                  : advanceGameAction;
            } else {
              // to update existing action in full array for adding new Behavior
              var newBehavior = {};
              if (behaviorType === "tween") {
                if (tweenType === "invisible") {
                  newBehavior = setInvisibleTween(tweenInvisibleBehavior);
                } else if (tweenType === "move") {
                  newBehavior = setMoveTween(tweenMoveBehavior);
                } else {
                  console.log("Something went wrong!");
                }
              } else {
                var editPathBehavior = {};
                editPathBehavior["path"] = pathBehavior;
                addPathBehavior.behaviors = editPathBehavior;
                newBehavior = addPathBehavior;
              }
              currentObject.behaviors.bin.actions.full[actionIndex] = newBehavior;
            }
          } else {
            // to add new action in full Array for removeObject Or advanceGame
            if (
              actionType === "removeObject" ||
              actionType === "advanceGame" ||
              actionType === "replaceObject"
            ) {
              currentObject.behaviors.bin.actions.full.push(
                actionType === "removeObject"
                  ? removeObjectAction
                  : actionType === "replaceObject"
                  ? replaceObjectAction
                  : advanceGameAction
              );
            } else {
              //to add new action in full Array for adding new Behavior
              var newBehavior = {};
              if (behaviorType === "tween") {
                if (tweenType === "invisible") {
                  newBehavior = setInvisibleTween(tweenInvisibleBehavior);
                } else if (tweenType === "move") {
                  newBehavior = setMoveTween(tweenMoveBehavior);
                } else {
                  console.log("Something went wrong!");
                }
              } else {
                var editPathBehavior = {};
                editPathBehavior["path"] = pathBehavior;
                addPathBehavior.behaviors = editPathBehavior;
                newBehavior = addPathBehavior;
              }
              currentObject.behaviors.bin.actions.full.push(newBehavior);
            }
          }
        }
      }
      dispatch(updateSelectedTrueCreativePropsFunc(creativeUid, sourceCode, true));
      dispatch(updateLoaderState(false));
      handleClose();
    }
  };

  /**
   * to close current Modal
   * @param {Event} e
   */
  const handleClose = async (e) => {
    await dispatch(updateLoaderState(false));
    await dispatch(closeEditBinGameObjectBehavoirModal());
    setErrors({});
    setDefaultStateValue();
  };

  const [
    handleChangeActionType,
    handleCancel,
    handleChangeBehaviorType,
    handleChangeAction,
    handlePathBehaviorChange,
    handleTweenInvisibleChange,
    handleTweenMoveChange,
  ] = BinBehaviorForm(
    setActionType,
    setPathTransitions,
    setIsPathTransitionEdit,
    setIsAddingTransition,
    setTweenType,
    setBehaviorType,
    setAdvanceGameAction,
    setRemoveObjectAction,
    setReplaceObjectAction,
    setPathStart,
    setAddPathBehavior,
    setPathBehavior,
    setTweenInvisibleBehavior,
    setTweenMoveBehavior,
    advanceGameAction,
    removeObjectAction,
    replaceObjectAction,
    pathBehavior,
    addPathBehavior,
    pathStart,
    pathTransitions,
    sourceCode,
    sceneIndex,
    tweenInvisibleBehavior,
    tweenMoveBehavior
  );

  return (
    <Modal show={isShowEditBinBehaviorActionModal} onHide={handleClose}>
      {isLoaderEnabled ? <Loader /> : ""}
      <Modal.Header closeButton>
        <Modal.Title>
          {!isEdit ? "Add Bin Behavior Action" : "Edit Bin Behavior action "}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
          <div className="form-group">
            <label>Action Type</label>
            <div className="input-control">
              <span className="custom-control custom-radio custom-control-inline">
                <input
                  type="radio"
                  disabled={isEdit}
                  value={"advanceGame"}
                  checked={actionType === "advanceGame" ? true : false}
                  onChange={(evt) => {
                    handleChangeActionType(evt);
                    setErrors({});
                    setDefaultStateValue();
                    setActionType("advanceGame");
                  }}
                  name="actionType"
                  className="custom-control-input"
                  id="advance-game"
                />
                <label className="custom-control-label" htmlFor="advance-game">
                  {"Advance Game"}
                </label>
              </span>

              <span className="custom-control custom-radio custom-control-inline">
                <input
                  type="radio"
                  disabled={isEdit}
                  value={"removeObject"}
                  name="actionType"
                  checked={actionType === "removeObject" ? true : false}
                  onChange={(evt) => {
                    handleChangeActionType(evt);
                    setErrors({});
                    setDefaultStateValue();
                    setActionType("removeObject");
                  }}
                  className="custom-control-input"
                  id="remove-object"
                />
                <label className="custom-control-label" htmlFor="remove-object">
                  {"Remove Object"}
                </label>

                <span className="form-group info-icon" data-tip data-for="removeObjectTip">
                  <img src={infoIcon} width="15px" height="15px" alt="" />
                </span>
                <ReactTooltip id="removeObjectTip" place="right" effect="solid">
                  <span class="tooltip-text">Will Remove the Target Object</span>
                </ReactTooltip>
              </span>

              <span className="custom-control custom-radio custom-control-inline">
                <input
                  type="radio"
                  disabled={isEdit}
                  value={"replaceObject"}
                  name="actionType"
                  checked={actionType === "replaceObject" ? true : false}
                  onChange={(evt) => {
                    handleChangeActionType(evt);
                    setErrors({});
                    setDefaultStateValue();
                    setActionType("replaceObject");
                  }}
                  className="custom-control-input"
                  id="replace-object"
                />
                <label className="custom-control-label" htmlFor="replace-object">
                  {"Replace Object"}
                </label>
                <span className="form-group info-icon" data-tip data-for="replaceObjectTip">
                  <img src={infoIcon} width="15px" height="15px" alt=""  />
                </span>
                <ReactTooltip id="replaceObjectTip" place="right" effect="solid">
                  <span class="tooltip-text">
                    Will Replace the Targeted Object to Replacement Object
                  </span>
                </ReactTooltip>
              </span>

              <span className="custom-control custom-radio custom-control-inline">
                <input
                  type="radio"
                  disabled={isEdit}
                  value={"addBehavior"}
                  name="actionType"
                  checked={actionType === "addBehavior" ? true : false}
                  onChange={(evt) => {
                    handleChangeActionType(evt);
                    setErrors({});
                    setDefaultStateValue();
                    setActionType("addBehavior");
                  }}
                  className="custom-control-input"
                  id="add-behaviour"
                />
                <label className="custom-control-label" htmlFor="add-behaviour">
                  {"Add Behaviour"}
                </label>
              </span>
            </div>
          </div>

          {actionType === "replaceObject" ? (
            <>
              <div className="form-group">
                <label>Target Object</label>
                <select
                  class="custom-select"
                  name="targetObject"
                  value={replaceObjectAction.layer + "=>" + replaceObjectAction.target}
                  onChange={(e) => handleChangeAction("replaceObject", e)}
                >
                  <option key={""} value={""}>
                    {"Select Target Object"}
                  </option>
                  {gameObjectOptions}
                </select>
                {errors && errors.targetIsEmpty && <small>{errors.targetIsEmpty}</small>}
              </div>
              <div className="form-group">
                <label>Replacement Object</label>
                <select
                  class="custom-select"
                  name="replacementObject"
                  value={replaceObjectAction.replacement}
                  onChange={(e) => handleChangeAction("replaceObject", e)}
                >
                  <option key={""} value={""}>
                    {"Select Target Object"}
                  </option>
                  {targetObjectOptions}
                </select>
                {errors && errors.replacementIsEmpty && <small>{errors.replacementIsEmpty}</small>}
              </div>
            </>
          ) : (
            <>
              {actionType === "removeObject" ? (
                <div className="form-group">
                  <label>Object</label>
                  <select
                    class="custom-select"
                    name="targetObject"
                    value={removeObjectAction.layer + "=>" + removeObjectAction.target}
                    onChange={(e) => handleChangeAction("removeObject", e)}
                  >
                    <option key={""} value={""}>
                      {"Select Target Object"}
                    </option>
                    {gameObjectOptions}
                  </select>

                  {errors && errors.objectIsEmpty && <small>{errors.objectIsEmpty}</small>}
                </div>
              ) : (
                <>
                  {actionType === "advanceGame" ? (
                    <div className="form-group">
                      <div>
                        <label>Delay(in milliseconds)</label>
                        <div className="sidebar-input">
                          <input
                            type="number"
                            pattern="[0-9]*"
                            inputMode="numeric"
                            name="delay"
                            placeholder={"Delay(in milliseconds)"}
                            value={advanceGameAction.delay}
                            onChange={(e) => handleChangeAction("advanceGame", e)}
                          />
                        </div>
                        {errors && errors.delayIsEmpty && <small>{errors.delayIsEmpty}</small>}
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="form-group">
                        <label>Tween Behavior</label>
                        <div className="input-control">
                          <span className="custom-control custom-radio custom-control-inline">
                            <input
                              type="radio"
                              disabled={isEdit}
                              value={"tween"}
                              checked={behaviorType === "tween" ? true : false}
                              onChange={(evt) => {
                                handleChangeBehaviorType(evt);
                                setErrors({});
                                setDefaultStateValue();
                                setActionType("addBehavior");
                                setBehaviorType("tween");
                              }}
                              name="behaviorType"
                              className="custom-control-input"
                              id="tween-behavior-type"
                            />
                            <label className="custom-control-label" htmlFor="tween-behavior-type">
                              {"Tween"}
                            </label>
                            <span className="form-group info-icon" data-tip data-for="tweenBoth">
                              <img src={infoIcon} width="15px" height="15px" alt=""  />
                            </span>
                            <ReactTooltip id="tweenBoth" place="right" effect="solid">
                              <img src={tweenX} width="426px" height="240px" alt=""  />
                              <img src={tweenY} width="426px" height="240px" alt=""  />
                            </ReactTooltip>
                          </span>

                          <span className="custom-control custom-radio custom-control-inline">
                            <input
                              type="radio"
                              disabled={isEdit}
                              value={"path"}
                              name="behaviorType"
                              checked={behaviorType === "path" ? true : false}
                              onChange={(evt) => {
                                handleChangeBehaviorType(evt);
                                setErrors({});
                                setDefaultStateValue();
                                setActionType("addBehavior");
                                setBehaviorType("path");
                              }}
                              className="custom-control-input"
                              id="path-behavior-type"
                            />
                            <label className="custom-control-label" htmlFor="path-behavior-type">
                              {"Path"}
                            </label>
                            <span
                              className="form-group info-icon"
                              data-tip
                              data-for="pathBehaviour"
                            >
                              <img src={infoIcon} width="15px" height="15px" alt=""  />
                            </span>
                            <ReactTooltip id="pathBehaviour" place="right" effect="solid">
                              <img src={horizontalX} width="426px" height="240px" alt=""  />
                              <img src={verticalY} width="426px" height="240px" alt=""  />
                            </ReactTooltip>
                          </span>
                        </div>
                      </div>

                      {behaviorType === "tween" ? (
                        <>
                          <div className="form-group">
                            <label>Tween Type</label>
                            <div className="input-control">
                              <span className="custom-control custom-radio custom-control-inline">
                                <input
                                  type="radio"
                                  disabled={isEdit}
                                  value={"invisible"}
                                  name="tweenType"
                                  checked={tweenType === "invisible" ? true : false}
                                  onChange={(evt) => {
                                    handleChangeBehaviorType(evt);
                                    setErrors({});
                                    setDefaultStateValue();
                                    setActionType("addBehavior");
                                    setBehaviorType("tween");
                                    setTweenType("invisible");
                                  }}
                                  className="custom-control-input"
                                  id="tween-invisible-type"
                                />
                                <label className="custom-control-label" htmlFor="tween-invisible-type">
                                  {"Remove Target Object"}
                                </label>
                              </span>

                              <span className="custom-control custom-radio custom-control-inline">
                                <input
                                  type="radio"
                                  disabled={isEdit}
                                  value={"move"}
                                  name="tweenType"
                                  checked={tweenType === "move" ? true : false}
                                  onChange={(evt) => {
                                    handleChangeBehaviorType(evt);
                                    setErrors({});
                                    setDefaultStateValue();
                                    setActionType("addBehavior");
                                    setBehaviorType("tween");
                                    setTweenType("move");
                                  }}
                                  className="custom-control-input"
                                  id="tween-move-type"
                                />
                                <label className="custom-control-label" htmlFor="tween-move-type">
                                  {"Move Target Object"}
                                </label>
                              </span>
                            </div>
                          </div>

                          {tweenType === "invisible" ? (
                            <>
                              <div className="form-group">
                                <label>Target Object</label>
                                <select
                                  className="custom-select"
                                  name="target"
                                  value={tweenInvisibleBehavior.target}
                                  onChange={(e) => handleTweenInvisibleChange("targetObject", e)}
                                >
                                  <option key={""} value={""}>
                                    {"Select Target Object"}
                                  </option>
                                  {targetObjectOptions}
                                </select>
                                {errors && errors.tweenTargetIsEmpty && (
                                  <small>{errors.tweenTargetIsEmpty}</small>
                                )}
                              </div>

                              <div className="form-group">
                                <label>Delay(in milliseconds)</label>
                                <div className="sidebar-input">
                                  <input
                                    type="number"
                                    pattern="[0-9]*"
                                    inputMode="numeric"
                                    name="delay"
                                    placeholder={"Delay Duration(in milliseconds)"}
                                    value={tweenInvisibleBehavior.delay}
                                    onChange={(e) => handleTweenInvisibleChange("delay", e)}
                                  />
                                  {errors && errors.tweenDelayIsEmpty && (
                                    <small>{errors.tweenDelayIsEmpty}</small>
                                  )}
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="form-group">
                                <label>Target Object</label>
                                <select
                                  className="custom-select"
                                  name="target"
                                  value={tweenMoveBehavior.target}
                                  onChange={(e) => handleTweenMoveChange("targetObject", e)}
                                >
                                  <option key={""} value={""}>
                                    {"Select Target Object"}
                                  </option>
                                  {targetObjectOptions}
                                </select>
                                {errors && errors.tweenMoveTargetIsEmpty && (
                                  <small>{errors.tweenMoveTargetIsEmpty}</small>
                                )}
                              </div>

                              <div className="form-group">
                                <label>Delay(in milliseconds)</label>
                                <div className="sidebar-input">
                                  <input
                                    type="number"
                                    pattern="[0-9]*"
                                    inputMode="numeric"
                                    name="delay"
                                    placeholder={"Delay(in milliseconds)"}
                                    value={tweenMoveBehavior.delay}
                                    onChange={(e) => handleTweenMoveChange("delay", e)}
                                  />
                                </div>
                                {errors && errors.tweenMoveDelayIsEmpty && (
                                  <small>{errors.tweenMoveDelayIsEmpty}</small>
                                )}
                              </div>
                              <div className="form-group">
                                <label>Move Direction</label>
                                <div className="input-control">
                                  <span className="custom-control custom-radio custom-control-inline">
                                    <input
                                      type="radio"
                                      disabled={isEdit}
                                      value={"x"}
                                      name="moveDirection"
                                      checked={
                                        tweenMoveBehavior.moveDirection === "x" ? true : false
                                      }
                                      onChange={(e) => handleTweenMoveChange("moveDirection", e)}
                                      className="custom-control-input"
                                      id="position-horizontal-type"
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor="position-horizontal-type"
                                    >
                                      {"Horizontal"}
                                    </label>
                                  </span>

                                  <span className="custom-control custom-radio custom-control-inline">
                                    <input
                                      type="radio"
                                      disabled={isEdit}
                                      value={"y"}
                                      checked={
                                        tweenMoveBehavior.moveDirection === "y" ? true : false
                                      }
                                      onChange={(e) => handleTweenMoveChange("moveDirection", e)}
                                      name="moveDirection"
                                      className="custom-control-input"
                                      id="position-veritcal-type"
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor="position-veritcal-type"
                                    >
                                      {"Vertical"}
                                    </label>
                                  </span>
                                </div>
                              </div>
                              <div className="form-group">
                                <label>Destination</label>
                                <div className="sidebar-input">
                                  <input
                                    type="number"
                                    pattern="[0-9]*"
                                    inputMode="numeric"
                                    name="position"
                                    value={tweenMoveBehavior.position}
                                    onChange={(e) => handleTweenMoveChange("position", e)}
                                  />
                                </div>
                                {errors && errors.tweenMovePositionIsEmpty && (
                                  <small>{errors.tweenMovePositionIsEmpty}</small>
                                )}
                              </div>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <div className="form-group">
                            <label>Target Object</label>
                            <select
                              class="custom-select"
                              name="target"
                              value={addPathBehavior.target}
                              onChange={(e) => handlePathBehaviorChange(e, "target")}
                            >
                              <option key={""} value={""}>
                                {"Select Target Object"}
                              </option>
                              {targetObjectOptions}
                            </select>
                            {errors && errors.pathBehaviorIsEmpty && (
                              <small>{errors.pathBehaviorIsEmpty}</small>
                            )}
                          </div>
                          <div className="form-group">
                            <div className="form-group">
                              <label>Start</label>
                              <div className="row property-section">
                                <div className="col-sm-6">
                                  <label>Horizontal</label>
                                  <input
                                    type="number"
                                    pattern="[0-9]*"
                                    inputMode="numeric"
                                    name="x"
                                    value={pathStart.x}
                                    placeholder="Horizontal"
                                    onChange={(e) => handlePathBehaviorChange(e, "startPosition")}
                                  />
                                  {errors && errors.pathStartXIsEmpty && (
                                    <small>{errors.pathStartXIsEmpty}</small>
                                  )}
                                </div>
                                <div className="col-sm-6">
                                  <label>Vertical</label>
                                  <input
                                    type="number"
                                    pattern="[0-9]*"
                                    inputMode="numeric"
                                    name="y"
                                    value={pathStart.y}
                                    placeholder="Vertical"
                                    onChange={(e) => handlePathBehaviorChange(e, "startPosition")}
                                  />
                                  {errors && errors.pathStartYIsEmpty && (
                                    <small>{errors.pathStartYIsEmpty}</small>
                                  )}
                                </div>
                              </div>
                            </div>

                            {pathBehavior &&
                            pathBehavior.transitions &&
                            pathBehavior.transitions.length > 0 ? (
                              <div className="form-group">
                                <label>{"Existing Transition Properties"}</label>
                                <div className="form-group">
                                  {pathBehavior.transitions.map((transition, idx) => (
                                    <div className="row property-section">
                                      <div className="form-group col-md-3 col-sm-6">
                                        <label>{"Position: X"}</label>
                                        <input
                                          disabled={true}
                                          type="text"
                                          value={transition.x}
                                        ></input>
                                      </div>
                                      <div className="form-group col-md-3 col-sm-6">
                                        <label>{"Position: Y"}</label>
                                        <input
                                          disabled={true}
                                          type="text"
                                          value={transition.y}
                                        ></input>
                                      </div>
                                      <div className="form-group col-md-3 col-sm-6">
                                        <label>{"Delay(in milliseconds)"}</label>
                                        <input
                                          disabled={true}
                                          type="text"
                                          value={transition.duration}
                                        ></input>
                                      </div>
                                      <div className="form-group col-md-2 col-sm-12">
                                        <span
                                          className="btn btn-default obj-action-btn text-edit"
                                          onClick={(evt) => editPathTransitions(evt, idx)}
                                        >
                                          <EditIcon />
                                        </span>
                                        <span
                                          className="btn btn-default btn-delete obj-action-btn text-delete"
                                          onClick={(evt) => deletePathTransitions(evt, idx)}
                                        >
                                          <DeleteIcon />
                                        </span>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            ) : (
                              ""
                            )}

                            <div className="form-group">
                              <small style={{ color: "green" }}> Needs to add 2 Transtions</small>

                              <div
                                className="form-group"
                                hidden={isAddingTransition || pathBehavior.transitions.length === 2}
                              >
                                <div className="input-control row">
                                  <button
                                    className="btn btn-primary btn-switch"
                                    onClick={(evt) => setIsAddingTransition(true)}
                                  >
                                    Add New Transition
                                  </button>
                                </div>
                              </div>
                              {isAddingTransition ? (
                                <div className="form-group">
                                  <label>Transition Properties</label>
                                  <div className="form-group">
                                    <label>Position</label>
                                    <div className="row property-section">
                                      <div className="col-sm-6">
                                        <label>Horizontal</label>
                                        <input
                                          type="number"
                                          pattern="[0-9]*"
                                          inputMode="numeric"
                                          name="x"
                                          value={pathTransitions.x}
                                          placeholder="Horizontal"
                                          onChange={(e) =>
                                            handlePathBehaviorChange(e, "transitions")
                                          }
                                        />
                                      </div>
                                      <div className="col-sm-6">
                                        <label>Vertical</label>
                                        <input
                                          type="number"
                                          pattern="[0-9]*"
                                          inputMode="numeric"
                                          name="y"
                                          value={pathTransitions.y}
                                          placeholder="Vertical"
                                          onChange={(e) =>
                                            handlePathBehaviorChange(e, "transitions")
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="form-group">
                                    <label>Duration</label>
                                    <input
                                      type="number"
                                      pattern="[0-9]*"
                                      inputMode="numeric"
                                      name="duration"
                                      value={pathTransitions.duration}
                                      placeholder="Duration(in milliseconds)"
                                      onChange={(e) => handlePathBehaviorChange(e, "transitions")}
                                    />
                                  </div>
                                  <div className="form-group">
                                    <div className="input-control row">
                                      <button
                                        disabled={
                                          !pathTransitions.x ||
                                          !pathTransitions.y ||
                                          !pathTransitions.duration
                                        }
                                        className="btn btn-primary btn-switch"
                                        onClick={(evt) => savePathTransitions(evt)}
                                      >
                                        {isPathTransitionEdit.status &&
                                        isPathTransitionEdit.index > -1
                                          ? "Update Transition"
                                          : "Save Transition"}
                                      </button>
                                      <button
                                        className="btn btn-primary btn-switch"
                                        onClick={(evt) => handleCancel(evt)}
                                      >
                                        Cancel
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      </Modal.Body>
      <Modal.Footer>
        {behaviorType === "path" ? (
          <>
            <Button
              variant="primary"
              disabled={pathBehavior.transitions && pathBehavior.transitions.length < 2}
              onClick={handleSaveAction}
            >
              Save Changes
            </Button>
          </>
        ) : (
          <Button variant="primary" onClick={handleSaveAction}>
            Save Changes
          </Button>
        )}

        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

function mapStateToProps(state) {
  return {
    isShowEditBinBehaviorActionModal: state.gameObject.isShowEditBinBehaviorActionModal,
    gameObjectkey: state.gameObject.gameObjectKey,
    creativeUid: state.gameObject.creativeUid,
    actionArray: state.gameObject.actionArray,
    actionIndex: state.gameObject.actionIndex,
    isLoaderEnabled: state.gameObject.isLoaderEnabled,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeEditBinGameObjectBehavoirModal: () => dispatch(closeEditBinGameObjectBehavoirModal()),
    updateSelectedTrueCreativePropsFunc: (creativeUid, sourceCode, behaviorBool) =>
      dispatch(updateSelectedTrueCreativePropsFunc(creativeUid, sourceCode, behaviorBool)),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(EditBinBehaviorActionModal);
