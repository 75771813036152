import { Types } from "../constants/campaign-types";

const INITIAL_STATE = {
  xpsCampaigns: {},
  selectedCampaign:undefined,
};

export default function campaign(state = INITIAL_STATE, action) {
  if (action.type === Types.GET_ALL_XPS_CAMPAIGNS_ACCOUNT_ID) {
    return { ...state, xpsCampaigns: action.payload };
  } else if (action.type === Types.ERROR_IN_LOADING_CAMPAIGNS) {
    return { ...state, campaignMsg: action.payload };
  } else if (action.type === Types.GET_CURRENT_CAMPAIGN) {
    return { ...state, selectedCampaign: action.payload };
  } else if (action.type === Types.CLEAR_CURRENT_CAMPAIGN) {
    return { ...state, xpsCampaigns: undefined };
  } else {
    return state;
  }
}
