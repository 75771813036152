import { Types } from "./../constants/auth-types";

const INITIAL_STATE = {
  authMsg: "",
  isLoggingIn: false,
  isLoggingOut: false,
  isVerifying: false,
  loginError: false,
  logoutError: false,
  isAuthenticated: false,
  user: {},
  isAdminUser: false
};

export default function auth(state = INITIAL_STATE, action) {
  if (action.type === Types.LOGIN_REQUEST) {
    return { ...state, authMsg: "", isLoggingIn: true, loginError: false };
  } else if (action.type === Types.LOGIN_SUCCESS) {
    return { ...state, authMsg: "", isLoggingIn: false, isAuthenticated: true, user: action.payload.user };
  } else if (action.type === Types.LOGIN_FAILURE) {
    return {
      ...state,
      authMsg: action.payload,
      isLoggingIn: false,
      isAuthenticated: false,
      loginError: true,
    };
  } else if (action.type === Types.LOGOUT_REQUEST) {
    return { ...state, authMsg: "", isLoggingOut: true, logoutError: false };
  } else if (action.type === Types.LOGOUT_SUCCESS) {
    return { ...state, authMsg: "", isLoggingOut: false, isAuthenticated: false, user: {} };
  } else if (action.type === Types.LOGOUT_FAILURE) {
    return { ...state, authMsg: action.payload, isLoggingOut: false, logoutError: true };
  } else if (action.type === Types.VERIFY_REQUEST) {
    return { ...state, isVerifying: true, verifyingError: false };
  } else if (action.type === Types.VERIFY_SUCCESS) {
    return { ...state, isVerifying: false };
  } else {
    return state;
  }
}

