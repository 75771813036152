import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Loader from "./loader";
import requireAuth from "./hoc/requireAuth";
import { compose } from "redux";
import EditGameObjectModal from "../components/modals/editGameObjectModal";
import {
  getTruengageCreativePropsByID,
  clearCreativeState,
  loadJynxVersions,
} from "../store/actions/truengageCreatives";

import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import EditCreativeSidebar from "../components/editCreativeSidebar";

import TruengagePreview from "./truengagePreview";
import { loadImageResourcesByAccountId } from "../store/actions/image-resouces";
import { cancelEditingOfObject } from "../store/actions/gameObjects";
const EditTruengageCreative = ({
  dragableModel,
  sourceCode,
  creativeName,
  creativeAccountId,
  gameObjectArray,
  cancelEdit,
  jynxVersions,
  loadJynxData,
  truengageCreativeType,
  location: { search },
}) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [versionURL, setVersionURL] = useState(null);
  let query = useQuery();

  function useQuery() {
    return new URLSearchParams(search);
  }

  useEffect(() => {
    (async () => {
      // remove cpc Banner if already exists over Ad Preview
      var cpcBannerDiv = document.getElementById("pkmediaCPCBannerSeqId");
      if (cpcBannerDiv) {
        cpcBannerDiv.remove();
      }

      await dispatch(getTruengageCreativePropsByID(id));
      await dispatch(loadImageResourcesByAccountId(creativeAccountId));
      await loadJynxData();
    })();
    return () => {
      dispatch(clearCreativeState());
      // remove cpc Banner on Unmount component
      var cpcBannerDiv = document.getElementById("pkmediaCPCBannerSeqId");
      if (cpcBannerDiv) {
        cpcBannerDiv.remove();
      }
    };
  }, []);

  useEffect(() => {
    (async () => {
      if (cancelEdit) {
        await dispatch(getTruengageCreativePropsByID(id));
        dispatch(cancelEditingOfObject());
      }
    })();
  }, [cancelEdit]);

  useEffect(() => {
    if (jynxVersions) {
      let found = false;
      Object.keys(jynxVersions).forEach((item, idx) => {
        if (item === query.get("version")) {
          setVersionURL(jynxVersions[item]);
          found = true;
        }
      });
      if (!found) {
        setVersionURL("https://assets.xpsads.com/jynx/v2.2.1.js");
      }
    }
  }, [jynxVersions, sourceCode]);

  return (
    <div>
      {dragableModel ? (
        <div className="page-sidebar">
          <div className="ad-content">
            <h2>Ad Preview</h2>
            <TruengagePreview
              sourceCode={sourceCode}
              creativeName={creativeName}
              width={dragableModel.width}
              height={dragableModel.height}
              versionURL={versionURL}
              truengageCreativeType={truengageCreativeType}
            />
          </div>
          <EditCreativeSidebar
            dragableModel={dragableModel}
            creativeName={creativeName}
            creativeId={id}
          />
        </div>
      ) : (
        <Loader />
      )}

      <EditGameObjectModal
        creativeAccountId={creativeAccountId}
        gameObjectArray={gameObjectArray}
      />
    </div>
  );
};

function mapStateToProps(state) {
  return {
    dragableModel: state.truengageCreative.dragableModel,
    sourceCode: state.truengageCreative.sourceCode,
    creativeName: state.truengageCreative.creativeName,
    creativeAccountId: state.truengageCreative.creativeAccountId,
    gameObjectArray: state.truengageCreative.gameObjectArray,
    cancelEdit: state.gameObject.cancelEdit,
    jynxVersions: state.truengageCreative.jynxVersions,
    truengageCreativeType: state.truengageCreative.truengageCreativeType,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getTruengageCreativePropsByID: (id) => dispatch(getTruengageCreativePropsByID(id)),
    loadImageResourcesByAccountId: (id) => dispatch(loadImageResourcesByAccountId(id)), // call all images of account
    clearCreativeState: () => dispatch(clearCreativeState()),
    loadJynxData: () => dispatch(loadJynxVersions()),
  };
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  requireAuth
)(EditTruengageCreative);
