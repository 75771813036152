import { Types } from "./../constants/api-types";

const INITIAL_STATE = {
  apiCallsInProgress: 0,
};

function actionTypeEndsInSuccess(type) {
  return type.substring(type.length - 8) === "_SUCCESS";
}

export default function apiCallStatusReducer(state = INITIAL_STATE, action) {
  if (action.type === Types.BEGIN_API_CALL) {
    return { ...state, apiCallsInProgress: 1 };
  } else if (action.type === Types.API_CALL_ERROR) {
    return { ...state, apiCallsInProgress: 0 };
  } else if (actionTypeEndsInSuccess(action.type)) {
    return { ...state, apiCallsInProgress: 0 };
  } else if (action.type === Types.API_CALL_RESET) {
    return { ...state, apiCallsInProgress: 0 };
  } else {
    return state;
  }
}
