import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { compose } from "redux";
import {
  addNewImage,
  loadImageResourcesByAccountId,
  resetImageAddedResponse,
  addNewImageToAWS,
  resetImageAddedAWSResponse,
} from "../../store/actions/image-resouces";
import { updateLoaderState } from "./../../store/actions/gameObjects";
import ValidateContainer from "../../utils/containerObject/validateContainerObject";
import { SketchPicker } from "react-color";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import reactCSS from "reactcss";
import ContainerObjectForm from "../../utils/containerObject/containerObjectForm";
import {
  setColorPickerCSSStyle,
  fontSizeArray,
  setColorPickerCSS,
} from "./../../utils/appUtils";
import { defaultColor } from "./../../constants/defaultValues";
import { ReactComponent as EditIcon } from "../../images/edit.svg";
import { ReactComponent as DeleteIcon } from "../../images/trash.svg";
import { ReactComponent as ArrowIcon } from "../../images/down-arrow.svg";
import { confirmAlert } from "react-confirm-alert";
import { ReactComponent as ImageIcon } from "../../images/image.svg";
import { ReactComponent as TextIcon } from "../../images/text.svg";
import { ReactComponent as ShapeIcon } from "../../images/shapes.svg";
import { ReactComponent as ContainerIcon } from "../../images/container.svg";
import { ReactComponent as SpriteIcon } from "../../images/spritesheet-icon.svg";

const ContainerGameObject = ({
  gameObject = {
    name: "",
    image: {},
    position: { x: "", y: "" },
    scale: { x: "", y: "" },
  },
  imageResources,
  setGameObject,
  addNewImageFunc,
  creativeAccountId,
  gameObjectArray,
  emptyContainerCheck,
  imageOperationResponse,
  auth,
  resetImageAddedResponse,
  loadImageResourcesByAccount,
  addImageToAWS,
  awsResponse,
  frames,
  setFrames,
  position,
  setPosition,
  scale,
  setScale,
  sourceCode,
  objType,
  sceneNo,
  creativeUid,
  isEdit,
  showContainerForm,
  setShowContainerForm,
}) => {
  const dispatch = useDispatch();
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [color, setColor] = useState(defaultColor);
  // const [showContainerForm, setShowContainerForm] = useState(false);
  const [shapeColor, setShapeColor] = useState("#FFFFFF");
  const [originalGameObject, setOriginalGameObject] = useState({});
  const [shapeDimension, setShapeDimension] = useState({
    offsetX: "",
    offsetY: "",
    width: "",
    height: "",
  });

  const [currentTextObj, setCurrentTextObj] = useState({
    color: { r: "241", g: "112", b: "19", a: "1" },
    font: 24,
  });

  let styles = reactCSS(setColorPickerCSSStyle(shapeColor));
  const textStyles = reactCSS(setColorPickerCSS(color));
  const [currentObject, setCurrentObject] = useState({
    name: "",
    position: { x: "", y: "" },
    scale: { x: "", y: "" },
  });
  const [objectIdx, setObjectIdx] = useState(-1);
  const sizeArray = fontSizeArray();
  const options = sizeArray.map((elem, idx) => (
    <option key={idx} value={elem}>
      {elem}
    </option>
  ));
  const [file, setFile] = useState(null);
  const fileRef = useRef();
  const [isObjectEdit, setIsObjectEdit] = useState(false);
  const [currentObjectType, setCurrentObjectType] = useState("Image");
  const [showTextForm, setShowTextForm] = useState(false);
  const [textError, setTextError] = useState({});
  const [handleFrameChange, handleContainerChange, handleShapeChange] = ContainerObjectForm(
    frames,
    setFrames,
    currentObject,
    setCurrentObject,
    shapeDimension,
    setShapeColor,
    setShapeDimension
  );
  const [validateInnerObject] = ValidateContainer(
    gameObject,
    currentObject,
    currentObjectType,
    isEdit,
    isObjectEdit,
    gameObjectArray,
    shapeDimension
  );
  const [errorsObj, setErrors] = useState({});

  const imageResourceBaseUrl =
    "https://s3-" +
    process.env.REACT_APP_REGION +
    ".amazonaws.com/" +
    process.env.REACT_APP_BUCKET_NAME +
    "/" +
    process.env.REACT_APP_DIR_NAME +
    "";
  useEffect(() => {
    if (gameObject) {
      setOriginalGameObject(JSON.parse(JSON.stringify(gameObject)));
    }
  }, [gameObject]);

  useEffect(() => {
    if (!isObjectEdit) {
      setCurrentObject({
        name: "",
        position: { x: "", y: "" },
        scale: { x: "", y: "" },
      });
    }
    setErrors({});
  }, [currentObjectType]);

  const initializeShape = (val) => {
    if (val.shape && val.shape.graphics && val.shape.graphics.length > 0) {
      if (val.shape.graphics[0] && val.shape.graphics[0][1]) {
        setShapeColor(val.shape.graphics[0][1]);
      }
      if (val.shape.graphics[1]) {
        setShapeDimension({
          offsetX: val.shape.graphics[1][1] ? val.shape.graphics[1][1] : "",
          offsetY: val.shape.graphics[1][2] ? val.shape.graphics[1][2] : "",
          width: val.shape.graphics[1][3] ? val.shape.graphics[1][3] : "",
          height: val.shape.graphics[1][4] ? val.shape.graphics[1][4] : "",
        });
      }
    }
  };

  // hook to detect image operation response
  useEffect(() => {
    if (imageOperationResponse) {
      if (!imageOperationResponse.success) toast.error(imageOperationResponse.message);
      else toast.success(imageOperationResponse.message);
      loadImageResourcesByAccount(creativeAccountId);
      resetImageAddedResponse(); // state reset
    }
  }, [imageOperationResponse]);

  // hook to detect AWS image added response
  useEffect(() => {
    if (awsResponse) {
      if (awsResponse.success) {
        // call the firebase save image function
        saveImageToFirebase();
      } else {
        toast.info(awsResponse.message);
      }
    }
    dispatch(resetImageAddedAWSResponse()); // state reset
  }, [awsResponse]);

  // setting the index of object we need to edit, and mutating the color object
  const startEditObject = (idx, item) => {
    let tempObj = {};
    let colors = { r: "241", g: "112", b: "19", a: "1" };
    if (item[2]) {
      let arr = item[2].match(/\d+/g);
      colors = {
        r: arr[0],
        g: arr[1],
        b: arr[2],
        a: arr.length > 4 ? arr[3] + "." + arr[4] : arr[3],
      };
    }
    tempObj.description = item[0] ? item[0] : "";
    tempObj.font = item[1] ? item[1].replace(/[^0-9]/g, "") : 1;
    tempObj.bold = item[1].includes("bold") ? true : false;
    tempObj.color = colors;
    tempObj.hex = returnHex(item[2]);
    //setColor(returnHex(item[2]));
    setColor(colors);
    setObjectIdx(idx);
    setCurrentTextObj({ ...tempObj });
    setShowTextForm(true);
  };

  const handleEditTextObject = (e) => {
    let tempObj = { ...currentTextObj };
    // if there is no e.target, it means the color is being edited
    if (e.target) {
      if (e.target.name === "bold") tempObj["bold"] = !tempObj["bold"];
      else tempObj[e.target.name] = e.target.value;
    } else {
      // set the hex color from rgb string, to display on screen
      tempObj["color"] = e.rgb;
      setColor(e.rgb);
    }

    setCurrentTextObj({ ...tempObj });
  };

  const handleFileChange = (e) => {
    let fileObj = e.target.files[0];
    if (fileObj.type.indexOf("image/") === -1) {
      alert("Only Images are allowed");
      return;
    }
    dispatch(updateLoaderState(true));
    let fileDimensions = { width: 0, height: 0 };
    // ************* to get the image dimensions via plain javascript
    var url = URL.createObjectURL(fileObj);
    var img = new Image();

    img.onload = () => {
      fileDimensions.width = img.width;
      fileDimensions.height = img.height;
      fileObj.fileDimensions = fileDimensions;
      setFile(fileObj);
      // ****** aws function to put file into bucket
      addImageToAWS(fileObj);
    };
    img.src = url;
  };

  const saveImageToFirebase = () => {
    let formData = {
      account: creativeAccountId,
      bucket: process.env.REACT_APP_BUCKET_NAME,
      filename: file.name,
      height: file.fileDimensions.height,
      size: file.size,
      mime_type: file.type,
      status: "Active",
      updated_at: +new Date(), // current timestamp
      updated_by: auth.uid, // current user id
      width: file.fileDimensions.width,
    };
    addNewImageFunc(formData);
    dispatch(updateLoaderState(false));
  };

  const reset = () => {
    let tempObj = {
      description: "",
      font: 24,
      bold: false,
      color: { r: "241", g: "112", b: "19", a: "1" },
    };
    setObjectIdx(-1);
    setCurrentTextObj(tempObj);
    setShowTextForm(false);
    setTextError({ description: "" });
    setErrors({});
    setColor({ r: "241", g: "112", b: "19", a: "1" });
  };

  const resetContainerForm = () => {
    setGameObject({ ...originalGameObject });
    setShowContainerForm(false);
    setErrors({});
  };

  const saveTextObject = () => {
    let tempObj = "";
    let currentObj = { ...currentObject };
    tempObj = [];
    if (!currentTextObj || !currentTextObj.description || currentTextObj.description === "") {
      setTextError({ description: "Please enter some text" });
      return;
    }
    tempObj.push(currentTextObj.description);

    if (currentTextObj.bold) tempObj.push("bold " + currentTextObj.font + "px Helvetica");
    else tempObj.push(currentTextObj.font + "px Helvetica");

    tempObj.push(
      "rgba(" +
        currentTextObj.color.r +
        "," +
        currentTextObj.color.g +
        "," +
        currentTextObj.color.b +
        "," +
        currentTextObj.color.a +
        ")"
    );

    if (!currentObj.text) currentObj.text = [];

    if (objectIdx >= 0) {
      currentObj.text[objectIdx] = tempObj;
    } else {
      currentObj.text.push(tempObj);
    }
    setCurrentObject(currentObj);
    reset();
  };

  const updateObject = () => {
    let gameObj = { ...gameObject };
    let obj = gameObj.container.objects;
    for (var key in obj) {
      if (key === currentObject.name) {
        let errors = validateInnerObject();

        setErrors(errors);
        if (Object.keys(errors).length === 0) {
          gameObj.container.objects[key] = { ...currentObject };
          let emptyObject = {
            name: "",
            position: { x: "", y: "" },
            scale: { x: "", y: "" },
          };
          setCurrentObject({ ...emptyObject });
          setFrames({ width: "", height: "", regX: "", regY: "" });
          setShowContainerForm(false);
          return;
        } else {
          console.log("not validated");
        }
      }
    }
  };

  const deleteContainerObject = (objName) => {
    confirmAlert({
      title: "Confirm to delete",
      message: `Are you sure to delete`,
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteContainerGameObject(objName),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const deleteContainerGameObject = (objName) => {
    let gameObj = { ...gameObject };
    delete gameObj.container.objects[objName];
    //setShowContainerForm(true);
    setGameObject(gameObj);
  };

  const addObject = () => {
    let gameObj = { ...gameObject };
    let currentObj = { ...currentObject };

    let errors = validateInnerObject();

    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      if (!gameObj.container) gameObj.container = {};
      if (!gameObj.container.objects) gameObj.container.objects = {};
      if (!gameObj.container.objects[currentObj.name])
        gameObj.container.objects[currentObj.name] = {};

      if (currentObjectType !== "Spritesheet") {
        delete currentObj.frames;
        delete currentObj.framerate;
      }

      if (currentObjectType === "Text") {
        delete currentObj.spritesheet;
        delete currentObj.image;
        delete currentObj.shape;
      } else if (currentObjectType === "Spritesheet") {
        delete currentObj.text;
        delete currentObj.image;
        delete currentObj.shape;
      } else if (currentObjectType === "Image") {
        delete currentObj.text;
        delete currentObj.spritesheet;
        delete currentObj.shape;
      } else if (currentObjectType === "Shape") {
        delete currentObj.text;
        delete currentObj.image;
        delete currentObj.spritesheet;
      }

      gameObj.container.objects[currentObj.name] = currentObj;

      if (gameObj.container.objects.image) delete gameObj.container.objects.image;

      setGameObject({ ...gameObj });

      reset();
      let emptyObject = {
        name: "",
        position: { x: "", y: "" },
        scale: { x: "", y: "" },
      };
      setCurrentObject({ ...emptyObject });
      setFrames({ width: "", height: "", regX: "", regY: "" });
      setShowContainerForm(false);
    } else {
      // not validated
      console.log("form not validated");
    }
  };

  const deleteTextObject = (item, idx) => {
    confirmAlert({
      title: "Confirm to delete",
      message: `Are you sure to delete`,
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteTextGameObject(item, idx),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const deleteTextGameObject = (item, idx) => {
    let currentObj = { ...currentObject };
    currentObj.text.splice(idx, 1);
    setCurrentObject(currentObj);
  };

  // function to return the hex color from rgb string, to display on screen
  const returnHex = (color) => {
    const rgba = color.replace(/^rgba?\(|\s+|\)$/g, "").split(",");
    const hex = `#${(
      (1 << 24) +
      (parseInt(rgba[0]) << 16) +
      (parseInt(rgba[1]) << 8) +
      parseInt(rgba[2])
    )
      .toString(16)
      .slice(1)}`;
    return hex;
  };

  const returnObjType = (item) => {
    if (item.shape) setCurrentObjectType("Shape");
    if (item.text) setCurrentObjectType("Text");
    if (item.image) setCurrentObjectType("Image");
    if (item.spritesheet) setCurrentObjectType("Spritesheet");

    setCurrentObject({ ...item });
    setShowContainerForm(true);
    setIsObjectEdit(true);
    if (item.shape) {
      initializeShape(item);
    }
    if (item.spritesheet) {
      setFrames(item.spritesheet.frames);
    }
  };

  // ********** upon clicking on add new, automatically switch the type to a non-inserted object **********.
  const handleAddNew = () => {
    let emptyObject = {
      name: "",
      position: { x: "", y: "" },
      scale: { x: "", y: "" },
    };
    setCurrentObject({ ...emptyObject });
    setFrames({ width: "", height: "", regX: "", regY: "" });
    setShapeDimension({
      offsetX: "",
      offsetY: "",
      width: "",
      height: "",
    });
    setColor(defaultColor);
    setShapeColor("#FFFFFF");
    setShowContainerForm(!showContainerForm);
    setIsObjectEdit(false);

    // come here
  };

  // ******************* check whether to show add new object to container, if all types of object are already there, user can only edit them **********************
  const returnAllObjectsCheck = () => {
    let allObjects = true;
    let imageCheck = false;
    let shapeCheck = false;
    let textCheck = false;
    let spriteCheck = false;

    if (gameObject && gameObject?.container && gameObject?.container?.objects) {
      let obj = gameObject.container.objects;
      for (var key in obj) {
        var val = obj[key];
        if (val.image) imageCheck = true;
        if (val.spritesheet) spriteCheck = true;
        if (val.text) textCheck = true;
        if (val.shape) shapeCheck = true;
      }

      if (imageCheck && shapeCheck && textCheck && spriteCheck) allObjects = true;
      else allObjects = false;
    } else allObjects = false;
    return allObjects;
  };

  var imageUrlArray = [];
  var isImageSelected = false;
  if (currentObject && currentObject.image && currentObject.image.source) {
    isImageSelected = true;
    imageUrlArray.push(currentObject.image.source);
  }
  if (currentObject && currentObject.spritesheet && currentObject.spritesheet.source) {
    isImageSelected = true;
    imageUrlArray.push(currentObject.spritesheet.source);
  }
  imageResources.map((item, idx) => {
    if (imageUrlArray.indexOf(imageResourceBaseUrl + item.filename) === -1) {
      imageUrlArray.push(imageResourceBaseUrl + item.filename);
    }
  });

  const resetStateOnObjectTypeChange = (currentObjectType) => {
    if (currentObjectType === "Text") {
      setShapeDimension({
        offsetX: "",
        offsetY: "",
        width: "",
        height: "",
      });
      setShapeColor("#FFFFFF");
      setCurrentObject({
        name: "",
        position: { x: "", y: "" },
        scale: { x: "", y: "" },
      });

      setCurrentTextObj({
        color: { r: "241", g: "112", b: "19", a: "1" },
        font: 24,
        description: "",
        bold: false,
      });
    } else if (currentObjectType === "Spritesheet") {
      setCurrentTextObj({
        color: { r: "241", g: "112", b: "19", a: "1" },
        font: 24,
      });
      setShapeDimension({
        offsetX: "",
        offsetY: "",
        width: "",
        height: "",
      });
      setShapeColor("#FFFFFF");
      setObjectIdx(-1);
      setCurrentObject({
        name: "",
        position: { x: "", y: "" },
        scale: { x: "", y: "" },
      });
      setFrames({ width: "", height: "", regX: "", regY: "" });
    } else if (currentObjectType === "Image") {
      setCurrentTextObj({
        color: { r: "241", g: "112", b: "19", a: "1" },
        font: 24,
      });
      setShapeDimension({
        offsetX: "",
        offsetY: "",
        width: "",
        height: "",
      });
      setShapeColor("#FFFFFF");
      setObjectIdx(-1);
      setCurrentObject({
        name: "",
        position: { x: "", y: "" },
        scale: { x: "", y: "" },
      });
    } else if (currentObjectType === "Shape") {
      setCurrentTextObj({
        color: { r: "241", g: "112", b: "19", a: "1" },
        font: 24,
      });
      setObjectIdx(-1);
      setCurrentObject({
        name: "",
        position: { x: "", y: "" },
        scale: { x: "", y: "" },
      });
    }
  };

  return (
    <div className="objects-container-section">
      <h3>Container Objects</h3>
      <span className="text-errors" style={{ color: "red" }}>
        {emptyContainerCheck}
      </span>
      <div className="form-group">
        {gameObject &&
          gameObject.container &&
          gameObject.container.objects &&
          Object.keys(gameObject.container.objects).map((item, idx) => (
            <div className="object-list" key={idx}>
              <div className="object-list-label">
                {gameObject.container.objects[item].image ? (
                  <span className="object-icon">
                    <ImageIcon />
                  </span>
                ) : gameObject.container.objects[item].text ? (
                  <span className="object-icon">
                    <TextIcon />
                  </span>
                ) : gameObject.container.objects[item].spritesheet ? (
                  <span className="object-icon">
                    <SpriteIcon />
                  </span>
                ) : gameObject.container.objects[item].shape ? (
                  <span className="object-icon">
                    <ShapeIcon />
                  </span>
                ) : gameObject.container.objects[item].container ? (
                  <span className="object-icon">
                    <ContainerIcon />
                  </span>
                ) : null}
                <label>{item}</label>
              </div>
              <div className="object-buttons">
                <span
                  className="btn btn-default obj-action-btn text-edit"
                  onClick={() => returnObjType(gameObject.container.objects[item])}
                >
                  <EditIcon />
                </span>
                <span
                  className="btn btn-default btn-delete obj-action-btn text-delete"
                  onClick={() => deleteContainerObject(item)}
                >
                  <DeleteIcon />
                </span>
              </div>
            </div>
          ))}
      </div>
      <hr />
      <div className="addnew-objects-container">
        <h3
          onClick={() => handleAddNew()}
          // hidden={returnAllObjectsCheck}
          className="toggle-content-title"
        >
          Add New Object
          <span className="arrow">
            <ArrowIcon />
          </span>
        </h3>
        <div className="container-form" hidden={!showContainerForm}>
          {/*   TYPE OF OBJECT  */}
          <div className="form-group">
            <label>Type</label>
            <div className="input-control">
              <span className="custom-control custom-radio custom-control-inline">
                <input
                  type="radio"
                  value={"Image"}
                  disabled={isObjectEdit}
                  checked={currentObjectType === "Image" ? true : false}
                  name="containerObjectType"
                  onChange={(evt) => {
                    setCurrentObjectType(evt.target.value);
                    resetStateOnObjectTypeChange("Image");
                  }}
                  className="custom-control-input"
                  id="cont-type-img"
                />
                <label className="custom-control-label" htmlFor="cont-type-img">
                  {"Image"}
                </label>
              </span>
              <span className="custom-control custom-radio custom-control-inline">
                <input
                  type="radio"
                  value={"Text"}
                  disabled={isObjectEdit}
                  checked={currentObjectType === "Text" ? true : false}
                  name="containerObjectType"
                  onChange={(evt) => {
                    setCurrentObjectType(evt.target.value);
                    resetStateOnObjectTypeChange("Text");
                  }}
                  className="custom-control-input"
                  id="cont-type-text"
                />
                <label className="custom-control-label" htmlFor="cont-type-text">
                  {"Text"}
                </label>
              </span>
              <span className="custom-control custom-radio custom-control-inline">
                <input
                  type="radio"
                  value={"Spritesheet"}
                  disabled={isObjectEdit}
                  checked={currentObjectType === "Spritesheet" ? true : false}
                  name="containerObjectType"
                  onChange={(evt) => {
                    setCurrentObjectType(evt.target.value);
                    resetStateOnObjectTypeChange("Spritesheet");
                  }}
                  className="custom-control-input"
                  id="cont-type-sprite"
                />
                <label className="custom-control-label" htmlFor="cont-type-sprite">
                  {"Spritesheet"}
                </label>
              </span>
              <span className="custom-control custom-radio custom-control-inline">
                <input
                  type="radio"
                  value={"Shape"}
                  disabled={isObjectEdit}
                  checked={currentObjectType === "Shape" ? true : false}
                  name="containerObjectType"
                  onChange={(evt) => {
                    setCurrentObjectType(evt.target.value);
                    resetStateOnObjectTypeChange("Shape");
                  }}
                  className="custom-control-input"
                  id="cont-type-shape"
                />
                <label className="custom-control-label" htmlFor="cont-type-shape">
                  {"Shape"}
                </label>
              </span>
            </div>
          </div>

          {/*   END OF TYPE OF OBJECT  */}

          <div
            id="imageSection"
            className="container-objects-elements"
            hidden={currentObjectType === "Text" || currentObjectType === "Shape"}
          >
            <div className="add-image-div">
              <input
                type="file"
                id="gameObjectImageInput"
                ref={fileRef}
                accept="image/*"
                onChange={handleFileChange}
              />
              <label htmlFor="gameObjectImageInput">
                <span className="plus-icon">+</span>Add Image
              </label>
            </div>

            <div className="image-container">
              {imageUrlArray.map((imageUrl, idx) => (
                <img
                  key={idx}
                  src={imageUrl}
                  // style={idx == 0 && isImageSelected ? { marginRight: "10px", border: "5px solid #42801c" } : { marginRight: "10px" }}
                  onClick={(evt) =>
                    currentObjectType === "Image"
                      ? handleContainerChange(evt, "image", imageUrl)
                      : handleFrameChange(evt, imageUrl)
                  }
                  width="100px"
                  height="100px"
                  alt=""
                  className={idx === 0 && isImageSelected ? "selected" : " "}
                />
              ))}
            </div>
            <span className="text-errors" style={{ color: "red" }}>
              {errorsObj.image}
            </span>
          </div>

          {/* OBJECT NAME,SCALE AND POSITION  */}
          <div className="container-objects-elements">
            <div className="form-group">
              <label>Name</label>
              <div className="input-control">
                <input
                  type="text"
                  name="name"
                  value={currentObject && currentObject.name}
                  disabled={isObjectEdit}
                  placeholder="Name"
                  onChange={(evt) => handleContainerChange(evt, undefined)}
                />
              </div>
              <span className="text-errors" style={{ color: "red" }}>
                {errorsObj.name}
              </span>
            </div>

            <div className="form-group">
              <label>Position</label>
              <div className="row property-section">
                <div className="form-group col-sm-6">
                  <label>Horizontal</label>
                  <div className="input-control">
                    <input
                      type="number"
                      pattern="[0-9]*"
                      inputMode="numeric"
                      name="x"
                      value={currentObject && currentObject.position && currentObject.position.x}
                      placeholder="Horizontal"
                      onChange={(evt) => handleContainerChange(evt, "position")}
                    />
                  </div>
                </div>
                <div className="form-group col-sm-6">
                  <label>Vertical</label>
                  <div className="input-control">
                    <input
                      type="number"
                      pattern="[0-9]*"
                      inputMode="numeric"
                      name="y"
                      value={currentObject && currentObject.position && currentObject.position.y}
                      placeholder="Horizontal"
                      onChange={(evt) => handleContainerChange(evt, "position")}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="form-group">
              <label>Scale</label>
              <div className="row property-section">
                <div className="form-group col-sm-6">
                  <label>Horizontal</label>
                  <div className="input-control">
                    <input
                      type="number"
                      pattern="[0-9]*"
                      inputMode="numeric"
                      name="x"
                      value={currentObject && currentObject.scale && currentObject.scale.x}
                      placeholder="Horizontal"
                      onChange={(evt) => handleContainerChange(evt, "scale")}
                    />
                    {/* {errors.scaleXIsValid && <small>{errors.scaleXIsValid}</small>} */}
                  </div>
                </div>
                <div className="form-group col-sm-6">
                  <label>Vertical</label>
                  <div className="input-control">
                    <input
                      type="number"
                      pattern="[0-9]*"
                      inputMode="numeric"
                      name="y"
                      value={currentObject && currentObject.scale && currentObject.scale.y}
                      placeholder="Horizontal"
                      onChange={(evt) => handleContainerChange(evt, "scale")}
                    />
                    {/* {errors.scaleYIsValid && <small>{errors.scaleYIsValid}</small>} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*   END OF OBJECT NAME AND POSITION  */}

          {/*   SPRITESHEET SECTION    */}
          <div className="container-objects-elements" hidden={currentObjectType !== "Spritesheet"}>
            <div className="form-group" hidden={currentObjectType !== "Spritesheet"}>
              <label>Frames</label>
              <div className="input-control row">
                <div className="col-md-3 col-sm-6">
                  <label>Width</label>
                  <input
                    type="number"
                    pattern="[0-9]*"
                    inputMode="numeric"
                    name="width"
                    value={frames.width}
                    onChange={(evt) => handleFrameChange(evt)}
                  />
                  <span className="text-errors" style={{ color: "red" }}>
                    {errorsObj && errorsObj.frames && errorsObj.frames.width}
                  </span>
                </div>
                <div className="col-md-3 col-sm-6">
                  <label>Height</label>
                  <input
                    type="number"
                    pattern="[0-9]*"
                    inputMode="numeric"
                    name="height"
                    value={frames.height}
                    onChange={(evt) => handleFrameChange(evt)}
                  />
                  <span className="text-errors" style={{ color: "red" }}>
                    {errorsObj && errorsObj.frames && errorsObj.frames.height}
                  </span>
                </div>
                <div className="col-md-3 col-sm-6">
                  <label>Reg X</label>
                  <input
                    type="number"
                    pattern="[0-9]*"
                    inputMode="numeric"
                    name="regX"
                    value={frames.regX}
                    onChange={(evt) => handleFrameChange(evt)}
                  />
                  <span className="text-errors" style={{ color: "red" }}>
                    {errorsObj && errorsObj.frames && errorsObj.frames.regX}
                  </span>
                </div>
                <div className="col-md-3 col-sm-6">
                  <label>Reg Y</label>
                  <input
                    type="number"
                    pattern="[0-9]*"
                    inputMode="numeric"
                    name="regY"
                    value={frames.regY}
                    onChange={(evt) => handleFrameChange(evt)}
                  />
                  <span className="text-errors" style={{ color: "red" }}>
                    {errorsObj && errorsObj.frames && errorsObj.frames.regY}
                  </span>
                </div>
              </div>
            </div>

            <div className="form-group" hidden={currentObjectType !== "Spritesheet"}>
              <label>Frame Rate</label>
              <input
                type="number"
                pattern="[0-9]*"
                inputMode="numeric"
                name="framerate"
                value={
                  (currentObject &&
                    currentObject.spritesheet &&
                    currentObject.spritesheet.framerate) ||
                  0
                }
                onChange={(evt) => handleFrameChange(evt)}
              />
              <span className="text-errors" style={{ color: "red" }}>
                {errorsObj && errorsObj && errorsObj.framerate}
              </span>
              <span className="text-errors d-block" style={{ color: "red" }}>
                {errorsObj && errorsObj.spritesheet}
              </span>
            </div>
          </div>
          {/*  END OF SPRITESHEET SECTION    */}

          {/* TEXTS SECTION  */}
          <div className="container-objects-elements" hidden={currentObjectType !== "Text"}>
            <div id="existingTexts" hidden={currentObjectType !== "Text"}>
              {currentObject && currentObject.text && currentObject.text.length > 0 ? (
                <>
                  <h3>Existing texts details</h3>
                  <ul className="existing-texts">
                    {currentObject &&
                      currentObject.text &&
                      currentObject.text.map((text, index) => (
                        <li key={index}>
                          <span
                            className="text-span"
                            style={{ marginRight: "50px", color: text[2] }}
                          >
                            {" "}
                            {text[0]}{" "}
                          </span>
                          <span
                            className="btn btn-default obj-action-btn text-edit"
                            onClick={() => startEditObject(index, text)}
                          >
                            <EditIcon />
                          </span>
                          <span
                            className="btn btn-default btn-delete obj-action-btn text-delete"
                            onClick={() => deleteTextObject(text[0], index)}
                          >
                            <DeleteIcon />
                          </span>
                        </li>
                      ))}
                  </ul>
                </>
              ) : null}

              <span className="text-errors" style={{ color: "red" }}>
                {errorsObj.texts}
              </span>
              {objectIdx === -1 ? (
                <span
                  className="btn btn-primary btn-sm"
                  style={{ cursor: "pointer" }}
                  onClick={() => setShowTextForm(true)}
                >
                  Add New
                </span>
              ) : (
                <h3>{`Editing ${currentTextObj.description}`} </h3>
              )}
            </div>
            <div
              className="form-group"
              style={{ marginTop: "20px" }}
              hidden={currentObjectType !== "Text" || !showTextForm}
            >
              <div className="row property-section">
                <div className="col-md-12 col-sm-12">
                  <label>Description</label>
                  <div className="input-control">
                    <input
                      type="text"
                      onChange={handleEditTextObject}
                      name="description"
                      value={currentTextObj.description}
                    />
                    <span style={{ color: "red" }}>{textError.description}</span>
                  </div>
                </div>
              </div>
              <div className="row property-section">
                <div className="col-md-4 col-sm-4">
                  <label>Font Size</label>
                  <select
                    name="font"
                    className="custom-select"
                    value={currentTextObj.font}
                    onChange={handleEditTextObject}
                  >
                    {options}
                  </select>
                </div>
                <div className="col-md-4 col-sm-4">
                  <label>Font Color</label>
                  <div className="color-box">
                    <div
                      style={textStyles.swatch}
                      onClick={() => setDisplayColorPicker(!displayColorPicker)}
                    >
                      <div style={textStyles.color} />
                    </div>
                    {displayColorPicker ? (
                      <div style={textStyles.popover}>
                        <div
                          style={textStyles.cover}
                          onClick={() => setDisplayColorPicker(false)}
                        />
                        <SketchPicker
                          color={currentTextObj.color}
                          name="color"
                          onChange={handleEditTextObject}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-3 col-sm-6">
                  <label>Font Weight</label>
                  <span className="custom-control custom-radio custom-control-inline">
                    <input
                      type="checkbox"
                      defaultChecked={currentTextObj.bold}
                      //id="bold"
                      name="bold"
                      checked={currentTextObj.bold}
                      onClick={handleEditTextObject}
                      className="custom-control-input"
                      id="type-bold"
                    />
                    <label className="custom-control-label" htmlFor="type-bold">
                      {"Bold"}
                    </label>
                  </span>
                </div>

                <div className="col-sm-12 mr-t-15">
                  <span className="btn btn-primary btn-sm" onClick={() => saveTextObject()}>
                    {objectIdx === -1 ? "Add" : `Update`}{" "}
                  </span>
                  <span className="btn btn-primary btn-sm" onClick={reset}>
                    Cancel{" "}
                  </span>
                </div>

                {/* END OF TEXTS SECTION  */}
              </div>
            </div>
          </div>
          {/*  ********************* SHAPE SECTION  ********************     */}
          <div hidden={currentObjectType !== "Shape"}>
            <div className="form-group">
              <div className="row property-section">
                <div className="form-group col-sm-4">
                  <label>Color</label>
                  <div className="color-box">
                    <div
                      style={styles.swatch}
                      onClick={() => setDisplayColorPicker(!displayColorPicker)}
                    >
                      <div style={styles.color} />
                    </div>
                    {displayColorPicker ? (
                      <div style={styles.popover}>
                        <div style={styles.cover} onClick={() => setDisplayColorPicker(false)} />
                        <SketchPicker
                          color={shapeColor}
                          name="color"
                          onChange={handleShapeChange}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="form-group col-sm-4">
                  <label>Offset X</label>
                  <input
                    type="number"
                    pattern="[0-9]*"
                    inputMode="numeric"
                    name="offsetX"
                    value={shapeDimension.offsetX}
                    onChange={handleShapeChange}
                  />
                  {errorsObj && errorsObj.offsetXIsEmpty && (
                    <small>{errorsObj.offsetXIsEmpty}</small>
                  )}
                </div>
                <div className="form-group col-sm-4">
                  <label>Offset Y</label>
                  <input
                    type="number"
                    pattern="[0-9]*"
                    inputMode="numeric"
                    name="offsetY"
                    value={shapeDimension.offsetY}
                    onChange={handleShapeChange}
                  />
                  {errorsObj && errorsObj.offsetYIsEmpty && (
                    <small>{errorsObj.offsetYIsEmpty}</small>
                  )}
                </div>
                <div className="form-group col-sm-4">
                  <label>Width</label>
                  <input
                    type="number"
                    pattern="[0-9]*"
                    inputMode="numeric"
                    name="width"
                    value={shapeDimension.width}
                    onChange={handleShapeChange}
                  />
                  {errorsObj && errorsObj.shapeWidthIsEmpty && (
                    <small>{errorsObj.shapeWidthIsEmpty}</small>
                  )}
                </div>
                <div className="form-group col-sm-4">
                  <label>Height</label>
                  <input
                    type="number"
                    pattern="[0-9]*"
                    inputMode="numeric"
                    name="height"
                    value={shapeDimension.height}
                    onChange={handleShapeChange}
                  />
                  {errorsObj && errorsObj.shapeHeightIsEmpty && (
                    <small>{errorsObj.shapeHeightIsEmpty}</small>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* END OF SHAPE SECTION    */}
          {/* <hr style={{ "marginTop": "30px", "marginBottom": "30px" }} /> */}
          <div className="button-group">
            <span
              onClick={() => addObject()}
              hidden={isObjectEdit}
              className="btn btn-primary btn-sm"
            >
              Add Object
            </span>
            <span
              hidden={!isObjectEdit}
              onClick={() => updateObject()}
              className="btn btn-primary btn-sm"
            >
              Update Object
            </span>
            <span onClick={() => resetContainerForm()} className="btn btn-primary btn-sm">
              Cancel
            </span>
          </div>
        </div>
      </div>
      <hr />
    </div>
  );
};

function mapStateToProps(state) {
  return {
    imageResources: state.imageReducer.imageResources,
    auth: state.firebase.auth,
    imageOperationResponse: state.imageReducer.imageOperationResponse,
    awsResponse: state.imageReducer.awsResponse,
    sceneNo: state.gameObject.sceneNo,
    creativeUid: state.gameObject.creativeUid,
    creativeAccountId: state.truengageCreative.creativeAccountId,
    gameObjectArray: state.truengageCreative.gameObjectArray,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addNewImageFunc: (formData) => dispatch(addNewImage(formData)),
    resetImageAddedResponse: () => dispatch(resetImageAddedResponse()),
    loadImageResourcesByAccount: (id) => dispatch(loadImageResourcesByAccountId(id)),
    addImageToAWS: (fileObj) => dispatch(addNewImageToAWS(fileObj)),
    updateLoaderState: (status) => dispatch(updateLoaderState(status)),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(ContainerGameObject);