export const PUBLISH_CREATIVE_API_URL =
  "https://us-central1-firebase-xps-production.cloudfunctions.net/publishCreative";
  
export const defaultSource = {
  width: 1280,
  height: 720,
  scenes: [
    {
      duration: 30000,
      layers: [
        {
          id: "background",
          name: "background",
          objects: ["background"],
        },
        {
          id: "static",
          name: "static",
          objects: [],
        },
        {
          id: "bin",
          name: "bin",
          objects: [],
        },
        {
          id: "draggable",
          name: "draggable",
          objects: [],
        },
      ],
      objects: {
        background: {
          name: "background",
          image: {
            source:
              "https://s3-us-west-2.amazonaws.com/assets.xpsads.com/truengage/demos/transparent.gif",
          },
          position: {
            x: 0,
            y: 0,
          },
          scale: {
            x: 1,
            y: 1,
          },
        },
      },
    },
  ],
};

export const launchableDefaultSource = {
  width: 1280,
  height: 720,
  scenes: [
    {
      duration: 30000,
      layers: [
        {
          id: "background",
          name: "background",
          objects: ["background"],
        },
        {
          id: "static",
          name: "static",
          objects: [],
        },
        {
          id: "bin",
          name: "bin",
          objects: [],
        },
        {
          id: "launchable",
          name: "launchable",
          objects: [],
        },
      ],
      objects: {
        background: {
          name: "background",
          image: {
            source:
              "https://s3-us-west-2.amazonaws.com/assets.xpsads.com/truengage/demos/transparent.gif",
          },
          position: {
            x: 0,
            y: 0,
          },
          scale: {
            x: 1,
            y: 1,
          },
        },
      },
    },
  ],
};

export const collectableDefaultSource = {
  width: 1280,
  height: 720,
  scenes: [
    {
      duration: 30000,
      layers: [
        {
          id: "background",
          name: "background",
          objects: ["background"],
        },
        {
          id: "static",
          name: "static",
          objects: [],
        },
        {
          id: "collectable",
          name: "collectable",
          objects: [],
        },
      ],
      objects: {
        background: {
          name: "background",
          image: {
            source:
              "https://s3-us-west-2.amazonaws.com/assets.xpsads.com/truengage/demos/transparent.gif",
          },
          position: {
            x: 0,
            y: 0,
          },
          scale: {
            x: 1,
            y: 1,
          },
        },
      },
    },
  ],
};

export const drawableDefaultSource = {
  width: 1280,
  height: 720,
  scenes: [
    {
      duration: 30000,
      layers: [
        {
          id: "background",
          name: "background",
          objects: ["background"],
        },
        {
          id: "static",
          name: "static",
          objects: [],
        },
        {
          id: "drawable",
          name: "drawable",
          objects: ["marker"],
        },
      ],
      objects: {
        background: {
          name: "background",
          image: {
            source:
              "https://s3-us-west-2.amazonaws.com/assets.xpsads.com/truengage/demos/transparent.gif",
          },
          position: {
            x: 0,
            y: 0,
          },
          scale: {
            x: 1,
            y: 1,
          },
        },
        marker: {
          name: "marker",
          position: {
            x: 1,
            y: 1,
          },
          scale: {
            x: 1,
            y: 1,
          },
          image: {
            source:
              "https://s3-us-west-2.amazonaws.com/resources.staging.xpsads.com-us-west-2/truengage_creative_resources/2021-04-09.png",
          },
          behaviors: {
            drawable: {
              color: "#000000",
              lineWidth: 20,
              targetObjects: [],
              targetedArea: 50,
              actions: {
                empty: [
                  {
                    type: "advanceGame",
                    delay: 100,
                  },
                ],
              },
              dimensions: {
                width: 150,
                height: 200,
              },
            },
          },
        },
      },
    },
  ],
};

export const newDragableStateSource = {
  duration: 5000,
  layers: [
    {
      id: "background",
      name: "background",
      objects: ["background"],
    },
    {
      id: "static",
      name: "static",
      objects: [],
    },
    {
      id: "bin",
      name: "bin",
      objects: [],
    },
    {
      id: "draggable",
      name: "draggable",
      objects: [],
    },
  ],
  objects: {
    background: {
      name: "background",
      image: {
        source:
          "https://s3-us-west-2.amazonaws.com/assets.xpsads.com/truengage/demos/transparent.gif",
      },
      position: {
        x: 0,
        y: 0,
      },
      scale: {
        x: 1,
        y: 1,
      },
    },
  },
};

export const newLaunchableStateSource = {
  duration: 5000,
  layers: [
    {
      id: "background",
      name: "background",
      objects: ["background"],
    },
    {
      id: "static",
      name: "static",
      objects: [],
    },
    {
      id: "bin",
      name: "bin",
      objects: [],
    },
    {
      id: "launchable",
      name: "launchable",
      objects: [],
    },
  ],
  objects: {
    background: {
      name: "background",
      image: {
        source:
          "https://s3-us-west-2.amazonaws.com/assets.xpsads.com/truengage/demos/transparent.gif",
      },
      position: {
        x: 0,
        y: 0,
      },
      scale: {
        x: 1,
        y: 1,
      },
    },
  },
};

export const newCollectableStateSource = {
  duration: 5000,
  layers: [
    {
      id: "background",
      name: "background",
      objects: ["background"],
    },
    {
      id: "static",
      name: "static",
      objects: [],
    },
    {
      id: "collectable",
      name: "collectable",
      objects: [],
    },
  ],
  objects: {
    background: {
      name: "background",
      image: {
        source:
          "https://s3-us-west-2.amazonaws.com/assets.xpsads.com/truengage/demos/transparent.gif",
      },
      position: {
        x: 0,
        y: 0,
      },
      scale: {
        x: 1,
        y: 1,
      },
    },
  },
};

export const newDrawableStateSource = {
  duration: 5000,
  layers: [
    {
      id: "background",
      name: "background",
      objects: ["background"],
    },
    {
      id: "static",
      name: "static",
      objects: [],
    },
    {
      id: "drawable",
      name: "drawable",
      objects: [],
    },
  ],
  objects: {
    background: {
      name: "background",
      image: {
        source:
          "https://s3-us-west-2.amazonaws.com/assets.xpsads.com/truengage/demos/transparent.gif",
      },
      position: {
        x: 0,
        y: 0,
      },
      scale: {
        x: 1,
        y: 1,
      },
    },
  },
};

export const binBehaviour = {
  bin: {
    holds: 1,
    area: {
      x: 0,
      y: 0,
      width: 283,
      height: 550,
    },
    masks: {},
    actions: {
      full: [
        {
          type: "advanceGame",
          delay: 100,
        },
      ],
    },
  },
};

export const launchableBinBehaviour = {
  bin: {
    actions: {
      full: [{ type: "advanceGame", delay: 100 }],
    },
  },
};

/**
 * deafault behavior for Launchable type Game Object
 */
export const launchableBehavior = {
  launchable: {
    reloads: 2,
    actions: {
      empty: [{ type: "advanceGame", delay: 100 }],
    },
  },
};

export const dragableBehaviour = {
  draggable: {
    snapback: true,
  },
};

export const defaultClickableBehavior = {
  clickable: {
    actions: {
      click: [],
    },
  },
};

export const defaultTweenBehavior = {
  tween: {
    loop: true,
    transitions: [],
  },
};

export const defaultCollectableBehavior = {
  collectable: {
    dimensions: {
      width: 100,
      height: 100,
    },
    targetObjects: [],
    actions: {
      empty: [{ type: "advanceGame", delay: 100 }],
    },
  },
};

export const defaultDrawableBehavior = {
  drawable: {
    color: "#000000",
    lineWidth: 10,
    targetObjects: [],
    targetedArea: 50,
    actions: {
      empty: [{ type: "advanceGame", delay: 100 }],
    },
  },
};

export const defaultColor = {
  r: "241",
  g: "112",
  b: "19",
  a: "1",
};

export const defaultShapeColor = {
  r: "255",
  g: "255",
  b: "255",
  a: "1",
};

export const maskingToolTipDesc =
  "Masks are optional images that appear after objects are dropped onto a bin. If a mask is not provided for an object, the object will disappear once it has been dropped onto a bin.";

export const holdingCapacityToolTipDesc =
  "How many objects Bin can hold to complete a Creative unit";

export const binActionTooltipDesc =
  "Actions to be performed when drag and drop are performed to complete Truengage Creative.";

export const launchableBinActionTooltipDesc =
  "Actions to be performed when launch action is performed.";

export const reloadToolTipDesc =
  "A number of retries to launch an object to Bin Game Object. If you failed in all retries then Truengage Creative will automatically end";

export const launchableActionToolTipDesc =
  "Actions to be performed when there is no retry left to complete Truengage Creative.";
