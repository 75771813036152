import { Types } from "../constants/image-resource-types";
import { beginApiCall, apiCallError } from "./apiStatus";
import API from "../../services/firebaseAPI";

//const db = firebase.database();

// ADD NEW IMAGE DISPATCHER
export const addNewImageResource = (values) => async (dispatch) => {
  dispatch({
    type: Types.ADD_NEW_IMAGE,
    payload: values,
  });
};

export const resetImageAddedResponse = () => async (dispatch) => {
  dispatch({
    type: Types.RESET_IMAGE_OPERATION_RESPONSE,
  });
};
export const resetImageAddedAWSResponse = () => async (dispatch) => {
  dispatch({
    type: Types.RESET_ADD_NEW_IMAGE_AWS_RESPONSE,
  });
};

export const addNewImageResourceToAWS = (values) => async (dispatch) => {
  dispatch({
    type: Types.ADD_NEW_IMAGE_AWS,
    payload: values,
  });
};

export const addNewImageToAWS = (fileObj) => async (dispatch) => {
  let result = await API.addNewImageResourceAWS(fileObj);
  dispatch(addNewImageResourceToAWS(result));
};

// ADD NEW IMAGE API HIT
export const addNewImage = (formData) => async (dispatch) => {
  let result = await API.addNewImageResourceFirestore(formData);
  dispatch(addNewImageResource(result));
};

// Load all image Resource by AccountID
export const loadImageResourcesByAccountId = (accountId) => async (dispatch, getState) => {
  try {
    var creativeAccountId = getState().truengageCreative.creativeAccountId;
    dispatch(beginApiCall());
    if (creativeAccountId !== undefined) {
      await API.getImageResouceByAccountId(creativeAccountId || accountId).then(
        (data) => {
          dispatch({
            type: Types.GET_IMAGE_RESOURCES_BY_ACCOUNT_ID,
            payload: data,
          });
        },
        (error) => {
          console.log(error);
        }
      );
    } else {
      alert(`Error occured !!!!!`);
    }
  } catch (err) {
    dispatch(apiCallError());
    dispatch({ type: Types.ERROR_IN_LOADING_CAMPAIGNS, payload: "Error in loading XPS Accounts" });
  }
};
