import firebase from "./../config/firebase";
const db = firebase.database();

const loadVideolinesByCampaignId = (campaignId) => {
  const response = undefined;
  return new Promise((resolve) => {
    db.ref("video_lines")
      .orderByChild("campaign")
      .equalTo(campaignId)
      .once("value")
      .then(function (querySnapShot) {
        if (!querySnapShot.err) {
          resolve(querySnapShot.val());
        } else {
          resolve(response);
        }
      });
  });
};

const loadVideoLineMap = () => {
  const videoLineMap = {};
  return new Promise((resolve) => {
    db.ref("/video_line_versions").once("value", (querySnapShot) => {
      if (!querySnapShot.err) {
        for (let [key, value] of Object.entries(querySnapShot.val())) {
          videoLineMap[key] = value;
        }
        resolve(videoLineMap);
      } else {
        resolve(videoLineMap);
      }
    });
  });
};

const getSelectedVideoLineDetail = (lineId) => {
  const response = undefined;
  return new Promise((resolve) => {
    db.ref("video_lines/" + lineId).once("value", (querySnapShot) => {
      if (!querySnapShot.err) {
        resolve(querySnapShot.val());
      } else {
        resolve(response);
      }
    });
  });
};

const getSelectedVideoLineVersionDetail = (versionId) => {
  const response = undefined;
  return new Promise((resolve) => {
    db.ref("video_line_versions/" + versionId).once("value", (querySnapShot) => {
      if (!querySnapShot.err) {
        resolve(querySnapShot.val());
      } else {
        resolve(response);
      }
    });
  });
};

const loadVideoBillingRecords = (campaignId) => {
  const response = [];
  return new Promise((resolve) => {
    const campaignRef = db.ref().child("campaigns/" + campaignId + "/video_billing_records");
    campaignRef.on("child_added", function (snap) {
      response.push(snap.key);
      resolve(response);
    });
  });
};

const loadVideoLines = (billingRecordId) => {
  const response = [];
  return new Promise((resolve) => {
    const videoBillingRecordRef = db
      .ref()
      .child("video_billing_records/" + billingRecordId + "/video_lines");
    videoBillingRecordRef.on("child_added", function (snap) {
      response.push(snap.key);
      resolve(response);
    });
  });
};

export default {
  loadVideolinesByCampaignId,
  loadVideoLineMap,
  getSelectedVideoLineDetail,
  getSelectedVideoLineVersionDetail,
  loadVideoBillingRecords,
  loadVideoLines,
};
