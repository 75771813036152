import { Types } from "./../constants/campaign-types";
import { beginApiCall, apiCallError } from "./apiStatus";

import API from "../../services/campaignService";

const setXPSCampaigns = (campaigns) => {
  return {
    type: Types.GET_ALL_XPS_CAMPAIGNS_ACCOUNT_ID,
    payload: campaigns,
  };
};

const setErrorInLoadingXPSCampaigns = () => {
  return {
    type: Types.ERROR_IN_LOADING_CAMPAIGNS,
    payload: "Error in loading XPS Campaigns",
  };
};

const setSelectedCampaign = (campaign) => {
  return {
    type: Types.GET_CURRENT_CAMPAIGN,
    payload: campaign,
  };
};

const clearSelectedCampaign = () => {
  return {
    type: Types.CLEAR_CURRENT_CAMPAIGN,
    payload: undefined,
  };
};

/**
 * Load all xps campaigns By AccountID
 * @param {accountId} accountId
 */
export const loadXPSCampaignsByAccountId = (accountId) => async (dispatch) => {
  try {
    dispatch(beginApiCall());
    await API.loadXPSCampaignsByAccountId(accountId).then(
      (data) => {
        dispatch(setXPSCampaigns(data));
      },
      (error) => {
        console.log(error);
      }
    );
  } catch (err) {
    dispatch(apiCallError());
    dispatch(setErrorInLoadingXPSCampaigns());
  }
};

/**
 * load Campaign Detail By campaignId
 * @param {campaignId} campaignId
 */
export const getSelectedCampaignDetail = (campaignId) => async (dispatch) => {
  try {
    dispatch(beginApiCall());
    await API.getSelectedCampaignDetail(campaignId).then(
      (data) => {
        dispatch(setSelectedCampaign(data));
      },
      (error) => {
        console.log(error);
      }
    );
  } catch (err) {
    dispatch(apiCallError());
  }
};

/**
 * to clear Campaign state in Redux
 */
export const clearCampaignState = () => async (dispatch) => {
  dispatch(clearSelectedCampaign());
};
