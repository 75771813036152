import React, { useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import postscribe from "postscribe";
import queryString from "query-string";

const TruegagePreviewFrame = ({ location: { search } }) => {
  var width = "640";
  var height = "360";
  var versionURL = "https://assets.xpsads.com/jynx/v2.2.1.js";
  var sourceCode = "";
  const queryStringParams = queryString.parse(search);
  sourceCode = queryStringParams.sourceCode;
  versionURL = queryStringParams.versionURL;
  width = queryStringParams.width;
  height = queryStringParams.height;
  useEffect(() => {
    if (versionURL) {
      postscribe("#truengageAdPreview", `<script src=${versionURL}></script>`);
      postscribe(
        "#truengageAdPreview",
        '<script src="/ad.js?sourceCode=' + sourceCode + '"></script>'
      );
    }
  }, [versionURL]);

  return (
    <div
      id="truengageAdPreview"
      className="game-canva-container"
      style={{ width: width + "px", height: height + "px" }}
    >
      <canvas
        width={width}
        height={height}
        style={{ width: width + "px", height: height + "px" }}
        id="truengagePreviewFrame"
        className="demo md"
      ></canvas>
    </div>
  );
};

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(TruegagePreviewFrame);
