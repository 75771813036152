import React from "react";
import { Switch, Route } from "react-router-dom";
import "./../scss/index.scss";
import { connect } from "react-redux";
import Main from "./main";
import Login from "./login";
import Campaigns from "./campaigns";
import VideoLine from "./videoLine";
import Header from "./header";
import Sidenav from "./sidenav";
import TruengagePreview from "./truengagePreview";
import TruengagePreviewFrame from "./truengagePreviewFrame";
import TruengageCreativeList from "./truengageCreativeList";
import EditTruengageCreative from "./editTruengageCreative";
import * as routes from "../constants/routes";
import { Redirect } from "react-router-dom";
import Loader from "./loader";
const App = ({ auth }) => {
  const uri = window.location.pathname;
  if (uri.includes("/login")) {
    return <Redirect to="/" />;
  }
  return (
    <div className={!uri.includes("/truengage_preview") ? "App dashboard-layout" : ""}>
      {!uri.includes("/truengage_preview") ? <Header /> : ""}

      {!auth.isLoaded ? (
        <Loader />
      ) : !auth.isEmpty && !uri.includes("/truengage_preview") ? (
        <aside className="sidebar">
          <Sidenav />
        </aside>
      ) : (
        ""
      )}

      <Switch>
        <Route exact path={routes.SIGN_IN} component={() => <Login />} />
        <div className="main-content">
          <Route exact path={routes.HOME} component={() => <Main />} />
          <Route
            exact
            path={routes.CAMPAIGN_LIST}
            component={(props) => <Campaigns {...props} />}
          />
          <Route
            exact
            path={routes.VIDEO_LINE_LIST}
            component={(props) => <VideoLine {...props} />}
          />
          <Route exact path={routes.AD_PREVIEW} component={TruengagePreview} />
          <Route exact path={routes.AD_PREVIEW_FRAME} component={TruengagePreviewFrame} />
          <Route
            exact
            path={routes.TRUENGAGE_CREATIVE_LIST}
            component={(props) => <TruengageCreativeList {...props} />}
          />
          <Route
            exact
            path={routes.EDIT_TRUENGAGE_CREATIVE}
            component={(props) => <EditTruengageCreative {...props} />}
          />
        </div>
      </Switch>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    auth: state.firebase.auth,
  };
}

export default connect(mapStateToProps)(App);
