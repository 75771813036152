import { combineReducers } from "redux";
import { firebaseReducer } from "react-redux-firebase";
import authReducer from "./auth";
import apiStatusReducer from "./apiStatus";
import gameConfigReducer from "./image-resources";
import accountReducer from "./accounts";
import campaignReducer from "./campaigns";
import videolineReducer from "./videolines";
import gameObjectReducer from "./gameObjects";
import truengageCreativeReducer from "./truengageCreatives";
import imageResourcesReducer from "./image-resources";
export default combineReducers({
  firebase: firebaseReducer,
  auth: authReducer,
  apiStatus: apiStatusReducer,
  gameConfig: gameConfigReducer,
  account: accountReducer,
  campaign: campaignReducer,
  videoline: videolineReducer,
  gameObject: gameObjectReducer,
  truengageCreative: truengageCreativeReducer,
  imageReducer: imageResourcesReducer,
});
