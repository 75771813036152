import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { useDispatch } from "react-redux";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import {
  closeEditMouseOverBehaviorModal,
  openEditMouseOverBehaviorModal,
  openEditPathBehaviorModal,
} from "../../store/actions/gameObjects";

import { getCurrentObjectFromSourceCode } from "./../../utils/appUtils";
import { updateSelectedTrueCreativePropsFunc } from "../../store/actions/truengageCreatives";
import { Button, Modal } from "react-bootstrap";
import * as R from "ramda";
import { ReactComponent as ArrowIcon } from "../../images/down-arrow.svg";
import { ReactComponent as EditIcon } from "../../images/edit.svg";
import { ReactComponent as DeleteIcon } from "../../images/trash.svg";
import { confirmAlert } from "react-confirm-alert";
import validate from "../../utils/draggableObjectBehavior/validateDraggableObjectForm";
import { updateLoaderState } from "../../store/actions/gameObjects";
import Loader from "./../../pages/loader";
import EditPathBehaviorModal from "./../../components/modals/editPathBehaviorModal";
const DragableGameObjectBehavior = ({
  isShowEditMouseOverModal,
  editMouseEventType,
  mouseEventIndex,
  mouseEventActionObject,
  gameObject,
  selectedLayers,
  sourceCode,
  sceneIndex,
  gameObjectkey,
  creativeUid,
  isLoaderEnabled,
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const [isShowDetail, setIsShowDetail] = useState(false);
  const [mouseEventObjectAction, setMouseEventObjectAction] = useState({
    type: "applyTween",
    target: "",
    transitions: [],
  });

  const [mouseEvent, setMouseEvent] = useState({
    target: "",
    layer: "",
    alpha: 1,
    delay: "",
    eventType: "mouseOver",
  });
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  useEffect(() => {
    if (isShowEditMouseOverModal && mouseEventActionObject) {
      var tempMouseEvent = mouseEvent;

      if (editMouseEventType === "mouseover") {
        tempMouseEvent["eventType"] = "mouseOver";
      } else {
        tempMouseEvent["eventType"] = "mouseOut";
      }
      tempMouseEvent["target"] = mouseEventActionObject.target;
      tempMouseEvent["layer"] = "";
      tempMouseEvent["alpha"] = mouseEventActionObject.transitions[0][1].alpha;
      tempMouseEvent["delay"] = mouseEventActionObject.transitions[0][2];
      setMouseEvent({ ...tempMouseEvent });
    } else {
      setDefaultStateValue();
    }
  }, [isShowEditMouseOverModal]);

  const setDefaultStateValue = () => {
    setMouseEvent({
      target: "",
      layer: "",
      alpha: 1,
      delay: "",
      eventType: "mouseOver",
    });

    setMouseEventObjectAction({
      type: "applyTween",
      target: "",
      transitions: [],
    });
    setIsEdit(false);
    setErrors({});
    dispatch(updateLoaderState(false));
  };

  const handleMouseOverClose = (e) => {
    dispatch(closeEditMouseOverBehaviorModal());
    setDefaultStateValue();
  };

  const handleSave = (e) => {
    dispatch(updateLoaderState(true));
    e.persist();
    setErrors({});
    // to validate form submit before saving it to DB
    var errors = validate(mouseEvent);
    setErrors(errors);
    var response = true;
    if (errors && Object.keys(errors).length > 0) {
      response = false;
    }
    if (response) {
      var selectedScene = R.path(["scenes"], sourceCode)[sceneIndex];
      var objects = {};
      if (R.path(["objects"], selectedScene)) {
        objects = R.path(["objects"], selectedScene);
      }
      if (objects && objects[gameObjectkey]) {
        var currentObject = objects[gameObjectkey];
        if (currentObject && currentObject.behaviors && currentObject.behaviors.draggable) {
          if (currentObject.behaviors.draggable.actions) {
            var tempMouseEventObjectAction = mouseEventObjectAction;
            var obj = [];
            obj.push("to");
            obj.push({ alpha: mouseEvent.alpha });
            obj.push(mouseEvent.delay);
            var transitions = [];
            transitions.push(obj);
            tempMouseEventObjectAction["target"] = mouseEvent.target;
            tempMouseEventObjectAction["transitions"] = transitions;

            if (mouseEvent.eventType === "mouseOver") {
              var mouseover = [];
              if (editMouseEventType === "mouseover" && mouseEventIndex >= 0) {
                currentObject.behaviors.draggable.actions.mouseover[
                  mouseEventIndex
                ] = tempMouseEventObjectAction;
              } else {
                if (currentObject.behaviors.draggable.actions.mouseover) {
                  mouseover = currentObject.behaviors.draggable.actions.mouseover;
                  mouseover.push(tempMouseEventObjectAction);
                } else {
                  mouseover.push(tempMouseEventObjectAction);
                }
                currentObject.behaviors.draggable.actions["mouseover"] = mouseover;
              }
              dispatch(updateSelectedTrueCreativePropsFunc(creativeUid, sourceCode, true));
            } else {
              var mouseout = [];
              if (editMouseEventType === "mouseout" && mouseEventIndex >= 0) {
                currentObject.behaviors.draggable.actions.mouseout[
                  mouseEventIndex
                ] = tempMouseEventObjectAction;
              } else {
                if (currentObject.behaviors.draggable.actions.mouseout) {
                  mouseout = currentObject.behaviors.draggable.actions.mouseout;
                  mouseout.push(tempMouseEventObjectAction);
                } else {
                  mouseout.push(tempMouseEventObjectAction);
                }
                currentObject.behaviors.draggable.actions["mouseout"] = mouseout;
              }
              dispatch(updateSelectedTrueCreativePropsFunc(creativeUid, sourceCode, true));
            }
          } else {
            tempMouseEventObjectAction = mouseEventObjectAction;
            obj = [];
            obj.push("to");
            obj.push({ alpha: mouseEvent.alpha });
            obj.push(mouseEvent.delay);
            transitions = [];
            transitions.push(obj);
            tempMouseEventObjectAction["target"] = mouseEvent.target;
            tempMouseEventObjectAction["transitions"] = transitions;

            if (mouseEvent.eventType === "mouseOver") {
              mouseover = [];
              mouseover.push(tempMouseEventObjectAction);

              currentObject.behaviors.draggable["actions"] = {};
              currentObject.behaviors.draggable.actions["mouseover"] = mouseover;

              dispatch(updateSelectedTrueCreativePropsFunc(creativeUid, sourceCode, true));
            } else {
              mouseout = [];
              mouseout.push(tempMouseEventObjectAction);
              currentObject.behaviors.draggable["actions"] = {};
              currentObject.behaviors.draggable.actions["mouseout"] = mouseout;
              dispatch(updateSelectedTrueCreativePropsFunc(creativeUid, sourceCode, true));
            }
          }
        }
      }
      handleMouseOverClose();
    }
  };

  const handleChangeAction = (e, type) => {
    if (type === "targetObj") {
      var targetedObject = e.target.value;
      setMouseEvent({
        ...mouseEvent,
        target: targetedObject,
      });
    } else if (type === "event") {
      var value = e.target.value;
      setMouseEvent({
        ...mouseEvent,
        alpha: Number(value),
      });
    } else if (type === "delay") {
      value = e.target.value;
      setMouseEvent({
        ...mouseEvent,
        delay: Number(value),
      });
    } else if (type === "eventType") {
      value = e.target.value;
      setMouseEvent({
        ...mouseEvent,
        eventType: value,
      });
    }
  };

  const gameObjectOptions = selectedLayers.map(
    (layer) =>
      layer.objects &&
      layer.objects.map((object) => (
        <option key={object} value={object}>
          {layer.name + "=>" + object}
        </option>
      ))
  );

  const addNewMouseOverEvent = (e) => {
    e.preventDefault();
    dispatch(openEditMouseOverBehaviorModal(undefined, undefined, undefined));
  };

  const addPathBehavior = (e) => {
    e.preventDefault();
    dispatch(openEditPathBehaviorModal(undefined));
  };

  const deleteMouseActionEventConfirmation = (
    editMouseEventType,
    mouseEventIndex,
    mouseEventActionObject
  ) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to to delete Behavior Action.",
      buttons: [
        {
          label: "Yes",
          onClick: () =>
            deleteMouseActionEvent(editMouseEventType, mouseEventIndex, mouseEventActionObject),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const deleteMouseActionEvent = (editMouseEventType, mouseEventIndex, mouseEventActionObject) => {
    var selectedScene = R.path(["scenes"], sourceCode)[sceneIndex];
    var objects = {};
    if (R.path(["objects"], selectedScene)) {
      objects = R.path(["objects"], selectedScene);
    }
    if (objects && objects[gameObjectkey]) {
      var currentObject = objects[gameObjectkey];
      if (
        currentObject &&
        currentObject.behaviors &&
        currentObject.behaviors.draggable &&
        currentObject.behaviors.draggable.actions
      ) {
        if (editMouseEventType === "mouseover" && mouseEventIndex >= 0) {
          currentObject.behaviors.draggable.actions.mouseover.splice(mouseEventIndex, 1);
          if (currentObject.behaviors.draggable.actions.mouseover.length === 0) {
            delete currentObject.behaviors.draggable.actions["mouseover"];
          }
        } else if (editMouseEventType === "mouseout" && mouseEventIndex >= 0) {
          currentObject.behaviors.draggable.actions.mouseout.splice(mouseEventIndex, 1);
          if (currentObject.behaviors.draggable.actions.mouseout.length === 0) {
            delete currentObject.behaviors.draggable.actions["mouseout"];
          }
        }
        if (
          !currentObject.behaviors.draggable.actions.mouseover &&
          !currentObject.behaviors.draggable.actions.mouseout
        ) {
          delete currentObject.behaviors.draggable["actions"];
        }
        dispatch(updateSelectedTrueCreativePropsFunc(creativeUid, sourceCode, true));
      }
    }
  };

  const handlePathDelete = () => {
    confirmAlert({
      title: "Confirm to delete",
      message: `Are you sure to delete`,
      buttons: [
        {
          label: "Yes",
          onClick: () => handlePathObjDelete(),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const handlePathObjDelete = () => {
    var currentGameObject = getCurrentObjectFromSourceCode(sourceCode, sceneIndex, gameObjectkey);
    if (
      currentGameObject &&
      currentGameObject.behaviors &&
      currentGameObject.behaviors.path &&
      currentGameObject.behaviors.path.transitions
    ) {
      delete currentGameObject["behaviors"]["path"];
      dispatch(updateSelectedTrueCreativePropsFunc(creativeUid, sourceCode, true));
    }
  };

  return (
    <>
      <div className={isShowDetail ? "bin-object-container show" : "bin-object-container"}>
        <h3
          style={{ cursor: "pointer" }}
          onClick={() => setIsShowDetail(!isShowDetail)}
          className="toggle-content-title"
        >
          Draggable Game Object Behaviors
          <span className="arrow">
            <ArrowIcon />
          </span>
        </h3>

        {isShowDetail ? (
          <>
            <div
              className="form-group"
            >
              <div className="row">
                <div className="form-group col-md-10 col-sm-6">
                  <label>Path Behaviors</label>
                </div>
                <div className="form-group col-md-2 col-sm-6" hidden={
                !(
                  gameObject &&
                  gameObject.behaviors &&
                  gameObject.behaviors.path &&
                  gameObject.behaviors.path.transitions &&
                  gameObject.behaviors.path.transitions.length > 0
                )
              }>
                  <span
                    className="btn btn-default obj-action-btn text-edit"
                    onClick={() =>
                      dispatch(openEditPathBehaviorModal(gameObject && gameObject.behaviors ? gameObject.behaviors: undefined))
                    }
                  >
                    <EditIcon />
                  </span>
                  <span
                    className="btn btn-default btn-delete obj-action-btn text-delete"
                    onClick={() => handlePathDelete()}
                  >
                    <DeleteIcon />
                  </span>
                </div>
              </div>
              <div>
                {gameObject &&
                gameObject.behaviors &&
                gameObject.behaviors.path &&
                gameObject.behaviors.path.transitions &&
                gameObject.behaviors.path.transitions.length > 0 ? (
                  <div className="row property-section">
                    <div className="form-group col-md-4 col-sm-6">
                      <label>{"Position:X"}</label>
                    </div>
                    <div className="form-group col-md-4 col-sm-6">
                      <label>{"Position:Y"}</label>
                    </div>
                    <div className="form-group col-md-4 col-sm-6">
                      <label>{"Delay(in milliseconds)"}</label>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              {gameObject &&
                gameObject.behaviors &&
                gameObject.behaviors.path &&
                gameObject.behaviors.path.transitions &&
                gameObject.behaviors.path.transitions.length > 0 &&
                gameObject.behaviors.path.transitions.map((transition, idx) => (
                  <div className="row property-section">
                    <div className="form-group col-md-4 col-sm-6">
                      <input disabled={true} type="text" value={transition.x}></input>
                    </div>
                    <div className="form-group col-md-4 col-sm-6">
                      <input disabled={true} type="text" value={transition.y}></input>
                    </div>
                    <div className="form-group col-md-4 col-sm-6">
                      <input disabled={true} type="text" value={transition.duration}></input>
                    </div>
                  </div>
                ))}
            </div>
            {gameObject && gameObject.behaviors && gameObject.behaviors.path ? (
              ""
            ) : (
              <div className="form-group">
                <div className="input-control row property-section">
                  <button
                    className="btn btn-primary btn-switch"
                    onClick={(evt) => addPathBehavior(evt)}
                  >
                    Add Path Behavior
                  </button>
                </div>
              </div>
            )}

            <div className="form-group">
              <label>Mouse Actions</label>
              <div
                className="input-control row property-section"
                hidden={
                  !(
                    gameObject &&
                    gameObject.behaviors &&
                    gameObject.behaviors.draggable &&
                    gameObject.behaviors.draggable.actions
                  )
                }
              >
                <div className="form-group col-md-3 col-sm-12">
                  <label>{"Action Type"}</label>
                </div>
                <div className="form-group col-md-3 col-sm-12">
                  <label>{"Action"}</label>
                </div>
                <div className="form-group col-md-2 col-sm-12">
                  <label>{"Target"}</label>
                </div>
                <div className="form-group col-md-2 col-sm-12">
                  <label>{"Delay(in milliseconds)"}</label>
                </div>
              </div>

              {gameObject &&
                gameObject.behaviors &&
                gameObject.behaviors.draggable &&
                gameObject.behaviors.draggable.actions &&
                gameObject.behaviors.draggable.actions.mouseout &&
                gameObject.behaviors.draggable.actions.mouseout.map((action, idx) => (
                  <>
                    <div className="input-control row property-section">
                      <div className="form-group col-md-3 col-sm-12">
                        <input disabled={true} type="text" value="Mouse Out"></input>
                      </div>
                      <div className="form-group col-md-3 col-sm-12">
                        <input
                          disabled={true}
                          type="text"
                          value={
                            action.transitions[0][1].alpha === 1 ? "Add Object" : "Remove Object"
                          }
                        ></input>
                      </div>
                      <div className="form-group col-md-2 col-sm-12">
                        <input disabled={true} type="text" value={action.target}></input>
                      </div>
                      <div className="form-group col-md-2 col-sm-12">
                        <input disabled={true} type="text" value={action.transitions[0][2]}></input>
                      </div>

                      <div className="form-group col-md-2 col-sm-12">
                        <span
                          className="btn btn-default obj-action-btn text-edit"
                          onClick={() =>
                            dispatch(openEditMouseOverBehaviorModal("mouseout", idx, action))
                          }
                        >
                          <EditIcon />
                        </span>
                        <span
                          className="btn btn-default btn-delete obj-action-btn text-delete"
                          onClick={() =>
                            deleteMouseActionEventConfirmation("mouseout", idx, action)
                          }
                        >
                          <DeleteIcon />
                        </span>
                      </div>
                    </div>
                  </>
                ))}
              {gameObject &&
                gameObject.behaviors &&
                gameObject.behaviors.draggable &&
                gameObject.behaviors.draggable.actions &&
                gameObject.behaviors.draggable.actions.mouseover &&
                gameObject.behaviors.draggable.actions.mouseover.map((action, idx) => (
                  <>
                    <div className="input-control row property-section">
                      <div className="form-group col-md-3 col-sm-12">
                        <input disabled={true} type="text" value="Mouse Over"></input>
                      </div>
                      <div className="form-group col-md-3 col-sm-12">
                        <input
                          disabled={true}
                          type="text"
                          value={
                            action.transitions[0][1].alpha === 1 ? "Add Object" : "Remove Object"
                          }
                        ></input>
                      </div>
                      <div className="form-group col-md-2 col-sm-12">
                        <input disabled={true} type="text" value={action.target}></input>
                      </div>
                      <div className="form-group col-md-2 col-sm-12">
                        <input disabled={true} type="text" value={action.transitions[0][2]}></input>
                      </div>

                      <div className="form-group col-md-2 col-sm-12">
                        <span
                          className="btn btn-default obj-action-btn text-edit"
                          onClick={() =>
                            dispatch(openEditMouseOverBehaviorModal("mouseover", idx, action))
                          }
                        >
                          <EditIcon />
                        </span>
                        <span
                          className="btn btn-default btn-delete obj-action-btn text-delete"
                          onClick={() => deleteMouseActionEventConfirmation("mouseover", idx)}
                        >
                          <DeleteIcon />
                        </span>
                      </div>
                    </div>
                  </>
                ))}

              <div className="input-control row property-section">
                <button
                  className="btn btn-primary btn-switch"
                  onClick={(evt) => addNewMouseOverEvent(evt)}
                >
                  Add Mouse Behavior
                </button>
              </div>
            </div>

            {gameObject && gameObject.behaviors.draggable ? (
              ""
            ) : (
              <div className="form-group">
                <div className="input-control row property-section">
                  <button
                    className="btn btn-primary btn-switch"
                    onClick={(evt) => addNewMouseOverEvent(evt)}
                  >
                    Add New Mouse Over Event
                  </button>
                  <button
                    className="btn btn-primary btn-switch"
                    onClick={(evt) => addNewMouseOverEvent(evt)}
                  >
                    Add New Mouse Over Event
                  </button>
                </div>
              </div>
            )}
          </>
        ) : (
          ""
        )}
      </div>

      <Modal show={isShowEditMouseOverModal} onHide={handleMouseOverClose}>
        {isLoaderEnabled ? <Loader /> : ""}
        <Modal.Header closeButton>
          <Modal.Title>{isEdit ? "Edit Mouse Over" : "Add Mouse Over"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label>Mouse Action Type</label>

            <div className="input-control">
              <span className="custom-control custom-radio custom-control-inline">
                <input
                  type="radio"
                  disabled={mouseEventActionObject}
                  value={"mouseOver"}
                  checked={mouseEvent.eventType === "mouseOver" ? true : false}
                  onChange={(e) => {
                    handleChangeAction(e, "eventType");
                    setDefaultStateValue();
                    setMouseEvent({
                      target: "",
                      layer: "",
                      alpha: 1,
                      delay: "",
                      eventType: "mouseOver",
                    });
                  }}
                  name="mouseEventType"
                  className="custom-control-input"
                  id="over-object"
                />
                <label className="custom-control-label" htmlFor="over-object">
                  {"Mouse Over"}
                </label>
              </span>
              <span className="custom-control custom-radio custom-control-inline">
                <input
                  type="radio"
                  disabled={mouseEventActionObject}
                  value={"mouseOut"}
                  checked={mouseEvent.eventType === "mouseOut" ? true : false}
                  onChange={(e) => {
                    handleChangeAction(e, "eventType");
                    setDefaultStateValue();
                    setMouseEvent({
                      target: "",
                      layer: "",
                      alpha: 1,
                      delay: "",
                      eventType: "mouseOut",
                    });
                  }}
                  name="mouseEventType"
                  className="custom-control-input"
                  id="out-object"
                />
                <label className="custom-control-label" htmlFor="out-object">
                  {"Mouse Out"}
                </label>
              </span>
            </div>
          </div>
          <div className="form-group">
            <label>Target Object</label>
            <select
              class="custom-select"
              name="targetObject"
              value={mouseEvent.target}
              onChange={(e) => handleChangeAction(e, "targetObj")}
            >
              <option key={""} value={""}>
                {"Select Target Object"}
              </option>
              {gameObjectOptions}
            </select>
            {errors && errors.targetIsEmpty && <small>{errors.targetIsEmpty}</small>}
          </div>

          <div className="form-group">
            <label>Action on Target Object</label>
            <div className="input-control">
              <span className="custom-control custom-radio custom-control-inline">
                <input
                  type="radio"
                  value={1}
                  checked={mouseEvent.alpha = 1 ? true : false}
                  onChange={(e) => handleChangeAction(e, "event")}
                  name="mouseActionType"
                  className="custom-control-input"
                  id="add-object"
                />
                <label className="custom-control-label" htmlFor="add-object">
                  {"Add Object"}
                </label>
              </span>
              <span className="custom-control custom-radio custom-control-inline">
                <input
                  type="radio"
                  value={0}
                  checked={mouseEvent.alpha === 0 ? true : false}
                  onChange={(e) => handleChangeAction(e, "event")}
                  name="mouseActionType"
                  className="custom-control-input"
                  id="remove-object"
                />
                <label className="custom-control-label" htmlFor="remove-object">
                  {"Remove Object"}
                </label>
              </span>
            </div>
          </div>

          <div className="form-group">
            <label>Delay(in milliseconds)</label>
            <input
              type="number"
              pattern="[0-9]*"
              inputMode="numeric"
              name="delay"
              placeholder="Delay Duration(in milliseconds)"
              value={mouseEvent.delay}
              onChange={(e) => handleChangeAction(e, "delay")}
            />
            {errors && errors.delayIsEmpty && <small>{errors.delayIsEmpty}</small>}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={(evt) => handleSave(evt)}>
            Save Changes
          </Button>
          <Button variant="secondary" onClick={(evt) => handleMouseOverClose()}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <EditPathBehaviorModal
        sourceCode={sourceCode}
        sceneIndex={sceneIndex}
        gameObjectKey={gameObjectkey}
      />
    </>
  );
};

function mapStateToProps(state) {
  return {
    isShowEditMouseOverModal: state.gameObject.isShowEditMouseOverModal,
    editMouseEventType: state.gameObject.editMouseEventType,
    mouseEventIndex: state.gameObject.mouseEventIndex,
    mouseEventActionObject: state.gameObject.mouseEventActionObject,
    gameObjectkey: state.gameObject.gameObjectKey,
    isLoaderEnabled: state.gameObject.isLoaderEnabled,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateSelectedTrueCreativePropsFunc: (creativeUid, sourceCode, behaviorBool) =>
      dispatch(updateSelectedTrueCreativePropsFunc(creativeUid, sourceCode, behaviorBool)),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(DragableGameObjectBehavior);
