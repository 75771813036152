import { Types } from "./../constants/videoline-types";
import { beginApiCall, apiCallError } from "./apiStatus";
import API from "../../services/videolineService";

const setVideoLines = (videolines) => {
  return {
    type: Types.GET_ALL_XPS_VIDEOLINES_CAMPAIGN_ID,
    payload: videolines,
  };
};

const setVideoLineMap = (videolineMap) => {
  return {
    type: Types.VIDEO_LINE_MAP,
    payload: videolineMap,
  };
};

const setErrorInLoadingVideolines = () => {
  return {
    type: Types.ERROR_IN_LOADING_VIDEOLINES,
    payload: "Error in loading Videolines",
  };
};

const setSelectedVideoline = (videoline, videolineVersion) => {
  return {
    type: Types.GET_CURRENT_VIDEOLINE,
    payload: {
      videoline: videoline,
      videolineVersion: videolineVersion,
    },
  };
};

//Load Video line Map
export const loadVideoLineMap = () => async (dispatch) => {
  try {
    dispatch(beginApiCall());
    await API.loadVideoLineMap().then(
      (data) => {
        dispatch(setVideoLineMap(data));
      },
      (error) => {
        console.log(error);
      }
    );
  } catch (err) {
    dispatch(apiCallError());
  }
};

//Load all xps videolines
export const loadXPSVideoLinesByCampaignId = (campaignId) => async (dispatch, getState) => {
  try {
    dispatch(beginApiCall());
    await API.loadVideolinesByCampaignId(campaignId).then(
      (data) => {
        dispatch(setVideoLines(data));
      },
      (error) => {
        console.log(error);
        dispatch(setErrorInLoadingVideolines());
      }
    );
  } catch (err) {
    dispatch(apiCallError());
    dispatch(setErrorInLoadingVideolines());
  }
};

const clearXPSVideoLines = () => {
  return {
    type: Types.CLEAR_XPSVIDEO_LINES,
    payload: undefined,
  };
};

export const clearXPSVideos = () => async (dispatch) => {
  dispatch(clearXPSVideoLines());
};

//Load all xps videolines
export const loadXPSVideoLinesByCampaignId2 = (campaignId) => async (dispatch, getState) => {
  try {
    dispatch(beginApiCall());

    const billingRecordIds = await API.loadVideoBillingRecords(campaignId);
    let jsonObj = {};

    for (const billingRecordId in billingRecordIds) {
      const videoLineIds = await API.loadVideoLines(billingRecordIds[billingRecordId]);

      for (const id in videoLineIds) {
        let res = await API.getSelectedVideoLineDetail(videoLineIds[id]);
        jsonObj[videoLineIds[id]] = res;
      }
    }

    // if (Object.keys(jsonObj).length == 0) { jsonObj = null };
    dispatch(setVideoLines(jsonObj));
  } catch (err) {
    dispatch(apiCallError());
    dispatch(setErrorInLoadingVideolines());
  }
};

//Load all xps videoline by UID
export const getSelectedVideolineDetail = (lineId) => async (dispatch) => {
  try {
    dispatch(beginApiCall());
    const videoline = await API.getSelectedVideoLineDetail(lineId);
    await API.getSelectedVideoLineVersionDetail(videoline.video_line_version).then(
      (data) => {
        dispatch(setSelectedVideoline(videoline, data));
      },
      (error) => {
        console.log(error);
      }
    );
  } catch (err) {
    dispatch(apiCallError());
  }
};
