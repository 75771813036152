import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { useDispatch } from "react-redux";
import { getCurrentObjectFromSourceCode } from "./../../utils/appUtils";
import { updateSelectedTrueCreativePropsFunc } from "../../store/actions/truengageCreatives";
import EditGameObjectBehaviorModal from "../../components/modals/editGameObjectBehaviorModal";
import { openEditGameObjectBehaviorModal } from "../../store/actions/gameObjects";
import { ReactComponent as EditIcon } from "../../images/edit.svg";
import { ReactComponent as DeleteIcon } from "../../images/trash.svg";
import { ReactComponent as ArrowIcon } from "../../images/down-arrow.svg";
import { confirmAlert } from "react-confirm-alert"; // Import

const GameObjectBehavior = ({
  gameObject,
  sourceCode,
  sceneIndex,
  gameObjectKey,
  selectedLayers,
  creativeUid,
}) => {
  const [isShowDetail, setIsShowDetail] = useState(false);
  const [currentGameObjectBehavior, setCurrentGameObjectBehavior] = useState("click");
  const dispatch = useDispatch();
  useEffect(() => {
    if (gameObject && gameObject.behaviors && gameObject.behaviors.clickable) {
      setCurrentGameObjectBehavior("click");
    }
  }, [gameObject]);

  const handleClickableDelete = (idx) => {
    confirmAlert({
      title: "Confirm to delete",
      message: `Are you sure to delete`,
      buttons: [
        {
          label: "Yes",
          onClick: () => handleClickableObjDelete(idx),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const handleClickableObjDelete = (idx) => {
    var currentGameObject = getCurrentObjectFromSourceCode(sourceCode, sceneIndex, gameObjectKey);
    if (
      currentGameObject &&
      currentGameObject.behaviors &&
      currentGameObject.behaviors.clickable &&
      currentGameObject.behaviors.clickable.actions &&
      currentGameObject.behaviors.clickable.actions.click &&
      currentGameObject.behaviors.clickable.actions.click[idx]
    ) {
      if (currentGameObject.behaviors.clickable.actions.click.length === 1) {
        delete currentGameObject["behaviors"];
        dispatch(updateSelectedTrueCreativePropsFunc(creativeUid, sourceCode, true));
      } else {
        currentGameObject.behaviors.clickable.actions.click.splice(idx, 1);
        dispatch(updateSelectedTrueCreativePropsFunc(creativeUid, sourceCode, true));
      }
    }
  };

  const handleTweenDelete = () => {
    confirmAlert({
      title: "Confirm to delete",
      message: `Are you sure to delete`,
      buttons: [
        {
          label: "Yes",
          onClick: () => handleTweenObjDelete(),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const handleTweenObjDelete = () => {
    var currentGameObject = getCurrentObjectFromSourceCode(sourceCode, sceneIndex, gameObjectKey);
    if (
      currentGameObject &&
      currentGameObject.behaviors &&
      currentGameObject.behaviors.tween &&
      currentGameObject.behaviors.tween.transitions
    ) {
      delete currentGameObject["behaviors"];
      dispatch(updateSelectedTrueCreativePropsFunc(creativeUid, sourceCode, true));
    }
  };

  const handlePathDelete = () => {
    confirmAlert({
      title: "Confirm to delete",
      message: `Are you sure to delete`,
      buttons: [
        {
          label: "Yes",
          onClick: () => handlePathObjDelete(),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const handlePathObjDelete = () => {
    var currentGameObject = getCurrentObjectFromSourceCode(sourceCode, sceneIndex, gameObjectKey);
    if (
      currentGameObject &&
      currentGameObject.behaviors &&
      currentGameObject.behaviors.path &&
      currentGameObject.behaviors.path.transitions
    ) {
      delete currentGameObject["behaviors"];
      dispatch(updateSelectedTrueCreativePropsFunc(creativeUid, sourceCode, true));
    }
  };

  const addNewbehaviour = (e) => {
    e.preventDefault();
    dispatch(openEditGameObjectBehaviorModal("click", undefined, undefined, false));
  };

  const addNewClickableBehaviour = (e) => {
    e.preventDefault();
    dispatch(openEditGameObjectBehaviorModal("click", undefined, undefined, true));
  };

  return (
    <div className={isShowDetail ? "bin-object-container show" : "bin-object-container"}>
      <h3
        style={{ cursor: "pointer" }}
        onClick={() => setIsShowDetail(!isShowDetail)}
        className="toggle-content-title"
      >
        Game Object Behaviors
        <span className="arrow">
          <ArrowIcon />
        </span>
      </h3>

      {isShowDetail ? (
        <>
          <div
            className="form-group"
            hidden={!(gameObject && gameObject.behaviors && gameObject.behaviors.clickable)}
          >
            <label>Clickable Behaviors</label>
            {gameObject &&
              gameObject.behaviors &&
              gameObject.behaviors.clickable &&
              gameObject.behaviors.clickable.actions.click.map((action, idx) => (
                <div className="row property-section">
                  <div className="form-group col-md-5 col-sm-6">
                    <label>{"Action Type"}</label>
                    <input type="text" disabled={true} value={action.type}></input>
                  </div>
                  <div className="form-group col-md-4 col-sm-6">
                    <label>{action.type === "advanceGame" ? "Delay" : "Navigation Url"}</label>
                    <input
                      disabled={true}
                      type="text"
                      value={action.type === "advanceGame" ? action.delay : action.url}
                    ></input>
                  </div>
                  <div className="form-group col-md-3 col-sm-12">
                    <span
                      className="btn btn-default obj-action-btn text-edit"
                      onClick={() =>
                        dispatch(
                          openEditGameObjectBehaviorModal("click", gameObject.behaviors, idx)
                        )
                      }
                    >
                      <EditIcon />
                    </span>
                    <span
                      className="btn btn-default btn-delete obj-action-btn text-delete"
                      onClick={() => handleClickableDelete(idx)}
                    >
                      <DeleteIcon />
                    </span>
                  </div>
                </div>
              ))}

            <div
              className="row property-section"
              hidden={
                gameObject &&
                gameObject.behaviors &&
                gameObject.behaviors.clickable &&
                gameObject.behaviors.clickable.actions &&
                gameObject.behaviors.clickable.actions.click &&
                gameObject.behaviors.clickable.actions.click.length >= 2
              }
            >
              <button
                className="btn btn-primary btn-switch"
                onClick={(evt) => addNewClickableBehaviour(evt)}
              >
                Add New Clickable Behavior
              </button>
            </div>
          </div>

          <div
            className="form-group"
            hidden={
              !(
                gameObject &&
                gameObject.behaviors &&
                gameObject.behaviors.tween &&
                gameObject.behaviors.tween.transitions &&
                gameObject.behaviors.tween.transitions.length === 1
              )
            }
          >
            <label>Tween Move Behaviors</label>
            {gameObject &&
              gameObject.behaviors &&
              gameObject.behaviors.tween &&
              gameObject.behaviors.tween.transitions &&
              gameObject.behaviors.tween.transitions.length === 1 &&
              gameObject.behaviors.tween.transitions.map((transition, idx) => (
                <div className="row property-section">
                  <div className="form-group col-md-3 col-sm-6">
                    <label>{"Move Direction"}</label>
                    <input
                      disabled={true}
                      type="text"
                      value={transition[1] && transition[1].x ? "Horizontal" : "Vertical"}
                    ></input>
                  </div>
                  <div className="form-group col-md-3 col-sm-6">
                    <label>{"Final Position"}</label>
                    <input
                      disabled={true}
                      type="text"
                      value={
                        transition[1] || transition[1].y ? transition[1].x || transition[1].y : ""
                      }
                    ></input>
                  </div>
                  <div className="form-group col-md-3 col-sm-6">
                    <label>{"Delay(in milliseconds)"}</label>
                    <input
                      disabled={true}
                      type="text"
                      value={transition[2] ? transition[2] : ""}
                    ></input>
                  </div>
                  <div className="form-group col-md-2 col-sm-12">
                    <span
                      className="btn btn-default obj-action-btn text-edit"
                      onClick={() =>
                        dispatch(
                          openEditGameObjectBehaviorModal("tween", gameObject.behaviors, idx)
                        )
                      }
                    >
                      <EditIcon />
                    </span>
                    <span
                      className="btn btn-default btn-delete obj-action-btn text-delete"
                      onClick={() => handleTweenDelete()}
                    >
                      <DeleteIcon />
                    </span>
                  </div>
                </div>
              ))}
          </div>

          <div
            className="form-group"
            hidden={
              !(
                gameObject &&
                gameObject.behaviors &&
                gameObject.behaviors.tween &&
                gameObject.behaviors.tween.transitions &&
                gameObject.behaviors.tween.transitions.length === 2
              )
            }
          >
            <div className="row">
              <div className="form-group col-md-10 col-sm-6">
                <label>Tween Resize Behaviors</label>
              </div>
              <div className="form-group col-md-2 col-sm-6">
                <span
                  className="btn btn-default obj-action-btn text-edit"
                  onClick={() =>
                    dispatch(openEditGameObjectBehaviorModal("tween", gameObject.behaviors, 0))
                  }
                >
                  <EditIcon />
                </span>
                <span
                  className="btn btn-default btn-delete obj-action-btn text-delete"
                  onClick={() => handleTweenDelete()}
                >
                  <DeleteIcon />
                </span>
              </div>
            </div>

            {gameObject &&
            gameObject.behaviors &&
            gameObject.behaviors.tween &&
            gameObject.behaviors.tween.transitions &&
            gameObject.behaviors.tween.transitions.length === 2 ? (
              <div className="row property-section">
                <div className="form-group col-md-3 col-sm-6">
                  <label>{"Direction"}</label>
                </div>
                <div className="form-group col-md-3 col-sm-6">
                  <label>{"Position"}</label>
                </div>
                <div className="form-group col-md-3 col-sm-6">
                  <label>{"Scale Value"} </label>
                </div>
                <div className="form-group col-md-3 col-sm-6">
                  <label>{"Delay(in milliseconds)"}</label>
                </div>
              </div>
            ) : (
              ""
            )}
            {gameObject &&
              gameObject.behaviors &&
              gameObject.behaviors.tween &&
              gameObject.behaviors.tween.transitions &&
              gameObject.behaviors.tween.transitions.length === 2 &&
              gameObject.behaviors.tween.transitions.map((transition, idx) => (
                <div className="row property-section">
                  <div className="form-group col-md-3 col-sm-6">
                    <input
                      type="text"
                      disabled={true}
                      value={transition[1] && transition[1].x ? "Horizontal" : "Vertical"}
                    ></input>
                  </div>
                  <div className="form-group col-md-3 col-sm-6">
                    <input
                      disabled={true}
                      type="text"
                      value={
                        transition[1] || transition[1].y ? transition[1].x || transition[1].y : ""
                      }
                    ></input>
                  </div>
                  <div className="form-group col-md-3 col-sm-6">
                    <input
                      disabled={true}
                      type="text"
                      value={
                        transition[1] || transition[1].scaleY
                          ? transition[1].scaleX || transition[1].scaleY
                          : ""
                      }
                    ></input>
                  </div>
                  <div className="form-group col-md-3 col-sm-6">
                    <input
                      disabled={true}
                      type="text"
                      value={transition[2] ? transition[2] : ""}
                    ></input>
                  </div>
                </div>
              ))}
          </div>

          <div
            className="form-group"
            hidden={
              !(
                gameObject &&
                gameObject.behaviors &&
                gameObject.behaviors.path &&
                gameObject.behaviors.path.transitions &&
                gameObject.behaviors.path.transitions.length > 0
              )
            }
          >
            <div className="row">
              <div className="form-group col-md-10 col-sm-6">
                <label>Path Behaviors</label>
              </div>
              <div className="form-group col-md-2 col-sm-6">
                <span
                  className="btn btn-default obj-action-btn text-edit"
                  onClick={() =>
                    dispatch(openEditGameObjectBehaviorModal("path", gameObject.behaviors, 0))
                  }
                >
                  <EditIcon />
                </span>
                <span
                  className="btn btn-default btn-delete obj-action-btn text-delete"
                  onClick={() => handlePathDelete()}
                >
                  <DeleteIcon />
                </span>
              </div>
            </div>
            <div>
              {gameObject &&
              gameObject.behaviors &&
              gameObject.behaviors.path &&
              gameObject.behaviors.path.transitions &&
              gameObject.behaviors.path.transitions.length > 0 ? (
                <div className="row property-section">
                  <div className="form-group col-md-4 col-sm-6">
                    <label>{"Position:X"}</label>
                  </div>
                  <div className="form-group col-md-4 col-sm-6">
                    <label>{"Position:Y"}</label>
                  </div>
                  <div className="form-group col-md-4 col-sm-6">
                    <label>{"Delay(in milliseconds)"}</label>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            {gameObject &&
              gameObject.behaviors &&
              gameObject.behaviors.path &&
              gameObject.behaviors.path.transitions &&
              gameObject.behaviors.path.transitions.length > 0 &&
              gameObject.behaviors.path.transitions.map((transition, idx) => (
                <div className="row property-section">
                  <div className="form-group col-md-4 col-sm-6">
                    {/* <label>{"Position:X"}</label> */}
                    <input disabled={true} type="text" value={transition.x}></input>
                  </div>
                  <div className="form-group col-md-4 col-sm-6">
                    {/* <label>{"Position:Y"}</label> */}
                    <input disabled={true} type="text" value={transition.y}></input>
                  </div>
                  <div className="form-group col-md-4 col-sm-6">
                    {/* <label>{"Delay"}</label> */}
                    <input disabled={true} type="text" value={transition.duration}></input>
                  </div>
                </div>
              ))}
          </div>

          {gameObject && gameObject.behaviors ? (
            ""
          ) : (
            <div className="form-group">
              <div className="input-control row property-section">
                <button
                  className="btn btn-primary btn-switch"
                  onClick={(evt) => addNewbehaviour(evt)}
                >
                  Add New Behavior
                </button>
              </div>
            </div>
          )}
        </>
      ) : (
        ""
      )}

      <EditGameObjectBehaviorModal
        currentGameObject={gameObject}
        sourceCode={sourceCode}
        sceneIndex={sceneIndex}
        gameObjectKey={gameObjectKey}
        selectedLayers={selectedLayers}
      />
    </div>
  );
};

function mapStateToProps(state) {
  return {
    behaviorType: state.gameObject.behaviorType,
    editBehavior: state.gameObject.editBehavior,
    behaviorIndex: state.gameObject.behaviorIndex,
    creativeUid: state.gameObject.creativeUid,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(GameObjectBehavior);
