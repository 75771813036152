import firebase from "./../config/firebase";
import { firestore } from "firebase";
//import * as R from "ramda";
const db = firebase.database();
const firestoreDB = firebase.firestore();


const loadXPSAccounts = () => {
  return new Promise((resolve) => {
    db.ref("/accounts").orderByChild("name").once("value", (querySnapShot) => {
      const response = undefined;
      if (!querySnapShot.err) {
        resolve(querySnapShot.val());
      } else {
        resolve(response);
      }
    });
  });
};

const getSelectedAccountDetail = (accountId) => {
  const response = undefined;
  return new Promise((resolve) => {
    db.ref("accounts/" + accountId).once("value", (querySnapShot) => {
      if (!querySnapShot.err) {
        resolve(querySnapShot.val());
      } else {
        resolve(response);
      }
    });
  });
};

export default {
  loadXPSAccounts,
  getSelectedAccountDetail,
};
