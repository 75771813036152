import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { useDispatch } from "react-redux";
import { openEditLaunchableGameObjectBehaviorModal } from "../../store/actions/gameObjects";
import { ReactComponent as EditIcon } from "../../images/edit.svg";
import { ReactComponent as DeleteIcon } from "../../images/trash.svg";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { reloadArray } from "../../../src/utils/appUtils";
import EditLaunchableBehaviorActionModal from "../../components/modals/editLaunchableBehaviorActionModal";
import { updateSelectedTrueCreativePropsFunc } from "../../store/actions/truengageCreatives";
import { ReactComponent as ArrowIcon } from "../../images/down-arrow.svg";
import infoIcon from "../../images/info-logo.svg";
import ReactTooltip from "react-tooltip";
import { reloadToolTipDesc, launchableActionToolTipDesc } from "./../../constants/defaultValues";
const LaunchableGameObjectBehavior = ({
  currentGameObject,
  sourceCode,
  sceneIndex,
  selectedLayers,
  gameObjectKey,
  creativeUid,
}) => {
  const dispatch = useDispatch();
  const [isShowDetail, setIsShowDetail] = useState(false);
  const [reloads, setReloads] = useState(2);
  const [actions, setActions] = useState();
  useEffect(() => {
    if (currentGameObject) {
      setReloads(
        currentGameObject &&
          currentGameObject.behaviors &&
          currentGameObject.behaviors.launchable &&
          currentGameObject.behaviors.launchable.reloads
          ? currentGameObject.behaviors.launchable.reloads
          : 1
      );
      setActions(
        currentGameObject &&
          currentGameObject.behaviors &&
          currentGameObject.behaviors.launchable &&
          currentGameObject.behaviors.launchable.actions &&
          currentGameObject.behaviors.launchable.actions.empty
          ? currentGameObject.behaviors.launchable.actions.empty
          : []
      );
    }
  }, [currentGameObject]);

  const handleShow = (e) => {
    e.preventDefault();
    dispatch(openEditLaunchableGameObjectBehaviorModal(undefined, undefined));
  };

  const reloadOptionsArray = reloadArray();
  const reloadOptions = reloadOptionsArray.map((elem) => (
    <option key={elem} value={elem}>
      {elem}
    </option>
  ));

  const handleLaunchableBehaviorChange = (e) => {
    e.persist();
    let copyObj = { ...currentGameObject };
    if (e.target.name === "reloads") {
      setReloads(e.target.value && e.target.value !== "" ? Number(e.target.value) : "");
      copyObj.behaviors.launchable.reloads =
        e.target.value && e.target.value !== "" ? Number(e.target.value) : "";
    }
  };

  const deleteLaunchableBehaviorActionConfirmation = (index) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to to delete Behavior Action.",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteLaunchableBehaviorAction(index),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const deleteLaunchableBehaviorAction = (index) => {
    if (
      currentGameObject &&
      currentGameObject.behaviors &&
      currentGameObject.behaviors.launchable &&
      currentGameObject.behaviors.launchable.actions &&
      currentGameObject.behaviors.launchable.actions.empty &&
      currentGameObject.behaviors.launchable.actions.empty[index]
    ) {
      currentGameObject.behaviors.launchable.actions.empty.splice(index, 1);
    }
    dispatch(updateSelectedTrueCreativePropsFunc(creativeUid, sourceCode, true));
  };

  function _launchableBehaviorActions(actions) {
    return actions.map((action, index) => {
      return (
        <div className="form-group">
          {action.type === "advanceGame" ? (
            <div className="row property-section">
              <div className="form-group col-md-5 col-sm-6">
                <label>{"Action Type"}</label>
                <input type="text" disabled={true} value={action.type}></input>
              </div>
              <div className="form-group col-md-4 col-sm-6">
                <label>{"Delay(in milliseconds)"}</label>
                <input disabled={true} type="text" value={action.delay ? action.delay : ""}></input>
              </div>
              <div className="form-group col-md-3 col-sm-12">
                <span
                  className="btn btn-default obj-action-btn text-edit"
                  onClick={() =>
                    dispatch(openEditLaunchableGameObjectBehaviorModal(actions, index))
                  }
                >
                  <EditIcon />
                </span>

                <span
                  className="btn btn-default btn-delete obj-action-btn text-delete"
                  onClick={() => deleteLaunchableBehaviorActionConfirmation(index)}
                >
                  <DeleteIcon />
                </span>
              </div>
            </div>
          ) : (
            <div className="row property-section">
              <div className="form-group col-md-3 col-sm-6">
                <label>{"Action Type"}</label>
                <input type="text" disabled={true} value={action.type}></input>
              </div>
              <div className="form-group col-md-3 col-sm-6">
                <label>{"Layer"}</label>
                <input disabled={true} type="text" value={action.layer ? action.layer : ""}></input>
              </div>
              <div className="form-group col-md-3 col-sm-6">
                <label>{"Target Object"}</label>
                <input
                  disabled={true}
                  type="text"
                  value={action.target ? action.target : ""}
                ></input>
              </div>
              <div className="form-group col-md-3 col-sm-12">
                <span
                  className="btn btn-default obj-action-btn text-edit"
                  onClick={() =>
                    dispatch(openEditLaunchableGameObjectBehaviorModal(actions, index))
                  }
                >
                  <EditIcon />
                </span>

                <span
                  className="btn btn-default btn-delete obj-action-btn text-delete"
                  onClick={() => deleteLaunchableBehaviorActionConfirmation(index)}
                >
                  <DeleteIcon />
                </span>
              </div>
            </div>
          )}
        </div>
      );
    });
  }

  return (
    <div className={isShowDetail ? "bin-object-container show" : "bin-object-container"}>
      <h3 onClick={() => setIsShowDetail(!isShowDetail)} className="toggle-content-title">
        Launchable Game Object Behaviors
        <span className="arrow">
          <ArrowIcon />
        </span>
      </h3>
      {isShowDetail ? (
        <>
          <div className="form-group">
            <label>Default Behavior Properties</label>
            <div className="input-control row property-section">
              <div className="col-md-6 col-sm-6">
                <label>Retry Count</label>
                <span className="form-group info-icon" data-tip data-for="reloadToolTipDesc">
                  <img src={infoIcon} width="15px" height="15px" alt="" />
                </span>
                <ReactTooltip id="reloadToolTipDesc" place="right" effect="solid">
                  <span class="tooltip-text">{reloadToolTipDesc}</span>
                </ReactTooltip>
                <select
                  name="reloads"
                  className="custom-select"
                  value={reloads}
                  onChange={handleLaunchableBehaviorChange}
                >
                  {reloadOptions}
                </select>
              </div>
            </div>
          </div>
          <div className="form-group">
            <label>Behavior Actions</label>
            <span className="form-group info-icon" data-tip data-for="launchableActionToolTipDesc">
              <img src={infoIcon} width="15px" height="15px" alt="" />
            </span>
            <ReactTooltip id="launchableActionToolTipDesc" place="right" effect="solid">
              <span class="tooltip-text">{launchableActionToolTipDesc}</span>
            </ReactTooltip>
            {_launchableBehaviorActions(actions)}
            <div className="input-control row property-section">
              <button className="btn btn-primary btn-switch" onClick={handleShow}>
                Add New Action
              </button>
            </div>
          </div>

          <EditLaunchableBehaviorActionModal
            sceneIndex={sceneIndex}
            selectedLayers={selectedLayers}
            sourceCode={sourceCode}
            gameObjectKey={gameObjectKey}
          />
        </>
      ) : (
        ""
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    creativeUid: state.gameObject.creativeUid,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    openEditLaunchableGameObjectBehaviorModal: (actions, actionIndex) =>
      dispatch(openEditLaunchableGameObjectBehaviorModal(actions, actionIndex)),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(LaunchableGameObjectBehavior);
