import React, { useEffect, useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
const TruegagePreview = ({ sourceCode, truengageCreativeType, width, height, versionURL }) => {
  const [frameWidth, setFrameWidth] = useState("640");
  const [frameHeight, setFrameHeight] = useState("360");

  function Iframe(props) {
    return <div dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : "" }} />;
  }

  useEffect(() => {
    /*if (truengageCreativeType && truengageCreativeType === "Collectable") {
      setFrameWidth(width ? width : "640");
      setFrameHeight(height ? height : "640");
    }*/
  }, [sourceCode, truengageCreativeType, width, height, versionURL]);

  const ad = {
    preview:
      '<iframe width="' +
      frameWidth +
      '" height="' +
      frameHeight +
      '" scrolling="no" frameborder="1" src="/truengage_preview?width=' +
      frameWidth +
      "&height=" +
      frameHeight +
      "&versionURL=" +
      versionURL +
      "&sourceCode=" +
      window.btoa(JSON.stringify(sourceCode)) +
      '"></iframe>',
  };

  return <>{versionURL ? <Iframe iframe={ad["preview"]} /> : null}</>;
};

function mapStateToProps(state) {
  return {
    auth: state.firebase.auth,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(TruegagePreview);
