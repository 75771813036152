import { Types } from "./../constants/truengage-creative-types";
import API from "../../services/truengageCreativeService";
import jynxVersionAPI from "../../services/jynxVersionService";
import { beginApiCall, apiCallError } from "./apiStatus";
import { deleteGameObject, addNewGameState } from "./../../utils/appUtils";
import {
  newDragableStateSource,
  newLaunchableStateSource,
  newCollectableStateSource,
  newDrawableStateSource,
} from "./../../constants/defaultValues";
import { updateLoaderState } from "./gameObjects";
import authService from "../../services/authService";

const setTruengageCreatives = (truengageCreatives) => {
  return {
    type: Types.GET_TRUENGAGE_CREATIVES_BY_LINE_ID,
    payload: truengageCreatives,
  };
};

const setPublishCreativeResponse = (status) => {
  return {
    type: Types.PUBLISH_CREATIVE_RESPONSE,
    payload: {
      status: status,
    },
  };
};

const setTruengageCreativeErrorMessage = () => {
  return {
    type: Types.GET_TRUENGAGE_CREATIVE_ERROR_MESSAGE,
    payload: "No Truengage creative found for selected videoline",
  };
};

const clearSelectedCreative = () => {
  return {
    type: Types.CLEAR_CURRENT_CREATIVE,
    payload: undefined,
  };
};

// to open Edit Game Object Modal
export const openEditTruengageCreativeModal = (truengageCreativeId, truengageCreative) => async (
  dispatch
) => {
  dispatch({
    type: Types.SHOW_EDIT_TRUENGAGE_CREATIVE_MODAL,
    payload: {
      status: true,
      editTruengageCreative: truengageCreative,
      editTruengageCreativeId: truengageCreativeId,
    },
  });
};
// to close Edit Game Object Modal
export const closeEditTruengageCreativeModal = () => async (dispatch) => {
  dispatch({
    type: Types.HIDE_EDIT_TRUENGAGE_CREATIVE_MODAL,
    payload: false,
  });
};
// to open View Creative Source Modal
export const openViewTruengageCreativeModal = (source) => async (dispatch) => {
  dispatch({
    type: Types.SHOW_VIEW_TRUENGAGE_CREATIVE_MODAL,
    payload: { status: true, selectedSource: source },
  });
};
// to close View Creative Source Modal
export const closeViewTruengageCreativeModal = () => async (dispatch) => {
  dispatch({
    type: Types.HIDE_VIEW_TRUENGAGE_CREATIVE_MODAL,
    payload: { status: false, selectedSource: undefined },
  });
};

const setJynxVersions = (versions) => {
  return {
    type: Types.SET_JYNX_VERSIONS,
    payload: versions,
  };
};

const setTruengageCreative = (
  truengageCreative,
  sourceCode,
  creativeName,
  originalResponse,
  creativeAccountId,
  gameObjectArray,
  isCpcBannerEnabled,
  truengageCreativeType
) => {
  return {
    type: Types.CURRENT_SELECTED_TRUENGAGE_CREATIVE,
    payload: {
      truengageCreative: truengageCreative,
      sourceCode: sourceCode,
      creativeName: creativeName,
      originalResponse: originalResponse,
      creativeAccountId: creativeAccountId,
      gameObjectArray: gameObjectArray,
      isCpcBannerEnabled: isCpcBannerEnabled,
      truengageCreativeType: truengageCreativeType,
    },
  };
};

export const resetOperationResponse = () => async (dispatch) => {
  dispatch({
    type: Types.RESET_OPERATION_RESPONSE,
    payload: null,
  });
};

export const updateSelectedTrueCreative = (values) => async (dispatch) => {
  dispatch({
    type: Types.UPDATE_SELECTED_TRUENGAGE_CREATIVE,
    payload: values,
  });
};

export const deleteGameStateObject = (values) => async (dispatch) => {
  dispatch({
    type: Types.DELETE_GAME_STATE,
    payload: values,
  });
};

export const saveTrueEngageCreativeResource = (values) => async (dispatch) => {
  dispatch({
    type: Types.SAVE_TRUENGAGE_CREATIVE,
    payload: values,
  });
};

/**
 * to delete Game Object from Source Code
 * @param {*} creativeUid
 * @param {*} objectName
 * @param {*} objectType
 * @param {*} sceneIndex
 * @param {*} sourceCode
 */
export const deleteGameObjectFunc = (
  creativeUid,
  objectName,
  objectType,
  sceneIndex,
  sourceCode,
  truengageCreativeType
) => async (dispatch) => {
  const updatedSourceCode = deleteGameObject(
    sourceCode,
    sceneIndex,
    objectName,
    objectType,
    truengageCreativeType
  );
  await API.updateSelectedTrueCreativePropsFunc(creativeUid, updatedSourceCode);
  await API.getTruengageCreativePropsByID(creativeUid).then(
    (data) => {
      if (data != null) {
        updateTruengageCreativeRedux(dispatch, data);
      }
    },
    (error) => {
      console.log(error);
    }
  );
};

/**
 * to save new Game Object in Source Code
 * @param {*} creativeUid
 * @param {*} sourceCode
 */
export const addGameStateFunc = (creativeUid, sourceCode, truengageCreativeType) => async (
  dispatch
) => {
  var updatedSourceCode = "";
  if (truengageCreativeType === "Draggable") {
    updatedSourceCode = addNewGameState(JSON.stringify(newDragableStateSource), sourceCode);
  } else if (truengageCreativeType === "Launchable") {
    updatedSourceCode = addNewGameState(JSON.stringify(newLaunchableStateSource), sourceCode);
  } else if (truengageCreativeType === "Collectable") {
    updatedSourceCode = addNewGameState(JSON.stringify(newCollectableStateSource), sourceCode);
  } else if (truengageCreativeType === "Drawing") {
    updatedSourceCode = addNewGameState(JSON.stringify(newDrawableStateSource), sourceCode);
  }
  await API.updateSelectedTrueCreativePropsFunc(creativeUid, updatedSourceCode);
  await API.getTruengageCreativePropsByID(creativeUid).then(
    (data) => {
      if (data !== null) {
        updateTruengageCreativeRedux(dispatch, data);
      }
    },
    (error) => {
      console.log(error);
    }
  );
};

/**
 * to update Game Object props in source code
 * @param {*} uid
 * @param {*} sourceCode
 */
export const updateSelectedTrueCreativePropsFunc = (uid, sourceCode, behaviourAction) => async (
  dispatch
) => {
  let updated = await API.updateSelectedTrueCreativePropsFunc(uid, sourceCode);
  if (updated) {
    await API.getTruengageCreativePropsByID(uid).then(
      (data) => {
        if (data != null) {
          updateTruengageCreativeRedux(dispatch, data);
          let obj = "behaviour";
          dispatch(saveTrueEngageCreativeResource(behaviourAction ? obj : updated));
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
};

/**
 * to update Ad Dimensions in source code
 * @param {*} uid
 * @param {*} inputType
 * @param {*} value
 * @param {*} sourceCode
 */
export const updateSelectedTrueCreativeDimensionFunc = (
  uid,
  inputType,
  value,
  sourceCode
) => async (dispatch) => {
  sourceCode[inputType] = value;
  let updated = await API.updateSelectedTrueCreativePropsFunc(uid, sourceCode);
  if (updated) {
    await API.getTruengageCreativePropsByID(uid).then(
      (data) => {
        if (data != null) {
          updateTruengageCreativeRedux(dispatch, data);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
};

/**
 * to update State Duration in Source code
 * @param {*} uid
 * @param {*} sourceCode
 */
export const updateSelectedTrueCreativeStateDurationFunc = (uid, sourceCode) => async (
  dispatch
) => {
  let updated = await API.updateSelectedTrueCreativePropsFunc(uid, sourceCode);
  if (updated) {
    await API.getTruengageCreativePropsByID(uid).then(
      (data) => {
        if (data != null) {
          updateTruengageCreativeRedux(dispatch, data);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
};

/**
 * to load all truengage Creatives by Video line Id
 * @param {*} lineId
 */
export const loadTruengageCreativesByLineId = (lineId) => async (dispatch) => {
  try {
    dispatch(beginApiCall());
    await API.loadTruengageCreativesByLineId(lineId).then(
      (data) => {
        if (data != null) {
          dispatch(setTruengageCreatives(data));
        } else {
          dispatch(setTruengageCreativeErrorMessage());
        }
      },
      (error) => {
        console.log(error);
      }
    );
  } catch (err) {
    dispatch(apiCallError());
  }
};

/**
 * to load all truengage Creatives by Video line Id
 * @param {*} lineId
 */
export const loadTruengageCreativePropsByLineId = (lineId) => async (dispatch) => {
  try {
    dispatch(beginApiCall());
    await API.loadTruengageCreativePropsByLineId(lineId).then(
      (data) => {
        if (data != null) {
          dispatch(setTruengageCreatives(data));
        } else {
          dispatch(setTruengageCreativeErrorMessage());
        }
      },
      (error) => {
        console.log(error);
      }
    );
  } catch (err) {
    dispatch(apiCallError());
  }
};

/**
 * Load Truengage Creative props by UID
 * @param {*} id
 */
export const getTruengageCreativePropsByID = (id) => async (dispatch) => {
  try {
    dispatch(beginApiCall());
    await API.getTruengageCreativePropsByID(id).then(
      (data) => {
        if (data != null) {
          updateTruengageCreativeRedux(dispatch, data);
        } else {
        }
      },
      (error) => {
        console.log(error);
      }
    );
  } catch (err) {
    dispatch(apiCallError());
  }
};

/**
 * Save Truengage Creative props
 * @param {*} videoLineId
 * @param {*} creativeObj
 */
export const saveTruengageCreativeProps = (videoLineId, creativeObj) => async (dispatch) => {
  try {
    dispatch(beginApiCall());
    const saved = await API.saveTruengageCreativeProps(creativeObj);
    if (saved) {
      await API.loadTruengageCreativePropsByLineId(videoLineId).then(
        (data) => {
          if (data != null) {
            dispatch(setTruengageCreatives(data));
          } else {
            dispatch(setTruengageCreativeErrorMessage());
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
  } catch (err) {
    dispatch(apiCallError());
  }
};

/**
 * to update truengage creative props
 * @param {*} videoLineId
 * @param {*} editTruengageCreativeId
 * @param {*} creativeObj
 */
export const updateTruengageCreativeProps = (
  videoLineId,
  editTruengageCreativeId,
  creativeObj
) => async (dispatch) => {
  try {
    dispatch(beginApiCall());
    const isUpdated = await API.updateTruengageCreativeProps(editTruengageCreativeId, creativeObj);
    if (isUpdated) {
      await API.loadTruengageCreativePropsByLineId(videoLineId).then(
        (data) => {
          if (data != null) {
            dispatch(setTruengageCreatives(data));
          } else {
            dispatch(setTruengageCreativeErrorMessage());
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
  } catch (err) {
    dispatch(apiCallError());
  }
};

const clearCreativesList = () => {
  return {
    type: Types.CLEAR_CREATIVES_LIST,
    payload: undefined,
  };
};

export const clearCreatives = () => async (dispatch) => {
  dispatch(clearCreativesList());
};

/**
 * to update truengage creative state in redux
 * @param {*} dispatch
 * @param {*} data
 */
function updateTruengageCreativeRedux(dispatch, data) {
  var sourceCode = undefined;
  var creativeName = undefined;
  var originalResponse = undefined;
  var creativeAccountId = undefined;
  var isCpcBannerEnabled = false;
  var truengageCreativeType = "Draggable";
  var gameObjectArray = [];
  if (data["creativeAccountId"]) {
    creativeAccountId = data["creativeAccountId"];
    delete data["creativeAccountId"];
  }
  if (data["gameObjectArray"]) {
    gameObjectArray = data["gameObjectArray"];
    delete data["gameObjectArray"];
  }
  if (data["source"]) {
    sourceCode = data["source"];
    delete data["source"];
  }
  if (data["isCpcBannerEnabled"]) {
    isCpcBannerEnabled = data["isCpcBannerEnabled"];
    delete data["isCpcBannerEnabled"];
  }
  if (data["name"]) {
    creativeName = data["name"];
    delete data["name"];
  }
  if (data["originalResponse"]) {
    originalResponse = data["originalResponse"];
    delete data["originalResponse"];
  }
  if (data["truengageCreativeType"]) {
    truengageCreativeType = data["truengageCreativeType"];
    delete data["truengageCreativeType"];
  }
  dispatch(
    setTruengageCreative(
      data,
      sourceCode,
      creativeName,
      originalResponse,
      creativeAccountId,
      gameObjectArray,
      isCpcBannerEnabled,
      truengageCreativeType
    )
  );
}

export const clearCreativeState = () => async (dispatch) => {
  dispatch(clearSelectedCreative());
};

/**
 * load all jynx versions
 */
export const loadJynxVersions = () => async (dispatch) => {
  try {
    dispatch(beginApiCall());
    await jynxVersionAPI.loadJynxVersions().then(
      (data) => {
        if (data != null) {
          dispatch(setJynxVersions(data));
        }
      },
      (error) => {
        console.log(error);
      }
    );
  } catch (err) {
    dispatch(apiCallError());
  }
};

export const publishCreative = (creativeObj, key) => async (dispatch) => {
  try {
    dispatch(beginApiCall());
    creativeObj["recordId"] = key;
    const response = await API.publisherTruengageCreative(creativeObj);
    if (response != null) {
      if (response && !response.success && response.message.indexOf("Unauthorized") !== -1) {
        dispatch(updateLoaderState(false));
        authService.removeLocalStorageProps();
        return;
      } else if (response && !response.success) {
        dispatch(setPublishCreativeResponse(response.message));
        dispatch(updateLoaderState(false));
        return;
      } else {
        await API.loadTruengageCreativePropsByLineId(creativeObj.video_line).then(
          (data) => {
            if (data != null) {
              console.log(data);
              dispatch(updateLoaderState(false));
              dispatch(setPublishCreativeResponse(response.message));
              dispatch(setTruengageCreatives(data));
            } else {
              dispatch(updateLoaderState(false));
              dispatch(setTruengageCreativeErrorMessage());
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }
    } else {
    }
  } catch (err) {
    dispatch(apiCallError());
  }
};