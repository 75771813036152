import firebase from "./../config/firebase";
const db = firebase.database();
const setLocalStorageProps = (response) => {
  return new Promise((resolve) => {
    if (response && response.user) {
      var authToken = response.user.ya;
      localStorage.setItem("access_token", authToken);
      localStorage.setItem("uid", response.user.uid);
      resolve(true);
    } else {
      resolve(false);
    }
  });
};

const removeLocalStorageProps = () => {
  return new Promise((resolve) => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("uid");
    resolve(true);
  });
};

const isUserLoggedIn = () => {
  return new Promise((resolve) => {
    if (localStorage.access_token && localStorage.uid) {
      resolve(true);
    } else {
      resolve(false);
    }
  });
};

const isAdminUser = (uid) => {
  return new Promise((resolve) => {
    if(uid !== undefined){
      db.ref("users/" + uid).once("value", (querySnapShot) => {
        if (!querySnapShot.err) {
          resolve(querySnapShot.val().role === "admin" ? true : false);
        } else {
          resolve(false);
        }
      });
    } else {
      resolve(false);
    }
  });
};

export default {
  setLocalStorageProps,
  removeLocalStorageProps,
  isUserLoggedIn,
  isAdminUser,
};
