import firebase from "./../config/firebase";
import * as R from "ramda";
import AuthService from "./authService";
import { PUBLISH_CREATIVE_API_URL } from "./../constants/defaultValues";
const firestoreDB = firebase.firestore();

const updateSelectedTrueCreativePropsFunc = (uid, sourceCode) => {
  return new Promise((resolve) => {
    firestoreDB
      .collection("truengage_creative_props")
      .doc(uid)
      .update({
        source: JSON.stringify(sourceCode),
        updated_at: new Date().getTime(),
        updated_by: localStorage.getItem("uid"),
      })
      .then((docRef) => {
        resolve(true);
      })
      .catch((error) => {
        resolve(false);
      });
  });
};

const loadTruengageCreativePropsByLineId = (lineId) => {
  var response = {};
  return new Promise((resolve) => {
    firestoreDB
      .collection("truengage_creative_props")
      .where("video_line", "==", lineId)
      .get()
      .then((snapshot) => {
        snapshot.docs.forEach((doc) => {
          response[doc.id] = doc.data();
          resolve(response);
        });
        resolve(undefined);
      });
  });
};

const saveTruengageCreativeProps = (obj) => {
  return new Promise((resolve) => {
    firestoreDB
      .collection("truengage_creative_props")
      .add(obj)
      .then((docRef) => {
        resolve(true);
      })
      .catch((error) => {
        console.log(error);
        resolve(false);
      });
  });
};

const updateTruengageCreativeProps = (uid, creativeObject) => {
  return new Promise((resolve) => {
    firestoreDB
      .collection("truengage_creative_props")
      .doc(uid)
      .update(creativeObject)
      .then((docRef) => {
        resolve(true);
      })
      .catch((error) => {
        resolve(false);
      });
  });
};

const getTruengageCreativePropsByID = (id) => {
  const response = undefined;
  return new Promise((resolve) => {
    firestoreDB
      .collection("truengage_creative_props")
      .doc(id)
      .get()
      .then((snapshot) => {
        var result = snapshot.data();
        var creativeAccountId = result.account;
        var truengageCreativeType = result.type ? result.type : "Draggable";
        var selectedCreative = result;
        var creativeName = selectedCreative.name;
        var isCpcBanner = selectedCreative.is_cpc_banner ? selectedCreative.is_cpc_banner : false;
        var sourceCode = {};
        Object.assign(sourceCode, JSON.parse(selectedCreative.source));
        var dragableModel = loadTruengageCreativeModel({ ...sourceCode }, truengageCreativeType);
        dragableModel["creativeAccountId"] = creativeAccountId;
        dragableModel["truengageCreativeType"] = truengageCreativeType;
        dragableModel["name"] = creativeName;
        dragableModel["isCpcBannerEnabled"] = isCpcBanner;
        dragableModel["originalResponse"] = selectedCreative;
        resolve(dragableModel);
      })
      .catch((err) => {
        resolve(response);
      });
  });
};

const loadTruengageCreativeModel = (sourceCode, truengageCreativeType) => {
  var dragableModel = {};
  dragableModel["source"] = sourceCode;
  Object.assign(dragableModel, R.pick(["width"], sourceCode));
  Object.assign(dragableModel, R.pick(["height"], sourceCode));
  var sceneArray = [];
  var scenes = R.path(["scenes"], sourceCode);
  var gameNameArray = [];
  scenes.forEach(function (scene) {
    var sceneModel = {};
    if (R.path(["cpcBanner"], scene)) {
      Object.assign(dragableModel, R.pick(["cpcBanner"], scene));
    }
    var objects = R.path(["objects"], scene);
    var gameObjects = {};
    gameObjects["objects"] = filterGameObjects(
      objects !== undefined ? objects : {},
      gameNameArray,
      R.path(["layers"], scene),
      truengageCreativeType
    );
    Object.assign(sceneModel, gameObjects);
    Object.assign(sceneModel, R.pick(["layers"], scene));
    Object.assign(sceneModel, R.pick(["duration"], scene));
    Object.assign(sceneModel, R.pick(["transitions"], scene));
    sceneArray.push(sceneModel);
  });
  var sceneObj = {};
  sceneObj["scenes"] = sceneArray;
  Object.assign(dragableModel, sceneObj);
  dragableModel["gameObjectArray"] = gameNameArray;
  return dragableModel;
};

/**
 * to filter all gameObjects from Source Code
 * @param {} objects
 */
const filterGameObjects = (objects, gameNameArray, layerArray, truengageCreativeType) => {
  var layerObjects = [];
  layerArray.forEach(function (layer) {
    layer.objects.forEach(function (obj) {
      layerObjects.push(obj);
    });
  });
  var sceneGameObjectArray = [];
  var gameObjects = {};
  var nonInteractiveObjects = {};
  var dragableObjects = {};
  var maskableObjects = {};
  var collectableObjects = {};
  var drawableObjects = {};
  var binObjects = {};
  var launchableObjects = {};
  for (let [key, value] of Object.entries(objects)) {
    if (layerObjects.includes(key)) {
      if (R.path(["behaviors", "draggable", "snapback"], value)) {
        value = setObjectType(value);
        dragableObjects[key] = value;
      } else if (R.path(["behaviors", "launchable"], value)) {
        value = setObjectType(value);
        launchableObjects[key] = value;
      } else if (R.path(["behaviors", "collectable"], value)) {
        value = setObjectType(value);
        collectableObjects[key] = value;
      } else if (R.path(["behaviors", "drawable"], value)) {
        value = setObjectType(value);
        drawableObjects[key] = value;
      } else if (R.path(["behaviors", "bin"], value)) {
        value = setObjectType(value);
        binObjects[key] = value;
      } else {
        value = setObjectType(value);
        nonInteractiveObjects[key] = value;
      }
    } else {
      if (R.path(["behaviors", "draggable", "snapback"], value)) {
        value = setObjectType(value);
        dragableObjects[key] = value;
      } else if (R.path(["behaviors", "launchable"], value)) {
        value = setObjectType(value);
        launchableObjects[key] = value;
      } else if (R.path(["behaviors", "bin"], value)) {
        value = setObjectType(value);
        binObjects[key] = value;
      } else if (R.path(["behaviors", "collectable"], value)) {
        value = setObjectType(value);
        collectableObjects[key] = value;
      } else if (R.path(["behaviors", "drawable"], value)) {
        value = setObjectType(value);
        drawableObjects[key] = value;
      } else {
        value = setObjectType(value);
        maskableObjects[key] = value;
      }
    }
    sceneGameObjectArray.push(key);
  }
  gameNameArray.push(sceneGameObjectArray);
  gameObjects["nonInteractiveObjects"] = nonInteractiveObjects;
  if (truengageCreativeType === "Draggable") {
    gameObjects["dragableObjects"] = dragableObjects;
    gameObjects["binObjects"] = binObjects;
    gameObjects["maskableObjects"] = maskableObjects;
  } else if (truengageCreativeType === "Launchable") {
    gameObjects["launchableObjects"] = launchableObjects;
    gameObjects["binObjects"] = binObjects;
  } else if (truengageCreativeType === "Collectable") {
    gameObjects["collectableObjects"] = collectableObjects;
  } else if (truengageCreativeType === "Drawing") {
    gameObjects["drawableObjects"] = drawableObjects;
  }
  return gameObjects;
};

const setObjectType = (value) => {
  if (R.path(["container"], value)) {
    value["objectType"] = "Container";
  } else if (R.path(["spritesheet"], value)) {
    value["objectType"] = "Spritesheet";
  } else if (R.path(["image"], value)) {
    value["objectType"] = "Image";
  } else if (R.path(["text"], value)) {
    value["objectType"] = "Text";
  } else if (R.path(["shape"], value)) {
    value["objectType"] = "Shape";
  }
  return value;
};

const publisherTruengageCreative = (requestBody) => {
  return new Promise((resolve) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authtoken: localStorage.getItem("access_token"),
      },
      body: JSON.stringify(requestBody),
    };
    fetch(PUBLISH_CREATIVE_API_URL, requestOptions)
      .then(handleResponse, handleError)
      .then((response) => {
        resolve(true);
      })
      .error((err) => {
        console.log("err " + err);
        resolve(false);
      });
  });
};

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        console.log("Unauthorized User");
        AuthService.removeLocalStorageProps();
        alert("Unauthorized User! Your access token has been expired");
        window.location.reload();
      }
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}

function handleError(error) {
  return Promise.reject(error && error.message);
}

export default {
  loadTruengageCreativePropsByLineId,
  saveTruengageCreativeProps,
  updateTruengageCreativeProps,
  getTruengageCreativePropsByID,
  updateSelectedTrueCreativePropsFunc,
  publisherTruengageCreative,
};
