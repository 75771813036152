import firebase from "./../config/firebase";
const db = firebase.database();

const loadXPSCampaignsByAccountId = (accountId) => {
  const response = undefined;
  return new Promise((resolve) => {
    db.ref("campaigns")
      .orderByChild("account")
      .equalTo(accountId)
      .once("value")
      .then(function (querySnapShot) {
        if (!querySnapShot.err) {
          resolve(querySnapShot.val());
        } else {
          resolve(response);
        }
      }).catch((err) => {
        console.log(`error `+ err);
      });
  });
};

const getSelectedCampaignDetail = (campaignId) => {
  const response = undefined;
  return new Promise((resolve) => {
    db.ref("campaigns/" + campaignId).once("value", (querySnapShot) => {
      if (!querySnapShot.err) {
        resolve(querySnapShot.val());
      } else {
        resolve(response);
      }
    });
  });
};

export default {
  loadXPSCampaignsByAccountId,
  getSelectedCampaignDetail,
};
