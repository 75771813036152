import React, { useEffect } from "react";
import { connect } from "react-redux";
import Loader from "./loader";
import requireAuth from "./hoc/requireAuth";
import { compose } from "redux";
import {
  openEditTruengageCreativeModal,
  openViewTruengageCreativeModal,
  loadTruengageCreativePropsByLineId,
  clearCreativeState,
  loadJynxVersions,
  clearCreatives,
  publishCreative,
} from "../store/actions/truengageCreatives";
import {
  updateLoaderState,
} from "../store/actions/gameObjects";
import { useDispatch } from "react-redux";
import EditCreativeModal from "../components/modals/editCreativeModal";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import ViewCreativeModal from "../components/modals/viewCreativeSourceModal";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { getSelectedVideolineDetail } from "../store/actions/videolines";
import { ReactComponent as EditIcon } from "../images/edit.svg";
import { ReactComponent as ViewIcon } from "../images/view.svg";
import { ReactComponent as CopyIcon } from "../images/copy.svg";
import { ReactComponent as CloudIcon } from "../images/cloudicon.svg";
import ReactTooltip from "react-tooltip";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
const TruengageCreativeList = ({
  truengageCreatives,
  truengageCreativeErrorMessage,
  selectedVideoline,
  selectedVideoLineVersion,
  loadJynxData,
  publishCreativeResponse
}) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadTruengageCreativePropsByLineId(id));
    dispatch(getSelectedVideolineDetail(id));
    loadJynxData();
    return () => {
      dispatch(clearCreatives());
    };
  }, []);

  const publishCreativeConfirmation = (creativeObj, key) => {
    confirmAlert({
      title: "Are you sure to Publish your truengage to XPS System?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            dispatch(updateLoaderState(true));
            dispatch(publishCreative(creativeObj, key));
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  // to list out Truengage Creatives
  function _bindTruengageCreatives(truengageCreatives) {
    return Object.keys(truengageCreatives).map((item, index) => {
      return (
        <tr key={index}>
          <td>
            <span
              className="cursor-pointer"
              onClick={() =>
                dispatch(openEditTruengageCreativeModal(item, truengageCreatives[item]))
              }
            >
              {truengageCreatives[item].name.length > 20
                ? truengageCreatives[item].name.substring(0, 20) + "..."
                : truengageCreatives[item].name}
            </span>
          </td>

          <td>{truengageCreatives[item].type}</td>
          <td>
            <span>{truengageCreatives[item].duration}</span>
          </td>
          <td>
            <span>{"v" + truengageCreatives[item].engine_version}</span>
          </td>
          <td>
            <div className="action-btns-group">
              <Link
                to={`/edit/truengage-creative/${item}?version=${truengageCreatives[item].engine_version}`}
                className="action-btn"
              >
                <span data-tip data-for="editTip">
                  <EditIcon />
                </span>
              </Link>
              <CopyToClipboard
                text={truengageCreatives[item].source}
                onCopy={() => console.log(`copied`)}
              >
                <button className="action-btn" data-tip data-for="copyTip">
                  <CopyIcon />
                </button>
              </CopyToClipboard>
              <button
                data-tip
                data-for="viewTip"
                className="action-btn"
                onClick={() =>
                  dispatch(openViewTruengageCreativeModal(truengageCreatives[item].source))
                }
              >
                <ViewIcon />
              </button>
              <button
                data-tip
                data-for="publishTip"
                className="action-btn"
                onClick={() => publishCreativeConfirmation(truengageCreatives[item], item)}
              >
                <CloudIcon />
              </button>
            </div>
          </td>
        </tr>
      );
    });
  }

  return (
    <div className="page">
      {selectedVideoline && selectedVideoline.campaign ? (
        <nav aria-label="breadcrumb" className="page-breadcrumb">
          <ol className="breadcrumb">
            <li key={"account"} className="breadcrumb-item">
              <Link to={"/"}>
                <span>Accounts</span>
              </Link>
            </li>
            <li key={"campign"} className="breadcrumb-item">
              <Link to={`/campaigns/${selectedVideoline.account}`}>
                <span>Campaign</span>
              </Link>
            </li>
            <li key={"videoline"} className="breadcrumb-item" aria-current="page">
              <Link to={`/video-line/${selectedVideoline.campaign}`}>
                <span>Videoline</span>
              </Link>
            </li>
          </ol>
        </nav>
      ) : (
        ""
      )}
      <div className="row page-header">
        <div className="col">
          <h3>{selectedVideoLineVersion && selectedVideoLineVersion.name}</h3>
        </div>
        <button
          className="btn btn-primary btn-switch"
          onClick={() => dispatch(openEditTruengageCreativeModal(undefined, undefined))}
        >
          Add New Creative Unit
        </button>
      </div>
      {truengageCreatives && Object.keys(truengageCreatives).length > 0 ? (
        <div className="userListTable">
          <ReactTooltip id="editTip" place="top" effect="solid">
            Edit Truengage Creative Source Attributes
          </ReactTooltip>
          <ReactTooltip id="copyTip" place="top" effect="solid">
            Copy Source Code
          </ReactTooltip>
          <ReactTooltip id="viewTip" place="top" effect="solid">
            View Source Code
          </ReactTooltip>
          <ReactTooltip id="publishTip" place="top" effect="solid">
            Publish Truengage Creative to XPS System
          </ReactTooltip>
          <div className="responsive-table">
            <table cellPadding="0" cellSpacing="0" border="1">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Type</th>
                  <th>Duration(in seconds)</th>
                  <th>Game Engine Version</th>
                  <th className="action-col">Quick Actions</th>
                </tr>
              </thead>
              <tbody>{_bindTruengageCreatives(truengageCreatives)}</tbody>
            </table>
          </div>
        </div>
      ) : (
        [
          truengageCreativeErrorMessage && truengageCreativeErrorMessage !== "" ? (
            <div className="form-group">
              <div className="row property-section">
                <div className="form-group col-md-12 col-sm-12">
                  <label>{truengageCreativeErrorMessage}</label>
                </div>
              </div>
            </div>
          ) : (
            <Loader />
          ),
          "",
        ]
      )}

      <EditCreativeModal videoLineId={id} />
      <ViewCreativeModal />
    </div>
  );
};

function mapStateToProps(state) {
  return {
    truengageCreatives: state.truengageCreative.truengageCreatives,
    truengageCreativeErrorMessage: state.truengageCreative.truengageCreativeErrorMessage,
    publishCreativeResponse: state.truengageCreative.publishCreativeResponse,
    selectedVideoline: state.videoline.selectedVideoline,
    selectedVideoLineVersion: state.videoline.selectedVideoLineVersion,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    openEditTruengageCreativeModal: () => dispatch(openEditTruengageCreativeModal()),
    loadTruengageCreativePropsByLineId: (id) => dispatch(loadTruengageCreativePropsByLineId(id)),
    openViewTruengageCreativeModal: (source) => dispatch(openViewTruengageCreativeModal(source)),
    getSelectedVideolineDetail: (id) => dispatch(getSelectedVideolineDetail(id)),
    clearCreativeState: () => dispatch(clearCreativeState()),
    loadJynxData: () => dispatch(loadJynxVersions()),
    publishCreative: (creativeObj) => dispatch(publishCreative(creativeObj)),
  };
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  requireAuth
)(TruengageCreativeList);
